import React, { useEffect, useState } from 'react';
import {
  Paper,
  Stack,
  Typography,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  Button,
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import RefreshIcon from '@mui/icons-material/Refresh';
import Datatable from '../Datatable/Datatable';
import { useDispatch, useSelector } from 'react-redux';
import { messageData } from './dummy';
import { deliveryData } from './dummy1';
import { getNotificationHistoryData, refreshHistoryData, sendNotificationData } from "../../redux/features/notificationSlice"
import { useNavigate } from 'react-router';
import moment from "moment-timezone";


function NotificationHistory() {
  const dispatch = useDispatch();
  const { notificationHistoryData, refreshHisData } = useSelector((state) => ({ ...state.notification }));
  const history = useNavigate();


  const timeConverter = (UNIX_timestamp) => {
    var m = moment.tz(UNIX_timestamp, 'Asia/Kolkata').format('YYYY-MM-DD HH:MM')
    // console.log(m);
    return m;
  }
  const refreshCol = (history, tableData) => {
    // console.log("tableData", tableData)
    dispatch(refreshHistoryData(tableData[1]))
  }

  const handleResend = (history, tableData) => {
    let data = {};
    if (tableData[2] === "flipshope extension") {
      data.type = "flipshope";
    } else {
      data.type = "amazon"
    }
    data.title = tableData[3];
    data.link = tableData[4];
    data.description = tableData[5];
    data.time = tableData[9];
    console.log(tableData, "tableData", data);
    // dispatch(sendNotificationData(data));
    history("/notification/send");
  }

  // head cells message
  const messageHead = [
    {
      name: 'action',
      label: 'Action',
      options: {
        customBodyRender: (action, tableMeta, updateValue) => {
          return (
            <Button variant="text" endIcon={<SendIcon size="small" />} sx={{ textTransform: " initial" }} onClick={() => handleResend(history, tableMeta.rowData)}><b>{action}</b></Button>
          )
        },
        filter: true,
        sort: true,
      }
    },
    {
      name: 'id',
      label: 'Parent ID',
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: 'mode',
      label: 'Mode',
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: 'title',
      label: 'Title',
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: 'link',
      label: 'Link',
      options: {
        customBodyRender: (link) => {
          return (
            <a href={link} target="_blank" rel="noreferrer">{link}</a>
          )
        },
        filter: true,
        sort: true,
      }
    },
    {
      name: 'description',
      label: 'Description',
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: 'image',
      label: 'Image Link',
      options: {
        customBodyRender: (row_link) => {
          return (
            <a href={row_link} target="_blank" rel="noreferrer">{row_link}</a>
          )
        },
        filter: true,
        sort: true,
      }
    },
    {
      name: 'sent_users',
      label: 'Sent User Count',
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: 'skip_users',
      label: 'Skip User Count',
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: 'alivetime',
      label: 'Alive Time',
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: 'created',
      label: 'Date',
      options: {
        customBodyRender: (data) => <p>{timeConverter(data)}</p>,
        filter: true,

        sort: true,
      }
    }
  ];
  // headcells delivery
  const deliveryHead = [
    {
      name: 'action',
      label: 'Action',
      options: {
        customBodyRender: (action, tableMeta, updateValue) => {
          return (
            <Button variant="text" startIcon={<RefreshIcon size="small" />} sx={{ textTransform: " initial" }} onClick={() => refreshCol(history, tableMeta.rowData)} ><b>{action}</b></Button>
          )
        },
        filter: true,
        sort: true,
      }
    },
    {
      name: 'parent_id',
      label: 'Parent ID',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'sent_to',
      label: 'Send To',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'successful',
      label: 'Successful',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'failed',
      label: 'Failed',
      options: {
        filter: true,
        sort: true,
      },
    },

  ];

  const [type, setType] = useState("message");
  const [page, setPage] = useState(1);

  useEffect(() => {
    dispatch(getNotificationHistoryData({ type, page }));
    // dispatch(refreshHistoryData({ type, page }));
  }, [type, page])
  // console.log(notificationHistoryData, "notificationHistoryData")
  return (
    <Paper elevation={5}>
      <Stack
        direction="row"
        spacing={3}
        p={4}
        // justifyContent="center"
        alignItems="center"
      >
        <Typography variant="h6">
          <b>Select Type :</b>
        </Typography>
        <FormControl>
          {/* Radio Button Group to toggle between Flipshope Users and Amazon Users*/}
          <RadioGroup
            name="notify-hist-radio-buttons-group"
            row
            value={type}
            onChange={(event) => { setType(event.target.value) }}
          >
            <FormControlLabel value="message" control={<Radio />} label="message" />
            <FormControlLabel value="delivery" control={<Radio />} label="delivery" />
          </RadioGroup>
        </FormControl>
      </Stack>

      {(type === "message"
        ? <Datatable name="Message History Table" columns={messageHead} data={notificationHistoryData && notificationHistoryData} />
        : <Datatable name="Message Delivery Table" columns={deliveryHead} data={notificationHistoryData && notificationHistoryData} />)}
    </Paper>
  );
}

export default NotificationHistory;
import { Toolbar, Paper, Stack, Box, Typography } from '@mui/material';
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getFlashSaleProductsData } from '../../../redux/features/salesSlice';
import DateChangeComponent from "./dateChangeComponent"
const ChangeSaleDate = () => {
    const { flashsaleProductsData } = useSelector((state) => ({ ...state.sales }));

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getFlashSaleProductsData());
    }, [])
    return (
        <Stack spacing={3}>
            <Paper elevation={3}>
                <Toolbar
                    sx={{ padding: "1.2rem", borderBottom: "2px solid var(--primary-color)" }}
                >
                    <Typography
                        sx={{
                            color: "var(--primary-color)",
                            fontWeight: "bold",
                        }}
                        variant="h5"
                    >
                        Change Sale Date
                    </Typography>
                </Toolbar>
                {flashsaleProductsData && flashsaleProductsData.map(productData =>
                    <DateChangeComponent flashsaleproductData={productData} />)}
            </Paper>
        </Stack>
    )
}

export default ChangeSaleDate
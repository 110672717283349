import React from 'react'
import { NavLink } from 'react-router-dom';
import Datatable from '../../Datatable/Datatable'
import { Button } from '@mui/material'; 
import AddBoxIcon from '@mui/icons-material/AddBox';

function ShowWebsiteImage() {
    const columns = [
        {
            name: 'type_id',
            label: 'Type ID',
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: 'image_type',
            label: 'Image Type',
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: 'title',
            label: 'Title',
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: 'alt_text',
            label: 'Alt Text',
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: 'img_url',
            label: 'Img URL',
            options: {
                customBodyRender: (link) => {
                    return (
                        <a href={link} target="_blank" rel="noreferrer">{link}</a>
                    )
                },
                filter: true,
                sort: true,
            }
        },
        {
            name: 'width',
            label: 'Width',
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: 'height',
            label: 'Height',
            options: {
                filter: true,
                sort: true
            }
        }
    ];

    const data = [
        {
            type_id: 'flipshope image',
            image_type: 'temporary data',
            store_name: 'amazon',
            title: 'temporary title',
            alt_text: 'temporary text',
            img_url: 'https://www.amazon.in',
            width: '140px',
            height: '200px',
        },
        {
            type_id: 'flipshope image',
            image_type: 'temporary data',
            store_name: 'amazon',
            title: 'temporary title',
            alt_text: 'temporary text',
            img_url: 'https://www.amazon.in',
            width: '140px',
            height: '200px',
        },
        {
            type_id: 'flipshope image',
            image_type: 'temporary data',
            store_name: 'amazon',
            title: 'temporary title',
            alt_text: 'temporary text',
            img_url: 'https://www.amazon.in',
            width: '140px',
            height: '200px',
        },
        {
            type_id: 'flipshope image',
            image_type: 'temporary data',
            store_name: 'amazon',
            title: 'temporary title',
            alt_text: 'temporary text',
            img_url: 'https://www.amazon.in',
            width: '140px',
            height: '200px',
        },
        {
            type_id: 'flipshope image',
            image_type: 'temporary data',
            store_name: 'amazon',
            title: 'temporary title',
            alt_text: 'temporary text',
            img_url: 'https://www.amazon.in',
            width: '140px',
            height: '200px',
        },
        {
            type_id: 'flipshope image',
            image_type: 'temporary data',
            store_name: 'amazon',
            title: 'temporary title',
            alt_text: 'temporary text',
            img_url: 'https://www.amazon.in',
            width: '140px',
            height: '200px',
        },
        {
            type_id: 'flipshope image',
            image_type: 'temporary data',
            store_name: 'amazon',
            title: 'temporary title',
            alt_text: 'temporary text',
            img_url: 'https://www.amazon.in',
            width: '140px',
            height: '200px',
        },
        {
            type_id: 'flipshope images',
            image_type: 'temporary data',
            store_name: 'amazon',
            title: 'temporary title',
            alt_text: 'temporary text',
            img_url: 'https://www.amazon.in',
            width: '140px',
            height: '200px',
        },
        {
            type_id: 'flipshope image',
            image_type: 'temporary data',
            store_name: 'amazon',
            title: 'temporary title',
            alt_text: 'temporary text',
            img_url: 'https://www.amazon.in',
            width: '140px',
            height: '200px',
        },
    ];
    return (
        <div>
            <NavLink to="/websitedata/add-website-image">
                <Button variant="contained" color="success" startIcon={<AddBoxIcon />} sx={{ marginBottom: "15px" }}>
                    <strong>Add New Image</strong>
                </Button>
            </NavLink>
            <Datatable
                name="Website Image Data"
                columns={columns}
                data={data}
            />
        </div>
    )
}

export default ShowWebsiteImage
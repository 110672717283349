import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import * as jobPostApi from "../../api/websiteAPI/jobPostApi";

// Initial State
const initialState = {
  jobPostData: [],
  jobCategoryData: [],
  loading: false,
  error: "",
  success: "",
};
//============================== getJobPost ==============================//
export const getJobPostingData = createAsyncThunk(
  "/jobPosting/getJobPostingData",
  async (_, { rejectWithValue }) => {
    try {
      const resp = await jobPostApi.getJobPost();
      if (resp.data.code === 200) {
        // message.success(resp.data.success);
        return resp.data.data;
      } else {
        console.log(resp.data.message);
        return rejectWithValue(resp.data.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

//============================== addJobPost ==============================//
export const addJobPosting = createAsyncThunk(
  "/jobPosting/addJobPosting",
  async (formData, { rejectWithValue }) => {
    try {
      const resp = await jobPostApi.addJobPost(formData);
      if (resp.data.code === 200) {
        message.success(
          resp.data.message ? resp.data.message : "Job Posted successfully"
        );
        return resp.data.data;
      } else {
        message.error(
          resp.data.message ? resp.data.message : "Something went wrong"
        );
        return rejectWithValue(resp.data.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

//============================== addJobPost ==============================//
export const activeJobPosting = createAsyncThunk(
  "/jobPosting/activeJobPosting",
  async (formData, { rejectWithValue }) => {
    try {
      const resp = await jobPostApi.activeJobPost(formData);
      if (resp.data.code === 200) {
        message.success(
          resp.data.message
            ? resp.data.message
            : "Action performed successfully"
        );
        return resp.data.data;
      } else {
        message.error(
          resp.data.message ? resp.data.message : "Something went wrong"
        );
        return rejectWithValue(resp.data.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

//============================== getJobPost ==============================//
export const getJobPostCategoryData = createAsyncThunk(
  "/jobPosting/getalljobcategory",
  async (_, { rejectWithValue }) => {
    try {
      const resp = await jobPostApi.getJobPostCategoryDataApi();
      if (resp.data.code === 200) {
        // message.success(resp.data.success);
        return resp.data.data;
      } else {
        console.log(resp.data.message);
        return rejectWithValue(resp.data.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

// SLice
const JobPostingSlice = createSlice({
  name: "jobPosting",
  initialState,
  extraReducers: (builder) => {
    //=================================getJObPost Data===========================//
    builder.addCase(getJobPostingData.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getJobPostingData.fulfilled, (state, action) => {
      state.loading = false;
      state.jobPostData = action.payload;
    });
    builder.addCase(getJobPostingData.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    //=================================getJobPostCategoryData Data===========================//
    builder.addCase(getJobPostCategoryData.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getJobPostCategoryData.fulfilled, (state, action) => {
      state.loading = false;
      state.jobCategoryData = action.payload;
    });
    builder.addCase(getJobPostCategoryData.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    //=================================addJobPost Data===========================//
    builder.addCase(addJobPosting.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(addJobPosting.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(addJobPosting.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    //=================================activeJobPost Data===========================//
    builder.addCase(activeJobPosting.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(activeJobPosting.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(activeJobPosting.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export default JobPostingSlice.reducer;

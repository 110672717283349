/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import {
  Paper,
  Box,
  Stack,
  TextField,
  Typography,
  Toolbar,
  Button,
  LinearProgress,
} from "@mui/material";
import TravelExploreRoundedIcon from '@mui/icons-material/TravelExploreRounded';
import { useDispatch, useSelector } from "react-redux"
import { getProductByUrlData } from "../../redux/features/salesSlice";
import Flipkart from "./productSites/Flipkart";
import Amazon from "./productSites/Amazon";
import Mi from "./productSites/Mi";
import Realme from "./productSites/Realme";

function AddProductSale() {
  const dispatch = useDispatch()

  const { loading, productUrlData } = useSelector(state => state.sales)

  // product url
  const [url, setUrl] = useState("");
  const [site, setSite] = useState("");
  const [error, setError] = useState(false);

  const getSiteFromUrl = (link) => {
    let name = "";
    if (url.includes("www.") && url.includes(".com")) {
      name = url.split("www.")[1].split(".com")[0]
    }
    else if (url.includes("www.") && url.includes(".in")) {
      name = url.split("www.")[1].split(".in")[0]
    }
    else if (url.includes("buy.") && url.includes(".com")) {
      name = url.split("buy.")[1].split(".com")[0]
    }
    // console.log(name)
    setSite(name)
  }

  const addProductByUrl = (e) => {
    e.preventDefault()
    getSiteFromUrl(url)
    if (url[url.length - 1] === '/') setError(true)
    else dispatch(getProductByUrlData({ link: url }))
  }

  return (
    <Stack spacing={3}>
      <Paper elevation={3}>
        <Box>
          <Toolbar
            sx={{ padding: "1.2rem", borderBottom: "2px solid var(--primary-color)" }}
          >
            <Typography
              sx={{
                color: "var(--primary-color)",
                fontWeight: "bold",
              }}
              variant="h5"
            >
              Add Product to Sale Data
            </Typography>
          </Toolbar>
          <Stack spacing={1} justifyContent="center"
            alignItems="center" p={5}>
            <Typography variant="h6">
              <b>Enter Product URL below</b>
            </Typography>
            <form onSubmit={addProductByUrl} style={{ minWidth: "75%" }}>
              <Stack direction="row" spacing={1} width='100%' justifyContent='center'>
                <TextField
                  // size="small"
                  placeholder="Enter URL here...."
                  value={url}
                  onChange={(e) => { setUrl(e.target.value); setSite(""); setError(false) }}
                  sx={{ flex: 1 }}
                  error={error}
                />
                <Button
                  variant="outlined"
                  color="success"
                  typ="submit"
                  startIcon={<TravelExploreRoundedIcon />}
                  onClick={addProductByUrl}
                >
                  <strong>Fetch</strong>
                </Button>
              </Stack>
              {error && <Typography variant="subtitle1" mt={0.3} ml={1} fontSize='0.85rem' fontFamily="inherit" fontWeight={600} sx={{ color: 'var(--bs-danger)' }}>URL must not end with a slash ('/')</Typography>}
            </form>
          </Stack>
        </Box>
        {loading && <LinearProgress color="success" />}
      </Paper>
      {!loading && !error && <>
        {site === "flipkart" && <Flipkart site={site} productUrlData={productUrlData} />}
        {site === "amazon" && <Amazon site={site} productUrlData={productUrlData} />}
        {site === "mi" && <Mi site={site} productUrlData={productUrlData} />}
        {site === "realme" && <Realme site={site} productUrlData={productUrlData} />}
      </>}
    </Stack>
  )
}

export default AddProductSale;
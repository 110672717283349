import axios from "axios";
import AppConstants from "../../../constants/AppConstants";

const API = axios.create({ baseURL: AppConstants.baseURL });


///================================================banner offer images===========================================/////
export const getBannerOfferImages = (formData) => API.post("/webdata/getbannerofferimages", formData);
export const getLiveBannerOffers = (formData) => API.post("/webdata/livebanneroffers", formData);
export const updateManageBannersAndOffers = (formData) => API.post('/webdata/updatebanneroffer', formData);
export const addManageBannersAndOffers = (formData) => API.post('/webdata/addbanneroffer', formData);
export const deleteManageBannersAndOffers = (formData) => API.post('/webdata/deletebanneroffer', formData);

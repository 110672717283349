import React, { useState } from "react";
import {
  Paper,
  Stack,
  Table,
  TableCell,
  TableRow,
  TextField,
  Typography,
  tableCellClasses,
  Radio,
  RadioGroup,
  FormControlLabel,
  TableBody,
  Toolbar,
  Button,
  Autocomplete,
  TextareaAutosize,
} from "@mui/material";
import { ContentState, convertFromHTML, EditorState } from 'draft-js';
import { stateToHTML } from "draft-js-export-html";
import { Editor } from 'react-draft-wysiwyg';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import SaveIcon from '@mui/icons-material/Save';
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { useDispatch, useSelector } from "react-redux";
import { addNewCoupon, addScrapCouponData, updateCouponData } from "../../redux/features/couponsSlice";
import { useLocation } from "react-router";

export const nodesfromHtml = (html) => {
  const blocksFromHTML = convertFromHTML(html);
  const nodes = ContentState.createFromBlockArray(
    blocksFromHTML.contentBlocks,
    blocksFromHTML.entityMap
  );
  return EditorState.createWithContent(nodes);
}

function AddCoupon() {
  const dispatch = useDispatch();
  const { state } = useLocation()
  // Editor state
  const [editorState, setEditorState] = useState(state && state.couponData ? nodesfromHtml(state.couponData.details) : EditorState.createEmpty())
  // combo box data
  const { sites, categories } = useSelector(state => state.coupons && state.coupons.allCouponsData);

  // form type
  const [formType, setFormType] = useState("coupon");

  // description type
  const [descriptionType, setDescriptionType] = useState("text");

  // Coupon Data
  const [couponData, setCouponData] = useState({
    Coupon_ID: state && state.couponData && state.couponData.Coupon_ID ? state.couponData.Coupon_ID : null,
    site_id: state && state.couponData && state.couponData.site_id ? state.couponData.site_id : null,
    category_ids: "",
    store_name: state && state.couponData && state.couponData.store_name ? state.couponData.store_name : "",
    category_type: "",
    title: state && state.couponData && state.couponData.title ? state.couponData.title : "",
    short_title: state && state.couponData && state.couponData.short_title ? state.couponData.short_title : "",
    short_desc: state && state.couponData && state.couponData.short_desc ? state.couponData.short_desc : "",
    details: state && state.couponData && state.couponData.details ? state.couponData.details : "",
    coupon_id: state && state.couponData && state.couponData.coupon_id ? state.couponData.coupon_id : null,
    coupon_code: state && state.couponData && state.couponData.coupon_code ? state.couponData.coupon_code : "",
    deal_url: state && state.couponData && state.couponData.deal_url ? state.couponData.deal_url : "",
    reference: state && state.couponData && state.couponData.reference ? state.couponData.reference : "",
    promotion: state && state.couponData && state.couponData.promotion ? state.couponData.promotion : 0,
    weightage: state && state.couponData && state.couponData.weightage ? state.couponData.weightage : 0,
    expiry_date: state && state.couponData && state.couponData.expiry_date ? state.couponData.expiry_date : dayjs(),
    isVerified: state && state.couponData && state.couponData.isVerified ? state.couponData.isVerified : 1,
    isNewUser: 1,
  });

  // handle value changes
  const handleChange = (key) => (event) => {
    setCouponData({ ...couponData, [key]: event.target.value });
  };
  const handleStore = (val) => {
    for (let i = 0; i < sites.length; i++) {
      if (sites[i].site_name === val)
        setCouponData({ ...couponData, site_id: sites[i].site_id, store_name: sites[i].site_name });
    };
  }
  const handleCategory = (val) => {
    for (let i = 0; i < categories.length; i++) {
      if (categories[i].category === val)
        setCouponData({ ...couponData, category_ids: categories[i].category_id, category_type: categories[i].category });
    }
  };
  // Description type - html
  const handledetailsChange = event => {
    setCouponData({ ...couponData, [event.target.name]: event.target.value });
    const state = nodesfromHtml(event.target.value)
    setEditorState(state);
  };
  // Description type - text
  const handleEditorChange = editorState => {
    setEditorState(editorState)
    var html = stateToHTML(editorState.getCurrentContent());
    setCouponData({ ...couponData, details: html });
  }

  // handle form submit
  const handleSubmit = (event) => {
    event.preventDefault();
    if (state && state.couponData) {
      if (state?.from === "scrapCoupon") dispatch(addScrapCouponData(couponData))
      else dispatch(updateCouponData(couponData))
    }
    else dispatch(addNewCoupon(couponData))
    setCouponData({
      site_id: null,
      category_ids: null,
      store_name: "",
      category_type: "",
      title: "",
      short_title: "",
      details: "",
      coupon_id: null,
      coupon_code: "",
      deal_url: "",
      reference: "",
      promotion: 0,
      weightage: 0,
      expiry_date: dayjs(),
      isVerified: true,
      isNewUser: true,
      short_desc: "",
    });
    setEditorState(EditorState.createEmpty())
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Paper elevation={3}>
        <form
          onSubmit={handleSubmit}
        >
          <Stack spacing={3} direction="column">
            <Toolbar
              mt={3}
              sx={{ padding: "1.2rem", borderBottom: "2px solid var(--primary-color)" }}
            >
              <Typography
                sx={{
                  flex: "1 1 100%",
                  color: "var(--primary-color)",
                  fontWeight: "bold",
                }}
                variant="h5"
                id="tableTitle"
              >
                Add New Coupon
              </Typography>
              <Button variant="contained" type="submit" color="info" startIcon={<SaveIcon />} onClick={handleSubmit}>
                <strong>Save</strong>
              </Button>
            </Toolbar>
            <Table
              size="small"
              sx={{
                width: "auto",
                margin: "8px !important",
                marginTop: "16px !important",
                [`& .${tableCellClasses.root}`]: {
                  borderBottom: "none",
                },
              }}
            >
              <TableBody>
                {/* <TableRow>
                  <TableCell>
                    <Typography variant="h6">
                      <b>Select Type</b>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <RadioGroup
                      name="type"
                      aria-labelledby="type"
                      row
                      value={formType}
                      onChange={(e) => setFormType(e.target.value)}
                    >
                      <FormControlLabel
                        control={<Radio />}
                        label="Coupon"
                        value="coupon"
                      />
                      <FormControlLabel
                        control={<Radio />}
                        label="Deal"
                        value="deal"
                      />
                    </RadioGroup>
                  </TableCell>
                </TableRow> */}
                <TableRow>
                  <TableCell>
                    <Typography variant="h6">
                      <b>Select Store</b>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Autocomplete
                      // disablePortal
                      freeSolo
                      disableClearable
                      size="small"
                      options={sites ? sites.map(item => item.site_name) : []}
                      value={couponData.store_name}
                      sx={{ width: 300 }}
                      renderInput={(params) => (
                        <TextField {...params} placeholder="Select Store Name" />
                      )}
                      onChange={(e, val) =>
                        handleStore(val)
                      }
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Typography variant="h6">
                      <b>Select Category</b>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Autocomplete
                      // disablePortal
                      freeSolo
                      disableClearable
                      size="small"
                      id="category-combo-box"
                      options={categories ? categories.map(item => item.category) : []}
                      value={couponData.category_type}
                      sx={{ width: 300 }}
                      renderInput={(params) => (
                        <TextField {...params} placeholder="Select category" />
                      )}
                      onChange={(e, val) =>
                        handleCategory(val)
                      }
                    />
                  </TableCell>
                </TableRow>

                {formType === 'coupon' ? (<TableRow>
                  <TableCell>
                    <Typography variant="h6">
                      <b>Coupon Title</b>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <TextField
                      size="small"
                      fullWidth
                      placeholder="Enter coupon title"
                      value={couponData.title}
                      onChange={handleChange("title")}
                    />
                  </TableCell>
                </TableRow>) : (<TableRow>
                  <TableCell>
                    <Typography variant="h6">
                      <b>Deal Title</b>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <TextField
                      size="small"
                      fullWidth
                      placeholder="Enter coupon title"
                      value={couponData.title}
                      onChange={handleChange("title")}
                    />
                  </TableCell>
                </TableRow>)}
                {formType === "coupon" ? (
                  <TableRow>
                    <TableCell>
                      <Typography variant="h6">
                        <b>Coupon Title (short)</b>
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <TextField
                        size="small"
                        fullWidth
                        placeholder="Enter short coupon title"
                        value={couponData.short_title}
                        onChange={handleChange("short_title")}
                      />
                    </TableCell>
                  </TableRow>) : (
                  <TableRow>
                    <TableCell>
                      <Typography variant="h6">
                        <b>Deal Title (short)</b>
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <TextField
                        size="small"
                        fullWidth
                        placeholder="Enter short deal title"
                        value={couponData.short_title}
                        onChange={handleChange("short_title")}
                      />
                    </TableCell>
                  </TableRow>
                )}
                {formType === "coupon" ? (
                  <TableRow>
                    <TableCell>
                      <Typography variant="h6">
                        <b>Coupon Code</b>
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <TextField
                        size="small"
                        fullWidth
                        placeholder="Enter coupon code"
                        value={couponData.coupon_code}
                        onChange={handleChange("coupon_code")}
                      />
                    </TableCell>
                  </TableRow>
                ) : (
                  <TableRow>
                    <TableCell>
                      <Typography variant="h6">
                        <b>Deal URL</b>
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <TextField
                        size="small"
                        fullWidth
                        placeholder="Enter deal url"
                        value={couponData.deal_url}
                        onChange={handleChange("deal_url")}
                      />
                    </TableCell>
                  </TableRow>
                )}
                <TableRow>
                  <TableCell>
                    <Typography variant="h6">
                      <b>Source Website Name</b>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <TextField
                      size="small"
                      fullWidth
                      placeholder="Enter source website"
                      value={couponData.reference}
                      onChange={handleChange("reference")}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Typography variant="h6">
                      <b>Promotion Level</b>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <TextField
                      size="small"
                      type="number"
                      value={couponData.promotion}
                      onChange={handleChange("promotion")}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Typography variant="h6">
                      <b>Weightage</b>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <TextField
                      size="small"
                      type="number"
                      value={couponData.weightage}
                      onChange={handleChange("weightage")}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Typography variant="h6">
                      <b>Expiry Date</b>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    {/* <DatePicker
                      value={couponData.expiry_date}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          placeholder="Select Expiry Date"
                        />
                      )}
                      onChange={(val) => setCouponData({ ...couponData, expiry_date: val })}
                    /> */}
                    {/* <TextField
                    size="small"
                    type="date"
                    value={couponData.expiryDate}
                    onChange={handleChange("expiryDate")}
                  /> */}
                    <DateTimePicker
                      value={couponData.expiry_date}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          placeholder="Select Expiry Date"
                        />
                      )}
                      onChange={(val) => setCouponData({ ...couponData, expiry_date: val })}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Typography variant="h6">
                      <b>New User ?</b>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <RadioGroup
                      name="isNewUser"
                      aria-labelledby="new-user"
                      row //align-row
                      value={couponData.isNewUser}
                      onChange={handleChange("isNewUser")}
                    >
                      <FormControlLabel
                        control={<Radio color="success" />}
                        label="Yes"
                        value={1}
                      />
                      <FormControlLabel
                        control={<Radio color="error" />}
                        label="No"
                        value={0}
                      />
                    </RadioGroup>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Typography variant="h6">
                      <b>Is Verified ?</b>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <RadioGroup
                      name="isVerified"
                      aria-labelledby="verified-user"
                      row
                      value={couponData.isVerified}
                      onChange={handleChange("isVerified")}
                    >
                      <FormControlLabel
                        control={<Radio color="success" />}
                        label="Yes"
                        value={1}
                      />
                      <FormControlLabel
                        control={<Radio color="error" />}
                        label="No"
                        value={0}
                      />
                    </RadioGroup>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Typography variant="h6">
                      <b>Short Description</b>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <TextareaAutosize
                      minRows={1}
                      maxRows={4}
                      placeholder="Short description"
                      value={couponData.short_desc}
                      onChange={handleChange("short_desc")}
                      style={{ width: "100%", padding: "8px 12px", fontSize: '1rem' }}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Typography variant="h6">
                      <b>Select Description Type</b>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <RadioGroup
                      name="descriptionType"
                      aria-labelledby="description-type"
                      row
                      value={descriptionType}
                      onChange={(e) => setDescriptionType(e.target.value)}
                    >
                      <FormControlLabel
                        control={<Radio />}
                        label="Text"
                        value="text"
                      />
                      <FormControlLabel
                        control={<Radio />}
                        label="HTML"
                        value="html"
                      />
                    </RadioGroup>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Typography variant="h6">
                      <b>Description</b>
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ width: '60%' }}>
                    {descriptionType === "text"
                      ? <div style={{ border: "1px solid black", height: "241px", overflow: "auto" }}>
                        <Editor
                          editorState={editorState}
                          toolbarClassName="toolbarClassName"
                          wrapperClassName="wrapperClassName"
                          editorClassName="editorClassName"
                          onEditorStateChange={handleEditorChange}
                        />
                      </div>
                      :
                      <TextareaAutosize
                        minRows={10}
                        maxRows={10}
                        type="text"
                        value={couponData.details}
                        name="details"
                        onChange={handledetailsChange}
                        style={{ width: "100%", padding: "8px 12px", fontSize: '0.95rem' }}
                      />}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan="2" className="text-center" >
                    <Button type="submit" variant="contained" size="large" sx={{ marginTop: 5, width: '160px' }}>
                      <b>Submit</b>
                    </Button>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Stack>
        </form>
      </Paper>
    </LocalizationProvider>
  );
}

export default AddCoupon;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as pricesApi from "../../api/websiteAPI/pricesApi";

const initialState = {
    wishlistData: [],
    recentpricedropData: [],
    loading: false,
    error: "",
    success: "",
};

//======================================================= deals data using asyncthunk=====================================

export const getWishlistDataByUser = createAsyncThunk(
    "prices/wishlist",
    async (formData, { rejectWithValue }) => {
        try {
            const resp = await pricesApi.getWishlistByUser(formData);
            if (resp.data.code === 200) {
                return resp.data.data;
            } else {
                console.log(resp.data.message)
                return rejectWithValue(resp.data);
            }
        } catch (error) {
            rejectWithValue(error.resp.data);
        }
    }
);



// export const addDealsPanel = createAsyncThunk(
//     "deals/addDealsPanel",
//     async (formData, { rejectWithValue }) => {
//         try {
//             const resp = await dealsApi.adddealsweb(formData);
//             if (resp.data.code == 200) {
//                 return resp.data.message;
//             } else {
//                 return rejectWithValue(resp.data);
//             }
//         } catch (error) {
//             rejectWithValue(error.resp.data);
//         }
//     }
// );

// export const updateDealsPanel = createAsyncThunk(
//     "deals/updateDealsPanel",
//     async (formData, { rejectWithValue }) => {
//         try {
//             const resp = await dealsApi.updateDealsWeb(formData);
//             if (resp.data.code == 200) {
//                 return resp.data.message;
//             } else {
//                 return rejectWithValue(resp.data);
//             }
//         } catch (error) {
//             rejectWithValue(error.resp.data);
//         }
//     }
// );

// export const activateDeactivateDealsPanel = createAsyncThunk(
//     "deals/activateDeactivateStores",
//     async (formData, { rejectWithValue }) => {
//         try {
//             const resp = await dealsApi.activateDeactivateDealsWeb(formData);
//             if (resp.data.code == 200) {
//                 return resp.data.message;
//             } else {
//                 console.log(resp.data.message)
//                 return rejectWithValue(resp.data);
//             }
//         } catch (error) {
//             rejectWithValue(error.resp.data);
//         }
//     }
// );

export const getRecentPriceDropForWebPanel = createAsyncThunk(
    "prices/show-recent-price-drop",
    async (_, { rejectWithValue }) => {
        try {
            const resp = await pricesApi.getRecentPriceDropForWeb();
            if (resp.data.code === 200) {
                return resp.data.data;
            } else {
                console.log(resp.data.message)
                return rejectWithValue(resp.data);
            }
        } catch (error) {
            rejectWithValue(error.resp.data);
        }
    }
);


const pricesSlice = createSlice({
    name: "prices",
    initialState,
    // reducers: {
    //     setCategories: (state, action) => {
    //         state.catArrData = action.payload
    //     }
    // },

    extraReducers: (builder) => {
        ///=================================getAllStoresData data===========================//////////////////////

        builder.addCase(getWishlistDataByUser.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getWishlistDataByUser.fulfilled, (state, action) => {
            state.loading = false;
            state.wishlistData = action.payload;
        });
        builder.addCase(getWishlistDataByUser.rejected, (state, action) => {
            state.error = action.payload;
        });


        ///=================================addDealsPanel data===========================//////////////////////

        // builder.addCase(addDealsPanel.pending, (state) => {
        //     state.loading = true;
        // });
        // builder.addCase(addDealsPanel.fulfilled, (state, action) => {
        //     state.loading = false;
        //     state.success = action.payload;
        // });
        // builder.addCase(addDealsPanel.rejected, (state, action) => {
        //     state.error = action.payload;
        // });


        ///=================================activateDeactivateDealsPanel data===========================//////////////////////

        // builder.addCase(activateDeactivateDealsPanel.pending, (state) => {
        //     state.loading = true;
        // });
        // builder.addCase(activateDeactivateDealsPanel.fulfilled, (state, action) => {
        //     state.loading = false;
        //     state.success = action.payload;
        // });
        // builder.addCase(activateDeactivateDealsPanel.rejected, (state, action) => {
        //     state.error = action.payload;
        // });

        ///=================================updateDealsPanel data===========================//////////////////////

        // builder.addCase(updateDealsPanel.pending, (state) => {
        //     state.loading = true;
        // });
        // builder.addCase(updateDealsPanel.fulfilled, (state, action) => {
        //     state.loading = false;
        //     state.success = action.payload;
        // });
        // builder.addCase(updateDealsPanel.rejected, (state, action) => {
        //     state.error = action.payload;
        // });
        // ==================================showrecentpricedrop=====================================
        builder.addCase(getRecentPriceDropForWebPanel.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getRecentPriceDropForWebPanel.fulfilled, (state, action) => {
            state.loading = false;
            state.recentpricedropData = action.payload;
        });
        builder.addCase(getRecentPriceDropForWebPanel.rejected, (state, action) => {
            state.error = action.payload;
        });
    },
});
// export const { setCategories } = storesSlice.actions;
export default pricesSlice.reducer;
import React, { useEffect, useState } from 'react';
import Datatable from '../../Datatable/Datatable';
import { Button, ButtonGroup } from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
import { getRecentPriceDropForWebPanel } from '../../../redux/features/websiteSlices/pricesSlice';

function ShowRecentPriceDrop() {
    const dispatch = useDispatch();
    const { recentpricedropData } = useSelector((state) => ({ ...state.prices }));
    console.log(recentpricedropData);
    function dateConverter(str) {
        // console.log(str, "str")
        var date = new Date(str),
            mnth = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2);
        let hours = ("0" + date.getHours()).slice(-2);
        let minutes = ("0" + date.getMinutes()).slice(-2);
        let seconds = ("0" + date.getSeconds()).slice(-2);
        let year = date.getFullYear();
        return `${year}-${mnth}-${day} ${hours}:${minutes}:${seconds}`
    }
    const columns = [
        {
            name: "store_name",
            label: "Store Name",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "store_id",
            label: "Store Id",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "title",
            label: "Title",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "img_url",
            label: "Img URL",
            options: {
                customBodyRender: (link) => {
                    return (
                        <a href={link} target="_blank" rel="noreferrer">{link}</a>
                    )
                },
                filter: true,
                sort: true,
            }
        },
        {
            name: "description",
            label: "Description",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "id",
            label: "ID",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "last_notified_price",
            label: "Last Notified Price",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "pid",
            label: "PID",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "start_price",
            label: "Start Price",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "time",
            label: "Time",
            options: {

                filter: true,
                sort: true
            }
        },
        {
            name: "total_off_per",
            label: "Total Off Per",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: 'active',
            label: 'Status',
            options: {
                customBodyRender: (active) => {
                    if (active === 1) { return <Button color='error' size="small" variant="outlined" ><b>Deactivate</b></Button> }
                    else if(active === 0) { return <Button color='success' size="small" variant="outlined" ><b>Activate</b></Button> }
                },
                filter: true,
                sort: true,
            },
        }
    ];

    // const data = [
    //     {
    //         store_name: 'amazon',
    //         store_url: "https://www.amazon.in"
    //     },
    // ]
    useEffect(() => {
        dispatch(getRecentPriceDropForWebPanel());
    }, [])
    return (
        <div>
            <Datatable
                name="Recent Price Drop"
                columns={columns}
                data={recentpricedropData}
            />
        </div>
    )
}

export default ShowRecentPriceDrop

// {
// active
// description
// id
// img_url
// last_notified_price
// pid
// start_price
// store_id
// store_name
// time
// title
// total_off_per
// }
import axios from "axios";
import AppConstants from "../../../constants/AppConstants";

const API = axios.create({ baseURL: AppConstants.baseURL });

export const getJobPost = () => API.post("/webdata/getjobposting");
export const addJobPost = (formData) =>
  API.post("/webdata/addjobposting", formData);
export const activeJobPost = (formData) =>
  API.post("/webdata/activejobposting", formData);

export const getJobPostCategoryDataApi = () =>
  API.get("/webdata/getalljobcategory");

/* eslint-disable react-hooks/exhaustive-deps */
import { React, useEffect, useReducer, useState } from "react";
import {
  TableBody,
  Table,
  TableCell,
  TableRow,
  Paper,
  Stack,
  Toolbar,
  Typography,
  Autocomplete,
  TextField,
  Box,
  Fab,
  Badge,
  Tooltip,
  CircularProgress,
} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import Datatable from "../Datatable/Datatable";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { DateRangePicker } from "react-date-range";
import { addDays } from "date-fns";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
// import axios from 'axios'
import BarChart from "./Charts/BarChart";
import { useDispatch, useSelector } from "react-redux";
import {
  getDownloadListData,
  downloadsListByDate,
} from "../../redux/features/downloadsSlice";

function DownloadHistory() {
  // data
  const extensionData = ["Flipshope New", "Amazon Ext"];
  const dispatch = useDispatch();
  const { status_data, chart_data, table_data } = useSelector((state) => ({
    ...state.downloads,
  }));
  // const [status_data_temp, dispatch] = useReducer();
  // console.log({ status_data, chart_data, table_data })
  // Download List columns
  const column = [
    {
      name: "sl_no",
      label: "SL No",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "Id",
      label: "ID",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "Last_Update_Date",
      label: "Date",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "Extension_Id",
      label: "EXT. ID",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "Reason",
      label: "AFF. ID",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "IP",
      label: "IP",
      options: {
        filter: true,
        sort: true,
      },
    },
  ];

  // selected extension
  const [extension, setExtension] = useState("Flipshope New");
  const [type, setType] = useState("fs_new");

  // date range
  const [dateRange, setDateRange] = useState([
    {
      startDate: addDays(new Date(), -8),
      endDate: addDays(new Date(), -1),
      key: "selection",
    },
  ]);
  // Date converter
  const dateConverter = (date) => {
    const arr = date.toString().split(" ");
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const date_str =
      arr[3] + "-" + (months.indexOf(arr[1]) + 1).toString() + "-" + arr[2];
    return date_str;
  };

  // Date range for API
  const [apiDate, setApiDate] = useState({
    type: type,
    from: dateConverter(dateRange[0].startDate),
    to: dateConverter(dateRange[0].endDate),
  });

  // Change date range
  const handleDateRangeChange = (item) => {
    setDateRange([
      {
        startDate: item.selection.startDate,
        endDate: item.selection.endDate,
        key: "selection",
      },
    ]);
    setApiDate({
      type: type,
      from: dateConverter(item.selection.startDate),
      to: dateConverter(item.selection.endDate),
    });
  };

  // // Status data
  // const [statusData, setStatusData] = useState([])
  // // Chart data
  // const [chartData, setChartData] = useState([])

  // API call by type
  // const DownloadHistoryApi = async (type) => {
  //   let res = await axios.get(`https://team.flipshope.com/api/user/action/downloadlist`, { params: { type: type } });
  //   setStatusData(res.data.data[0].statusData)
  //   setChartData(res.data.data[0].chart_data)
  //   setTableData(res.data.data[0].table_data)
  // }
  // API call by type and date range
  // const DownloadHistoryByDateApi = async (apiDate) => {
  //   let res = await axios.post(`https://team.flipshope.com/api/user/action/downloadlistbydate`, apiDate);
  //   setStatusData({ ...statusData, today: res.data.data[0].statusData.today, sum: res.data.data[0].statusData.sum })
  //   setChartData(res.data.data[0].chart_data)
  // }

  // on extension change
  const handleExtension = (val) => {
    setExtension(val);
    val === "Flipshope New" ? setType("fs_new") : setType("amazon");
  };
  console.log(type, "===>");

  // Refresh Handler
  const [refresh, setRefresh] = useState(false);
  const handleRefresh = async () => {
    setRefresh(true);
    dispatch(getDownloadListData(type));
    setDateRange([
      {
        startDate: addDays(new Date(), -8),
        endDate: addDays(new Date(), -1),
        key: "selection",
      },
    ]);
    setRefresh(false);
  };

  // Call downloadlist
  useEffect(() => {
    dispatch(getDownloadListData(type));
    console.log(`type dispatch called with ${type}`);
  }, [type]);
  // useEffect(() => {
  //   DownloadHistoryApi(type)
  // }, [type])

  // Call downloadlistbydate
  useEffect(() => {
    dispatch(downloadsListByDate(apiDate));
    // DownloadHistoryByDateApi(apiDate)
  }, [apiDate]);

  return (
    <Paper elevation={3}>
      <Stack spacing={3} direction="column">
        <Toolbar
          sx={{
            padding: "1.2rem !important",
            borderBottom: "2px solid var(--primary-color)",
          }}
        >
          <Typography
            sx={{
              flex: "1 1 100%",
              color: "var(--primary-color)",
              fontWeight: "bold",
            }}
            variant="h5"
            id="tableTitle"
          >
            Download History
          </Typography>

          <Autocomplete
            disableClearable
            size="small"
            id="extension-combo-box"
            value={extension}
            options={extensionData}
            sx={{ width: 600 }}
            renderInput={(params) => (
              <TextField {...params} placeholder="Select Extension" />
            )}
            onChange={(e, val) => handleExtension(val)}
          />
        </Toolbar>
        <Badge
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          sx={{
            "& .MuiBadge-badge": {
              left: 24,
              top: 0,
            },
          }}
          badgeContent={
            <Tooltip title="Reset" arrow>
              <span>
                <Fab
                  size="small"
                  color="primary"
                  sx={{
                    "&:hover": {
                      bgcolor: "var(--secondary-color)",
                      color: "white",
                    },
                  }}
                  onClick={() => handleRefresh()}
                >
                  <RefreshIcon />
                </Fab>
                {refresh && (
                  <CircularProgress
                    size={44}
                    sx={{
                      color: "inherit",
                      position: "absolute",
                      top: -12,
                      left: 4,
                      zIndex: 1,
                    }}
                  />
                )}
              </span>
            </Tooltip>
          }
        >
          <Table>
            <TableBody>
              <TableRow>
                <TableCell
                  width="25%"
                  sx={{ borderRight: "1px solid grey !important" }}
                >
                  <Box className="text-center">
                    <FileDownloadOutlinedIcon />
                    <b>Today</b>
                    <h1>{status_data ? status_data.today : 0}</h1>
                  </Box>
                </TableCell>

                <TableCell
                  width="25%"
                  sx={{ borderRight: "1px solid grey !important" }}
                >
                  <Box className="text-center">
                    <FileDownloadOutlinedIcon />
                    <b>Yesterday</b>
                    <h1>{status_data ? status_data.yesterday : 0}</h1>
                  </Box>
                </TableCell>

                <TableCell
                  width="25%"
                  sx={{ borderRight: "1px solid grey !important" }}
                >
                  <Box className="text-center">
                    <FileDownloadOutlinedIcon />
                    <b>Last 7 Days</b>
                    <h1>{status_data ? status_data.last7days : 0}</h1>
                  </Box>
                </TableCell>

                <TableCell width="25%">
                  <Box className="text-center">
                    <FileDownloadOutlinedIcon />
                    <b>Graph Total</b>
                    <h1>{status_data ? status_data.sum : 0}</h1>
                  </Box>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Badge>
        <Box>
          <DateRangePicker
            ranges={dateRange}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            months={2}
            direction="horizontal"
            onChange={(item) => handleDateRangeChange(item)}
          />
        </Box>
        <Box>
          {chart_data && (
            <BarChart
              data={{
                labels: chart_data.map((row) => row.date.split("T")[0]),
                datasets: [
                  {
                    label: "Downloads",
                    data: chart_data.map((row) => row.downloads),
                    backgroundColor: [
                      // 'rgba(255, 99, 132, 0.2)',
                      "rgba(54, 162, 235, 0.2)",
                      // 'rgba(255, 206, 86, 0.2)',
                      // 'rgba(75, 192, 192, 0.2)',
                      // 'rgba(153, 102, 255, 0.2)',
                      // 'rgba(255, 159, 64, 0.2)',
                    ],
                    borderColor: [
                      // 'rgba(255, 99, 132, 1)',
                      "rgba(54, 162, 235, 1)",
                      // 'rgba(255, 206, 86, 1)',
                      // 'rgba(75, 192, 192, 1)',
                      // 'rgba(153, 102, 255, 1)',
                      // 'rgba(255, 159, 64, 1)',
                    ],
                    borderWidth: 2,
                    borderRadius: 10,
                  },
                ],
              }}
            />
          )}
        </Box>

        <Datatable
          name="Download List"
          columns={column}
          data={
            table_data &&
            table_data.map((row, index) => ({ ...row, sl_no: index + 1 }))
          }
        />
      </Stack>
    </Paper>
  );
}

export default DownloadHistory;

import { Box, Button, ButtonGroup, Tooltip, Typography } from "@mui/material";
import { NavLink } from "react-router-dom";
import { Popconfirm } from "antd";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Image } from "antd";
import Datatable from "../Datatable/Datatable";
import { useSelector, useDispatch } from "react-redux";
import { setFetchAgain } from "../../redux/features/dealsAppSlice";
// import axios from "axios";
import axios from "../../api/axios";
const UPDATE_DEAL_URL = "/api/deals/updateproductdb";
const FETCH_TABLE_URL = "/api/deals/fetchtable";

const ShowDealsApp = () => {
  const { fetchAgain } = useSelector((state) => state.dealsApp);

  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const columns = [
    {
      name: "_id",
      label: "Action",
      options: {
        customBodyRender: (id) => {
          const fullRowData = tableData.find((data) => data._id === id);
          // console.log(fullRowData, "fullRowData");

          // let data = allStoresData.filter((row) => row.store_id === id)[0];
          return (
            <ButtonGroup orientation="vertical" variant="text" color="success">
              <NavLink to="/add-deals-app" state={{ fullRowData }}>
                <Button color="success">
                  <strong>Edit</strong>
                </Button>
              </NavLink>
              {/* <Popconfirm
                                placement="right"
                                title="Delete Store"
                                description="Do you really want to delete the store?"
                                okText="Yes"
                                cancelText="No"
                                onConfirm={() => console.log(id)}
                            >
                                <Button color="error">
                                    <strong>Delete</strong>
                                </Button>
                            </Popconfirm> */}
            </ButtonGroup>
          );
        },
        filter: true,
        sort: true,
      },
    },
    {
      name: "pid",
      label: "Pid",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "sid",
      label: "Sid",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "title",
      label: "Title",
      options: {
        customBodyRender: (title) => {
          // console.log(title, "title");

          return (
            <Tooltip title={title} placement="top" arrow>
              <Typography
                // className=" line-clamp-2 w-[200px]"
                sx={{
                  width: "200px",
                  lineClamp: "2",
                  overflow: "hidden",
                  display: "-webkit-box",
                  WebkitLineClamp: "2",
                  WebkitBoxOrient: "vertical",
                }}
              >
                {title}
              </Typography>
            </Tooltip>
          );
        },
        filter: true,
        sort: true,
      },
    },
    // {
    //     name: "store_page_url",
    //     label: "Store Page URL",
    //     options: {
    //         customBodyRender: (link) => {
    //             return (
    //                 <a href={link} target="_blank" rel="noreferrer">{link}</a>
    //             )
    //         },
    //         filter: true,
    //         sort: true,
    //     }
    // },

    {
      name: "mrp",
      label: "MRP",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "latestPrice",
      label: "Latest Price",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "brand",
      label: "Brand",
      options: {
        customBodyRender: (brand) => {
          return <div style={{ width: "200px" }}>{brand}</div>;
        },
        filter: true,
        sort: true,
      },
    },
    {
      name: "category",
      label: "Category",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "sub_category",
      label: "Sub Category",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "color",
      label: "Color",
      options: {
        customBodyRender: (color) => {
          if (!color) return "---";
          else return color;
        },
        filter: true,
        sort: true,
      },
    },
    {
      name: "inStock",
      label: "In Stock",
      options: {
        customBodyRender: (inStoke) => {
          return (
            <Box
              sx={{ width: "100%", display: "flex", justifyContent: "center" }}
            >
              <Box
                sx={{
                  backgroundColor: `${inStoke ? "#4CAF50" : "#f44336"}`,
                  borderRadius: "100%",
                  width: "20px",
                  height: "20px",
                }}
              ></Box>
            </Box>
          );
        },
        filter: true,
        sort: true,
      },
    },
    {
      name: "imgurl",
      label: "Image",
      options: {
        customBodyRender: (link) => {
          return (
            <div
              // className="size-[40px] rounded overflow-hidden duration-150"
              style={{
                width: "40px",
                height: "40px",
                borderRadius: "4px",
                overflow: "hidden",
                transitionDuration: "0.150s",
              }}
            >
              <Image src={link} />
            </div>
          );
        },
        filter: true,
        sort: true,
      },
    },
    {
      name: "aff_url",
      label: "Affiliate Link",
      options: {
        customBodyRender: (link) => {
          // console.log(link, "link");
          return (
            <a href={link} target="_blank" rel="noreferrer">
              <div
                style={{
                  lineClamp: "2",
                  width: "200px",
                  overflow: "hidden",
                  display: "-webkit-box",
                  WebkitLineClamp: "2",
                  WebkitBoxOrient: "vertical",
                }}
                className=""
              >
                {link}
              </div>
            </a>
          );
        },
        filter: true,
        sort: true,
      },
    },
    {
      name: "inserted_at",
      label: "Inserted At",
      options: {
        customBodyRender: (isoDate) => {
          // console.log(link, "link");
          const date = new Date(isoDate);
          return (
            <div style={{ width: "200px" }}>
              {date.toLocaleString("en-US", {
                year: "numeric",
                month: "long",
                day: "numeric",
                hour: "numeric",
                minute: "numeric",
                hour12: true,
              })}
            </div>
          );
        },
        filter: true,
        sort: true,
      },
    },
    {
      name: "updated_at",
      label: "Updated At",
      options: {
        customBodyRender: (isoDate) => {
          // console.log(link, "link");
          const date = new Date(isoDate);
          return (
            <div style={{ width: "110px" }}>
              {date.toLocaleString("en-US", {
                year: "numeric",
                month: "long",
                day: "numeric",
                hour: "numeric",
                minute: "numeric",
                hour12: true,
              })}
            </div>
          );
        },
        filter: true,
        sort: true,
      },
    },

    {
      name: "active",
      label: "Status",
      options: {
        customBodyRender: (active, tableMeta) => {
          var data = {};
          data._id = tableMeta.rowData[0];
          data.active = active ? false : true;
          if (active)
            return (
              <Popconfirm
                placement="topRight"
                title="Deactivate Store"
                description="Do you really want to deactivate the store?"
                okText="Yes"
                cancelText="No"
                onConfirm={() => handleStatus(data)}
              >
                <Button color="error" size="small" variant="outlined">
                  <b>Deactivate</b>
                </Button>
              </Popconfirm>
            );
          else
            return (
              <Popconfirm
                placement="topRight"
                title="Activate Store"
                description="Do you really want to activate the store?"
                okText="Yes"
                cancelText="No"
                onConfirm={() => handleStatus(data)}
              >
                <Button color="success" size="small" variant="outlined">
                  <b>Activate</b>
                </Button>
              </Popconfirm>
            );
        },
        filter: true,
        sort: true,
      },
    },
  ];
  const handleStatus = async (data) => {
    try {
      const response = await axios.put(UPDATE_DEAL_URL, {
        _id: data._id,
        active: data.active,
      });
      toast.success(response.data.message);
      dispatch(setFetchAgain((prev) => !prev));
    } catch (error) {
      toast.error(error.response.data.message);
      console.log(error);
    }
  };

  useEffect(() => {
    const controller = new AbortController();

    const fetchData = async () => {
      try {
        const response = await axios.post(
          FETCH_TABLE_URL,
          {},
          {
            signal: controller.signal,
          }
        );
        setTableData(response?.data?.data);
      } catch (error) {
        console.log(error.message);
        toast(error?.response?.data.error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();

    return () => {
      controller.abort();
    };
  }, [fetchAgain]);
  return (
    <div className="w-full">
      {loading ? (
        <Typography variant="h5" align="center">
          Loading...
        </Typography>
      ) : (
        <Datatable name="Store Data" columns={columns} data={tableData} />
      )}
    </div>
  );
};
export default ShowDealsApp;

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext } from "react";
import { NavLink, useNavigate } from 'react-router-dom';
import { Avatar } from "@mui/material";
import { indigo } from "@mui/material/colors";
import { useAuth } from "../../context/authContext";

function Navbar() {
  const navigate = useNavigate();

  // user data
  const { userData, logout } = useAuth();

  const handleLogout = async () => {
    await logout();
    window.location.reload()
    // navigate("/login");
  }

  return (
    <>
      <header
        id="header"
        className="header fixed-top d-flex align-items-center"
      >
        <div className="d-flex align-items-center justify-content-between">
          <div style={{ marginRight: "16px" }}>
            <i className="bi bi-list toggle-sidebar-btn"
              onClick={() => document.querySelector('body').classList.toggle('toggle-sidebar')}>
            </i>
          </div>
          <NavLink className="logo d-flex align-items-center" to='/'>
            <span className="nav-heading">Flipshope</span>
          </NavLink>
        </div>
        {/* <!-- End Logo --> */}

        <div className="search-bar">
          <form
            className="search-form d-flex align-items-center justify-content-center"
            method="POST"
            action="#"
          >
            <input
              type="text"
              name="query"
              placeholder="Search"
              title="Enter search keyword"
            />
            <button type="submit" title="Search">
              <i className="bi bi-search"></i>
            </button>
          </form>
        </div>
        {/* <!-- End Search Bar --> */}

        <nav className="header-nav ms-auto">
          <ul className="d-flex align-items-center">
            <li className="nav-item d-block d-md-none">
              <a className="nav-link nav-icon search-bar-toggle"
                onClick={() => document.querySelector('.search-bar').classList.toggle('search-bar-show')}>
                <i className="bi bi-search"></i>
              </a>
            </li>
            {/* <!-- End Search Icon--> */}

            <li className="nav-item dropdown">
              <a
                className="nav-link nav-icon"
                href="#"
                data-bs-toggle="dropdown"
              >
                <i className="bi bi-bell"></i>
                <span className="badge bg-danger badge-number">4</span>
              </a>
              {/* <!-- End Notification Icon --> */}

              <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow notifications">
                <li className="dropdown-header">
                  You have new notifications
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>

                <li className="notification-item">
                  <i className="bi bi-exclamation-circle text-warning"></i>
                  <div>
                    <h4>Lorem Ipsum</h4>
                    <p>Quae dolorem earum veritatis oditseno</p>
                    <p><i className="bi bi-clock" style={{ fontSize: '13px', margin: '0 5px 0 0' }}></i>30 min. ago</p>
                  </div>
                </li>

                <li>
                  <hr className="dropdown-divider" />
                </li>

                <li className="notification-item">
                  <i className="bi bi-x-circle text-danger"></i>
                  <div>
                    <h4>Atque rerum nesciunt</h4>
                    <p>Quae dolorem earum veritatis oditseno</p>
                    <p><i className="bi bi-clock" style={{ fontSize: '13px', margin: '0 5px 0 0' }}></i>1 hr. ago</p>
                  </div>
                </li>

                <li>
                  <hr className="dropdown-divider" />
                </li>

                <li className="notification-item">
                  <i className="bi bi-check-circle text-success"></i>
                  <div>
                    <h4>Sit rerum fuga</h4>
                    <p>Quae dolorem earum veritatis oditseno</p>
                    <p><i className="bi bi-clock" style={{ fontSize: '13px', margin: '0 5px 0 0' }}></i>2 hrs. ago</p>
                  </div>
                </li>

                <li>
                  <hr className="dropdown-divider" />
                </li>

                <li className="notification-item">
                  <i className="bi bi-info-circle text-primary"></i>
                  <div>
                    <h4>Dicta reprehenderit</h4>
                    <p>Quae dolorem earum veritatis oditseno</p>
                    <p><i className="bi bi-clock" style={{ fontSize: '13px', margin: '0 5px 0 0' }}></i>4 hrs. ago</p>
                  </div>
                </li>

                <li>
                  <hr className="dropdown-divider" />
                </li>
                <li className="dropdown-footer text-center mt-2 text-decoration-underline">
                  <NavLink to='/notification/history'>Show all notifications</NavLink>
                </li>
              </ul>
              {/* <!-- End Notification Dropdown Items --> */}
            </li>
            {/* <!-- End Notification Nav --> */}

            <li className="nav-item dropdown pe-3">
              <a
                className="nav-link nav-profile d-flex align-items-center pe-0"
                href="#"
                data-bs-toggle="dropdown"
              >
                {/* <img
                  src="assets/img/profile-img.jpg"
                  alt="Profile"
                  className="rounded-circle"
                  style={{objectFit: 'contain'}}
                /> */}
                <Avatar sx={{
                  bgcolor: indigo[100],
                  width: '32px',
                  height: '32px',
                }}
                >{userData && userData.name && userData.name[0]}</Avatar>
                <span className="d-none d-md-block dropdown-toggle ps-2">
                  {userData && userData.name.split(" ")[0]}
                </span>
              </a>
              {/* <!-- End Profile Image Icon --> */}

              <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
                {/* <li className="dropdown-header">
                  <h6>John Wick</h6>
                  <span>Javascript Developer</span>
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li> */}

                <li>
                  <a
                    className="dropdown-item d-flex align-items-center"
                    onClick={() => navigate('/profile')}
                  >
                    <i className="bi bi-person"></i>
                    <span>My Profile</span>
                  </a>
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>
                <li>
                  <a
                    className="dropdown-item d-flex align-items-center logout-link"
                    onClick={() => handleLogout()}
                  >
                    <i className="bi bi-box-arrow-right"></i>
                    <span>Logout</span>
                  </a>
                </li>
              </ul>
              {/* <!-- End Profile Dropdown Items --> */}
            </li>
            {/* <!-- End Profile Nav --> */}
          </ul>
        </nav>
        {/* <!-- End Icons Navigation --> */}
      </header>
    </>
  );
};


export default Navbar;
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Box,
  Paper,
  Stack,
  Typography,
  TextField,
  InputAdornment,
  IconButton,
  MenuItem,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import LoadingButton from "@mui/lab/LoadingButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { NavLink, useNavigate } from "react-router-dom";
import { SignupSchema } from "../../validations/SignupValidation";
import { useAuth } from "../../context/authContext";
import { showRolesData } from "../../redux/features/authSlice";

function Signup() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userRolesData } = useSelector((state) => ({ ...state.userAuth }));
  // antd message
  const { signupUser } = useAuth();

  // signupData
  const [signupData, setSignupData] = useState({
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
    role: "admin",
  });
  // password toggle
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  // handle value changes
  const handleChange = (key) => (event) => {
    setSignupData({ ...signupData, [key]: event.target.value });
  };

  // toggle password show
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  // Signup handler
  const [loading, setLoading] = useState(false);
  const handleSignup = async (event) => {
    event.preventDefault();
    setLoading(true);
    await SignupSchema.validate(signupData).then(async (value) => {
      console.log(signupData, "signupData");
      await signupUser(signupData);
      navigate("/login");
    });
    setLoading(false);
  };

  useEffect(() => {
    dispatch(showRolesData());
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100vw",
        height: "100vh",
      }}
    >
      <Paper elevation={10} sx={{ borderRadius: "15px" }}>
        <Stack direction="row">
          <Box style={{ width: "400px", height: "inherit" }}>
            <img
              style={{
                display: "block",
                objectFit: "cover",
                height: "100%",
                width: "100%",
                borderRadius: "15px 0 0 15px",
              }}
              src="https://img.freepik.com/free-vector/geometric-wave-background-blue-color_343694-1288.jpg?w=996&t=st=1669959587~exp=1669960187~hmac=9ba6ec132be46eb2587372c3771f6a47dc733703fb67095f1a52ddfe404b51a5"
              alt="random"
            />
          </Box>
          <form onSubmit={handleSignup}>
            <Stack spacing={3} px={10} py={5}>
              <Typography variant="h4" align="center">
                Signup
              </Typography>

              <Stack spacing={2}>
                <TextField
                  label="Name"
                  required
                  size="small"
                  value={signupData.name}
                  onChange={handleChange("name")}
                />

                <TextField
                  label="Email"
                  required
                  size="small"
                  value={signupData.email}
                  onChange={handleChange("email")}
                />

                <TextField
                  label="Password"
                  required
                  size="small"
                  type={showPassword ? "text" : "password"}
                  value={signupData.password}
                  onChange={handleChange("password")}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle-password-visibility"
                          onClick={handleClickShowPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />

                <TextField
                  label="Confirm Password"
                  required
                  size="small"
                  type={showConfirmPassword ? "text" : "password"}
                  value={signupData.confirmPassword}
                  onChange={handleChange("confirmPassword")}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle-password-visibility"
                          onClick={handleClickShowConfirmPassword}
                          edge="end"
                        >
                          {showConfirmPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  helperText="Must be same as password"
                  error={
                    signupData.confirmPassword.length === 0 ||
                    (signupData.password.length >= 0 &&
                      signupData.confirmPassword.length >= 0 &&
                      signupData.password === signupData.confirmPassword)
                      ? false
                      : true
                  }
                />

                <TextField
                  label="Select Role"
                  select
                  value={signupData.role}
                  onChange={handleChange("role")}
                  size="small"
                  helperText="Select your role assigned by organization."
                >
                  {userRolesData &&
                    userRolesData.map((item) => {
                      return (
                        <MenuItem value={item.user_type}>
                          {item.user_type}
                        </MenuItem>
                      );
                    })}
                </TextField>
              </Stack>

              <Typography variant="body2">
                Already have an account?{" "}
                <NavLink to="/login">
                  <strong>Login</strong>
                </NavLink>
              </Typography>

              <Stack direction="row">
                <LoadingButton
                  variant="contained"
                  fullWidth
                  type="submit"
                  loading={loading}
                  disabled={
                    signupData.name.length > 0 &&
                    signupData.email.length > 0 &&
                    signupData.password.length >= 0 &&
                    signupData.confirmPassword.length >= 0 &&
                    signupData.role.length > 0 &&
                    signupData.password === signupData.confirmPassword
                      ? false
                      : true
                  }
                  onClick={handleSignup}
                >
                  Signup
                </LoadingButton>
              </Stack>
            </Stack>
          </form>
        </Stack>
      </Paper>
    </Box>
  );
}

export default Signup;

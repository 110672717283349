import React, { useEffect, useState } from "react";
import {
  Paper,
  Stack,
  Toolbar,
  Typography,
  Table,
  tableCellClasses,
  TableBody,
  TableRow,
  TableCell,
  FormControlLabel,
  RadioGroup,
  Radio,
  TextField,
  Button,
  Autocomplete,
} from "@mui/material";
import { Input, message, Upload } from "antd";
import { useDispatch, useSelector } from "react-redux";
import UploadIcon from "@mui/icons-material/Upload";
import {
  getImageTypeData,
  uploadImageData,
} from "../../redux/features/gallerySlice";
import { getAllStoresData } from "../../redux/features/storesSlice";

function AddImage() {
  const dispatch = useDispatch();
  const { allStoresData } = useSelector((state) => ({ ...state.stores }));
  const { imageType } = useSelector((state) => ({ ...state.gallery }));

  // type setter
  const [formType, setFormType] = useState("url");
  const [image, setImage] = useState();
  const [imgSize, setImageSize] = useState({ width: "", height: "" });
  // form data
  const [imageData, setImageData] = useState({
    type: "",
    image_type: "",
    type_id: "",
    store_name: "",
    title: "",
    alt_text: "",
    img_url: "",
    upload_image: "",
  });

  // data handler
  const handleChange = (key) => (e) => {
    setImageData({ ...imageData, [key]: e.target.value });
  };

  // const handleAutoComplete = (name) => {
  //   // setImageData({ ...imageData, store_name: name });
  //   if (allStoresData.length > 0) {
  //     for (var i = 0; i < allStoresData.length; i++) {
  //       // //console.log(categoryData[i])
  //       if (name === allStoresData[i].store_name) {
  //         setImageData({
  //           ...imageData,
  //           store_name: allStoresData[i].store_name,
  //         });
  //       }
  //     }
  //   }
  // };
  const handleImageType = (name) => {
    if (imageType.length > 0) {
      for (var i = 0; i < imageType.length; i++) {
        // //console.log(categoryData[i])
        if (name === imageType[i].type) {
          setImageData({ ...imageData, image_type: imageType[i].type });
          setImageSize({
            width: imageType[i].master_width,
            height: imageType[i].master_height,
          });
        }
      }
    }
  };
  // const onImageSelect = (e) => {
  //   e.preventDefault();
  //   console.log(e.target.files && e.target.files[0]);
  //   setImage(e.target.files[0]);
  //   message.success("Image uploaded");
  // };
  const [selectedImage, setSelectedImage] = useState(null);

  const onImageSelect = (e) => {
    e.preventDefault();
    // console.log(e.target.files && e.target.files[0]);
    setSelectedImage(e.target.files[0]); // store the selected file
    setImage(e.target.files[0]);
    message.success("Image uploaded");
  };

  // handle form submit
  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(image, "image");

    const formData = new FormData();
    if (imageData.img_url) formData.append("img_url", imageData.img_url);
    if (image) formData.append("file", image);
    formData.append("type", formType);
    formData.append("image_type", imageData.image_type);
    formData.append("type_id", 3);
    formData.append("store_name", imageData.store_name);
    formData.append("title", imageData.title);
    formData.append("alt_text", imageData.alt_text);
    for (let pair of formData.entries()) {
      console.log(pair[0] + ",  " + pair[1]);
    }
    // console.log(formData, "after formdata");
    dispatch(uploadImageData(formData));
    // for (let pair of formData.entries()) {
    //   console.log(pair[0] + ',  ' + pair[1]);
    // }

    setImageData({
      type: "",
      image_type: "",
      type_id: "",
      store_name: "",
      title: "",
      alt_text: "",
      img_url: "",
      upload_image: "",
    });
    setImage();
  };

  useEffect(() => {
    dispatch(getImageTypeData());
    dispatch(getAllStoresData());
  }, []);

  return (
    <Paper elevation={3}>
      <form onSubmit={handleSubmit}>
        <Stack spacing={3} direction="column">
          <Toolbar
            mt={3}
            sx={{
              padding: "1.2rem",
              borderBottom: "2px solid var(--primary-color)",
            }}
          >
            <Typography
              sx={{
                color: "var(--primary-color)",
                fontWeight: "bold",
              }}
              variant="h5"
              id="tableTitle"
            >
              Add Image
            </Typography>
          </Toolbar>
          <Table
            size="small"
            sx={{
              width: "auto",
              margin: "8px !important",
              marginTop: "16px !important",
              [`& .${tableCellClasses.root}`]: {
                borderBottom: "none",
              },
            }}
          >
            <TableBody>
              <TableRow>
                <TableCell>
                  <Typography variant="h6">
                    <b>Select Type</b>
                  </Typography>
                </TableCell>
                <TableCell>
                  <RadioGroup
                    name="image-type"
                    aria-labelledby="image-type"
                    row
                    value={formType}
                    onChange={(e) => {
                      imageData.type = formType;
                      setFormType(e.target.value);
                    }}
                  >
                    <FormControlLabel
                      control={<Radio />}
                      label="URL"
                      value="url"
                    />
                    <FormControlLabel
                      control={<Radio />}
                      label="Image"
                      value="image"
                    />
                  </RadioGroup>
                </TableCell>
              </TableRow>

              {/* <TableRow>
                <TableCell>
                  <Typography variant="h6">
                    <b>Store Name</b>
                  </Typography>
                </TableCell>
                <TableCell>
                  <TextField
                    size="small"
                    fullWidth
                    placeholder="Enter store name"
                    value={imageData.store_name}
                    onChange={handleChange("store_name")}
                  />
                  <Autocomplete
                    freeSolo
                    size="small"
                    id="user-combo-box"
                    name="store_name"
                    value={imageData.store_name}
                    options={
                      allStoresData &&
                      allStoresData.map((item) => item.store_name)
                    }
                    sx={{ width: 300 }}
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Select Site" />
                    )}
                    onChange={(e, val) => handleAutoComplete(val)}
                  />
                </TableCell>
              </TableRow> */}
              <TableRow>
                <TableCell>
                  <Typography variant="h6">
                    <b>Title</b>
                  </Typography>
                </TableCell>
                <TableCell>
                  <TextField
                    size="small"
                    fullWidth
                    placeholder="Enter Title"
                    value={imageData.title}
                    onChange={handleChange("title")}
                  />
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  <Typography variant="h6">
                    <b>Alt Text</b>
                  </Typography>
                </TableCell>
                <TableCell>
                  <TextField
                    size="small"
                    fullWidth
                    placeholder="Enter alt_text"
                    value={imageData.alt_text}
                    onChange={handleChange("alt_text")}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography variant="h6">
                    <b>Image Type</b>
                  </Typography>
                </TableCell>
                <TableCell>
                  {/* <TextField
                    size="small"
                    fullWidth
                    placeholder="Enter image type"
                    value={imageData.image_type}
                    onChange={handleChange("image_type")}
                  />*/}

                  <Autocomplete
                    freeSolo
                    size="small"
                    id="user-combo-box"
                    name="store_name"
                    value={imageData.image_type}
                    options={imageType && imageType.map((item) => item.type)}
                    sx={{ width: 300 }}
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Select Image Type" />
                    )}
                    onChange={(e, val) => handleImageType(val)}
                  />
                </TableCell>
              </TableRow>

              {formType === "url" ? (
                <TableRow>
                  <TableCell>
                    <Typography variant="h6">
                      <b>Image URL</b>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <TextField
                      size="small"
                      fullWidth
                      placeholder="Enter image url"
                      value={imageData.img_url}
                      onChange={handleChange("img_url")}
                    />
                  </TableCell>
                </TableRow>
              ) : (
                <TableRow>
                  <TableCell>
                    <Typography variant="h6">
                      <b>Upload Image</b>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Input
                      className="imageFile h-auto"
                      accept="image/png, image/gif, image/jpeg ,image/jpg"
                      required={true}
                      type="file"
                      id="img"
                      name="image"
                      onChange={onImageSelect}
                    />
                    {/* <Upload listType="picture-card" accept="image/png, image/gif, image/jpeg ,image/jpg"
                    required={true} type="file" id="img" name="image"
                    onChange={onImageSelect}>
                    {!image && <Typography variant="subtitle2" sx={{ color: "var(--bs-gray-500)" }}><UploadIcon /><b>Upload</b></Typography>}
                  </Upload> */}
                    {/* {image && (
                      <img
                        src={image}
                        alt="uploaded-img"
                        style={{ marginTop: "8px" }}
                        height={150}
                      />
                    )} */}
                  </TableCell>
                </TableRow>
              )}

              <TableRow>
                <TableCell colSpan={2}>
                  <Button
                    variant="contained"
                    type="submit"
                    size="large"
                    className="d-block mx-auto mt-4 mb-3"
                  >
                    <strong>Submit</strong>
                  </Button>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Stack>
      </form>
    </Paper>
  );
}

export default AddImage;

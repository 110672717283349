import React from "react";
import {
  Paper,
  Box,
  Button,
  Stack,
  TextField,
  Container,
  Toolbar,
  Typography,
  Table,
  TableCell,
  TableRow,
  tableCellClasses,
  TableBody,
} from "@mui/material";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { message } from "antd";
import ReplyIcon from "@mui/icons-material/Reply";
import { useDispatch, useSelector } from "react-redux";
import { getShortenUrl } from "../../../redux/features/websiteSlices/storeSalesSlice";

function isValidUrl(str) {
  const pattern = new RegExp(
    "^([a-zA-Z]+:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR IP (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$", // fragment locator
    "i"
  );
  return pattern.test(str);
}

function ShortLinkUrl() {
  const dispatch = useDispatch();
  const { shortLinkData } = useSelector((state) => state.storeSales);
  const [shortUrlLinkData, setShortUrlLinkData] = React.useState({
    url: "",
    code: "",
  });
  const handleUrlChange = (event) => {
    setShortUrlLinkData((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  };

  const handleUrlShortner = (event) => {
    event.preventDefault();
    if (!isValidUrl(shortUrlLinkData.url)) {
      return message.error("Invalid URL");
    }
    let data = {};
    data.url = shortUrlLinkData.url;
    if (shortUrlLinkData.code) {
      data.code = shortUrlLinkData.code;
    }
    dispatch(getShortenUrl(data));
  };
  // console.log(shortLinkData, "shortLinkData");

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
    message.success("Link Copied");
  };

  return (
    <>
      <Paper elevation={3}>
        <form onSubmit={handleUrlShortner}>
          <Stack spacing={3} direction="column">
            <Toolbar
              mt={3}
              sx={{
                padding: "1.2rem",
                borderBottom: "2px solid var(--primary-color)",
              }}
            >
              <Typography
                sx={{
                  flex: "1 1 100%",
                  color: "var(--primary-color)",
                  fontWeight: "bold",
                }}
                variant="h5"
                id="tableTitle"
              >
                Url Shortner
              </Typography>
              <NavLink to="/websitedata/show-short-link">
                <Button
                  variant="contained"
                  color="info"
                  sx={{ whiteSpace: "nowrap" }}
                  startIcon={<ReplyIcon />}
                >
                  <strong>Show Short Links</strong>
                </Button>
              </NavLink>
            </Toolbar>
            <Table
              size="small"
              sx={{
                width: "auto",
                margin: "8px !important",
                marginTop: "16px !important",
                [`& .${tableCellClasses.root}`]: {
                  borderBottom: "none",
                },
              }}
            >
              <TableBody>
                <TableRow>
                  <TableCell>
                    <Typography variant="h6">
                      <b>URL</b>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <TextField
                      label="url"
                      id="url"
                      name="url"
                      type="url"
                      required
                      value={shortUrlLinkData.url}
                      onChange={handleUrlChange}
                    />
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>
                    <Typography variant="h6">
                      <b>Code</b>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <TextField
                      label="code"
                      id="code"
                      name="code"
                      value={shortUrlLinkData.code}
                      onChange={handleUrlChange}
                    />
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell colSpan="2" className="text-center">
                    <Button
                      type="submit"
                      variant="contained"
                      size="large"
                      sx={{ marginTop: 2, width: "160px" }}
                    >
                      <b>Submit</b>
                    </Button>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Stack>
        </form>
        {shortLinkData && (
          <Stack spacing={2}>
            <Toolbar
              mt={3}
              sx={{
                padding: "1.2rem",
                borderBottom: "2px solid var(--primary-color)",
              }}
            ></Toolbar>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <Typography variant="h6">
                      <b>Short Link Url</b>
                    </Typography>
                  </TableCell>
                  <TableCell colSpan="2" className="text-center">
                    <a href={shortLinkData} target="_blank">
                      {shortLinkData}
                    </a>
                  </TableCell>
                  <TableCell colSpan="2" className="text-center">
                    <Button
                      onClick={() => handleCopy(shortLinkData)}
                      variant="contained"
                      size="large"
                      sx={{ marginTop: 2, width: "160px" }}
                    >
                      Copy Link
                    </Button>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Stack>
        )}
      </Paper>
    </>
  );
}

export default ShortLinkUrl;

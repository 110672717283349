import { Paper, Typography } from "@mui/material";
import CustomInput from "./Form/CustomInput";
import CustomImageShow from "./Form/CustomImageShow";
import CustomButton from "./Form/CustomButton";
import LineChart from "./Form/lineChart";
import { useEffect, useState } from "react";
import TelegramIcon from "@mui/icons-material/Telegram";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
// import axios from "../../api/axios/axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import axios from "../../api/axios";
import { io } from "socket.io-client";
const socket = io("http://localhost:5600");
const CreateForm = ({ dealsData, setLink, rowData = null }) => {
  const [CreateData, setCreateData] = useState({});
  const navigate = useNavigate();

  const handelChange = (event) => {
    // console.log(event, "event");
    setCreateData({ ...CreateData, [event.target.name]: event.target.value });
  };
  useEffect(() => {
    if (dealsData && Object.keys(dealsData).length > 0) {
      setCreateData({
        ...CreateData,
        _id: dealsData?._id,
        pid: dealsData?.pid,
        sid: dealsData?.sid,
        title: dealsData?.title,
        imgurl: dealsData?.imgurl,
        mrp: dealsData?.mrp,
        active: dealsData?.active,
        category: dealsData?.category,
        sub_category: dealsData?.sub_category,
        brand: dealsData?.brand,
        latestPrice:
          dealsData?.latestPrice?.[0]?.price || dealsData?.latestPrice,
        graph_data: dealsData?.graph_data,
        aff_url: dealsData?.aff_url,
        color: dealsData?.color,
        inStock: dealsData?.latestPrice?.[0]?.inStock,
      });
    }
  }, [dealsData]);

  const activeOptions = [
    {
      value: 1,
      label: "True",
    },
    {
      value: 0,
      label: "False",
    },
  ];
  // console.log(dealsData, "dealsdata");
  const saveToDb = async (event) => {
    const CREATE_DATA_URL = "/api/deals/savetodb";
    const UPDATE_DATA_URL = "/api/deals/updateproductdb";
    try {
      if (rowData) {
        const update = await axios.put(UPDATE_DATA_URL, {
          ...CreateData,
        });
        toast.success(update.data.message);
        navigate("/show-deals-app");
      } else {
        const save = await axios.post(CREATE_DATA_URL, {
          ...CreateData,
        });
        toast.success(save.data.msg);
      }
      setCreateData({});
      setLink("");
    } catch (error) {
      toast.error(error.response.data.msg);
      console.log(error, "error in fetch db");
    }
  };

  const SendAppDeals = async () => {
    try {
      const message = "Hello from the client!";
      socket.emit("send_message", message);
      socket.emit("send_deals", CreateData || message);
      // toast.success(response.data.msg);
    } catch (error) {
      toast.error(error.response.data.msg);
    }
  };

  useEffect(() => {
    // Listen for broadcast messages
    console.log("inside useeffect");
    socket.on("broadcast_message", (message) => {
      console.log(message, "check message");
    });

    // Clean up the socket connection on component unmount
    return () => {
      socket.off("broadcast_message");
    };
  }, []);

  return (
    <div>
      <Paper className="create-form-container">
        <Typography variant="h5" component="h5" className="form-title">
          Product Details
        </Typography>
        <div className="form-wrapper">
          <div className="form-row md-flex-row">
            <CustomInput
              label="Pid"
              name="pid"
              className="input-grow"
              value={CreateData.pid}
              disabled={true}
            />
            <CustomInput
              label="Sid"
              name="sid"
              className="input-grow"
              value={CreateData.sid}
              disabled={true}
            />
          </div>
          <div className="form-row">
            <CustomInput
              label="Title"
              name="title"
              className="input-grow"
              value={CreateData.title}
              handelChange={handelChange}
            />
          </div>
          <div className="form-row md-flex-row">
            <CustomInput
              label="Imgurl"
              className="input-grow"
              value={CreateData.imgurl}
              name="imgurl"
              disabled={true}
            />
            <CustomImageShow link={CreateData.imgurl} />
          </div>
          <div className="form-row md-flex-row">
            <CustomInput
              label="MRP"
              className="input-grow"
              name="mrp"
              value={CreateData.mrp}
              handelChange={handelChange}
            />
            <CustomInput
              label="Latest Prize"
              className="input-grow"
              value={CreateData.latestPrice}
              name="latestPrice"
              handelChange={handelChange}
            />
          </div>
          <div className="form-row md-flex-row">
            <CustomInput
              label="In Stock"
              className="input-grow md-half-width"
              name="inStock"
              value={CreateData?.inStock}
              handelChange={handelChange}
              optionValue={true}
              withButton={true}
              activeOptions={activeOptions}
            />
            <CustomInput
              label="Color"
              className="input-grow md-half-width"
              name="color"
              value={CreateData?.color}
              handelChange={handelChange}
            />
          </div>
          <div className="form-row md-flex-row">
            <CustomInput
              label="Category"
              className="input-grow"
              name="category"
              value={CreateData.category}
              handelChange={handelChange}
            />
            <CustomInput
              label="Sub Category"
              className="input-grow"
              value={CreateData.sub_category}
              name="sub_category"
              handelChange={handelChange}
            />
          </div>
          <CustomInput
            label="Brand"
            className="input-grow"
            value={CreateData.brand}
            name="brand"
            handelChange={handelChange}
          />
          <CustomInput
            label="Affiliate Link"
            className="input-grow"
            value={CreateData.aff_url}
            name="aff_url"
            handelChange={handelChange}
          />
        </div>
        <div className="mt-5">
          <LineChart graphData={CreateData?.graph_data} />
        </div>
        <div
          className=""
          style={{
            marginTop: "40px",
            marginBottom: "15px",
            gap: "16px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <CustomButton
            value="Telegram"
            id="telegram"
            className=""
            variant="outlined"
            href="https://chatgpt.com/"
            target="_blank"
            icon={TelegramIcon}
          />
          <CustomButton
            value="Deals App"
            id="dealsApp"
            className=""
            variant="outlined"
            target="_blank"
            icon={PhoneAndroidIcon}
            handleClick={SendAppDeals}
          />
          <CustomButton
            value={!rowData ? "Save" : "Update"}
            id="save"
            className=""
            variant="contained"
            handleClick={saveToDb}
          />
        </div>
      </Paper>
    </div>
  );
};
export default CreateForm;

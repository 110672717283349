import React, { useState } from "react";
import {
  Toolbar,
  Table,
  TableCell,
  TableRow,
  TextField,
  tableCellClasses,
  Button,
  TableBody,
  Paper,
  Stack,
  Typography,
  TextareaAutosize,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  Divider,
} from "@mui/material";
import { grey } from '@mui/material/colors'

function SendNotification() {
  const [showNotification, setshowNotification] = useState({
    sendTo: "Flipshope Users",
    title: "",
    weblink: "",
    description: "",
    time: "",
    skipUsers: "",
    sendUsers: "",
    imageLink: "",
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log({ showNotification });
    // form reset after submit
    setshowNotification({
      sendTo: "Flipshope Users",
      title: "",
      weblink: "",
      description: "",
      time: "",
      skipUsers: "",
      sendUsers: "",
      imageLink: "",
    })
  };

  const handleChange = (key) => (event) => {
    setshowNotification({ ...showNotification, [key]: event.target.value });
  };

  return (
    <Paper elevation={3}>
      <form onSubmit={handleSubmit}>
        <Stack spacing={3} direction="column">
          <Toolbar
            mt={3}
            sx={{
              padding: "1.2rem",
              borderBottom: "2px solid var(--primary-color)",
            }}
          >
            <Typography
              sx={{
                color: "var(--primary-color)",
                fontWeight: "bold",
              }}
              variant="h5"
              id="tableTitle"
            >
              Send Notification
            </Typography>
          </Toolbar>
          <Table
            size="small"
            sx={{
              width: "auto",
              margin: "8px !important",
              [`& .${tableCellClasses.root}`]: {
                borderBottom: "none",
              },
            }}
          >
            <TableBody>
              <TableRow>
                <TableCell>
                  <Typography variant="h6">
                    <b>Send To</b>
                  </Typography>
                </TableCell>
                <TableCell>
                  <FormControl>
                    <RadioGroup
                      aria-labelledby="users-radio-buttons-group"
                      value={showNotification.sendTo}
                      onChange={handleChange("sendTo")}
                    >
                      <div className="RadioButton" style={{ display: "flex" }}>
                        <FormControlLabel
                          value="Flipshope Users"
                          control={<Radio />}
                          label="Flipshope Users"
                        />
                        <FormControlLabel
                          value="Amazon Users"
                          control={<Radio />}
                          label="Amazon Users"
                        />
                      </div>
                    </RadioGroup>
                  </FormControl>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  <Typography variant="h6">
                    <b>Title</b>
                  </Typography>
                </TableCell>
                <TableCell>
                  <TextField
                    size="small"
                    placeholder="Max 50 Characters"
                    fullWidth
                    required
                    value={showNotification.title}
                    onChange={handleChange("title")}
                  />
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  <Typography variant="h6">
                    <b>Web Link</b>
                  </Typography>
                </TableCell>
                <TableCell>
                  <TextField
                    size="small"
                    placeholder="Redirect URL"
                    fullWidth
                    required
                    value={showNotification.weblink}
                    onChange={handleChange("weblink")}
                  />
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  <Typography variant="h6">
                    <b>Description</b>
                  </Typography>
                </TableCell>
                <TableCell>
                  <TextareaAutosize
                    minRows={1}
                    maxRows={4}
                    value={showNotification.description}
                    onChange={handleChange("description")}
                    style={{
                      width: "100%",
                      padding: "8px 12px",
                      fontSize: "1rem",
                    }}
                    placeholder="Text Description..... "
                  />
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell colSpan={2}>
                  <Divider>
                    <Typography variant="body2" my={2}>
                      OPTIONAL PARAMETERS
                    </Typography>
                  </Divider>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  <Typography variant="h6">
                    <b>Time</b>
                  </Typography>
                </TableCell>
                <TableCell>
                  <TextField
                    type="number"
                    size="small"
                    min={0}
                    placeholder="Time to Alive (sec)"
                    fullWidth
                    // required
                    value={showNotification.time}
                    onChange={handleChange("time")}
                  />
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  <Typography variant="h6">
                    <b>Skip #Users</b>
                  </Typography>
                </TableCell>
                <TableCell>
                  <TextField
                    type="number"
                    size="small"
                    placeholder="Number of Users to Skip"
                    fullWidth
                    // required
                    value={showNotification.skipUsers}
                    onChange={handleChange("skipUsers")}
                  />
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  <Typography variant="h6">
                    <b>Send #Users</b>
                  </Typography>
                </TableCell>
                <TableCell>
                  <TextField
                    type="number"
                    size="small"
                    placeholder="Number of Users to Send"
                    fullWidth
                    // required
                    value={showNotification.sendUsers}
                    onChange={handleChange("sendUsers")}
                  />
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  <Typography variant="h6">
                    <b>Image Link</b>
                  </Typography>
                </TableCell>
                <TableCell>
                  <TextField
                    size="small"
                    placeholder="Image to Show"
                    fullWidth
                    // required
                    value={showNotification.imageLink}
                    onChange={handleChange("imageLink")}
                  />
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell colSpan={2}>
                  <Stack
                    direction="row"
                    spacing={5}
                    className="d-block mx-auto mt-4 mb-3"
                    sx={{ width: "fit-content" }}
                  >
                    <Button
                      variant="contained"
                      type="submit"
                      size="large"
                      onClick={handleSubmit}
                    >
                      <strong>Submit</strong>
                    </Button>
                    <Button
                      variant="contained"
                      size="large"
                      sx={{
                        bgcolor: grey[`A700`],
                        '&:hover': {
                          bgcolor: grey[900]
                        }
                      }}
                    >
                      <strong>Demo</strong>
                    </Button>
                  </Stack>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Stack>
      </form>
    </Paper>
  );
}

export default SendNotification;

import React, { useState } from 'react'
import {
  Button,
  FormControlLabel,
  IconButton,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableRow,
  TextField,
  Toolbar,
  Tooltip,
  Typography
} from '@mui/material'
import { deepPurple } from '@mui/material/colors';
import AddIcon from '@mui/icons-material/Add';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';

function HeaderFooter() {
  // state
  const [formData, setFormData] = useState({
    type: "header",
    menu_name: "",
    has_submenu: 'false',
    menu_url: "",
    submenu: null,
  })

  // Sub-menu handler
  const [inputBoxes, setInputBoxes] = useState([{ submenu_url: "", submenu_name: "" }])

  const handleInputBoxes = () => {
    const inpBox = [...inputBoxes, { submenu_url: "", submenu_name: "" }]
    setInputBoxes(inpBox)
  };

  const handleSubmenuInputChange = (e, i, key) => {
    const data = [...inputBoxes]
    if (key === 'submenu_name')
      data[i].submenu_name = e.target.value
    if (key === 'submenu_url')
      data[i].submenu_url = e.target.value
    setInputBoxes(data)
    setFormData({ ...formData, submenu: data })
  }
  const handleSubmenuInputRemove = (i) => {
    const data = [...inputBoxes]
    data.splice(i, 1)
    setInputBoxes(data)
    setFormData({ ...formData, submenu: data })
  }

  // handle form submit
  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(formData)
  };

  return (
    <Paper>
      <form onSubmit={handleSubmit}>
        <Stack spacing={3} direction="column">
          <Toolbar
            sx={{ padding: "1.2rem", borderBottom: "2px solid var(--primary-color)", gap: 1 }}
          >
            <Typography
              sx={{
                color: "var(--primary-color)",
                fontWeight: "bold",
              }}
              variant="h5"
            >
              <span style={{ fontFamily: 'inherit', color: formData.type === "header" ? 'inherit' : 'var(--bs-gray-500)', }}>Header</span>
              &nbsp;/&nbsp;
              <span style={{ fontFamily: 'inherit', color: formData.type === "footer" ? 'inherit' : 'var(--bs-gray-500)', }}>Footer</span>
            </Typography>
            <Tooltip title={formData.type === 'header' ? 'Switch to footer' : 'Switch to header'} arrow placement="right">
              <IconButton
                color='secondary'
                sx={{ padding: '2px', color: deepPurple['A200'] }}
                onClick={() => { formData.type === "header" ? setFormData({ ...formData, type: "footer" }) : setFormData({ ...formData, type: "header" }) }}
              >
                <SwapHorizIcon sx={{ fontSize: 28 }} />
              </IconButton>
            </Tooltip>
          </Toolbar>
          <Table
            size="small"
            sx={{
              width: "auto",
              margin: "8px !important",
              marginTop: "16px !important",
              [`& .${tableCellClasses.root}`]: {
                borderBottom: "none",
              },
            }}
          >
            <TableBody>
              <TableRow>
                <TableCell>
                  <Typography variant="h6">
                    <b>Menu Name</b>
                  </Typography>
                </TableCell>
                <TableCell>
                  <TextField
                    size="small"
                    fullWidth
                    placeholder="Enter menu name"
                    value={formData.menu_name}
                    onChange={(e) => setFormData({ ...formData, menu_name: e.target.value })}
                  />
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  <Typography variant="h6">
                    <b>Has Sub-Menu</b>
                  </Typography>
                </TableCell>
                <TableCell>
                  <RadioGroup
                    name="type"
                    aria-labelledby="type"
                    row
                    value={String(formData.has_submenu)}
                    onChange={(e) => setFormData({ ...formData, has_submenu: e.target.value })}
                  >
                    <FormControlLabel
                      control={<Radio />}
                      label="Yes"
                      value={true}
                    />
                    <FormControlLabel
                      control={<Radio />}
                      label="No"
                      value={false}
                    />
                  </RadioGroup>
                </TableCell>
              </TableRow>

              {formData.has_submenu === 'false' ?
                <TableRow>
                  <TableCell>
                    <Typography variant="h6">
                      <b>Menu URL</b>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <TextField
                      size="small"
                      fullWidth
                      placeholder="Enter menu url"
                      value={formData.menu_url}
                      onChange={(e) => setFormData({ ...formData, menu_url: e.target.value })}
                    />
                  </TableCell>
                </TableRow>
                : <> {inputBoxes.map((box, index) =>
                  <React.Fragment key={`submenu-${index}`}>
                    <TableRow>
                      <TableCell>
                        <Typography variant="h6" sx={{ whiteSpace: 'nowrap' }}>
                          <b>Submenu-{index + 1}</b>
                        </Typography>
                      </TableCell>

                      <TableCell>
                        <Stack direction="row" spacing={1}>
                          <TextField
                            id="name"
                            type="text"
                            fullWidth
                            size="small"
                            label="Submenu Name"
                            value={box.submenu_name}
                            onChange={(e) => handleSubmenuInputChange(e, index, "submenu_name")}
                          />
                          <TextField
                            id="name"
                            type="text"
                            fullWidth
                            size="small"
                            label="Submenu URL"
                            value={box.submenu_url}
                            onChange={(e) => handleSubmenuInputChange(e, index, "submenu_url")}
                          />
                          <IconButton onClick={() => handleSubmenuInputRemove(index)}>
                            <DeleteRoundedIcon />
                          </IconButton>
                        </Stack>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                )}
                  <TableRow>
                    <TableCell colSpan={2} className="text-center">
                      <Button
                        variant="outlined"
                        color="secondary"
                        startIcon={<AddIcon />}
                        sx={{ textTransform: 'initial', whiteSpace: "nowrap" }}
                        onClick={handleInputBoxes}
                      >
                        <strong>Add Submenu</strong>
                      </Button>
                    </TableCell>
                  </TableRow>
                </>}
              <TableRow>
                <TableCell colSpan={2} className="text-center">
                  <Button
                    variant='contained'
                    size="large"
                    sx={{ marginTop: 5, width: '200px' }}
                    onClick={handleSubmit}
                  >
                    <strong>Submit</strong>
                  </Button>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Stack>
      </form>
    </Paper>
  )
}

export default HeaderFooter
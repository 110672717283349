import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import TextField from "@mui/material/TextField";
const CustomInput = ({
  size = "small",
  value = "",
  placeholder = "",
  id = "",
  label = "",
  variant = "outlined",
  type = "text",
  className = "",
  readOnly = false,
  name = "",
  withButton = false,
  icon = null,
  loading = false,
  disabled = false,
  postfix = false,
  postfixLabel = "",
  optionValue = false,
  handelChange = () => {},
  handelButtonClick = () => {},
  // values=[]
  activeOptions,
}) => {
  // console.log(optionValue, "optionValue");
  // console.log(readOnly, "readOnly");
  return !withButton ? (
    <TextField
      value={value}
      name={name}
      className={className}
      onChange={(e) => handelChange(e)}
      size={size}
      placeholder={placeholder}
      id={id}
      label={label}
      variant={variant}
      type={type}
      disabled={disabled}
      // slotProps={{
      //   input: {
      //     readOnly: { readOnly },
      //   },
      // }}
    ></TextField>
  ) : (
    <FormControl
      // sx={{ width: "25ch" }}
      variant="outlined"
      className={className}
      size="small"
      disabled={loading}
    >
      <InputLabel htmlFor="outlined-adornment-password">{label}</InputLabel>
      {optionValue ? (
        <Select
          id={id}
          value={value}
          onChange={(e) => handelChange(e)}
          name={name}
          // placeholder={placeholder}
          variant={variant}
          type={type}
          // endAdornment={
          //   <InputAdornment position="end">
          //     {postfix ? (
          //       postfixLabel
          //     ) : (
          //       <IconButton
          //         disabled={loading}
          //         onClick={handelButtonClick}
          //         edge="end"
          //       >
          //         {true ? icon : null}
          //       </IconButton>
          //     )}
          //   </InputAdornment>
          // }
          label={label}
        >
          {activeOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      ) : (
        <OutlinedInput
          id={id}
          value={value}
          onChange={(e) => handelChange(e)}
          name={name}
          placeholder={placeholder}
          variant={variant}
          type={type}
          endAdornment={
            <InputAdornment position="end">
              {postfix ? (
                postfixLabel
              ) : (
                <IconButton
                  disabled={loading}
                  onClick={handelButtonClick}
                  edge="end"
                >
                  {true ? icon : null}
                </IconButton>
              )}
            </InputAdornment>
          }
          label={label}
          select={optionValue ? true : false}
        />
      )}
    </FormControl>
  );
};
export default CustomInput;

import { Button, Link } from '@mui/material'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getSaleProductsData, updateSaleProductData } from '../../redux/features/salesSlice'
import Datatable from '../Datatable/Datatable'

function SaleActivation() {
  const dispatch = useDispatch()
  const { saleProductsData } = useSelector(state => state.sales)

  // Coumns
  const column = [
    {
      name: 'p_id',
      label: "PID",
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'site',
      label: "Site",
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'name',
      label: "Product name",
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'url',
      label: "Product Link",
      options: {
        customBodyRender: (link) => {
          return <Link href={link} target="_blank">Open Product</Link>
        },
        filter: true,
        sort: true
      }
    },
    {
      name: 'active',
      label: "Action",
      options: {
        customBodyRender: (active, tableMeta) => {
          let data = {};
          data.pid = tableMeta.rowData[0];
          if (active) {
            data.active = 0;
            return (
              <Button variant="outlined" color="error" size="small" sx={{ textTransform: 'initial' }} onClick={() => saleAction(data)}><b>Deactivate</b></Button>
            )
          }
          else {
            data.active = 1;
            return (
              <Button variant="outlined" color="success" size="small" sx={{ textTransform: 'initial' }} onClick={() => saleAction(data)}><b>Activate</b></Button>
            )
          }
        },
        filter: true,
        sort: true
      }
    }
  ]

  const cbFunc = () => {
    dispatch(getSaleProductsData())
  }

  const saleAction = (formData) => {
    dispatch(updateSaleProductData({ formData, cbFunc }))
  }

  useEffect(() => {
    cbFunc();
  }, [])

  return (
    <div>
      <Datatable
        name="Product Active/Deactive List"
        data={saleProductsData && saleProductsData}
        columns={column}
      />
    </div>
  )
}

export default SaleActivation
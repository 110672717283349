import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import * as authApi from "../api/authApi";

const initialState = {
  modulesByUserData: [],
  userRolesData: [],
  userPermissionsData: {},
  modulesData: [],
  allModules: [],
  isAuthenticate: false,
  allPanelUsers: [],
  loading: false,
  error: "",
  success: "",
};

//========================================Change Password========================================//
export const changePasswordData = createAsyncThunk(
  "auth/changepassword",
  async (formData, { rejectWithValue }) => {
    try {
      const resp = await authApi.changePassword(formData);
      if (resp.data.code === 200) {
        message.success(
          resp.data.message
            ? resp.data.message
            : "Password changed successfully"
        );
        return resp.data.data;
      } else {
        message.error(
          resp.data.message ? resp.data.message : "Something went wrong"
        );
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

//=================================module permisiion=================================//
export const addModulePermission = createAsyncThunk(
  "auth/addmodulepermission",
  async (formData, { rejectWithValue }) => {
    try {
      const resp = await authApi.modulePermissions(formData);
      if (resp.data.code === 200) {
        message.success(resp.data.message ? resp.data.message : "changed");
        return resp.data.data;
      } else {
        message.error(
          resp.data.message ? resp.data.message : "somrthing went wrong"
        );

        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

//=================================module by user=================================//
export const getModulesByUserData = createAsyncThunk(
  "auth/modulesbyuser",
  async (formData, { rejectWithValue }) => {
    try {
      const resp = await authApi.modulesByUserData(formData);
      if (resp.data.code === 200) {
        return resp.data.data;
      } else {
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

//=================================add role module=================================//
export const addRolesModules = createAsyncThunk(
  "auth/userProfile",
  async (_, { rejectWithValue }) => {
    try {
      const resp = await authApi.addRolesModules();
      if (resp.data.code === 200) {
        message.success(
          resp.data.message ? resp.data.message : "added successfully"
        );

        return resp.data.data;
      } else {
        message.error(resp.data.message ? resp.data.message : "not added");

        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

//=================================show roles=================================//
export const showRolesData = createAsyncThunk(
  "auth/showroles",
  async (_, { rejectWithValue }) => {
    try {
      const resp = await authApi.showRoles();
      if (resp.data.code === 200) {
        return resp.data;
      } else {
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

//=================================add new role=================================//
export const addNewRolePanel = createAsyncThunk(
  "auth/addrole",
  async (formData, { rejectWithValue }) => {
    try {
      const resp = await authApi.addNewRole(formData);
      if (resp.data.code === 200) {
        message.success(
          resp.data.message
            ? resp.data.message
            : "New Role Created Successfully"
        );
        return resp.data;
      } else {
        message.error(
          resp.data.message ? resp.data.message : "Something went wrong"
        );
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

//=================================role activation=================================//
export const roleActivation = createAsyncThunk(
  "auth/activerole",
  async (formData, { rejectWithValue }) => {
    try {
      const resp = await authApi.activeRole(formData);
      if (resp.data.code === 200) {
        message.success(
          resp.data.message
            ? resp.data.message
            : "New Role Created Successfully"
        );
        return resp.data;
      } else {
        message.error(
          resp.data.message ? resp.data.message : "Something went wrong"
        );
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

//=================================user type modules=================================//
export const showuserTypeModulesData = createAsyncThunk(
  "auth/showmoduleusertype",
  async (_, { rejectWithValue }) => {
    try {
      const resp = await authApi.showuserTypeModulesData();
      if (resp.data.code === 200) {
        return resp.data.data;
      } else {
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

//=================================module-user management=================================//
export const getuserManagementRolesData = createAsyncThunk(
  "auth/usermangageRoles",
  async (formData, { rejectWithValue }) => {
    try {
      const resp = await authApi.userManagementRolesData(formData);
      if (resp.data.code === 200) {
        return resp.data;
      } else {
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

//=================================panel users=================================//
export const getAllPanelUsersData = createAsyncThunk(
  "auth/allpanelusers",
  async (_, { rejectWithValue }) => {
    try {
      const resp = await authApi.getAllPanelUsers();
      if (resp.data.code === 200) {
        return resp.data.data;
      } else {
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

//=================================update panel user=================================//
export const updatePanelUserData = createAsyncThunk(
  "auth/updatepaneluserss",
  async ({ formData, cbFunc }, { rejectWithValue }) => {
    try {
      const resp = await authApi.updatePanelUser(formData);
      if (resp.data.code === 200) {
        message.success(
          resp.data.message ? resp.data.message : "Updated successfully"
        );
        cbFunc();
        return resp.data.data;
      } else {
        message.error(resp.data.message ? resp.data.message : "Not updated");
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

//=================================all modules=================================//
export const showAllModules = createAsyncThunk(
  "auth/showallmodules",
  async (_, { rejectWithValue }) => {
    try {
      const resp = await authApi.showAllModules();
      if (resp.data.code === 200) {
        return resp.data.data;
      } else {
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

//=================================add new or update module=================================//
export const addUpdateNewModules = createAsyncThunk(
  "auth/newmodules",
  async (formData, { rejectWithValue }) => {
    try {
      const resp = await authApi.addNewModules(formData);
      if (resp.data.code === 200) {
        message.success(
          resp.data.message ? resp.data.message : "Action successful"
        );
        return resp.data.data;
      } else {
        message.error(
          resp.data.message ? resp.data.message : "Something went wrong"
        );
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

//=================================activate/deactivate module=================================//
export const activateModulesData = createAsyncThunk(
  "auth/activemodules",
  async (formData, { rejectWithValue }) => {
    try {
      const resp = await authApi.activateModules(formData);
      if (resp.data.code === 200) {
        message.success("Action successful");
        return resp.data.data;
      } else {
        message.error(
          resp.data.message ? resp.data.message : "Action not taken"
        );

        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    //=========================clear-user management================================//
    clearUserManagementRolesData: (state) => {
      state.userPermissionsData = {};
    },
  },
  extraReducers: (builder) => {
    //=================================changePasswordData===========================//
    builder.addCase(changePasswordData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(changePasswordData.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(changePasswordData.rejected, (state, action) => {
      state.error = action.payload;
    });
    //=================================addModulePermission data===========================//
    builder.addCase(addModulePermission.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addModulePermission.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(addModulePermission.rejected, (state, action) => {
      state.error = action.payload;
    });

    //=================================addRoleModulesdata===========================//.
    builder.addCase(addRolesModules.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addRolesModules.fulfilled, (state, action) => {
      state.success = action.payload;
    });
    builder.addCase(addRolesModules.rejected, (state, action) => {
      state.error = action.payload;
    });

    //=================================getModulesByUserData data===========================//

    builder.addCase(getModulesByUserData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getModulesByUserData.fulfilled, (state, action) => {
      state.modulesByUserData = action.payload;
    });
    builder.addCase(getModulesByUserData.rejected, (state, action) => {
      state.error = action.payload;
    });

    //=================================showRolesData data===========================//

    builder.addCase(showRolesData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(showRolesData.fulfilled, (state, action) => {
      state.userRolesData = action.payload.data;
      state.modulesData = action.payload.modules_data;
      state.userRolesData = action.payload.data;
    });
    builder.addCase(showRolesData.rejected, (state, action) => {
      state.error = action.payload;
    });

    //=================================addRolesData data===========================//

    builder.addCase(addNewRolePanel.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addNewRolePanel.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(addNewRolePanel.rejected, (state, action) => {
      state.error = action.payload;
    });

    //=================================activeRolesData data===========================//

    builder.addCase(roleActivation.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(roleActivation.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(roleActivation.rejected, (state, action) => {
      state.error = action.payload;
    });

    //=================================getuserManagementRolesData data===========================//

    builder.addCase(getuserManagementRolesData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getuserManagementRolesData.fulfilled, (state, action) => {
      state.userPermissionsData = {
        data: action.payload.data,
        userExcludedRole: action.payload.userExcludedRole,
        userIncludedRoles: action.payload.userIncludedRoles,
      };
    });
    builder.addCase(getuserManagementRolesData.rejected, (state, action) => {
      state.error = action.payload;
    });

    //=================================getAllPanelUsersData data===========================//

    builder.addCase(getAllPanelUsersData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllPanelUsersData.fulfilled, (state, action) => {
      state.allPanelUsers = action.payload;
      state.loading = false;
    });
    builder.addCase(getAllPanelUsersData.rejected, (state, action) => {
      state.error = action.payload;
    });

    //=================================updatePanelUsers=================================//
    builder.addCase(updatePanelUserData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updatePanelUserData.fulfilled, (state, action) => {
      state.loading = false;
      state.success = action.payload;
    });
    builder.addCase(updatePanelUserData.rejected, (state, action) => {
      state.error = action.payload;
    });

    //=================================showAllModules=================================//
    builder.addCase(showAllModules.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(showAllModules.fulfilled, (state, action) => {
      state.allModules = action.payload;
      state.loading = false;
    });
    builder.addCase(showAllModules.rejected, (state, action) => {
      state.error = action.payload;
    });

    //=================================addUpdateModule=================================//
    builder.addCase(addUpdateNewModules.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addUpdateNewModules.fulfilled, (state, action) => {
      state.success = action.payload;
      state.loading = false;
    });
    builder.addCase(addUpdateNewModules.rejected, (state, action) => {
      state.error = action.payload;
    });

    //=================================activateModule=================================//
    builder.addCase(activateModulesData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(activateModulesData.fulfilled, (state, action) => {
      state.loading = false;
      state.success = action.payload;
    });
    builder.addCase(activateModulesData.rejected, (state, action) => {
      state.error = action.payload;
    });
  },
});
export const { clearUserManagementRolesData } = authSlice.actions;
export default authSlice.reducer;

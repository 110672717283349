import React, { useEffect, useState } from "react";
import {
  Table,
  TableCell,
  TableRow,
  TextField,
  tableCellClasses,
  Button,
  TableBody,
  Stack,
  Typography,
  Toolbar,
  Autocomplete,
  Chip,
  Paper,
} from "@mui/material";
import SaveIcon from '@mui/icons-material/Save';
import { NavLink, useLocation } from "react-router-dom";
import ReplyIcon from '@mui/icons-material/Reply';
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useDispatch, useSelector } from "react-redux";
import { getAllCategoryPanel, addStoreCategoryPanel, getAllStoresData } from '../../../redux/features/storesSlice';
import { message } from "antd";

const AssignStoreCategory = () => {
  const dispatch = useDispatch();
  const { state } = useLocation();

  const { allcatData, allStoresData } = useSelector((state) => ({ ...state.stores }));
  const [type, setType] = useState(state && state.type ? state.type : "assign_category");

  // const [value, setValue] = useState();
  const [assignCategory, setassignCategory] = useState({
    store_name: state && state.data ? state.data.store_name : "",
    store_id: state && state.data ? state.data.store_id : "",
    category_name: [],
    category_id: [],
  });
  const [assignToData, setassignToData] = useState([]);

  //==============================category handler for edit==============================//
  useEffect(() => {
    if (state?.data && assignToData.length === 0) {
      let arrNames = [];
      arrNames = (state.data.category_names.slice(1, state.data.category_names.length - 1).split(',')).map((item) => item.replace(" ", ""));
      let arrIds = [];
      arrIds = (state.data.category_ids.slice(1, state.data.category_ids.length - 1).split(',').map((item) => item.replace(" ", "")));
      // console.log(arrNames, arrIds, "names and ids state data");
      // console.log(assignToData, "assignToData");
      let cnt = 0;
      for (let i = 0; i < arrNames.length; i++) {
        if (cnt == arrNames.length) break;
        assignToData.push({ name: arrNames[i], id: arrIds[i] });
        cnt++;
      }
      setassignToData([...assignToData]);
      console.log(assignToData, "assignToData new");
    }
  }, []);

  // add button handler for change category type
  const handleAddBtn = () => {
    const index = assignToData.find(item => item.name === assignCategory.category_name[0]);
    if (!assignCategory.category_name?.length) {
      message.error("No category selected");
    } else if (!index?.name) {
      setassignToData([...assignToData, { name: assignCategory.category_name[0], id: assignCategory.category_id[0] }]);
    } else {
      message.error("Category type already exists");
    }
  };

  // add button handler for change category type - {incomplete for any future reference //bharat}
  // const handleAddBtnTags = () => {
  //   const index = assignToDataTags.indexOf(assignCategory.category_tags);
  //   console.log(index);
  //   if (assignCategory.category_tags === "" || assignCategory.category_tags === null || assignCategory.category_tags === undefined) {
  //     // message.error("No name selected");
  //   } else if (index === -1) {
  //     setassignCategoryId([...assignCategoryId, assignCategory.category_id])
  //     setassignToDataTags([...assignToDataTags, assignCategory]);
  //     console.log(assignToDataTags, "assignToDataTags======>");
  //     console.log(assignCategory.category_id, "assignCategory");
  //   } else {
  //     // message.error("Category type already exists");
  //   }
  // };
  const handleStoreName = (e, value) => {
    e.preventDefault();
    // console.log(value, "select store");
    if (state?.data) {
      assignCategory.store_name = state.data.store_name;
      setassignCategory({ ...assignCategory, store_name: state.data.store_name, store_id: state.id.store_id });
    }
    else if (allStoresData.length > 0) {
      for (var i = 0; i < allStoresData.length; i++) {
        if (value === allStoresData[i].store_name) {
          setassignCategory({ ...assignCategory, store_name: allStoresData[i].store_name, store_id: allStoresData[i].store_id });
        }
      }
    }
  };

  const handleCategoryType = (e, value) => {
    e.preventDefault();
    // console.log(value, "select");
    if (allcatData.length > 0) {
      var arr_name = [];
      var arr_id = [];
      for (var i = 0; i < allcatData.length; i++) {
        if (value == allcatData[i].cat_name) {
          // console.log(true);
          arr_name.push(allcatData[i].cat_name);
          arr_id.push(allcatData[i].cat_id);
        }
        // else console.log(false);
        // console.log(arr_name, arr_id);
      }
      // setassignCategory({...assignCategory,  category_name: [...arr_name] });
      // setassignCategory({ ...assignCategory, category_id: [...arr_id] });
      assignCategory.category_id = arr_id;
      assignCategory.category_name = arr_name;
      // setValue(value);

    }
  };

  // incomplete for any future reference //bharat
  // const handleCategoryTags = (e, value) => {
  //   e.preventDefault();
  //   console.log(value, "select tags");
  //   if (dummyData.length > 0) {
  //     for (var i = 0; i < dummyData.length; i++) {
  //       if (value === dummyData[i].name) {
  //         setassignCategory({ ...assignCategory, category_tags: dummyData[i].tags });
  //       }
  //     }
  //   }
  // };

  // chip delete handler for category type
  const handleDelete = (item) => {
    // console.log("handle");
    setassignToData(assignToData.filter((key) => key.id !== item));
  };

  // chip delete handler for tags
  // const handleDeleteTags = (item) => {
  //   console.log("handle tags");
  //   setassignToData(assignToDataTags.filter((key) => key.category_tags !== item));
  // };
  const handleSubmit = (e) => {
    e.preventDefault();
    var data = {};
    data.type = type;
    data.store_id = assignCategory.store_id;
    data.cat_name = assignToData.map((item) => item.name.toString());
    data.cat_id = assignToData.map((item) => item.id.toString());
    dispatch(addStoreCategoryPanel(data));
    console.log(data, "data-=");
    // console.log(assignToData, "assignToData======>");
    // console.log("category assinged successfully");
  };

  useEffect(() => {
    // console.log(storeCategories, "storeCategories");
    dispatch(getAllCategoryPanel());
    dispatch(getAllStoresData());
  }, []);


  return (
    <Paper elevation={5}>
      <Stack spacing={3} direction="column">
        <Toolbar
          mt={3}
          sx={{
            padding: "1.2rem",
            display: "flex",
            borderBottom: "2px solid var(--primary-color)",
          }}
        >
          <Typography
            sx={{
              color: "var(--primary-color)",
              fontWeight: "bold",
              flex: "1 1 100%",
            }}
            variant="h5"
          >
            Assing Category
          </Typography>
          <NavLink to="/stores/manage-store-category">
            <Button variant="contained" color="info" sx={{ whiteSpace: 'nowrap' }} startIcon={<ReplyIcon />}>
              <strong>Show Store Category</strong>
            </Button>
          </NavLink>
        </Toolbar>

        <Table
          size="small"
          sx={{
            width: "auto",
            margin: "8px !important",
            marginTop: "16px !important",
            [`& .${tableCellClasses.root}`]: {
              borderBottom: "none",
            },
          }}
        >
          <TableBody>
            <TableRow>
              <TableCell>
                <Typography variant="h6">
                  <b>Store Name</b>
                </Typography>
              </TableCell>
              <TableCell>
                {/* autocomplete to be used here temporary disabled */}
                <Autocomplete
                  freeSolo
                  size="small"
                  id="user-combo-box"
                  options={allStoresData && allStoresData.map(item => item.store_name)}
                  sx={{ width: 256 }}
                  value={state && state.data ? state.data.store_name : null}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Select Store Name" />
                  )}
                  onChange={(e, value) => handleStoreName(e, value)}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography variant="h6">
                  <b>Change Category Type</b>
                </Typography>
              </TableCell>
              <TableCell>
                <Stack direction="row" spacing={3}>
                  <Autocomplete
                    freeSolo
                    size="small"
                    id="user-combo-box"
                    options={allcatData && allcatData.filter(item => item.active === 1).map(item => item.cat_name)}
                    sx={{ width: 256 }}
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Select Category" />
                    )}
                    onChange={(e, value) => handleCategoryType(e, value)}
                  />
                  <Button
                    variant="contained"
                    color="success"
                    startIcon={<AddCircleOutlineIcon />}
                    onClick={handleAddBtn}
                  >
                    <strong>Add</strong>
                  </Button>
                </Stack>
              </TableCell>
            </TableRow>
            {/* for the chip used */}
            {assignToData.length > 0 ? (
              <TableRow>
                <TableCell></TableCell>
                <TableCell>
                  <Stack spacing={1}>
                    {assignToData.map((item, index) => (
                      <Chip
                        key={`chip-${index}`}
                        label={item.name}
                        color="warning"
                        sx={{ width: 'fit-content' }}
                        onDelete={() => handleDelete(item.id)}
                      />
                    ))}
                  </Stack>
                </TableCell>
              </TableRow>
            ) : null}

            {/* Tags incomplete for any future reference  //bharat */}
            {/* <TableRow>
            <TableCell>
              <Typography variant="h6">
                <b>Select Tags</b>
              </Typography>
            </TableCell>
            <TableCell>
              <Stack direction="row" spacing={3}>
                <Autocomplete
                  freeSolo
                  size="small"
                  id="user-combo-box"
                  options={dummyData && dummyData.map(item => item.tags)}
                  sx={{ width: 256 }}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Select Tags" />
                  )}
                  onChange={(e, value) => handleCategoryTags(e, value)}
                />
                <Button
                  variant="contained"
                  color="success"
                  startIcon={<AddCircleOutlineIcon />}
                  onClick={handleAddBtnTags}
                >
                  <strong>Add</strong>
                </Button>
              </Stack>
            </TableCell>
          </TableRow> */}
            {/* for the chip used */}
            {/* {assignToDataTags.length > 0 ? (
            <TableRow>
              <TableCell></TableCell>
              <TableCell>
                <Stack spacing={1}>
                  {assignToDataTags.map((item, index) => (
                    <Chip
                      key={`chip-${index}`}
                      label={item.category_tags}
                      color="warning"
                      sx={{ width: 'fit-content' }}
                      onDelete={() => handleDeleteTags(item.category_tags)}
                    />
                  ))}
                </Stack>
              </TableCell>
            </TableRow>
          ) : null} */}

            <TableRow >
              <TableCell colSpan={2} className="text-center">
                <Button variant="contained" type="submit" color="info" startIcon={<SaveIcon />} onClick={handleSubmit}>
                  <strong>submit</strong>
                </Button>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Stack>
    </Paper>
  )
}

export default AssignStoreCategory
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react'
import { NavLink } from 'react-router-dom';
import {
  Paper,
  Stack,
  Toolbar,
  Typography,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  Button,
  Autocomplete,
  TextField,
  Divider,
  ButtonGroup,
  LinearProgress,
} from '@mui/material';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import Datatable from "../Datatable/Datatable";
import { useDispatch, useSelector } from 'react-redux';
import { deleteCouponData, searchCouponsData } from '../../redux/features/couponsSlice';
import { Popconfirm } from 'antd';


function ShowCoupon() {
  const dispatch = useDispatch()
  const siteData = useSelector(state => (state.coupons.allCouponsData ? state.coupons.allCouponsData.sites : []))
  const loading = useSelector(state => state.coupons.loading)
  const couponSearchData = useSelector(state => state.coupons.searchCouponsData)

  // Delete the coupon
  const handleCouponDelete = (event, rowData) => {
    let formData = { site_id: rowData[0], coupon_code: rowData[5] }
    console.log(formData)
    dispatch(deleteCouponData({ formData, cbFunc }))
  }
  // Callback function
  const cbFunc = () => {
    dispatch(searchCouponsData(searchData))
  }
  // type setter
  const [searchData, setSearchData] = useState({
    type: "store",
    site: "",
    site_name: "",
    code: "",
  });

  // Autocomplete selection change
  const handleSelectionChange = (val) => {
    for (let i = 0; i < siteData.length; i++) {
      if (siteData[i].site_name.toLowerCase() === val.toLowerCase()) {
        setSearchData({ ...searchData, site: siteData[i].site_id, site_name: val })
      }
    }
  }

  // Search handler
  const handleSearch = (event) => {
    event.preventDefault();
    let timer = setTimeout(dispatch(searchCouponsData(searchData)), 5000)
    clearTimeout(timer)
  }


  // Coupon Columns
  const columns = [
    {
      name: 'Site_ID',
      label: 'Action',
      options: {
        customBodyRender: (id, tableMeta) => {
          const couponData = {
            site_id: tableMeta.rowData[0],
            coupon_id: tableMeta.rowData[1],
            store_name: tableMeta.rowData[3],
            deal_url: tableMeta.rowData[4],
            title: tableMeta.rowData[6],
            short_title: tableMeta.rowData[7],
            short_desc: tableMeta.rowData[8],
            details: tableMeta.rowData[9],
            coupon_code: tableMeta.rowData[5],
            promotion: tableMeta.rowData[13],
            weightage: tableMeta.rowData[14],
            expiry_date: tableMeta.rowData[11],
            isVerified: tableMeta.rowData[12],
          }
          return (
            <ButtonGroup orientation="vertical" variant="text" color="success">
              <NavLink to="/coupons/add-coupon" state={{ couponData }}>
                <Button size="small" color="success">
                  <strong>Edit</strong>
                </Button>
              </NavLink>
              <Popconfirm
                placement="right"
                title="Delete coupon"
                description="Do you really want to delete the coupon?"
                okText="Yes"
                cancelText="No"
                onConfirm={(event) => handleCouponDelete(event, tableMeta.rowData)}
              >
                <Button size="small" color="error"><strong>Delete</strong></Button>
              </Popconfirm>
            </ButtonGroup>
          )
        },
        sort: false,
        filter: false,
      }
    },

    {
      name: 'Coupon_ID',
      label: 'Coupon Id',
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: 'Start_Date',
      label: 'Start Date',
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: 'merchant',
      label: 'Site',
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: 'homepage',
      label: 'URL',
      options: {
        customBodyRender: (link) => {
          return (
            <a href={link} target="_blank" rel="noreferrer">{link}</a>
          )
        },
        filter: true,
        sort: true,
      }
    },
    {
      name: 'Coupon_Code',
      label: 'Code',
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: 'Coupon_Title',
      label: 'Title',
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: 'Very_Short_Title',
      label: 'Short Title',
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: 'Coupon_Short_Description',
      label: 'Short Description',
      options: {
        customBodyRender: (val) => <div dangerouslySetInnerHTML={{ __html: val }} />,
        filter: true,
        sort: false,
      }
    },
    {
      name: 'Coupon_Full_Description',
      label: 'Description',
      options: {
        customBodyRender: (val) => <div dangerouslySetInnerHTML={{ __html: val }} />,
        filter: true,
        sort: false,
      }
    },
    {
      name: 'Coupon_Link',
      label: 'Link',
      options: {
        customBodyRender: (link) => {
          return (
            <a href={link} target="_blank" rel="noreferrer">{link}</a>
          )
        },
        filter: true,
        sort: true,
      }
    },
    {
      name: 'Expiry_Date',
      label: 'Expiry Date',
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: 'verified',
      label: 'Verified',
      options: {
        customBodyRender: (verified) => {
          if (verified === 1) {
            return (
              <CheckCircleIcon color='success' />
            )
          }
          else {
            return (
              <CancelIcon color='error' />
            )
          }
        },
        filter: true,
        sort: true,
      }
    },
    {
      name: 'Weightage',
      label: 'Weightage',
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: 'Promotion_Level',
      label: 'Promotion',
      options: {
        filter: true,
        sort: true,
      }
    },
  ]

  return (
    <Paper elevation={5}>
      <Toolbar
        mt={3}
        sx={{ padding: "1.2rem", borderBottom: "2px solid var(--primary-color)" }}
      >
        <Typography
          sx={{
            flex: "1 1 100%",
            color: "var(--primary-color)",
            fontWeight: "bold",
          }}
          variant="h5"
          id="tableTitle"
        >
          Coupon Data
        </Typography>
        <NavLink to="/coupons/add-coupon">
          <Button variant="contained" sx={{ whiteSpace: 'nowrap' }} startIcon={<AddCircleOutlineRoundedIcon />}>
            <strong>Add New</strong>
          </Button>
        </NavLink>

      </Toolbar>
      <Stack
        direction="column"
        spacing={3}
        px={4}
        py={3}
        justifyContent="center"
        alignItems="center"
      >
        <Stack direction="row" spacing={4} alignItems="center" >
          <Typography variant="h6">
            <b>Search By :</b>
          </Typography>

          <FormControl>
            {/* Radio Button Group to toggle between Stores and Coupons*/}
            <RadioGroup
              name="coupon-type-radio-buttons-group"
              row
              value={searchData.type}
              onChange={(event) => { setSearchData({ ...searchData, type: event.target.value }) }}
            >
              <FormControlLabel value="store" control={<Radio />} label="Store" />
              <FormControlLabel value="code" control={<Radio />} label="Coupon" />
            </RadioGroup>
          </FormControl>
        </Stack>

        <form onSubmit={handleSearch}>
          <Stack direction="row" spacing={2} alignItems="center" >
            {searchData.type === "store"
              ? <Autocomplete
                size="small"
                freeSolo
                id="store-combo-box"
                value={searchData.site_name}
                options={siteData && siteData.map(row => row.site_name)}
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} placeholder="Select Store Here" />
                )}
                onChange={(e, val) => handleSelectionChange(val)} />
              : <TextField
                size="small"
                value={searchData.code}
                sx={{ width: 300 }}
                placeholder="Enter coupon code"
                onChange={(e) => setSearchData({ ...searchData, code: e.target.value })}
              />}
            <Button
              type="submit"
              variant="outlined"
              sx={{
                textTransform: 'initial',
                borderWidth: "2px",
                '&:hover': {
                  borderWidth: "2px",
                }
              }}
              onClick={handleSearch}
            ><b>Search</b></Button>
          </Stack>
        </form>

      </Stack >
      <Divider sx={{ borderColor: 'black' }} />
      {loading && <LinearProgress />}
      {couponSearchData.length > 0 && <Datatable name="Coupons List" columns={columns} data={couponSearchData} />}
    </Paper >
  );
}

export default ShowCoupon
import React, { useState, useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    ImageList,
    ImageListItem,
    ImageListItemBar,
    Paper,
    Stack,
    TextField,
    Toolbar,
    Tooltip,
    Typography,
    IconButton,
    RadioGroup,
    FormControlLabel,
    Radio,
} from '@mui/material';
import { Image, message, Skeleton } from 'antd';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import AddBoxIcon from '@mui/icons-material/AddBox';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { getManageBannersAndOffersImages, liveBannersAndOffers, updateBannerAndOffer, deleteManageBannersAndOffers } from '../../../redux/features/websiteSlices/bannerofferSlice';

function LiveOffers() {
    const dispatch = useDispatch();
    const { bannerofferData, liveBannerOffer, updateBannerOffer, deleteBannerOffer } = useSelector((state) => ({ ...state.bannerOffer }));
    // Dialog
    const [open, setOpen] = React.useState(false);
    const [dialogData, setDialogData] = useState({});

    const handleSubmit = () => {
        dispatch(updateBannerAndOffer(dialogData));
        dispatch(liveBannersAndOffers(dialogData));
        setOpen(false);
    };
    const handleEditImage = (e, data) => {
        e.preventDefault();
        setOpen(true);
        setDialogData(data);
    };
    const handleDeleteImage = (e, item) => {
        e.preventDefault();
        dispatch(deleteManageBannersAndOffers(item));
        dispatch(liveBannersAndOffers(item));
    };
    const copyImageUrl = async (e, img_url) => {
        e.preventDefault();
        await navigator.clipboard.writeText(img_url && img_url);
        message.success("Image url Copied");
    };


    useEffect(() => {
        let data = {};
        data.type = 'Offers';
        dispatch(liveBannersAndOffers(data));
    }, []);

    return (
        <Paper>
            <Stack spacing={3} direction="column">
                <Toolbar
                    sx={{ padding: "1.2rem", borderBottom: "2px solid var(--primary-color)" }}
                >
                    <Typography
                        sx={{
                            flex: "1 1 100%",
                            color: "var(--primary-color)",
                            fontWeight: "bold",
                        }}
                        variant="h5"
                    >
                        Live Offers
                    </Typography>

                    <NavLink to="/websitedata/upload-banner" state={{ type: 'Offers' }}>
                        <Button variant="contained" startIcon={<AddBoxIcon />} size="normal" sx={{ whiteSpace: 'nowrap' }}>
                            <strong>Add New</strong>
                        </Button>
                    </NavLink>
                </Toolbar>

                <Stack direction="row" spacing={2} px={4} pb={3}>
                    {/* for non live offers   */}
                    <Stack direction="column" spacing={2}>
                        <Typography className='text-center' fontSize={'1.2rem'}>
                            <b>Non-live Offers</b>
                        </Typography>
                        <Box sx={{ width: '100%', height: '62vh', overflowY: 'scroll' }}>
                            <Image.PreviewGroup>
                                <ImageList cols={1}>
                                    {liveBannerOffer ? liveBannerOffer.filter((item) => item.live === 0 && item.type === 'Offers').map((item) =>
                                        <ImageListItem key={item.b_id}>
                                            <Image src={item.img_url} alt={item.b_id ? item.b_id : ""}
                                                preview
                                                style={{ objectFit: "contain" }}
                                            />
                                            <ImageListItemBar
                                                sx={{ background: 'transparent' }}
                                                position="top"
                                                actionIcon={
                                                    <Tooltip title="Edit Image Data" arrow>
                                                        <IconButton
                                                            sx={{
                                                                borderRadius: '0 0 0 50%',
                                                                width: "32px",
                                                                height: "32px",
                                                                color: "white",
                                                                bgcolor: 'var(--secondary-color)',
                                                                '&:hover': {
                                                                    bgcolor: 'var(--secondary-color)',
                                                                    filter: 'contrast(2)',
                                                                }
                                                            }}
                                                            onClick={(e) => handleEditImage(e, item)}
                                                        >
                                                            <EditIcon sx={{ fontSize: 18 }} />
                                                        </IconButton>
                                                    </Tooltip>
                                                }
                                                actionPosition="right"
                                            />
                                            <ImageListItemBar
                                                sx={{
                                                    // padding: '7px 10px !important',
                                                    '& .MuiImageListItemBar-titleWrap': {
                                                        padding: '7px 10px !important',
                                                    }
                                                }}
                                                subtitle={
                                                    <Stack direction='row' justifyContent='space-between' alignItems='center'>
                                                        <Box>
                                                            <Typography><b>Priority:{item.priority ? item.priority : " - "}, Live:{item.live}</b></Typography>
                                                        </Box>
                                                        <Stack direction="row" spacing={2}>
                                                            <Tooltip title="Copy ImageUrl">
                                                                <IconButton
                                                                    size="small"
                                                                    color='warning'
                                                                    sx={{
                                                                        width: "32px",
                                                                        height: "32px",
                                                                        color: "black",
                                                                        bgcolor: 'var(--bs-light)',
                                                                        '&:hover': {
                                                                            color: 'black',
                                                                            bgcolor: 'var(--bs-light)',
                                                                            filter: 'contrast(2)',
                                                                        }
                                                                    }}
                                                                    onClick={(e) => copyImageUrl(e, item.img_url)}
                                                                >
                                                                    <ContentCopyIcon sx={{ fontSize: 18 }} />
                                                                </IconButton>
                                                            </Tooltip>
                                                            <Tooltip title="Delete Banner">
                                                                <IconButton
                                                                    size="small"
                                                                    color='error'
                                                                    sx={{
                                                                        width: "32px",
                                                                        height: "32px",
                                                                        color: "white",
                                                                        bgcolor: 'var(--bs-red)',
                                                                        '&:hover': {
                                                                            color: 'white',
                                                                            bgcolor: 'var(--bs-red)',
                                                                            filter: 'contrast(2)',
                                                                        }
                                                                    }}
                                                                    onClick={(e) => handleDeleteImage(e, item)}
                                                                >
                                                                    <DeleteIcon sx={{ fontSize: 18 }} />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </Stack>
                                                    </Stack>}
                                            />
                                        </ImageListItem>
                                    ) : <Skeleton.Image active />}
                                </ImageList>
                            </Image.PreviewGroup>
                        </Box>
                    </Stack>
                    <Divider orientation='vertical' flexItem sx={{ borderColor: 'black' }} />
                    {/* for live offers */}
                    <Stack direction="column" spacing={2}>
                        <Typography className='text-center' fontSize={'1.2rem'}>
                            <b>Live Offers</b>
                        </Typography>
                        <Box sx={{ width: '100%', height: '62vh', overflowY: 'scroll' }}>
                            <Image.PreviewGroup>
                                <ImageList cols={1}>
                                    {liveBannerOffer ? liveBannerOffer.filter((item) => item.live === 1 && item.type === 'Offers').map((item) =>
                                        <ImageListItem key={item.b_id}>
                                            <Image src={item.img_url} alt={item.b_id ? item.b_id : ""}
                                                preview
                                                style={{ objectFit: "contain" }}
                                            />
                                            <ImageListItemBar
                                                sx={{ background: 'transparent' }}
                                                position="top"
                                                actionIcon={
                                                    <Tooltip title="Edit Image Data" arrow>
                                                        <IconButton
                                                            sx={{
                                                                borderRadius: '0 0 0 50%',
                                                                width: "32px",
                                                                height: "32px",
                                                                color: "white",
                                                                bgcolor: 'var(--secondary-color)',
                                                                '&:hover': {
                                                                    bgcolor: 'var(--secondary-color)',
                                                                    filter: 'contrast(2)',
                                                                }
                                                            }}
                                                            onClick={(e) => handleEditImage(e, item)}
                                                        >
                                                            <EditIcon sx={{ fontSize: 18 }} />
                                                        </IconButton>
                                                    </Tooltip>
                                                }
                                                actionPosition="right"
                                            />
                                            <ImageListItemBar
                                                sx={{
                                                    // padding: '7px 10px !important',
                                                    '& .MuiImageListItemBar-titleWrap': {
                                                        padding: '7px 10px !important',
                                                    }
                                                }}
                                                subtitle={
                                                    <Stack direction='row' justifyContent='space-between' alignItems='center'>
                                                        <Box>
                                                            <Typography><b>Priority: {item.priority ? item.priority : " - "}, Live: {item.live}</b></Typography>
                                                        </Box>
                                                        <Stack direction="row" spacing={2}>
                                                            <Tooltip title="Copy ImageUrl">
                                                                <IconButton
                                                                    size="small"
                                                                    color='warning'
                                                                    sx={{
                                                                        width: "32px",
                                                                        height: "32px",
                                                                        color: "black",
                                                                        bgcolor: 'var(--bs-light)',
                                                                        '&:hover': {
                                                                            color: 'black',
                                                                            bgcolor: 'var(--bs-light)',
                                                                            filter: 'contrast(2)',
                                                                        }
                                                                    }}
                                                                    onClick={(e) => copyImageUrl(e, item.img_url)}
                                                                >
                                                                    <ContentCopyIcon sx={{ fontSize: 18 }} />
                                                                </IconButton>
                                                            </Tooltip>
                                                            {/* <Tooltip title="Delete Banner">
                                                                <IconButton
                                                                    size="small"
                                                                    color='error'
                                                                    sx={{
                                                                        width: "32px",
                                                                        height: "32px",
                                                                        color: "white",
                                                                        bgcolor: 'var(--bs-red)',
                                                                        '&:hover': {
                                                                            color: 'white',
                                                                            bgcolor: 'var(--bs-red)',
                                                                            filter: 'contrast(2)',
                                                                        }
                                                                    }}
                                                                    onClick={(e) => handleDeleteImage(e, item)}
                                                                >
                                                                    <DeleteIcon sx={{ fontSize: 18 }} />
                                                                </IconButton>
                                                            </Tooltip> */}
                                                        </Stack>
                                                    </Stack>}
                                            />
                                        </ImageListItem>
                                    ) : <Skeleton.Image active />}
                                </ImageList>
                            </Image.PreviewGroup>
                        </Box>
                    </Stack>
                </Stack>
            </Stack>

            <Dialog open={open}>
                <DialogTitle><b>Edit Priority Number</b></DialogTitle>
                <DialogContent>
                    <Box mt={2} className="d-flex align-items-center gap-2">
                        <Typography><b>New Image URL : </b></Typography>
                        <TextField
                            autoFocus
                            size="small"
                            value={dialogData.img_url}
                            type="string"
                            onChange={(e) => setDialogData({ ...dialogData, img_url: e.target.value })}
                        />
                    </Box>
                    <Box mt={2} className="d-flex align-items-center gap-2">
                        <Typography><b>Change Live Status: </b></Typography>
                        <RadioGroup
                            name="type"
                            aria-labelledby="type"
                            row
                            value={dialogData.live}
                            onChange={(e) => setDialogData({ ...dialogData, live: e.target.value })}
                        >
                            <FormControlLabel
                                control={<Radio />}
                                label="Live"
                                value={1}
                            />
                            <FormControlLabel
                                control={<Radio />}
                                label="Non-live"
                                value={0}
                            />
                        </RadioGroup>
                    </Box>
                    <Box mt={2} className="d-flex align-items-center gap-2">
                        <Typography><b>New Priority Number : </b></Typography>
                        <TextField
                            autoFocus
                            size="small"
                            value={dialogData.priority}
                            type="Number"
                            onChange={(e) => setDialogData({ ...dialogData, priority: e.target.value })}
                        />
                    </Box>
                    <Box mt={2} className="d-flex align-items-center gap-2">
                        <Typography><b>New Status : </b></Typography>
                        <RadioGroup
                            name="active"
                            aria-labelledby="type"
                            row
                            value={dialogData.active}
                            onChange={(e) => setDialogData({ ...dialogData, active: e.target.value })}
                        >
                            <FormControlLabel
                                control={<Radio />}
                                label="Active"
                                value={1}
                            />
                            <FormControlLabel
                                control={<Radio />}
                                label="Inactive"
                                value={0}
                            />
                        </RadioGroup>
                    </Box>
                </DialogContent>
                <DialogActions p={3}>
                    <Button variant="outlined" color="error" onClick={() => setOpen(false)}>Cancel</Button>
                    <Button variant="contained" color="primary" onClick={handleSubmit}>Save</Button>
                </DialogActions>
            </Dialog>
        </Paper>


    )
}

export default LiveOffers
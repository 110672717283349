export default function dateConverter(str) {
    // console.log(str, "str")
    var date = new Date(str),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
    let hours = ("0" + date.getHours()).slice(-2);
    let minutes = ("0" + date.getMinutes()).slice(-2);
    let seconds = ("0" + date.getSeconds()).slice(-2);
    let year = date.getFullYear();
    return `${year}-${mnth}-${day} ${hours}:${minutes}:${seconds}`
}
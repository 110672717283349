import React, { useEffect, useState } from 'react'
import { TableBody, Table, TableCell, TableRow, TextField, Paper, Stack, Button, Typography, tableCellClasses } from '@mui/material';
import Moment from "moment";
import { Checkbox } from "antd";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';
import { changeSaleDate, getFlashSaleProductsData } from "../../../redux/features/salesSlice"
import dateConverter from '../../../basicFunctions/dateConverter';


function DateChangeComponent({ flashsaleproductData }) {
  const dispatch = useDispatch();
  const [changeData, setChangeData] = useState({
    isDate2Null: true,
    isDate3Null: true,
    date1: null,
    date2: null,
    date3: null
  });

  const handleDatePicker = (dateString, name) => {
    console.log(dateString, "name", name)
    if (name == "date1") {
      setChangeData({
        ...changeData,
        date1: dayjs(dateString)
      })
    }
    else if (name == "date2") {
      setChangeData({
        ...changeData,
        date2: dayjs(dateString),
        isDate2Null: false
      })
    } else if (name == "date3") {
      setChangeData({
        ...changeData,
        date3: dayjs(dateString),
        isDate3Null: false
      })
    }
  }
  const handleCheckBox = (name) => {
    if (name == "date2") {
      setChangeData({
        ...changeData,
        isDate2Null: !changeData.isDate2Null,
        date2: null
      })
    } else if (name == "date3") {
      setChangeData({
        ...changeData,
        isDate3Null: !changeData.isDate3Null,
        date3: null
      })
    }
  }
  const cbFunc = () => {
    dispatch(getFlashSaleProductsData());
  }
  const handleSubmit = (e, pid) => {
    e.preventDefault();
    const { date1, date2, date3 } = changeData;
    let formData = {};
    if (date1) {
      formData.date1 = dateConverter(date1);
      if (date2) formData.date2 = dateConverter(date2);
      formData.date2 = null;
      if (date3) formData.date3 = dateConverter(date3);
      formData.date3 = null;
    } else {
      formData.date1 = dateConverter(flashsaleproductData.date1);
      if (date2) formData.date2 = dateConverter(date2);
      formData.date2 = null;
      if (date3) formData.date3 = dateConverter(date3);
      formData.date3 = null;
    }
    formData.pid = pid;
    console.log("formData", formData);
    // dispatch(changeSaleDate({ formData, cbFunc }))
    // changeSaleDate(data);

  }


  useEffect(() => {
    if (flashsaleproductData) {
      let dates = { 'date1': dayjs(flashsaleproductData.date1) }
      if (flashsaleproductData.date2) {
        dates['date2'] = dayjs(flashsaleproductData.date2)
        dates['isDate2Null'] = false;
      }
      if (flashsaleproductData.date3) {
        dates['date3'] = dayjs(flashsaleproductData.date3)
        dates['isDate3Null'] = false;
      }
      setChangeData({
        ...changeData,
        ...dates
      })
    }
  }, []);



  // console.log("flashsaleproductData", changeData)
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Paper elevation={3}>
          <Stack spacing={3} direction="column">
            <Table
              sx={{
                width: "auto",
                [`& .${tableCellClasses.root}`]: {
                  borderBottom: "none",
                },
              }}>
              <TableBody>
                <TableRow>
                  <TableCell colSpan={4} sx={{ backgroundColor: "var(--bs-gray-700)", color: "white" }} className="d-flex align-items-center justify-content-center">
                    <Typography variant="body1">
                      <b>{flashsaleproductData?.name} on {flashsaleproductData?.site}</b>
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow className='d-flex justify-content-center flex-wrap'>
                  <TableCell>
                    <DateTimePicker
                      fullWidth
                      value={changeData.date1 && changeData.date1}
                      onChange={(newValue) => handleDatePicker(newValue, "date1")}
                      renderInput={(params) => (
                        <TextField {...params} size="small" helperText="Clear Initial State" />
                      )}
                    />
                  </TableCell>
                  <TableCell className='d-flex'>
                    <Checkbox onChange={() => handleCheckBox("date2")} style={{ marginTop: "5px" }} checked={changeData.isDate2Null}>Null</Checkbox>

                    <DateTimePicker
                      value={changeData.date2 && changeData.date2}
                      onChange={(newValue) => handleDatePicker(newValue, "date2")}
                      renderInput={(params) => (
                        <TextField {...params} size="small" helperText="Clear Initial State" />
                      )}
                    />
                  </TableCell>
                  <TableCell className='d-flex'>
                    <Checkbox onChange={() => handleCheckBox("date3")} style={{ marginTop: "5px" }} checked={changeData.isDate3Null}>Null</Checkbox>
                    <DateTimePicker
                      value={changeData.date3 && changeData.date3}
                      onChange={(newValue) => handleDatePicker(newValue, "date3")}
                      renderInput={(params) => (
                        <TextField {...params} size="small" helperText="Clear Initial State" />
                      )}
                    />
                  </TableCell>
                  <TableCell>
                    <Button variant="contained" size="large" type="submit" color="info" onClick={(e) => handleSubmit(e, flashsaleproductData.p_id)} >
                      <strong>Submit</strong>
                    </Button>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Stack>
        </Paper>
      </LocalizationProvider>
    </>
  )
}

export default DateChangeComponent;
// "use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
if (process.env.NODE_ENV === 'development') {
    exports.default = {
        panelURL: "https://localhost:3000/",
        // baseURL: "https://flipshope.com/api",
        imageURL: "https://cdn.flipshope.com/",
        baseURL: "http://localhost:3000/api",
        backendURL: "http://localhost:5600/api"
    };
}
else {
    exports.default = {
        websiteURL: "https://team.flipshope.com/",
        baseURL: "https://team.flipshope.com/api",
        imageURL: "https://cdn.flipshope.com/",
        // baseURL: "http://localhost:3000/api",
        backendURL: "https://team.flipshope.com/api"
    };
}
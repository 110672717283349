// import React from 'react'
// import { Box, Button, CircularProgress, Dialog, DialogContent, DialogTitle, Divider, Stack } from '@mui/material'
// import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
// import { Chart as ChartJS, Legend, LineElement, PointElement, CategoryScale, LinearScale, Tooltip, Title } from 'chart.js';
// import { Line } from 'react-chartjs-2';
// import { useDispatch, useSelector } from 'react-redux';
// import { productPriceGraphData } from '../../redux/features/dealsSlice';

// ChartJS.register(
//     Legend,
//     LineElement,
//     PointElement,
//     CategoryScale,
//     LinearScale,
//     Tooltip,
//     Title,
// )

// function PIDGraph(props) {
//     const dispatch = useDispatch();
//     const { loading, priceGraphData } = useSelector(state => state.deals)

//     const [dialogOpen, setDialogOpen] = React.useState(false);

//     // graph dialog open
//     const openPidModal = () => {
//         let data = {};
//         data.Store = props.sid;
//         data.PID = props.pid;
//         dispatch(productPriceGraphData(data))



//         setDialogOpen(true)
//     }

//     // Graph dialog close
//     const handleClose = () => {
//         setDialogOpen(false);
//     }

//     return (
//         <>
//             <Button
//                 variant="outlined"
//                 size="small"
//                 color="secondary"
//                 sx={{ textTransform: 'initial' }}
//                 onClick={() => openPidModal()}
//             >
//                 <b>OpenModel</b>
//             </Button>
//             <Dialog open={dialogOpen} onClose={handleClose} fullWidth>
//                 <DialogTitle>
//                     <Stack direction="row">
//                         <b className='text-capitalize' style={{ flex: '1 1 100%' }}>{`PID Graph >> ${props.pid}`}</b>
//                         <Button
//                             color="error"
//                             variant="outlined"
//                             size="small"
//                             sx={{ padding: "4px", minWidth: 'fit-content' }}
//                             onClick={handleClose}
//                         >
//                             <CloseRoundedIcon />
//                         </Button>
//                     </Stack>
//                 </DialogTitle>
//                 <Divider sx={{ borderColor: 'black' }} />
//                 <DialogContent>
//                     <Box >
//                         {loading
//                             ? <Stack justifyContent="center" alignItems="center" sx={{ width: "100%", height: "100%" }}>
//                                 <CircularProgress
//                                     variant="indeterminate"
//                                     disableShrink />
//                             </Stack>
//                             : <Line
//                                 data={{
//                                     labels: priceGraphData.map(row => row.time),
//                                     datasets: [
//                                         {
//                                             label: 'Max Price',
//                                             data: priceGraphData.map(row => row.max_price),
//                                             backgroundColor: 'rgba(255, 97,97, 0.5)',
//                                             borderColor: 'rgba(255, 97,97, 1)',
//                                         },
//                                         {
//                                             label: 'Min Price',
//                                             data: priceGraphData.map(row => row.min_price),
//                                             backgroundColor: 'rgba(74, 188, 105, 0.5)',
//                                             borderColor: 'rgba(74, 188, 105, 1)',
//                                         }
//                                     ],
//                                 }}
//                                 options={{
//                                     responsive: true,
//                                     scales: {
//                                         y: {
//                                             beginAtZero: true,
//                                         },
//                                     },
//                                     plugins: {
//                                         title: {
//                                             display: true,
//                                             text: "Product Price Drop"
//                                         }
//                                     },
//                                     interaction: {
//                                         mode: 'index',
//                                         intersect: false,
//                                     },
//                                 }}
//                             />
//                         }
//                     </Box>
//                 </DialogContent>
//             </Dialog>
//         </>
//     )
// }

// export default PIDGraph




import React, { useState } from "react";
import styled from "styled-components";
import { Line } from "react-chartjs-2";
import "chartjs-adapter-moment";
import {
    Chart as ChartJS,
    Title,
    Tooltip,
    LineElement,
    Legend,
    CategoryScale,
    LinearScale,
    PointElement,
    Filler,
    TimeScale,
} from "chart.js";
import { Box, Button, CircularProgress, Dialog, DialogContent, DialogTitle, Divider, Stack } from '@mui/material'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { useDispatch, useSelector } from "react-redux";
import { productPriceGraphData } from "../../redux/features/dealsSlice";

ChartJS.register(
    TimeScale,
    Title,
    Tooltip,
    LineElement,
    Legend,
    CategoryScale,
    LinearScale,
    PointElement,
    Filler
);

// const ChartWrapper = styled.div`
//   background: #ffffff;
//   box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.16);
//   height: 471px;
//   > canvas {
//     max-height: 471px !important;
//   }
//   @media (min-width: 768px) and (max-width: 1024px) {
//     height: 331px;
//     > canvas {
//       max-height: 331px !important;
//     }
//   }
//   @media (max-width: 767px) {
//     height: 214px;
//     > canvas {
//       height: 214px !important;
//     }
//   }
// `;
const PIDGraph = (props) => {
    const dispatch = useDispatch();
    const { loading, priceGraphData } = useSelector(state => state.deals)

    const [dialogOpen, setDialogOpen] = useState(false);

    const openPidModal = () => {
        let data = {};
        data.Store = props.sid;
        data.PID = props.pid;
        dispatch(productPriceGraphData(data))
        setDialogOpen(true)
    }

    // Graph dialog close
    const handleClose = () => {
        setDialogOpen(false);
    }


    const labels = priceGraphData && priceGraphData.map((y) => y[0]);


    let displayData = {
        labels: labels,
        datasets: [
            {
                steppedLine: true,
                label: "Max Price",
                data: priceGraphData && priceGraphData.map((data) => data[2]),
                borderWidth: 1,
                pointRadius: 1,
                backgroundColor: "#ffe0b2",
                borderColor: "#0c5796", // "rgb(96, 125, 139)",
                fill: 1,
                stepped: "middle",
            },
            {
                label: "Min Price",
                stepped: "middle",
                data: priceGraphData && priceGraphData.map((data) => data[1]),
                borderWidth: 1,
                pointRadius: 1,
                backgroundColor: "transparent",
                // backgroundColor: "#0c579659",
                // borderColor:'#fb8c00'// 'blue'
                borderColor: "#0c5796",
                fill: false,
            },
        ],
    };
    let displayOptions = {
        responsive: true,
        plugins: {
            legend: {
                position: "top",
            },
            tooltip: {
                mode: "index",
                intersect: false,
                titleSpacing: 80,
                titleMarginBottom: 10,
                xPadding: 10,
                yPadding: 10,
                bodyFontFamily: "rubik, sans-serif",
                bodySpacing: 7,
                backgroundColor: "#0c5796",
                bodyFontColor: "white",
                titleFontColor: "white",
            },
        },

        scales: {
            x: {
                type: "time",
                time: {
                    minUnit: "day",
                    tooltipFormat: "DD-MMM-YY",
                },
                ticks: {
                    maxTicksLimit: 14,
                    maxRotation: 0,
                },
            },
            y: {
                ticks: {
                    // Include a dollar sign in the ticks
                    callback: function (value, index, values) {
                        return "Rs." + value;
                    },
                    stepSize: 1,
                    maxTicksLimit: 8,
                },
            },
        },
    };

    return (
        <>
            <Button
                variant="outlined"
                size="small"
                color="secondary"
                sx={{ textTransform: 'initial' }}
                onClick={() => openPidModal()}
            >
                <b>OpenModel</b>
            </Button>
            <Dialog open={dialogOpen} onClose={handleClose} fullWidth
                maxWidth=""
                sx={{
                    '& .MuiDialog-paper': {
                        width: "70%",
                        height: "80%",
                    }
                }}>
                <DialogTitle>
                    <Stack direction="row">
                        <b className='text-capitalize' style={{ flex: '1 1 100%' }}>{`PID Graph >> ${props.pid}`}</b>
                        <Button
                            color="error"
                            variant="contained"
                            size="small"
                            sx={{ padding: "4px", minWidth: 'fit-content' }}
                            onClick={handleClose}
                        >
                            <CloseRoundedIcon />
                        </Button>
                    </Stack>
                </DialogTitle>
                <Divider sx={{ borderColor: 'black' }} />
                <DialogContent>
                    <Stack justifyContent="center" alignItems="center" sx={{ width: '100%', height: '100%' }}>
                        {loading ?
                            <CircularProgress />
                            :
                            <Line data={displayData} options={displayOptions} />
                        }
                    </Stack>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default PIDGraph;

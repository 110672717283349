/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
    Paper,
    Stack,
    Table,
    TableCell,
    TableRow,
    TextField,
    Typography,
    tableCellClasses,
    TableBody,
    Toolbar,
    Button,
    Divider,
    Box,
} from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useDispatch } from "react-redux";
import { updateProductData } from "../../../redux/features/salesSlice";

function Realme({ site, productUrlData }) {
    const dispatch = useDispatch()

    const [saleData, setSaleData] = useState({})
    // handle value changes
    const handleChange = (key) => (event) => {
        setSaleData({ ...saleData, [key]: event.target.value });
    };

    // variant input handler
    const handleVariantChange = (index, key) => (event) => {
        let data = [...saleData.variants];
        data[index] = { ...data[index], [key]: event.target.value };
        setSaleData({ ...saleData, variants: data });
    }

    // remove variant
    const handleVariantRemove = (pid) => {
        let temp = saleData.variants.filter(item => !(item.id === pid));
        setSaleData({ ...saleData, variants: temp });
    }

    // handle form submit
    const handleSubmit = (event) => {
        event.preventDefault();
        let formData = {};
        formData.site = site;
        formData.main = {
            purl: saleData.purl,
            pname: saleData.pname,
            porder: saleData.porder,
            pdate: saleData.pdate
        }
        formData.variants = saleData.variants

        // console.log(formData)
        dispatch(updateProductData(formData))
    };

    useEffect(() => {
        let data = {}
        data.purl = productUrlData && productUrlData[0] && productUrlData[0].main && productUrlData[0].main[0].url
        data.pname = productUrlData && productUrlData[0] && productUrlData[0].main && productUrlData[0].main[0].title
        data.porder = productUrlData && productUrlData[0] && productUrlData[0].main && productUrlData[0].main[0].order
        data.pdate = productUrlData && productUrlData[0] && productUrlData[0].main && productUrlData[0].main[0].date
        data.url_path = productUrlData && productUrlData[0] && productUrlData[0].main && productUrlData[0].main[0].url_path

        data.variants = productUrlData && productUrlData[0] && productUrlData[0].variants ? productUrlData[0].variants : [];

        setSaleData({ ...saleData, ...data })
    }, [productUrlData])

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Paper elevation={3}>
                <form onSubmit={handleSubmit}>
                    <Stack direction="column" spacing={1}>
                        <Toolbar
                            sx={{ padding: "1.2rem", paddingBottom: "0" }}
                        >
                            <Typography
                                sx={{
                                    color: "var(--primary-color)",
                                    fontWeight: "bold",
                                }}
                                variant="h5"
                                component="u"
                            >
                                Product Information
                            </Typography>
                        </Toolbar>
                        <Table
                            size="small"
                            sx={{
                                width: "auto",
                                margin: "8px !important",
                                marginTop: "0 !important",
                                [`& .${tableCellClasses.root}`]: {
                                    borderBottom: "none",
                                },
                            }}
                        >
                            <TableBody>
                                <TableRow>
                                    <TableCell>
                                        <Typography variant="h6">
                                            <b>Product URL</b>
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            size="small"
                                            fullWidth
                                            placeholder="Product URL"
                                            value={saleData.purl}
                                            onChange={handleChange('purl')}
                                        />
                                    </TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell>
                                        <Typography variant="h6">
                                            <b>Product Name</b>
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            size="small"
                                            fullWidth
                                            placeholder="Product Name"
                                            value={saleData.pname}
                                            onChange={handleChange('pname')}
                                        />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        <Typography variant="h6">
                                            <b>Product Order</b>
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            size="small"
                                            fullWidth
                                            placeholder="Product Order"
                                            value={saleData.porder}
                                            onChange={handleChange('porder')}
                                        />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        <Typography variant="h6">
                                            <b>Product URL Path Match</b>
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            size="small"
                                            fullWidth
                                            placeholder="Path Match"
                                            value={saleData.url_path}
                                            onChange={handleChange('url_path')}
                                        />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        <Typography variant="h6">
                                            <b>Sale Date</b>
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <DateTimePicker
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    size="small"
                                                />
                                            )}
                                            value={saleData.pdate}
                                            onChange={(e, val) => handleChange('pdate')}
                                        />
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>

                        <Divider flexItem sx={{ borderColor: "black" }} />

                        {saleData.variants && saleData.variants.length > 0 &&
                            <>
                                <Toolbar>
                                    <Typography
                                        sx={{
                                            color: "var(--primary-color)",
                                            fontWeight: "bold",
                                        }}
                                        variant="h5"
                                        component="u"
                                    >
                                        Variant Information ({saleData.variants.length})
                                    </Typography>
                                </Toolbar>
                                {saleData.variants.map((variant, index) =>
                                    <Box key={`${variant.title}-${variant.pid}`}
                                        sx={{
                                            margin: "16px !important",
                                            padding: "16px 0 !important",
                                            border: "2px outset black",
                                            borderRadius: "15px",
                                        }}>
                                        <Stack direction="row" justifyContent='space-between' px={2} mb={1}>
                                            <Typography variant="h6" className="text-center" sx={{ color: "var(--secondary-color)" }}>
                                                <b>{variant.title}</b>
                                            </Typography>
                                            <Button
                                                color="error"
                                                variant="contained"
                                                size="small"
                                                sx={{ padding: "4px", minWidth: 'fit-content' }}
                                                onClick={() => handleVariantRemove(variant.id)}
                                            >
                                                <DeleteRoundedIcon />
                                            </Button>

                                        </Stack>
                                        <Table
                                            size="small"
                                            sx={{
                                                width: "100%",
                                                [`& .${tableCellClasses.root}`]: {
                                                    borderBottom: "none",
                                                },
                                            }}
                                        >
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell colSpan={3}>
                                                        <TextField
                                                            size="small"
                                                            fullWidth
                                                            label="PID"
                                                            value={variant.id}
                                                            onChange={handleVariantChange(index, 'id')}
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>
                                                        <TextField
                                                            size="small"
                                                            fullWidth
                                                            label="Variant Code"
                                                            value={variant.vcode}
                                                            onChange={handleVariantChange(index, 'vcode')}
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        <TextField
                                                            size="small"
                                                            fullWidth
                                                            label="Color"
                                                            value={variant.color}
                                                            onChange={handleVariantChange(index, 'color')}
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        <TextField
                                                            size="small"
                                                            fullWidth
                                                            label="Color Code"
                                                            value={variant.color_css}
                                                            onChange={handleVariantChange(index, 'color_css')}
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </Box>
                                )}
                            </>
                        }

                        <Stack alignItems="center">
                            <Button
                                variant="contained"
                                type="submit"
                                size="large"
                                className="d-block mx-auto mb-3 w-25"
                                onClick={handleSubmit}
                            >
                                <strong>Submit</strong>
                            </Button>
                        </Stack>
                    </Stack>
                </form>
            </Paper>
        </LocalizationProvider>
    )
}

export default Realme;

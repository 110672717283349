import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Table,
  TableCell,
  TableRow,
  TextField,
  tableCellClasses,
  Button,
  TableBody,
  Paper,
  Stack,
  Typography,
  Toolbar,
  Chip,
  TextareaAutosize,
  Autocomplete,
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { message } from "antd";
import {
  getAllPanelUsersData,
  showRolesData,
} from "../../redux/features/authSlice";
import {
  addTodoTaskReminder,
  updateTodoTaskReminder,
  showTodoTaskByUsers,
  showRecurrenceTaskPanel,
} from "../../redux/features/adminSlice";
import { useLocation } from "react-router";
// import { create } from "@mui/material/styles/createTransitions";
// import { display } from "@mui/system";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

function dateConverter(str) {
  var date = new Date(str),
    mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    day = ("0" + date.getDate()).slice(-2);
  let hours = ("0" + date.getHours()).slice(-2);
  let minutes = ("0" + date.getMinutes()).slice(-2);
  let seconds = ("0" + date.getSeconds()).slice(-2);
  let year = date.getFullYear();
  return `${year}-${mnth}-${day} ${hours}:${minutes}:${seconds}`;
}

function dateConverterwithoutTime(str) {
  var date = new Date(str),
    mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    day = ("0" + date.getDate()).slice(-2);
  let year = date.getFullYear();
  return `${year}-${mnth}-${day}`;
}

function CreateNewTask() {
  const { state } = useLocation();
  // user assignment
  // console.log(state, "state");
  const dispatch = useDispatch();
  const { allPanelUsers, updatedResults, userRolesData } = useSelector(
    (state) => ({ ...state.userAuth })
  );
  const { todoTaskData, recurrenceResults } = useSelector((state) => ({
    ...state.admin,
  }));
  const [assignToData, setassignToData] = useState(
    state && state.assigned_to && state.assigned_to.length
      ? allPanelUsers.filter((item) => state.assigned_to.includes(item.fp_uid))
      : []
  ); // for individual user
  const [assingToDataTeam, setAssingToDataTeam] = useState(
    state && state.team_id && state.team_id.length
      ? userRolesData.filter((item) => state.team_id.includes(item.id))
      : []
  ); //for entire team
  const [assignUser, setassignUser] = useState({
    fp_uid: "",
    name: "",
    role: "",
  });
  const [assingTeam, setAssingTeam] = useState({
    id: "",
    module_id: "",
    user_type: "",
  });
  const [assignUserId, setAssignUserId] = useState(
    state && state.assigned_to && state.assigned_to.length
      ? state.assigned_to
      : []
  );
  const [assingTeamId, setAssingTeamId] = useState(
    state && state.team_id && state.team_id.length ? state.team_id : []
  );
  const recurrence_date = [
    { id: 0, days: "One Time" },
    { id: 2, days: "Daily" },
    { id: 7, days: "Weekly " },
    { id: 30, days: "Monthly" },
    { id: 120, days: "Quartely" },
    { id: 365, days: "Yearly" },
  ];

  // task data
  const [createNewTask, setcreateNewTask] = useState({
    // title: "",
    // description: "",
    // assigned_to: [],
    // reminder_date: dayjs(),
    // deadline_days: "",
    // recurrence: 1,
    // assingedToTeam: [],
    // id: state && state.
    id: state && state.id ? state.id : null,
    title: state && state.title ? state.title : "",
    description: state && state.description ? state.description : "",
    assigned_to:
      state && state.assigned_to && state.assigned_to?.length
        ? state.assigned_to
        : [],
    reminder_date:
      state && state.reminder_date && state.reminder_date?.length
        ? dateConverterwithoutTime(state.reminder_date)
        : dayjs(),
    deadline_days: state ? state.deadline_days : "",
    recurrence:
      state && state.recurrence
        ? recurrence_date.filter((item) => item.id === state.recurrence)[0].id
        : 0,
    team_id:
      state && state.team_id && state.team_id?.length ? state.team_id : [],
  });
  // console.log(createNewTask, "createNewTask");

  // add button handler
  const handleAddBtn = () => {
    const index = assignUserId.indexOf(assignUser.fp_uid);
    // console.log(index);
    if (
      assignUser.name === "" ||
      assignUser.name === null ||
      assignUser.name === undefined
    ) {
      message.error("No user selected");
    } else if (index === -1) {
      setAssignUserId([...assignUserId, assignUser.fp_uid]);
      setassignToData([...assignToData, assignUser]);
    } else {
      message.error("User already added");
    }
  };

  // add button handler for team
  const handleAddBtnTeam = () => {
    const index = assingTeamId.indexOf(assingTeam.id);
    // console.log(index, "index");
    if (
      assingTeam.user_type === "" ||
      assingTeam.user_type === null ||
      assingTeam.user_type === undefined
    ) {
      alert("No team selected");
    }
    if (index === -1) {
      setAssingTeamId([...assingTeamId, assingTeam.id]);
      setAssingToDataTeam([...assingToDataTeam, assingTeam]);
    } else {
      message.error("Team already exists");
    }
  };
  // handle value changes
  const handleChange = (key) => (event) => {
    setcreateNewTask({ ...createNewTask, [key]: event.target.value });
  };
  // chip delete handler for user
  const handleDelete = (item) => {
    setAssignUserId(assignUserId.filter((key) => key !== item));
    setassignToData(assignToData.filter((key) => key.fp_uid !== item));
  };
  // chip delete handler for team
  const handleDeleteTeam = (item) => {
    setAssingTeamId(assingTeamId.filter((key) => key !== item));
    setAssingToDataTeam(assingToDataTeam.filter((key) => key.id !== item));
  };

  // handle form datepicker
  const handleDatePicker = (dateString, name) => {
    // console.log(dayjs(dateString), "dateString");
    if (name === "deadline_date") {
      setcreateNewTask({
        ...createNewTask,
        deadline_date: dateConverterwithoutTime(dateString),
      });
    } else if (name === "reminder_date") {
      setcreateNewTask({
        ...createNewTask,
        reminder_date: dateConverterwithoutTime(dateString),
      });
    }
  };

  // handle form autocomplete
  const handleAutoComplete = (e, value) => {
    e.preventDefault();
    if (recurrence_date.length > 0) {
      for (var i = 0; i < recurrence_date.length; i++) {
        // setcreateNewTask({ ...createNewTask, recurrence: value })
        if (value === recurrence_date[i].days) {
          setcreateNewTask({
            ...createNewTask,
            recurrence: recurrence_date[i].id,
          });
        }
      }
    }
  };

  // const handleAutoCompleteTeam = (e,value) =>{
  //   e.preventDefault();
  //   console.log(value, "select");
  //   if(teamwise_data.lenght > 0){
  //     for(var i = 0 ; i<teamwise_data.length; i++){
  //       if(value == teamwise_data[i].days){
  //         setcreateNewTask({...createNewTask, teamwise: teamwise_data[i].id});
  //       }
  //     }
  //   }
  // }

  // handle form userData
  const handleUsersData = (e, value) => {
    e.preventDefault();
    if (allPanelUsers.length > 0) {
      for (var i = 0; i < allPanelUsers.length; i++) {
        if (value === allPanelUsers[i].name) {
          setassignUser({ ...assignUser, ...allPanelUsers[i] });
        }
      }
    }
  };

  //handle form teamData
  const handleTeamData = (e, value) => {
    e.preventDefault();
    if (userRolesData.length > 0) {
      for (var i = 0; i < userRolesData.length; i++) {
        if (value === userRolesData[i].user_type) {
          setAssingTeam({ ...assingTeam, ...userRolesData[i] });
        }
      }
    }
  };

  // handle form submit
  const handleSubmit = (event) => {
    event.preventDefault();
    if (createNewTask.id) {
      let update_data = {};
      update_data.id = createNewTask.id;
      update_data.title = createNewTask.title;
      update_data.description = createNewTask.description;
      if (assignUserId) update_data.assigned_to = assignUserId;
      if (assingTeamId) update_data.team_id = assingTeamId;
      update_data.reminder_date = dateConverterwithoutTime(
        createNewTask.reminder_date
      );
      update_data.deadline_days = createNewTask.deadline_days;
      update_data.recurrence = createNewTask.recurrence;
      // console.log(update_data, "update_data");
      dispatch(updateTodoTaskReminder(update_data));
    } else {
      dispatch(addTodoTaskReminder(createNewTask));
    }
  };

  useEffect(() => {
    setcreateNewTask({
      ...createNewTask,
      assigned_to: assignUserId,
      team_id: assingTeamId,
    });
  }, [assignUserId, assingTeamId]);

  useEffect(() => {
    dispatch(getAllPanelUsersData());
    dispatch(showRolesData());
  }, []);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Paper elevation={3}>
        <form onSubmit={handleSubmit}>
          <Stack spacing={3} direction="column">
            <Toolbar
              mt={3}
              sx={{
                padding: "1.2rem",
                borderBottom: "2px solid var(--primary-color)",
              }}
            >
              <Typography
                sx={{
                  color: "var(--primary-color)",
                  fontWeight: "bold",
                }}
                variant="h5"
                id="tableTitle"
              >
                Create New Task
              </Typography>
            </Toolbar>
            <Table
              size="small"
              sx={{
                width: "auto",
                margin: "8px !important",
                marginTop: "16px !important",
                [`& .${tableCellClasses.root}`]: {
                  borderBottom: "none",
                },
              }}
            >
              <TableBody>
                <TableRow>
                  <TableCell>
                    <Typography variant="h6">
                      <b>Enter Task Title:</b>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <TextField
                      size="small"
                      placeholder="Task Title"
                      fullWidth
                      required
                      value={createNewTask.title}
                      onChange={handleChange("title")}
                    />
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>
                    <Typography variant="h6">
                      <b>Enter Task Description:</b>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    {/*<TextField
                    size="small"
                    placeholder="Task Description......"
                    fullWidth
                    required
                    value={createNewTask.description}
                    onChange={handleChange("description")}
          /> */}

                    <TextareaAutosize
                      minRows={1}
                      maxRows={4}
                      value={createNewTask.description}
                      onChange={handleChange("description")}
                      style={{
                        width: "100%",
                        padding: "8px 12px",
                        fontSize: "1rem",
                      }}
                      placeholder="Text Description....."
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Typography variant="h6">
                      <b>Assing To User: </b>
                    </Typography>
                  </TableCell>

                  <TableCell>
                    <Stack direction="row" spacing={3}>
                      <Autocomplete
                        freeSolo
                        size="small"
                        id="user-combo-box"
                        options={
                          allPanelUsers &&
                          allPanelUsers.map((item) => item.name)
                        }
                        sx={{ width: 256 }}
                        renderInput={(params) => (
                          <TextField {...params} placeholder="Select User" />
                        )}
                        onChange={(e, value) => handleUsersData(e, value)}
                      />
                      <Button
                        variant="contained"
                        color="success"
                        startIcon={<AddCircleOutlineIcon />}
                        onClick={handleAddBtn}
                      >
                        <strong>Add</strong>
                      </Button>
                    </Stack>
                  </TableCell>
                </TableRow>

                {assignToData.length >= 0 ? (
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell>
                      <Stack spacing={1}>
                        {assignToData.map((item, index) => (
                          <Chip
                            key={`chip-${index}`}
                            label={item.name}
                            color="warning"
                            sx={{ width: "fit-content" }}
                            size="small"
                            onDelete={() => handleDelete(item.fp_uid)}
                          />
                        ))}
                      </Stack>
                    </TableCell>
                  </TableRow>
                ) : null}

                <TableRow>
                  <TableCell>
                    <Typography variant="h6">
                      <b>Assing To Team: </b>
                    </Typography>
                  </TableCell>

                  <TableCell>
                    <Stack direction="row" spacing={3}>
                      <Autocomplete
                        freeSolo
                        size="small"
                        id="user-combo-box"
                        options={
                          userRolesData &&
                          userRolesData.map((item) => item.user_type)
                        }
                        sx={{ width: 256 }}
                        renderInput={(params) => (
                          <TextField {...params} placeholder="Select Team" />
                        )}
                        onChange={(e, value) => handleTeamData(e, value)}
                      />
                      <Button
                        variant="contained"
                        color="success"
                        startIcon={<AddCircleOutlineIcon />}
                        onClick={handleAddBtnTeam}
                      >
                        <strong>Add</strong>
                      </Button>
                    </Stack>
                  </TableCell>
                </TableRow>

                {assingToDataTeam.length >= 0 ? (
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell>
                      <Stack spacing={1}>
                        {assingToDataTeam.map((item, index) => (
                          <Chip
                            key={`chip-${index}`}
                            label={item.user_type}
                            color="warning"
                            sx={{ width: "fit-content" }}
                            size="small"
                            onDelete={() => handleDeleteTeam(item.id)}
                          />
                        ))}
                      </Stack>
                    </TableCell>
                  </TableRow>
                ) : null}

                <TableRow>
                  <TableCell>
                    <Typography variant="h6">
                      <b>Set Reminder Date: </b>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    {/* <DatePicker
                      size="small"
                      value={createNewTask.reminder_date && createNewTask.reminder_date}
                      onChange={(newValue) => handleDatePicker(newValue, "reminder_date")}
                      renderInput={(params) => (
                        <TextField {...params} size="small" />
                      )}
                    /> */}
                    <DesktopDatePicker
                      inputFormat="YYYY/MM/DD"
                      value={
                        createNewTask.reminder_date &&
                        createNewTask.reminder_date
                      }
                      onChange={(newValue) =>
                        handleDatePicker(newValue, "reminder_date")
                      }
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Typography variant="h6">
                      <b>Set Deadline Days: </b>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <TextField
                      size="small"
                      type={"number"}
                      // inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                      InputProps={{ inputProps: { min: 1 } }}
                      placeholder="Enter Deadline Days"
                      fullWidth
                      required
                      value={createNewTask.deadline_days}
                      onChange={handleChange("deadline_days")}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Typography variant="h6">
                      <b>Recurrence: </b>
                    </Typography>
                  </TableCell>

                  <TableCell>
                    <Stack direction="row" spacing={3}>
                      <Autocomplete
                        freeSolo
                        size="small"
                        id="user-combo-box"
                        options={
                          recurrence_date &&
                          recurrence_date.map((elem) => elem.days)
                        }
                        value={
                          recurrence_date.filter(
                            (item) => item.id == createNewTask.recurrence
                          )[0].days
                        }
                        sx={{ width: 256 }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Select Recurrence"
                          />
                        )}
                        onChange={(e, value) => handleAutoComplete(e, value)}
                      />
                    </Stack>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell colSpan={2}>
                    <Button
                      variant="contained"
                      type="submit"
                      size="large"
                      className="d-block mx-auto mt-4 mb-3"
                      onClick={handleSubmit}
                    >
                      <strong>Create </strong>
                    </Button>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Stack>
        </form>
      </Paper>
    </LocalizationProvider>
  );
}

export default CreateNewTask;

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Paper,
  Stack,
  Typography,
  Toolbar,
  Box,
  Card,
  Button,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import { showTodoTaskByUsers, deleteTodoTaskReminder, showTodoTaskByAdmin, updateTodoStatus } from "../../redux/features/adminSlice";

const ShowAssignedTask = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { todoTaskAdminData, taskStatusData } = useSelector((state) => ({ ...state.admin }));
  // console.log(todoTaskAdminData, "todoTaskAdminData");
  // console.log(taskStatusData, "taskStatusData");

  useEffect(() => {
    dispatch(showTodoTaskByUsers());
    dispatch(showTodoTaskByAdmin());
  }, []);

  function dateConverter(str) {
    // console.log(str, "str")
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    let hours = ("0" + date.getHours()).slice(-2);
    let minutes = ("0" + date.getMinutes()).slice(-2);
    let seconds = ("0" + date.getSeconds()).slice(-2);
    let year = date.getFullYear();
    return `${year}-${mnth}-${day} ${hours}:${minutes}:${seconds}`
  };

  function dateConverterwithoutTime(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    let year = date.getFullYear();
    return `${year}-${mnth}-${day}`
  };

  const [status, setStatus] = useState();

  const cbFunc = () => {
    dispatch(showTodoTaskByAdmin());
  };

  const handleUpdateTask = (val) => {
    let data = {};
    let date1 = new Date(val.deadline_date);
    let date2 = new Date(val.reminder_date);
    data.active = val.active;
    data.assinged_by = val.assigned_by;
    if (val.assigned_members && val.assigned_members?.length) { data.assigned_to = val.assigned_members.map((item) => item.user_id); }
    else { data.assigned_to = []; }
    if (val.assigned_team && val.assigned_team?.length) { data.team_id = val.assigned_team.map((item) => item.role_id); }
    else { data.team_id = []; }
    data.deadline_days = (date1 - date2) / (1000 * 60 * 60 * 24);
    data.description = val.description;
    data.id = val.id;
    data.title = val.title;
    data.reminder_date = val.reminder_date;
    // console.log(data, 'data');
    navigate('/todo/create-new-task', { state: data });
  };

  const handleDelete = (value1) => {
    // console.log(value1, "item deleted successfully");
    let formData = {};
    formData.id = value1;
    dispatch(deleteTodoTaskReminder(formData));
    cbFunc();
  };

  const handleChange = (event, val) => {
    // console.log('function called successfully')
    // console.log(event.target.value, val, "value");
    let data = {};
    data.id = val;
    data.status = event.target.value;
    // console.log(data, 'data');
    dispatch(updateTodoStatus(data)).then(() =>{dispatch(showTodoTaskByUsers());dispatch(showTodoTaskByAdmin());}).catch((error) => console.log(error));
  };

  return (
    <Paper elevation={3} >
      <Stack spacing={3} direction="column">
        <Toolbar
          mt={3}
          sx={{ padding: "1.2rem", borderBottom: "2px solid var(--primary-color)" }}
        >
          <Typography
            sx={{
              color: "var(--primary-color)",
              fontWeight: "bold",
            }}
            variant="h5"
            id="tableTitle"
          >
            Show Assinged Task by Admin to User
          </Typography>
        </Toolbar>
        <Box p={4} pt={1} className='d-grid' sx={{ gridTemplateColumns: 'repeat(auto-fill,minmax(300px,1fr))', gap: '24px' }}>
          {todoTaskAdminData && todoTaskAdminData?.length && todoTaskAdminData.map((item) => {
            {/* console.log(item, "item"); */}
            if (item.status != 5) {
              return (
                <Card sx={{ minWidth: "fit-content", border: '2px solid var(--bs-border-color)' }}>
                  <Table aria-label="assigned-task-table">
                    <TableBody>

                      <TableRow >
                        <TableCell>Assinged By:</TableCell>
                        <TableCell>
                          <Typography textTransform="capitalize">{item.name} (uid: {item.assigned_by})</Typography>
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>Task Title:</TableCell>
                        <TableCell>{item.title}</TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>Task Descriptions:</TableCell>
                        <TableCell>{item.description}</TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>Task ID:</TableCell>
                        <TableCell>{item.id}</TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>Assigned Members:</TableCell>
                        <TableCell>{item.assigned_members && item.assigned_members?.length && JSON.stringify(item.assigned_members.map((item) => item.user_id))}</TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>Assigned Teams:</TableCell>
                        <TableCell>{item.assigned_team && item.assigned_team?.length && JSON.stringify(item.assigned_team.map((item) => item.role_id))}</TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>Task Reminder Date:</TableCell>
                        <TableCell>{item.reminder_date ? item.reminder_date : ""}</TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>Task Deadline Date:</TableCell>
                        <TableCell>{item.deadline_date ? item.deadline_date : ""}</TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>Task Completed Date:</TableCell>
                        <TableCell>{dateConverterwithoutTime(item.completed_date) ? dateConverterwithoutTime(item.completed_date) : ""}</TableCell>
                      </TableRow>

                      {/* <TableRow>
                        <TableCell>Status:</TableCell>
                        <TableCell sx={{ display: "flex", alignItems: 'center', justifyContent: "center" }}>
                          {item.status}
                        </TableCell>
                      </TableRow> */}

                      <TableRow>
                        <TableCell>Status:</TableCell>
                        <TableCell sx={{ display: "flex", alignItems: 'center', justifyContent: "center" }}>
                          {item.status}
                          <FormControl>
                            <Select
                              size="small"
                              defaultValue={item.status}
                              style={{ marginLeft: "5px" }}
                              onChange={(e, val) => handleChange(e, item.id)}
                            >
                              {taskStatusData && taskStatusData.map(row => {
                                return (
                                  <MenuItem value={row.id}>{row.status}</MenuItem>
                                )
                              })}
                            </Select>
                          </FormControl>
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell colSpan={2} sx={{ width: '100%', display: "flex", flexDirection: 'row', justifyContent: 'space-between' }}>
                          <Button
                            variant="contained"
                            size="small"
                            color="primary"
                            onClick={() => handleUpdateTask(item)}
                            sx={{ marginRight: '10px' }}
                          >
                            <strong>Edit</strong>
                          </Button>
                          <Button
                            variant="contained"
                            size="small"
                            color="error"
                            onClick={() => handleDelete(item.id)}
                          >
                            <strong>Delete</strong>
                          </Button>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Card>
              )
            }
          })}
        </Box>
      </Stack>
    </Paper>
  )
}

export default ShowAssignedTask
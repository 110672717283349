/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import {
    Paper,
    Stack,
    Toolbar,
    Typography,
    FormControl,
    FormControlLabel,
    RadioGroup,
    Radio,
    Autocomplete,
    TextField,
    Button,
    Divider,
    Chip,
    TableRow,
    Table,
    TableBody,
    TableCell,
    tableCellClasses,
    FormGroup,
    Checkbox,
} from '@mui/material';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { useDispatch, useSelector } from 'react-redux';
import { addModulePermission, clearUserManagementRolesData, getAllPanelUsersData, getuserManagementRolesData, showRolesData } from '../../redux/features/authSlice';

function RoleModules() {
    const dispatch = useDispatch()
    // REDUX DATA
    const { userRolesData, allPanelUsers } = useSelector((state) => state.userAuth)
    const { data, userExcludedRole, userIncludedRoles } = useSelector((state) => state.userAuth.userPermissionsData)

    // Value setter
    const [selectedUser, setSelectedUser] = useState("");
    const [formData, setFormData] = useState({})
    const [includeData, setIncludeData] = useState([])
    const [excludeData, setExcludeData] = useState([])

    // role radio
    const [type, setType] = useState("role")
    const handleRadio = (value) => {
        setType(value)
        // setSelectedRole("")
        setSelectedUser("")
        setFormData({})
        dispatch(clearUserManagementRolesData())
    }

    // include data handler
    const handleIncludeData = (e) => {
        const index = includeData.indexOf(parseInt(e.target.value));
        if (index === -1) {
            setIncludeData([...includeData, parseInt(e.target.value)]);
        } else {
            setIncludeData(includeData.filter((key) => key !== parseInt(e.target.value)))
        }
    }

    // exclude data handler
    const handleExcludeData = (e) => {
        const index = excludeData.indexOf(parseInt(e.target.value));
        if (index === -1) {
            setExcludeData([...excludeData, parseInt(e.target.value)]);
        } else {
            setExcludeData(excludeData.filter((key) => key !== parseInt(e.target.value)))
        }
    }

    // user autocomplete handler
    const handleUserData = (val) => {
        for (let i = 0; i < allPanelUsers.length; i++) {
            if (allPanelUsers[i].name === val) {
                setSelectedUser(allPanelUsers[i].name)
                formData.role = allPanelUsers[i].role
                formData.fp_uid = allPanelUsers[i].fp_uid
                formData.name = allPanelUsers[i].name
            }
        }
    }

    // User module search
    const handleUserSearch = (e) => {
        e.preventDefault()
        dispatch(getuserManagementRolesData(formData))
    }
    const handleRoleSearch = (e) => {
        e.preventDefault();
        // let data = {};
        // data.role = selectedRole;
        // data.fp_uid = null
        dispatch(getuserManagementRolesData(formData))
    }
    useEffect(() => {
        if (data) {
            let temp = [];
            // includeData
            for (let i = 0; i < data.length; i++) {
                for (let j = 0; j < userIncludedRoles.length; j++) {
                    if (data[i].module_id === userIncludedRoles[j].module_id) {
                        temp.push(parseInt(data[i].module_id))
                    }
                }
            }
            setIncludeData([...temp])
            // excludedata
            temp = [];
            for (let i = 0; i < userExcludedRole.length; i++) {
                let flag = false;
                for (let j = 0; j < data.length; j++) {
                    if (userExcludedRole[i].module_id === data[j].module_id) {
                        flag = true;
                        break;
                    }
                }
                if (!flag) temp.push(parseInt(userExcludedRole[i].module_id))
            }
            setExcludeData([...temp])
        }
    }, [data])

    // permission change
    const handleSubmit = () => {
        let data = {};
        if (formData.fp_uid) data.fp_uid = formData.fp_uid;
        if (formData.role) data.role = data.role = formData.role;
        data.included_module_id_array = includeData
        data.excluded_module_id_array = excludeData
        // console.log(data, "dispatch===>")
        dispatch(addModulePermission(data))
        dispatch(getuserManagementRolesData({ fp_uid: formData.fp_uid ? formData.fp_uid : null, role: formData.role ? formData.role : null }))
    }

    useEffect(() => {
        dispatch(getAllPanelUsersData())
        dispatch(showRolesData())
    }, [])
    return (
        <Paper elevation={3}>
            <Stack spacing={3} direction="column">
                <Toolbar
                    mt={3}
                    sx={{ padding: "1.2rem", borderBottom: "2px solid var(--primary-color)" }}
                >
                    <Typography
                        sx={{
                            color: "var(--primary-color)",
                            fontWeight: "bold",
                        }}
                        variant="h5"
                    >
                        Role Modules
                    </Typography>
                </Toolbar>
                <Stack
                    direction="column"
                    spacing={3}
                    px={4}
                    pb={5}
                    justifyContent="center"
                    alignItems="center"
                >
                    <Stack direction="row" spacing={4} alignItems="center" >
                        <Typography variant="h6">
                            <b>Search By :</b>
                        </Typography>
                        <FormControl>
                            {/* Radio Button Group to toggle between role and user*/}
                            <RadioGroup
                                name="role-type-selector"
                                row
                                value={type}
                                onChange={(event) => { handleRadio(event.target.value) }}
                            >
                                <FormControlLabel value="role" control={<Radio />} label="Role Name" />
                                <FormControlLabel value="user" control={<Radio />} label="User Name" />
                            </RadioGroup>
                        </FormControl>
                    </Stack>
                    {type === "role" ?
                        <form onSubmit={handleRoleSearch}>
                            <Stack direction="row" spacing={2} alignItems="center" >
                                <Autocomplete
                                    size="small"
                                    freeSolo
                                    value={formData.role}
                                    options={userRolesData ? userRolesData.map(role => (role.user_type)) : []}
                                    sx={{ width: 300 }}
                                    renderInput={(params) => (
                                        <TextField {...params}
                                            placeholder={"Select Role "}
                                        />
                                    )}
                                    onChange={(e, val) => setFormData({ role: val })}
                                />
                                <Button
                                    // size="large"
                                    variant="outlined"
                                    sx={{
                                        textTransform: 'initial',
                                        borderWidth: "2px",
                                        '&:hover': {
                                            borderWidth: "2px",
                                        }
                                    }}
                                    onClick={handleRoleSearch}
                                ><b>Search</b></Button>
                            </Stack>
                        </form>
                        :
                        <form onSubmit={handleUserSearch}>
                            <Stack direction="row" spacing={2} alignItems="center" >
                                <Autocomplete
                                    size="small"
                                    freeSolo
                                    value={selectedUser}
                                    options={allPanelUsers.filter(user => user.approved === 1).map(user => (user.name))}
                                    sx={{ width: 300 }}
                                    renderInput={(params) => (
                                        <TextField {...params}
                                            placeholder={"Select User"}
                                        />
                                    )}
                                    onChange={(e, val) => handleUserData(val)}
                                />
                                <Button
                                    type="submit"
                                    variant="outlined"
                                    sx={{
                                        textTransform: 'initial',
                                        borderWidth: "2px",
                                        '&:hover': {
                                            borderWidth: "2px",
                                        }
                                    }}
                                    onClick={handleUserSearch}
                                ><b>Search</b></Button>
                            </Stack>
                        </form>}
                </Stack>
            </Stack>
            <Divider flexItem sx={{ borderColor: 'black', }} />
            {data &&
                <>
                    <Table
                        size="small"
                        sx={{
                            width: "auto",
                            margin: "8px !important",
                            marginTop: "16px !important",
                            [`& .${tableCellClasses.root}`]: {
                                borderBottom: "none",
                            },
                        }}>
                        <TableBody>
                            <TableRow>
                                {type === "user" && <TableCell>
                                    <Typography variant="body1" sx={{ whiteSpace: 'nowrap' }}><strong>Name : </strong>{formData.name}</Typography>
                                </TableCell>}
                                <TableCell>
                                    <Typography variant="body1" sx={{ whiteSpace: 'nowrap' }}><strong>Role : </strong>{formData.role}</Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell colSpan={2}>
                                    <Typography variant="body1" sx={{ whiteSpace: 'nowrap', display: "inline-block" }}><strong>Modules : </strong></Typography>
                                    {data?.map(item => <Chip key={`permitedmodule-${item.module_name}`} label={item.module_name} sx={{ margin: 0.5 }} />)}
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                    <Divider flexItem sx={{ borderColor: 'black', }} />
                    <Stack direction="row" sx={{ width: '100%' }}>
                        <Stack direction="column" alignItems="center" py={1} spacing={2} sx={{ width: "50%" }}>
                            <Typography variant="h6" component="u"><strong>Include to modules</strong></Typography>
                            <FormGroup sx={{ width: "100%", flexDirection: 'row', padding: '0 16px 0 24px' }}>
                                {userIncludedRoles?.map(item =>
                                    <FormControlLabel
                                        key={`excludemodule-${item.module_name}`}
                                        control={
                                            <Checkbox
                                                value={item.module_id}
                                                onChange={handleIncludeData}
                                                hidden
                                            />
                                        }
                                        label={
                                            <Chip
                                                icon={<AddRoundedIcon />}
                                                label={item.module_name}
                                                color="primary"
                                                variant={includeData.includes(item.module_id) ? 'contained' : 'outlined'}
                                                sx={{ marginBottom: 1, cursor: "pointer" }}
                                            />
                                        }
                                    />)}
                            </FormGroup>
                        </Stack>
                        <Divider orientation="vertical" flexItem sx={{ borderColor: 'black', }} />
                        <Stack direction="column" alignItems="center" py={1} spacing={2} sx={{ width: "50%" }}>
                            <Typography variant="h6" component="u"><strong>Exclude from modules</strong></Typography>
                            <FormGroup px={2} sx={{ width: "100%", flexDirection: 'row', padding: '0 16px 0 24px' }}>
                                {userExcludedRole?.map(item =>
                                    <FormControlLabel
                                        key={`excludemodule-${item.module_name}`}
                                        control={
                                            <Checkbox
                                                value={item.module_id}
                                                onChange={handleExcludeData}
                                                hidden
                                            />
                                        }
                                        label={
                                            <Chip
                                                icon={<CloseRoundedIcon fontSize='small' />}
                                                label={item.module_name}
                                                color="error"
                                                variant={excludeData.includes(item.module_id) ? 'contained' : 'outlined'}
                                                sx={{ marginBottom: 1, cursor: "pointer" }}
                                            />
                                        }
                                    />
                                )}
                            </FormGroup>
                        </Stack>
                    </Stack>
                    <Stack pb={2} alignItems={'center'} sx={{ width: '100%' }}>
                        <Button variant="contained" size="large" sx={{ marginTop: 5, width: '200px' }}
                            onClick={handleSubmit}>
                            <b>Submit</b>
                        </Button>
                    </Stack>
                </>}
        </Paper>
    )
}

export default RoleModules
import React from 'react'
import Datatable from '../Datatable/Datatable'

function ShowRecentlyUsedCoupon() {

    const columns = [
        {
            name: 'fuid',
            label: 'FUID',
            options:{
                filter: true,
                sort: true,
            }
        },
        {
            name: 'coupon_off',
            label: 'Coupon Off',
            options:{
                filter: true,
                sort: true,
            }
        },
        {
            name: 'store_id',
            label: 'Store Id',
            options:{
                filter: true,
                sort: true,
            }
        },
        {
            name: 'coupon_code',
            label: 'Coupon Code',
            options:{
                filter: true,
                sort: true,
            }
        },
    ]

    const data=[
        {
            fuid: '12',
            coupon_off: '20%',
            store_id: "afgdshe",
            coupon_code: "Bharat20",
        },
        {
            fuid: '12',
            coupon_off: '20%',
            store_id: "afgdshe",
            coupon_code: "Bharat20",
        },
        {
            fuid: '12',
            coupon_off: '20%',
            store_id: "afgdshe",
            coupon_code: "Bharat20",
        },
        {
            fuid: '12',
            coupon_off: '20%',
            store_id: "afgdshe",
            coupon_code: "Bharat20",
        },
        {
            fuid: '12',
            coupon_off: '20%',
            store_id: "afgdshe",
            coupon_code: "Bharat20",
        }
    ]
    return (
        <Datatable name="Recently Used Coupon" columns={columns} data={data} />
    )
}

export default ShowRecentlyUsedCoupon
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
    Table,
    TableCell,
    TableRow,
    TextField,
    tableCellClasses,
    Button,
    TableBody,
    Paper,
    Stack,
    Typography,
    Toolbar,
    Autocomplete,
} from "@mui/material";
import SaveIcon from '@mui/icons-material/Save';
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import ReplyIcon from '@mui/icons-material/Reply';
import { useDispatch, useSelector } from "react-redux";
import { addTrendingStoresPanel, getAllStoresData } from "../../redux/features/storesSlice";

const AddTrendingStore = () => {
    const dispatch = useDispatch();
    const { state } = useLocation()
    const navigate = useNavigate();

    const { allStoresData } = useSelector((state) => ({ ...state.stores }));

    // FormData
    const [storeData, setStoreData] = useState({
        store_id: state && state.data ? state.data.store_id : "",
        priority: state && state.data ? state.data.priority : 0,
        store_name: state && state.data ? allStoresData?.filter(store => store.store_id === state.data.store_id)[0]?.store_name : "",
    });

    const handleAutoComplete = (e) => {
        if (allStoresData.length > 0) {
            for (let i = 0; i < allStoresData.length; i++) {
                if (e === allStoresData[i].store_name) { setStoreData({ ...storeData, store_id: allStoresData[i].store_id, store_name: allStoresData[i].store_name }); }
            }
        }
    };

    const handleChange = (key) => (e) => {
        setStoreData({ ...storeData, [key]: e.target.value })
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(addTrendingStoresPanel(storeData));
        navigate("/stores/manage-trendingstore");
    };

    useEffect(() => {
        dispatch(getAllStoresData());
    }, []);

    return (
        <Paper elevation={3}>
            <form onSubmit={handleSubmit}>
                <Stack spacing={3} direction="column">
                    <Toolbar
                        mt={3}
                        sx={{
                            padding: "1.2rem",
                            display: "flex",
                            borderBottom: "2px solid var(--primary-color)",
                        }}
                    >
                        <Typography
                            sx={{
                                color: "var(--primary-color)",
                                fontWeight: "bold",
                                flex: "1 1 100%",
                            }}
                            variant="h5"
                        >
                            Add Trending Store
                        </Typography>
                        <NavLink to="/stores/manage-trendingstore">
                            <Button variant="contained" color="info" sx={{ whiteSpace: 'nowrap' }} startIcon={<ReplyIcon />}>
                                <strong>Show Trending Stores</strong>
                            </Button>
                        </NavLink>
                    </Toolbar>

                    <Table
                        size="small"
                        sx={{
                            width: "auto",
                            margin: "8px !important",
                            marginTop: "16px !important",
                            [`& .${tableCellClasses.root}`]: {
                                borderBottom: "none",
                            },
                        }}
                    >
                        <TableBody>
                            <TableRow>
                                <TableCell>
                                    <Typography variant="h6">
                                        <b>Store Name:</b>
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Autocomplete
                                        freeSolo
                                        size="small"
                                        id="user-combo-box"
                                        name="store_name"
                                        value={storeData.store_name}
                                        options={allStoresData && allStoresData.map((item) => item.store_name)}
                                        sx={{ width: 300 }}
                                        renderInput={(params) => (
                                            <TextField {...params} placeholder="Select Site" />
                                        )}
                                        onChange={(e, val) => handleAutoComplete(val)}

                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Typography variant="h6">
                                        <b>Priority:</b>
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        type="number"
                                        size="small"
                                        fullWidth
                                        placeholder="Enter Priority Number"
                                        value={storeData.priority}
                                        onChange={handleChange("priority")}
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell colSpan={2} className="text-center">
                                    <Button variant="contained" type="submit" color="info" startIcon={<SaveIcon />} sx={{ marginLeft: '15px' }} onClick={handleSubmit}>
                                        <strong>Save</strong>
                                    </Button>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Stack>
            </form>
        </Paper>
    )
}

export default AddTrendingStore
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import {
    Paper,
    Stack,
    Toolbar,
    Typography,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    tableCellClasses,
    Divider,
    FormGroup,
    FormControlLabel,
    Checkbox,
    Chip,
    LinearProgress,
} from '@mui/material'
import PersonAddRoundedIcon from '@mui/icons-material/PersonAddRounded';
import { styled } from '@mui/material/styles';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import { useDispatch, useSelector } from 'react-redux';
import { addNewRolePanel, roleActivation, showAllModules, showRolesData } from '../../redux/features/authSlice';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.action.hover,
        color: theme.palette.common.black,
        fontSize: "1rem",
        textAlign: "center",
        width: "max-content",
        padding: '10px !important',
        borderRight: "1px solid rgba(224, 224, 224, 1)"
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: "1rem",
        textAlign: "center",
        width: "max-content",
        padding: '7px 14px !important',
        borderRight: "1px solid rgba(224, 224, 224, 1)",
    },
}));

function ViewAdditionalRoles() {
    const dispatch = useDispatch()
    const { loading, userRolesData, modulesData } = useSelector(state => state.userAuth)

    const [dialogOpen, setDialogOpen] = useState(false);
    const [formData, setFormData] = useState({
        id: null,
        user_type: "",
        module_id_array: [],
    });
    const [includeModuleData, setIncludeModuleData] = useState([]);

    const handleClickOpen = () => {
        setDialogOpen(true);
    };

    const handleClose = () => {
        setDialogOpen(false);
    };

    // include data handler
    const handleIncludeModuleData = (e) => {
        const index = includeModuleData.indexOf(parseInt(e.target.value));
        if (index === -1) {
            setIncludeModuleData([...includeModuleData, parseInt(e.target.value)]);
        } else {
            setIncludeModuleData(includeModuleData.filter((key) => key !== parseInt(e.target.value)))
        }
    }

    // delete role
    const handleActive = (id, active) => {
        let data = {}
        data.id = id;
        data.active = active ? 0 : 1;
        dispatch(roleActivation(data))
        dispatch(showRolesData())
    }
    // edit role
    const handleEdit = async (role) => {
        console.log(role.id)
        formData.id = role.id
        formData.user_type = role.user_type
        setIncludeModuleData(await JSON.parse(role.module_id))
        handleClickOpen()
    }

    // create new role
    const handleSubmit = () => {
        formData.module_id_array = includeModuleData;
        console.log(formData);
        dispatch(addNewRolePanel(formData))
        setFormData({
            id: null,
            user_type: "",
            module_id_array: [],
        })
        handleClose()
        dispatch(showRolesData())
    }

    useEffect(() => {
        dispatch(showRolesData())
        dispatch(showAllModules())
    }, [])

    return (
        <Paper elevation={3}>
            <Stack spacing={1} direction="column">
                <Toolbar
                    mt={3}
                    sx={{ padding: "1.2rem", borderBottom: "2px solid var(--primary-color)" }}
                >
                    <Typography
                        sx={{
                            flex: "1 1 100%",
                            color: "var(--primary-color)",
                            fontWeight: "bold",
                        }}
                        variant="h5"
                        id="tableTitle"
                    >
                        Additional Roles
                    </Typography>
                    <Button
                        variant="contained"
                        color="info"
                        startIcon={<GroupAddIcon />}
                        sx={{ whiteSpace: "nowrap", minWidth: "fit-content" }}
                        onClick={handleClickOpen}
                    >
                        <strong>Create New</strong>
                    </Button>
                    <Dialog open={dialogOpen} onClose={handleClose} fullWidth sx={{
                        '.MuiDialog-paper': {
                            maxWidth: "768px"
                        }
                    }}>
                        <DialogTitle>
                            <Stack direction='row' justifyContent='space-between'>
                                <b>Add New Additional Role</b>
                                <Button variant="contained" startIcon={<PersonAddRoundedIcon />} onClick={handleSubmit} ><b>Create</b></Button>
                            </Stack>
                        </DialogTitle>
                        <Divider sx={{ borderColor: 'black' }} />
                        <DialogContent>
                            <Stack direction='row' spacing={4} alignItems='center'>
                                <Typography variant="body1" sx={{ whiteSpace: 'nowrap' }}>
                                    <strong>Enter Role</strong>
                                </Typography>
                                <TextField
                                    autoFocus
                                    type="text"
                                    fullWidth
                                    size="small"
                                    value={formData.user_type}
                                    onChange={(e) => setFormData({ user_type: e.target.value })}
                                />
                            </Stack>
                            <Stack direction='column' spacing={2} mt={2}>
                                <Typography variant="body1" sx={{ whiteSpace: 'nowrap' }}>
                                    <strong>Assign modules :</strong>
                                </Typography>
                                <FormGroup sx={{ width: "100%", flexDirection: 'row', }}>
                                    {modulesData?.map(item =>
                                        <FormControlLabel
                                            key={`addmodule-${item.module_name}`}
                                            control={
                                                <Checkbox
                                                    value={item.module_id}
                                                    onChange={handleIncludeModuleData}
                                                    hidden
                                                />
                                            }
                                            label={
                                                <Chip
                                                    // icon={<AddRoundedIcon />}
                                                    label={item.module_name}
                                                    color="success"
                                                    variant={includeModuleData.includes(item.module_id) ? 'contained' : 'outlined'}
                                                    sx={{ marginBottom: 1, cursor: "pointer" }}
                                                />
                                            }
                                        />)}
                                </FormGroup>
                            </Stack>
                            {/* <Stack justifyContent="center" alignItems="center">
                                <Button type="submit" variant="contained" sx={{ marginTop: 2, width: '160px' }}>
                                    <b>Submit</b>
                                </Button>
                            </Stack> */}
                        </DialogContent>
                    </Dialog>
                    {loading && <LinearProgress />}
                </Toolbar>
                <Stack width="100%" p={5}>
                    <Table align="center" sx={{ minWidth: 'fit-content', border: "1px solid rgba(224, 224, 224, 1)" }}>
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>
                                    <b>Role ID</b>
                                </StyledTableCell>
                                <StyledTableCell>
                                    <b>Role Name</b>
                                </StyledTableCell>
                                <StyledTableCell>
                                    <b>Action</b>
                                </StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {userRolesData?.map((role) => (
                                <TableRow key={`role-${role.id}`}>
                                    <StyledTableCell>{role.id}</StyledTableCell>
                                    <StyledTableCell>{role.user_type}</StyledTableCell>
                                    <StyledTableCell sx={{ gap: 1 }}>
                                        <Stack direction="row" gap={1} justifyContent="center">
                                            <Button color="info" variant="outlined" size="small" onClick={() => handleEdit(role)}><b>Edit</b></Button>
                                            {role.active === 1
                                                ? <Button color="error" variant="outlined" size="small" onClick={() => handleActive(role.id, role.active)}><b>Deactivate</b></Button>
                                                : <Button color="success" variant="outlined" size="small" onClick={() => handleActive(role.id, role.active)}><b>Activate</b></Button>
                                            }</Stack>
                                    </StyledTableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Stack>
            </Stack>
        </Paper>
    )
}

export default ViewAdditionalRoles
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import * as manageBannerOfferApi from '../../api/websiteAPI/manageBannerOfferApi';

const initialState = {
    bannerofferData: [],
    liveBannerOffer: [],
    updateBannerOffer: [],
    deleteBannerOffer: [],
    addBannerOffer: [],
    loading: false,
    error: "",
    success: "",
};

// ===================================data for banner and offer images==============================

export const getManageBannersAndOffersImages = createAsyncThunk(
    "bannerOffer/getbannerofferimages",
    async (formData, { rejectWithValue }) => {
        try {
            const resp = await manageBannerOfferApi.getBannerOfferImages(formData);
            if (resp.data.code === 200) {
                message.success(resp.data.success);
                return resp.data.data;
            } else {
                console.log(resp.data.message)
                return rejectWithValue(resp.data.data);
            }
        } catch (error) {
            rejectWithValue(error.resp.data);
        }
    }
)

export const liveBannersAndOffers = createAsyncThunk(
    "bannerOffer/livebanneroffers",
    async (formData, { rejectWithValue }) => {
        try {
            const resp = await manageBannerOfferApi.getLiveBannerOffers(formData);
            if (resp.data.code === 200) {
                message.success(resp.data.success);
                return resp.data.data;
            } else {
                console.log(resp.data.message)
                return rejectWithValue(resp.data.data);
            }
        } catch (error) {
            rejectWithValue(error.resp.data);
        }
    }
)

export const updateBannerAndOffer = createAsyncThunk(
    "bannerOffer/updatebanneroffer",
    async (formData, { rejectWithValue }) => {
        try {
            const resp = await manageBannerOfferApi.updateManageBannersAndOffers(formData);
            if (resp.data.code === 200) {
                // console.log(resp.data.data, "response data for update banner offer======>");
                message.success(resp.data.success)
                return resp.data.data;
            } else {
                console.log(resp.data.message)
                return rejectWithValue(resp.data.data);
            }
        } catch (error) {
            rejectWithValue(error.resp.data);
        }
    }
)

export const deleteManageBannersAndOffers = createAsyncThunk(
    'bannerOffer/deletebanneroffer',
    async (formData, { rejectWithValue }) => {
        try {
            const resp = await manageBannerOfferApi.deleteManageBannersAndOffers(formData);
            if (resp.data.code === 200) {
                message.success(resp.data.success);
                return resp.data.data;
            } else {
                console.log(resp.data.message)
                return rejectWithValue(resp.data.data);
            }
        } catch (error) {
            rejectWithValue(error.resp.data);
        }
    }
)

export const addManageBannersAndOffers = createAsyncThunk(
    'bannerOffer/addbanneroffer',
    async (formData, { rejectWithValue }) => {
        try {
            const resp = await manageBannerOfferApi.addManageBannersAndOffers(formData);
            if (resp.data.code === 200) {
                message.success(resp.data.success);
                return resp.data.data;
            } else {
                // console.log(resp.data.message)
                return rejectWithValue(resp.data.data);
            }
        } catch (error) {
            rejectWithValue(error.resp.data);
        }
    }
)

const bannerofferSlice = createSlice({
    name: 'bannerOffer',
    initialState,
    extraReducers: (builder) => {
        ///=================================getbannerofferdata===========================//////////////////////

        builder.addCase(getManageBannersAndOffersImages.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(getManageBannersAndOffersImages.fulfilled, (state, action) => {
            state.loading = false;
            state.bannerofferData = action.payload;
        });
        builder.addCase(getManageBannersAndOffersImages.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

        // ==============================liveBannersAndOffers========================
        builder.addCase(liveBannersAndOffers.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(liveBannersAndOffers.fulfilled, (state, action) => {
            // console.log(action.payload, "payload data======> for live banner and offer test");
            state.loading = false;
            state.liveBannerOffer = action.payload;
        });
        builder.addCase(liveBannersAndOffers.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

        // =============================updataManageBannersAndOffers====================
        builder.addCase(updateBannerAndOffer.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(updateBannerAndOffer.fulfilled, (state, action) => {
            // console.log(action.payload, "payload data======> for update banner and offer test");
            state.loading = false;
            state.updateBannerOffer = action.payload;
        });
        builder.addCase(updateBannerAndOffer.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

        // ================================deleteBannerAndOffer==============================
        builder.addCase(deleteManageBannersAndOffers.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(deleteManageBannersAndOffers.fulfilled, (state, action) => {
            state.loading = false;
            state.deleteBannerOffer = action.payload;
        });
        builder.addCase(deleteManageBannersAndOffers.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

        // ===================================addManageBannersAndOffers============================
        builder.addCase(addManageBannersAndOffers.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(addManageBannersAndOffers.fulfilled, (state, action) => {
            console.log(action.payload, "payload data======> for add banner and offer test");
            state.loading = false;
            state.addBannerOffer = action.payload;
        });
        builder.addCase(addManageBannersAndOffers.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
    }
});

export default bannerofferSlice.reducer;
import axios from "axios";
import AppConstants from "../../../constants/AppConstants";

const API = axios.create({ baseURL: AppConstants.baseURL });


///================================================wishlist for you===========================================/////
export const getWishlistByUser = (formData) => API.post("/webdata/showwishlistweb", formData);

export const getRecentPriceDropForWeb = () => API.get('/webdata/getrecentpricedropuser');

///================================================all category list===========================================/////
// export const adddealsweb = (formData) => API.post("/deals/adddealssweb", formData);


// ///================================================store wise coupons list===========================================/////
// export const updateDealsWeb = (formData) => API.get("/deals/updatedealsweb", formData);
// export const activateDeactivateDealsWeb = (formData) => API.get("/deals/activatedealsweb", formData);

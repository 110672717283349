import { Hidden, Link } from "@mui/material";
import { Image } from "antd";
const CustomImageShow = ({ link }) => {
  if (!link) {
    return;
  }
  return (
    <div
      style={{
        width: "40px",
        height: "40px",
        borderRadius: "100%",
        overflow: "hidden",
        transitionDuration: "1.5ms",
      }}
    >
      <Image src={link} />
    </div>
  );
};
export default CustomImageShow;

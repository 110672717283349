/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import Datatable from '../Datatable/Datatable';
import { Button } from "@mui/material";
import { NavLink } from 'react-router-dom';
import AddBoxIcon from '@mui/icons-material/AddBox';
import EditIcon from '@mui/icons-material/Edit';
import { getTrendingStoresPanel, activateDeactivateTrendingStoresPanel } from "../../redux/features/storesSlice";
import { Popconfirm } from 'antd';

function ShowTrendingStores() {
    const dispatch = useDispatch();
    const { trendingStoreData, } = useSelector((state) => ({ ...state.stores }));

    const handleStatus = (formData) => {
        dispatch(activateDeactivateTrendingStoresPanel(formData));
        dispatch(getTrendingStoresPanel());
    }

    const columns = [
        {
            name: "store_id",
            label: 'Action',
            options: {
                customBodyRender: (id, tableMeta) => {
                    let data = {}
                    data.store_id = tableMeta.rowData[2]
                    data.priority = tableMeta.rowData[3]
                    return (
                        <NavLink to="/stores/add-trendingstore" state={{ data }}>
                            <Button color="info" size="small" startIcon={<EditIcon />}>
                                <strong>Edit</strong>
                            </Button>
                        </NavLink>
                    )
                },
                filter: false,
                sort: false,
            }
        },
        {
            name: "store_name",
            label: "Store Name",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: 'store_id',
            label: 'Store ID',
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "priority",
            label: "Priority",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: 'active',
            label: 'Status',
            options: {
                customBodyRender: (active, tableMeta) => {
                    var data = {};
                    data.store_id = tableMeta.rowData[2];
                    data.active = (active) ? "0" : "1";
                    if (active)
                        return <Popconfirm
                            placement="topRight"
                            title="Deactivate Store"
                            description="Do you really want to deactivate the store?"
                            okText="Yes"
                            cancelText="No"
                            onConfirm={() => handleStatus(data)}
                        >
                            <Button color='error' size="small" variant="outlined"  ><b>Deactivate</b></Button>
                        </Popconfirm>
                    else
                        return <Popconfirm
                            placement="topRight"
                            title="Activate Store"
                            description="Do you really want to activate the store?"
                            okText="Yes"
                            cancelText="No"
                            onConfirm={() => handleStatus(data)}
                        >
                            <Button color='success' size="small" variant="outlined" ><b>Activate</b></Button>
                        </Popconfirm>
                },
                filter: true,
                sort: true,
            }
        },
        {
            name: 'created_at',
            label: 'Created',
            options: {
                filter: true,
                sort: true,
            }
        },
    ];

    useEffect(() => {
        dispatch(getTrendingStoresPanel());
    }, []);
    return (
        <div>
            <NavLink to="/stores/add-trendingstore">
                <Button variant="contained" color="success" startIcon={<AddBoxIcon />} sx={{ marginBottom: "15px", marginRight: "10px" }}>
                    <strong>Add New Trending Store</strong>
                </Button>
            </NavLink>
            <Datatable
                name='Trending Store Data'
                columns={columns}
                data={trendingStoreData && trendingStoreData}
            />
        </div>
    )
}

export default ShowTrendingStores;
/* eslint-disable react-hooks/exhaustive-deps */
import { React, useEffect, useState } from 'react'
import {
    Paper,
    Stack,
    Toolbar,
    Typography,
    Autocomplete,
    TextField,
    Button,
    Box,
    LinearProgress,
    Tooltip,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    RadioGroup,
    FormControlLabel,
    Radio,
    Divider,
    Table,
    TableRow,
    TableBody,
    TableCell,
    tableCellClasses,
} from '@mui/material';
import { LoadingButton } from '@mui/lab'
import AddIcon from '@mui/icons-material/Add';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import HowToRegRoundedIcon from '@mui/icons-material/HowToRegRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import RefreshIcon from '@mui/icons-material/Refresh';
import { DataGrid, GridActionsCellItem, GridToolbar } from '@mui/x-data-grid';
import { NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { getAllPanelUsersData, showRolesData, updatePanelUserData } from '../../redux/features/authSlice';

function AllUser() {
    const dispatch = useDispatch()
    const { userRolesData, allPanelUsers, loading } = useSelector((state) => state.userAuth)
    // Dialog input
    const [dialogOpen, setDialogOpen] = useState(false);

    const handleClose = () => {
        setDialogOpen(false);
    };

    // selected users
    // const [selectedUser, setSelectedUser] = useState("");

    // Edit User Data
    const [editUserData, setEditUserData] = useState({
        name: "",
        email: "",
        role: "",
        role_id: "",
        active: 0,
        approved: 0,
    })

    // for datable-grid
    const columns = [
        { field: 'fp_uid', headerName: 'ID', type: 'number', width: 50, disableColumnMenu: true, },
        { field: 'name', headerName: 'Name', minWidth: 190, flex: 0.3, disableColumnMenu: true, },
        { field: 'email', headerName: 'Email', minWidth: 250, flex: 0.4, disableColumnMenu: true, },
        { field: 'role', headerName: 'Role', minWidth: 80, flex: 0.1, disableColumnMenu: true, },
        // { field: 'joining_date', headerName: 'Joining Date', minWidth: 230, flex:1, },
        {
            field: 'approved',
            headerName: 'Approved',
            minWidth: 120,
            flex: 0.1,
            disableColumnMenu: true,
            valueFormatter: (params) => {
                if (params.value === 1) {
                    return 'Approved';
                } else {
                    return 'Not Approved';
                };
            },
        },
        {
            field: 'active',
            headerName: 'Status',
            minWidth: 100,
            flex: 0.1,
            disableColumnMenu: true,
            valueFormatter: (params) => {
                if (params.value === 1) {
                    return 'Active';
                } else {
                    return 'Inactive';
                };
            },
        },
        {
            field: 'actions',
            headerName: 'Actions',
            type: 'actions',
            minWidth: 80,
            sortable: false,
            disableColumnMenu: true,
            getActions: (params) => [
                <Tooltip title="Edit">
                    <GridActionsCellItem
                        icon={<EditRoundedIcon />}
                        label="Edit"
                        onClick={() => editUser(params.row)}
                    />
                </Tooltip>,
                <Tooltip title="Approve">
                    <GridActionsCellItem
                        icon={<HowToRegRoundedIcon />}
                        label="Validate"
                        onClick={() => approveUser(params.row)}
                    />
                </Tooltip>,
                <Tooltip title="Delete">
                    <GridActionsCellItem
                        icon={<DeleteRoundedIcon />}
                        label="Delete"
                        onClick={() => deleteUser(params.row)}
                    />
                </Tooltip>,
            ]
        },
    ];

    // delete user
    function deleteUser(data) {
        let formData = { ...data };
        formData.active = "0";
        formData.approved = "0";
        console.table(formData)
        dispatch(updatePanelUserData({ formData, cbFunc }))
    }

    // approve user
    function approveUser(data) {
        let formData = { ...data };
        formData.active = "1";
        formData.approved = "1";
        console.table(formData)
        dispatch(updatePanelUserData({ formData, cbFunc }))
    }

    // edit user
    function editUser(data) {
        setEditUserData({ ...data })
        setDialogOpen(true);
    }

    // handle user data for edit
    const handleEditUserData = (key) => (e) => {
        setEditUserData({ ...editUserData, [key]: e.target.value });
    }
    const handleSubmit = async () => {
        for (let i = 0; i < userRolesData.length; i++) {
            if (userRolesData[i].user_type === editUserData.role) {
                editUserData.role_id = userRolesData[i].id;
                break;
            }
        }
        let formData = { ...editUserData };
        dispatch(updatePanelUserData({ formData, cbFunc }))
        handleClose();
    }

    // Reload Data
    const [reload, setReload] = useState(false)
    const handleReload = () => {
        setReload(true);
        cbFunc()
        setReload(false)
    }

    const cbFunc = () => {
        dispatch(getAllPanelUsersData())
    }

    useEffect(() => {
        cbFunc()
        dispatch(showRolesData())
    }, [])
    return (
        <>
            <Paper elevation={3} >
                <Stack spacing={4} direction="column">
                    <Toolbar
                        sx={{ padding: "1.2rem", borderBottom: "2px solid var(--primary-color)" }}
                    >
                        <Typography
                            sx={{
                                flex: "1 1 100%",
                                color: "var(--primary-color)",
                                fontWeight: "bold",
                            }}
                            variant="h5"
                            id="tableTitle"
                        >
                            {`User Data (${allPanelUsers?.length})`}
                        </Typography>
                    </Toolbar>

                    {/* <Stack
                        direction="column"
                        spacing={2}
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Stack
                            direction="row"
                            spacing={2}
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Typography variant="h6">
                                <b>Select User :</b>
                            </Typography>
                            <Autocomplete
                                freeSolo
                                size="small"
                                id="user-combo-box"
                                value={selectedUser}
                                options={allPanelUsers && allPanelUsers.map(user => user.name)}
                                sx={{ width: 300 }}
                                renderInput={(params) => (
                                    <TextField {...params} placeholder="Select User" />
                                )}
                                onChange={(e, val) => setSelectedUser(val)}
                            />
                        </Stack>
                        <Button
                            // size="large"
                            variant="outlined"
                            sx={{
                                textTransform: 'initial',
                                borderWidth: "2px",
                                '&:hover': {
                                    borderWidth: "2px",
                                }
                            }}
                        ><b>Search</b></Button>
                    </Stack> */}
                    <Box>
                        <Stack direction="row" justifyContent="space-between" px={4}>
                            <LoadingButton
                                variant="outlined"
                                startIcon={<RefreshIcon size="small" />}
                                loading={reload}
                                loadingPosition="start"
                                sx={{ textTransform: " initial" }}
                                onClick={handleReload}
                            >
                                <b>Reload</b>
                            </LoadingButton>
                            <Stack spacing={2} direction={"row"} justifyContent={"flex-end"}>
                                {/* <Button
                                    variant="contained"
                                    startIcon={<DeleteRoundedIcon />}
                                    color="error"
                                >
                                    <strong>Remove</strong>
                                </Button> */}
                                <NavLink to="/admin/create-user">
                                    <Button
                                        variant="contained"
                                        startIcon={<AddIcon />}
                                    >
                                        <strong>Add New</strong>
                                    </Button>
                                </NavLink>
                            </Stack>
                        </Stack>

                        {/*Datatable below here*/}
                        <Box sx={{ width: '100%', padding: "2rem", }}>
                            <DataGrid
                                autoHeight
                                // autoPageSize
                                loading={loading}
                                getRowId={(row) => row.fp_uid}
                                rows={allPanelUsers && allPanelUsers}
                                columns={columns}
                                pageSize={allPanelUsers?.length}
                                checkboxSelection
                                hideFooter
                                components={{
                                    LoadingOverlay: LinearProgress,
                                    Toolbar: GridToolbar,
                                }}
                            />
                        </Box>
                    </Box>
                </Stack>
            </Paper>
            <Dialog open={dialogOpen} onClose={handleClose} fullWidth>
                <DialogTitle>
                    <b>Edit User</b>
                </DialogTitle>
                <Divider sx={{ borderColor: "black" }} />
                <DialogContent>
                    <Table
                        size="small"
                        sx={{
                            width: "100%",
                            [`& .${tableCellClasses.root}`]: {
                                borderBottom: "none",
                            },
                        }}>
                        <TableBody>
                            <TableRow>
                                <TableCell>
                                    <Typography variant="body1" sx={{ whiteSpace: 'nowrap' }}>
                                        <b>Name</b>
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        type="text"
                                        fullWidth
                                        value={editUserData?.name}
                                        size="small"
                                        onChange={handleEditUserData('name')}
                                    />
                                </TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell>
                                    <Typography variant="body1" sx={{ whiteSpace: 'nowrap' }}>
                                        <b>Email</b>
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        type="text"
                                        fullWidth
                                        value={editUserData?.email}
                                        size="small"
                                        onChange={handleEditUserData('email')}
                                    />
                                </TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell>
                                    <Typography variant="body1" sx={{ whiteSpace: 'nowrap' }}>
                                        <b>Role</b>
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Autocomplete
                                        disableClearable
                                        size="small"
                                        id="user-combo-box"
                                        value={editUserData?.role}
                                        options={userRolesData ? userRolesData.map(item => item.user_type) : []}
                                        sx={{ width: 200 }}
                                        renderInput={(params) => (
                                            <TextField {...params} />
                                        )}
                                        onChange={(e, val) => setEditUserData({ ...editUserData, role: val })}
                                    />
                                </TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell>
                                    <Typography variant="body1" sx={{ whiteSpace: 'nowrap' }}>
                                        <b>isApproved</b>
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <RadioGroup
                                        name="approved"
                                        aria-labelledby="isApproved"
                                        row
                                        value={editUserData?.approved}
                                        onChange={handleEditUserData('approved')}
                                    >
                                        <FormControlLabel
                                            control={<Radio />}
                                            label="Approved"
                                            value={1}
                                        />
                                        <FormControlLabel
                                            control={<Radio />}
                                            label="Not Approved"
                                            value={0}
                                        />
                                    </RadioGroup>
                                </TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell>
                                    <Typography variant="body1" sx={{ whiteSpace: 'nowrap' }}>
                                        <b>Status</b>
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <RadioGroup
                                        name="status"
                                        aria-labelledby="isActive"
                                        row
                                        value={editUserData?.active}
                                        onChange={handleEditUserData('active')}
                                    >
                                        <FormControlLabel
                                            control={<Radio />}
                                            label="Active"
                                            value={1}
                                        />
                                        <FormControlLabel
                                            control={<Radio />}
                                            label="Not Active"
                                            value={0}
                                        />
                                    </RadioGroup>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </DialogContent>
                <DialogActions>
                    <Button color="error" size="large" onClick={handleClose} ><b>Cancel</b></Button>
                    <Button variant="contained" size="large" onClick={handleSubmit} ><b>Save</b></Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default AllUser;
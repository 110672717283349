import React, { useState } from "react";
import Box from "@mui/material/Box";
import { Input, message, Upload } from "antd";
import { useDispatch } from "react-redux";
import { sendTelegramMessage } from "../../redux/features/gallerySlice";

function TelegramMessageSender() {
  const [textFieldText, setTextFieldText] = useState("");
  const [image, setImage] = useState();
  const dispatch = useDispatch();

  const handleTextFieldChanger = (e) => {
    console.log(e.target.value);
    setTextFieldText(e.target.value);
  };

  const onImageSelect = (e) => {
    console.log(e.target.files && e.target.files[0]);
    setImage(e.target.files[0]);
    message.success("Image uploaded");
  };

  const handleSubmit = (e) => {
    // Prevent the browser from reloading the page
    e.preventDefault();
    const formData = new FormData();
    if (image) formData.append("file", image);
    formData.append("image_type", "Telegram");
    formData.append("type_id", 7);
    formData.append("message", textFieldText);
    dispatch(sendTelegramMessage(formData));
  };
  return (
    <Box
      sx={{
        width: 500,
        maxWidth: "100%",
      }}
    >
      {/* <label>
        Write your Message:
        <textarea name="postContent" rows={10} cols={100} onChange={handleTextFieldChanger} />
      </label> */}
      <form method="post" onSubmit={handleSubmit}>
        <label>
          Write your Message:
          <textarea
            name="postContent"
            rows={10}
            cols={100}
            onChange={handleTextFieldChanger}
          />
        </label>
        <label>Upload image</label>
        <Input
          className="imageFile h-auto"
          accept="image/png, image/gif, image/jpeg ,image/jpg"
          // required={true}
          type="file"
          id="img"
          name="image"
          onChange={onImageSelect}
        />
        <hr />
        <button type="submit">Save post</button>
      </form>
    </Box>
  );
}

export default TelegramMessageSender;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as notificationApi from "../api/notificationApi";
import { message } from "antd";
const initialState = {
    notificationHistoryData: [],
    refreshHisData: [],
    catArrData: [],
    loading: false,
    error: "",
    success: "",
};

//======================================================= get deals data using asyncthunk=====================================

export const getNotificationHistoryData = createAsyncThunk(
    "notification/notifyhistory",
    async ({ type, page }, { rejectWithValue }) => {
        try {
            console.log(type, page)
            const resp = await notificationApi.getNotificationHistory(type, page);
            console.log(resp.data, "resp notification")
            if (resp.data.code === 200) {
                return resp.data.data;
            } else {
                return rejectWithValue(resp.data);
            }
        } catch (error) {
            rejectWithValue(error.resp.data);
        }
    }
);


//======================================================= addStoresPanel data=====================================

export const sendNotificationData = createAsyncThunk(
    "notification/notify",
    async (formData, { rejectWithValue }) => {
        try {
            const resp = await notificationApi.sendNotification(formData);
            if (resp.data.code === 200) {
                message.success(resp.data.message ? resp.data.message : "store added successfully")
                return resp.data.message;
            } else {
                message.error(resp.data.message ? resp.data.message : "store not added")
                return rejectWithValue(resp.data.message);
            }
        } catch (error) {
            rejectWithValue(error.resp.data);
        }
    }
);

export const refreshHistoryData = createAsyncThunk(
    "notification/refreshhistory",
    async (p_id, { rejectWithValue }) => {
        try {
            const resp = await notificationApi.refreshHistory(p_id);
            if (resp.data.code === 200) {
                message.success(resp.data.message ? resp.data.message : "refresh updated")

                return resp.data.message;
            } else {
                message.error(resp.data.message ? resp.data.message : "Not refresh")

                return rejectWithValue(resp.data);
            }
        } catch (error) {
            rejectWithValue(error.resp.data);
        }
    }
);


const notificationSlice = createSlice({
    name: "notification",
    initialState,

    extraReducers: (builder) => {
        ///=================================getAllStoresData data===========================//////////////////////

        builder.addCase(getNotificationHistoryData.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getNotificationHistoryData.fulfilled, (state, action) => {
            // state.loading = false;
            state.notificationHistoryData = action.payload;
        });
        builder.addCase(getNotificationHistoryData.rejected, (state, action) => {
            state.error = action.payload;
        });


        ///=================================sendNotificationData data===========================//////////////////////

        builder.addCase(sendNotificationData.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(sendNotificationData.fulfilled, (state, action) => {
            // state.loading = false;
            state.success = action.payload;
        });
        builder.addCase(sendNotificationData.rejected, (state, action) => {
            state.error = action.payload;
        });


        ///=================================refreshHistoryData data===========================//////////////////////

        builder.addCase(refreshHistoryData.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(refreshHistoryData.fulfilled, (state, action) => {
            // state.loading = false;
            state.refreshHisData = action.payload;
        });
        builder.addCase(refreshHistoryData.rejected, (state, action) => {
            state.error = action.payload;
        });
    },
});
// export const { setCategories } = notificationSlice.actions;
export default notificationSlice.reducer;
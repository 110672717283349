import axios from "axios";
import AppConstants from "../../constants/AppConstants";

const API = axios.create({ baseURL: AppConstants.baseURL });





/* Flash Sale Data Api's */


export const getFlashSaleProducts = async () => await API.get(`/sales/getsaleproducts`);
export const changeDate = async (formData) => await API.post("/sales/changesaledate", formData);

export const uploadData = async (formData) => await API.post("/sales/uploadsale", formData);
export const createJsonFile = async () => await API.get("/sales/createjsonfile",);
export const uploadFiles = async (formData) => await API.post(`/sales/uploadjson`, formData);

export const getProductByUrl = async (formData) => await API.post(`/sales/getproductsbyurl`, formData);
export const updateProduct = async (formData) => await API.post(`/sales/addsaleproducts`, formData);

export const getAllSaleProducts = async () => await API.get("/sales/getallproducts",);
export const updateSaleProducts = async (formData) => await API.post(`/sales/updatesaleproducts`, formData);

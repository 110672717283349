import React from 'react';
import {
  Typography,
  Box,
} from "@mui/material";
import LineChart from './Chart/LineChart';
import PieChart from './Chart/PieChart';

function Dashboard() {
  const labels = [1, 2, 3, 4, 5, 6, 7, 8];

  return (
    <>
      <Typography fontSize={25} fontWeight={700} color='#11142D'>
        Dashboard
      </Typography>
      <Box mt='20px' display='flex' flexWrap='wrap' gap={4}>
        <PieChart
          title="Daily Users"
          value={684}
          series={[75, 25]}
          colors={["#275be8", "#c4e8ef"]}
        />
        <PieChart
          title="Total Users"
          value={684}
          series={[60, 40]}
          colors={["#275be8", "#c4e8ef"]}
        />
        <PieChart
          title="Additional Data1"
          value={5686}
          series={[75, 25]}
          colors={['#475ae8', '#e4b8ef']}
        />
        <PieChart
          title="Additional Data2"
          value={555}
          series={[75, 25]}
          colors={['#475ae8', '#e4b8ef']}
        />
      </Box>
      <Box sx={{ backgroundColor: '#fcfcfc', marginTop: '20px', border: '1px solid #EDEDED', borderRadius: '15px', padding: '8px 8px' }}>
        <Typography sx={{ display: 'flex', justifyContent: 'center', marginTop: '5px' }}>
          <b>Daily Users Graph</b>
        </Typography>
        <LineChart
          data={{
            labels: labels,
            datasets: [{
              label: 'Daily Users',
              data: [65, 59, 80, 81, 56, 55, 40, 0],
              fill: false,
              tension: 0.1,
              backgroundColor: [
                'rgba(255, 99, 132, 0.2)',
                'rgba(54, 162, 235, 0.2)',
                'rgba(255, 206, 86, 0.2)',
                'rgba(75, 192, 192, 0.2)',
                'rgba(153, 102, 255, 0.2)',
                'rgba(255, 159, 64, 0.2)'
              ],
              borderColor: [
                'rgba(255, 99, 132, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(255, 206, 86, 1)',
                'rgba(75, 192, 192, 1)',
                'rgba(153, 102, 255, 1)',
                'rgba(255, 159, 64, 1)',
                'rgb(75, 192, 192)'
              ],
              borderWidth: 1
            }]
          }}
          options={{
            maintainAspectRatio: true,
            legend: {
              labels: {
                fontSize: 25,
              },
            },
          }}
        />
      </Box>
    </>
  )
}
export default Dashboard;
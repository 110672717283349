import React, { useEffect, useState } from "react";
import {
  Paper,
  Stack,
  Table,
  TableCell,
  TableRow,
  TextField,
  Typography,
  tableCellClasses,
  TableBody,
  Toolbar,
  Button,
  Autocomplete,
  TextareaAutosize,
  FormControlLabel,
  Checkbox,
  Chip,
  Radio,
  RadioGroup,
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ReplyIcon from "@mui/icons-material/Reply";
import { NavLink, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  ContentState,
  convertFromHTML,
  EditorState,
  convertToRaw,
} from "draft-js";
// import { convertToHTML } from "draft-convert";
import { stateToHTML } from "draft-js-export-html";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Image, message } from "antd";
import {
  addProductList,
  getStores_graph,
} from "../../redux/features/productsSlice";

export const nodesfromHtml = (html) => {
  const blocksFromHTML = convertFromHTML(html);
  const nodes = ContentState.createFromBlockArray(
    blocksFromHTML.contentBlocks,
    blocksFromHTML.entityMap
  );
  return EditorState.createWithContent(nodes);
};

function AddProductPid() {
  const { state } = useLocation();
  // console.log(state, 'state');
  const { store_graph } = useSelector((state) => ({ ...state.products }));

  const dispatch = useDispatch();

  const [addData, setAddData] = useState({
    store_name: null,
    store_id: null,
    pid: "",
    in_stock: 1,
    fs_pid: null,
  });
  const [descriptionType, setDescriptionType] = useState("html");
  const [editorState, setEditorState] = useState(
    state && state.data && state.data.description
      ? nodesfromHtml(state.data.description)
      : EditorState.createEmpty()
  );

  // formData
  const [productData, setProductData] = useState({
    fs_pid: state && state.productData ? state.productData.fs_pid : null,
    store_pid: state && state.productData ? state.productData.store_pid : [],
    img_url: state && state.productData ? state.productData.img_url : "",
    title: state && state.productData ? state.productData.title : "",
    description:
      state && state.productData ? state.productData.description : "",
    mrp: state && state.productData ? state.productData.mrp : 0,
  });
  console.log(productData, "productData");
  useEffect(() => {
    dispatch(getStores_graph());
  }, []);

  // Description type - html
  const handledetailsChange = (event) => {
    setProductData({
      ...productData,
      [event.target.name]: event.target.value,
    });
  };
  // Description type - text
  const handleEditorChange = (editorState) => {
    setEditorState(editorState);
    var html = stateToHTML(editorState.getCurrentContent());
    setProductData({ ...productData, description: html });
  };

  const handleStoreSelect = (val) => {
    if (store_graph.length > 0) {
      for (var i = 0; i < store_graph.length; i++) {
        if (store_graph[i].store_name === val) {
          setAddData({
            ...addData,
            store_id: store_graph[i].site_id,
            store_name: store_graph[i].store_name,
          });
        }
      }
    }
  };

  const handleAddBtn = () => {
    const present = productData.store_pid?.find(
      (item) => item.store_id === addData.store_id && item.pid === addData.pid
    );
    if (present === undefined) {
      setProductData({
        ...productData,
        store_pid: [...productData.store_pid, addData],
      });
    } else {
      message.error("PID already added");
    }
  };

  const handleChipDelete = (obj) => {
    let data = productData.store_pid?.filter(
      (item) => item.store_id !== obj.store_id && item.pid !== obj.pid
    );
    setProductData({ ...productData, store_pid: data });
  };

  const handleChange = (key) => (e) => {
    setProductData({ ...productData, [key]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    productData.mrp = productData.mrp - "0";
    console.log(productData, "productData");
    dispatch(addProductList(productData));

    // reset formData
    setProductData({
      fs_pid: null,
      store_pid: [],
      img_url: "",
      title: "",
      description: "",
      mrp: 0,
    });
  };
  return (
    <Paper elevation={3}>
      <form onSubmit={handleSubmit}>
        <Stack spacing={3} direction="column">
          <Toolbar
            mt={3}
            sx={{
              padding: "1.2rem",
              borderBottom: "2px solid var(--primary-color)",
            }}
          >
            <Typography
              sx={{
                flex: "1 1 100%",
                color: "var(--primary-color)",
                fontWeight: "bold",
              }}
              variant="h5"
              id="tableTitle"
            >
              Add Product IDs
            </Typography>
            <NavLink to="/products/manage-products">
              <Button
                variant="contained"
                color="info"
                sx={{ whiteSpace: "nowrap" }}
                startIcon={<ReplyIcon />}
              >
                <strong>Show Products</strong>
              </Button>
            </NavLink>
          </Toolbar>
          <Table
            size="small"
            sx={{
              width: "auto",
              margin: "8px !important",
              marginTop: "16px !important",
              [`& .${tableCellClasses.root}`]: {
                borderBottom: "none",
              },
            }}
          >
            <TableBody>
              <TableRow>
                <TableCell>
                  <Typography variant="h6" sx={{ whiteSpace: "nowrap" }}>
                    <b>Select Store</b>
                  </Typography>
                </TableCell>
                <TableCell>
                  <Stack direction="row" gap={4} sx={{ maxWidth: "80%" }}>
                    <Autocomplete
                      freeSolo
                      // disableClearable
                      size="small"
                      options={
                        store_graph
                          ? store_graph.map((item) => item.store_name)
                          : []
                      }
                      value={addData.store_name}
                      sx={{ width: 240 }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Select Store Name"
                        />
                      )}
                      onChange={(e, val) => handleStoreSelect(val)}
                    />
                    <TextField
                      size="small"
                      placeholder="Enter PID"
                      value={addData.pid}
                      onChange={(e) =>
                        setAddData({ ...addData, pid: e.target.value })
                      }
                    />
                    <FormControlLabel
                      control={<Checkbox />}
                      label="In Stock"
                      sx={{ whiteSpace: "nowrap" }}
                      checked={addData.in_stock === 1 ? true : false}
                      onChange={(e) =>
                        setAddData({
                          ...addData,
                          in_stock: e.target.checked ? 1 : 0,
                        })
                      }
                    />
                    <Button
                      variant="contained"
                      color="success"
                      startIcon={<AddCircleOutlineIcon />}
                      onClick={handleAddBtn}
                    >
                      <strong>Add</strong>
                    </Button>
                  </Stack>
                </TableCell>
              </TableRow>

              {productData.store_pid.length > 0 && (
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>
                    <Stack direction="row" gap={2} sx={{ flexWrap: "wrap" }}>
                      {productData?.store_pid?.map((item) => (
                        <Chip
                          key={`pid-chip-${item.pid}-${item.store_id}`}
                          label={`${item.store_name} - ${item.pid} - ${
                            item.in_stock === 1 ? "In Stock" : "Out of Stock"
                          }`}
                          color="warning"
                          variant="outlined"
                          sx={{ overflowWrap: "break-word" }}
                          onDelete={() => handleChipDelete(item)}
                        />
                      ))}
                    </Stack>
                  </TableCell>
                </TableRow>
              )}

              <TableRow>
                <TableCell>
                  <Typography variant="h6">
                    <b>Image URL</b>
                  </Typography>
                </TableCell>
                <TableCell>
                  <TextField
                    size="small"
                    fullWidth
                    placeholder="Enter image url"
                    value={productData.img_url}
                    onChange={handleChange("img_url")}
                  />
                </TableCell>
              </TableRow>

              {productData.img_url && (
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>
                    <Image
                      src={productData.img_url}
                      alt="product-preview"
                      height={150}
                    />
                  </TableCell>
                </TableRow>
              )}

              <TableRow>
                <TableCell>
                  <Typography variant="h6">
                    <b>Title</b>
                  </Typography>
                </TableCell>
                <TableCell>
                  <TextField
                    size="small"
                    fullWidth
                    placeholder="Enter product title"
                    value={productData.title}
                    onChange={handleChange("title")}
                  />
                </TableCell>
              </TableRow>

              {/* <TableRow>
                <TableCell>
                  <Typography variant="h6">
                    <b>Description</b>
                  </Typography>
                </TableCell>
                <TableCell>
                  <TextareaAutosize
                    minRows={1}
                    maxRows={4}
                    color="primary"
                    placeholder="Enter description"
                    value={productData.description}
                    onChange={handleChange("description")}
                    style={{ width: "100%", padding: "8px 12px", fontSize: '1rem' }}
                  />
                </TableCell>
              </TableRow> */}

              <TableRow>
                <TableCell>
                  <Typography variant="h6">
                    <b>Select Description Type</b>
                  </Typography>
                </TableCell>
                <TableCell>
                  <RadioGroup
                    name="descriptionType"
                    aria-labelledby="description-type"
                    row
                    value={descriptionType}
                    onChange={(e) => setDescriptionType(e.target.value)}
                  >
                    <FormControlLabel
                      control={<Radio />}
                      label="Text"
                      value="text"
                    />
                    <FormControlLabel
                      control={<Radio />}
                      label="HTML"
                      value="html"
                    />
                  </RadioGroup>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  <Typography variant="h6">
                    <b> Store Description</b>
                  </Typography>
                </TableCell>
                <TableCell sx={{ width: "60%" }}>
                  {descriptionType === "text" ? (
                    <div
                      style={{
                        border: "1px solid black",
                        height: "241px",
                        overflow: "auto",
                      }}
                    >
                      <Editor
                        editorState={editorState}
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="wrapperClassName"
                        editorClassName="editorClassName"
                        onEditorStateChange={handleEditorChange}
                      />
                    </div>
                  ) : (
                    <TextareaAutosize
                      minRows={10}
                      maxRows={10}
                      type="text"
                      value={productData.description}
                      name="description"
                      onChange={handledetailsChange}
                      style={{
                        width: "100%",
                        padding: "8px 12px",
                        fontSize: "0.95rem",
                      }}
                    />
                  )}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  <Typography variant="h6">
                    <b>MRP</b>
                  </Typography>
                </TableCell>
                <TableCell>
                  <TextField
                    size="small"
                    // fullWidth
                    type="number"
                    placeholder="Enter product mrp"
                    value={productData.mrp}
                    onChange={handleChange("mrp")}
                    onKeyDown={(e) => {
                      if (e.key === "ArrowUp" || e.key === "ArrowDown") {
                        e.preventDefault(); // prevent the default behavior of changing the input value
                      }
                    }}
                  />
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell colSpan="2" className="text-center">
                  <Button
                    type="submit"
                    variant="contained"
                    size="large"
                    sx={{ marginTop: 2, width: "160px" }}
                  >
                    <b>Submit</b>
                  </Button>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Stack>
      </form>
    </Paper>
  );
}

export default AddProductPid;

import axios from "axios";
import AppConstants from "../../constants/AppConstants";

const API = axios.create({ baseURL: AppConstants.baseURL });

export const getProductListApi = async (formData) =>
  await API.post(`/products/getprodlist`, formData);

export const addProductListApi = async (formData) =>
  await API.post("/products/addprodlist", formData);
export const getStores_graphApi = async () =>
  await API.get("/products/storegraphs");

export const activateProductListApi = async (formData) =>
  await API.post(`/products/activeprodlist`, formData);

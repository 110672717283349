import axios from "axios";
import AppConstants from "../../constants/AppConstants";

const API = axios.create({ baseURL: AppConstants.baseURL });

//========================================Coupons Stats========================================//
export const empTrackTable = async () => await API.post("/coupons/emptracktable");
export const getCouponCount = async () => await API.get("/coupons/getcouponcount");
export const getScrapCount = async () => await API.get("/coupons/getscrapcount");

//========================================All Coupons Data========================================//
export const getAllCoupons = async () => await API.get("/coupons/coupons");

//========================================Add Coupon========================================//
export const addCoupon = async (formData) => await API.post("/coupons/coupons", formData);

//========================================Update Coupon========================================//
export const updateCoupon = async (formData) => await API.post("/coupons//updatecoupons", formData);

//========================================Delete Coupon========================================//
export const deleteCoupon = async (formData) => await API.post("/coupons/deletecoupon", formData);

//========================================Search Coupons Data========================================//
export const searchCoupons = async (formData) => await API.post("/coupons/search", formData);

//========================================User Coupons========================================//
export const getUserCoupons = async () => await API.get("/coupons/getusercoupons");

//========================================Scraped Coupons========================================//
export const getScrapedCoupons = async () => await API.get("/coupons/getscrapedcoupons");

//========================================Add Scrap Coupon========================================//
export const addScrapCoupon = async (formData) => await API.post("/coupons/addscrapcoupon", formData);

//========================================Delete Scrap Coupon========================================//
export const deleteScrapCoupon = async (formData) => await API.post("/coupons/removescrapcoupon", formData);

//========================================Expired Coupons========================================//
export const getExpiredCoupons = async () => await API.get("/coupons/expiredcoupons");

//========================================Expiry Alerts========================================//
export const getExpiryAlerts = async () => await API.get("/coupons/alertcoupons");

//========================================Assign site to user========================================//
export const assignSiteToUser = async (formData) => await API.post("/coupons/assignsite", formData);

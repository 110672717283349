/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import Datatable from "../../Datatable/Datatable";
import { Button, LinearProgress } from "@mui/material";
import { NavLink } from "react-router-dom";
import AddBoxIcon from "@mui/icons-material/AddBox";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import { useDispatch, useSelector } from "react-redux";
import {
  activeStoreSales,
  getStoreSalesData,
  getStoreSalesImageData,
} from "../../../redux/features/websiteSlices/storeSalesSlice";
import dateConverter from "../../../basicFunctions/dateConverter";
import { getAllStoresData } from "../../../redux/features/storesSlice";

function ShowSales() {
  const dispatch = useDispatch();

  const { loading, storeSalesData } = useSelector((state) => state.storeSales);
  const { allStoresData } = useSelector((state) => state.stores);

  // get data
  const cbFunc = () => {
    dispatch(getStoreSalesData());
  };

  // handle activation
  const handleStoreSalesActivation = (formData) => {
    dispatch(activeStoreSales({ formData, cbFunc }));
  };

  // storeName from storeId
  const handleStoreNameFromId = (id) => {
    for (let i = 0; i < allStoresData.length; i++) {
      if (id === allStoresData[i].store_id) return allStoresData[i].store_name;
    }
  };

  // datatable columns
  const columns = [
    {
      name: "id",
      label: "Action",
      options: {
        customBodyRender: (id) => {
          let formData = storeSalesData.filter((row) => row.id === id)[0];
          return (
            <NavLink to="/websitedata/add-sales" state={{ formData }}>
              <Button
                color="info"
                variant="contained"
                size="small"
                startIcon={<EditRoundedIcon />}
              >
                <strong>Edit</strong>
              </Button>
            </NavLink>
          );
        },
        filter: true,
        sort: true,
      },
    },
    {
      name: "store_id",
      label: "Store ID",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "store_id",
      label: "Store Name",
      options: {
        customBodyRender: (storeId) => {
          return <p className="mb-0">{handleStoreNameFromId(storeId)}</p>;
        },
        filter: true,
        sort: true,
      },
    },
    {
      name: "description",
      label: "Description",
      options: {
        customBodyRender: (desc) => {
          return (
            <p
              style={{
                margin: 0,
                width: "300px",
              }}
            >
              {desc}
            </p>
          );
        },
        filter: true,
        sort: true,
      },
    },
    {
      name: "url",
      label: "URL",
      options: {
        customBodyRender: (link) => {
          return (
            <a href={link} target="_blank" rel="noreferrer">
              {link}
            </a>
          );
        },
        filter: true,
        sort: true,
      },
    },
    {
      name: "img_id",
      label: "Image ID",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "id",
      label: "Status",
      options: {
        customBodyRender: (id) => {
          let temp = storeSalesData.filter((s) => s.id === id)[0];
          let data = {};
          data.id = temp.id;
          data.active = +temp.active ? "0" : "1";
          if (+temp.active)
            return (
              <Button
                variant="outlined"
                color="error"
                size="small"
                onClick={() => handleStoreSalesActivation(data)}
              >
                <strong> Deactivate</strong>
              </Button>
            );
          else
            return (
              <Button
                variant="outlined"
                color="success"
                size="small"
                onClick={() => handleStoreSalesActivation(data)}
              >
                <strong>Activate</strong>
              </Button>
            );
        },
        filter: true,
        sort: true,
      },
    },
    {
      name: "start_date",
      label: "Start Date",
      options: {
        customBodyRender: (date) => {
          return <p className="mb-0">{dateConverter(date)}</p>;
        },
        filter: true,
        sort: true,
      },
    },
    {
      name: "end_date",
      label: "End Date",
      options: {
        customBodyRender: (date) => {
          return <p className="mb-0">{dateConverter(date)}</p>;
        },
        filter: true,
        sort: true,
      },
    },
  ];

  useEffect(() => {
    dispatch(getAllStoresData());
    dispatch(getStoreSalesImageData());
    cbFunc();
  }, []);

  return (
    <>
      <NavLink to="/websitedata/add-sales">
        <Button
          variant="contained"
          color="success"
          startIcon={<AddBoxIcon />}
          sx={{ marginBottom: "15px" }}
        >
          <strong>Add New Sale</strong>
        </Button>
      </NavLink>
      {loading && <LinearProgress />}
      <Datatable
        name="Sale Data"
        columns={columns}
        data={storeSalesData && storeSalesData}
      />
    </>
  );
}

export default ShowSales;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Paper,
  Stack,
  Box,
  Typography,
  Button,
  Toolbar,
  Autocomplete,
  TextField,
  FormControlLabel,
  Checkbox,
  Grid,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getAllPanelUsersData } from "../../redux/features/authSlice";
import { assignSite } from "../../redux/features/couponsSlice";

function AssignSite() {
  const dispatch = useDispatch()
  // all users
  const users = useSelector(state => state.userAuth.allPanelUsers && state.userAuth.allPanelUsers)

  // all stores
  const stores = useSelector(state => state.coupons.allCouponsData.sites);

  // formData
  const [formData, setFormData] = useState({
    user: 0,
    username: "",
    sites: [],
  })

  // handle users
  const handleChange = (val) => {
    for (let i = 0; i < users.length; i++) {
      if (users[i].name === val)
        setFormData({ ...formData, user: users[i].fp_uid, username: users[i].name })
    }
  }

  // handle stores
  const handleStores = (e) => {
    const index = formData.sites.indexOf(e.target.value);
    if (index === -1) {
      setFormData({ ...formData, sites: [...formData.sites, e.target.value] });
    } else {
      setFormData({ ...formData, sites: formData.sites.filter((key) => key !== e.target.value) })
    }
  };

  // Form handler
  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(formData);
    dispatch(assignSite(formData))
  };

  useEffect(() => {
    dispatch(getAllPanelUsersData())
  }, [])

  return (
    <Paper elevation={3}>
      <form onSubmit={handleSubmit}>
        <Stack spacing={5} direction="column">
          <Box
            sx={{
              padding: "1.2rem",
              borderBottom: "2px solid var(--primary-color)",
            }}
          >
            <Typography
              sx={{
                color: "var(--primary-color)",
                fontWeight: "bold",
              }}
              variant="h5"
              id="tableTitle"
            >
              Site Assignment
            </Typography>
          </Box>
          <Stack
            direction="row"
            spacing={3}
            justifyContent="center"
            alignItems="center"
          >
            <Typography variant="h6">
              <b>Select User :</b>
            </Typography>
            <Autocomplete
              freeSolo
              size="small"
              id="user-combo-box"
              value={formData.user_name}
              options={users ? users.map(user => user.name) : []}
              sx={{ width: 300 }}
              renderInput={(params) => (
                <TextField {...params} placeholder="Select User" />
              )}
              onChange={(e, newVal) => handleChange(newVal)}
            />
          </Stack>
          <Box>
            <Toolbar mt={3} sx={{ borderBottom: "2px solid grey" }}>
              <Typography
                sx={{
                  flex: "1 1 100%",
                  color: "var(--primary-col or)",
                  fontWeight: "bold",
                }}
                variant="h6"
              >
                Select stores
              </Typography>
              <Button variant="contained" type="submit" color="primary">
                <strong>Assign</strong>
              </Button>
            </Toolbar>
            <Grid
              container
              spacing={2}
              sx={{ margin: "-10px 0 0 0", padding: "1.5rem 1.2rem", width: "100%" }}
            >
              {Array.isArray(stores) && stores.map((store) => (
                <Grid
                  xs={3}
                  item
                  sx={{
                    padding: '0 !important',
                  }}
                  key={store.site_id}
                >
                  <FormControlLabel
                    className="text-capitalize"
                    label={store.site_name}
                    control={
                      <Checkbox
                        value={store.site_id}
                        checked={formData.sites.includes(store.site_id)}
                        onChange={handleStores}
                      />
                    }
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
        </Stack>
      </form>
    </Paper>
  );
}

export default AssignSite;


/* Flash Sale Data Api's */



// export const uploadData = async (formData) => await API.post("/sales/uploadsale", formData);

// export const getProductByUrl = async (formData) => await API.post(`/sales/getproductsbyurl`, formData);

// export const changeDate = async (formData) => await API.post("/sales/changesaledate", formData);

// export const updateProduct = async (formData) => await API.post(`/sales/addsaleproducts`, formData);

// export const createJsonFile = async () => await API.get("/sales/createjsonfile",);

// export const uploadFiles = async (formData) => await API.post(`/sales/uploadjson`, formData);

// export const getAllSaleProducts = async () => await API.get("/sales/getallproducts",);

// export const updateSaleProducts = async (formData) => await API.post(`/sales/updatesaleproducts`, formData);


import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as salesApi from "../api/salesApi";
import { message } from "antd";
const initialState = {
    saleProductsData: [],
    flashsaleProductsData: [],
    refreshHisData: [],
    loading: false,
    error: "",
    success: "",
};

//======================================================= get flash sale data using asyncthunk=====================================

export const getSaleProductsData = createAsyncThunk(
    "sales/getsaleproducts",
    async (_, { rejectWithValue }) => {
        try {
            const resp = await salesApi.getAllSaleProducts();
            // console.log(resp.data, "resp sales")
            if (resp.data.code === 200) {
                return resp.data.data;
            } else {
                return rejectWithValue(resp.data);
            }
        } catch (error) {
            rejectWithValue(error.resp.data);
        }
    }
);

export const updateSaleProductData = createAsyncThunk(
    "sales/updatesaleproduct",
    async ({ formData, cbFunc }, { rejectWithValue }) => {
        try {
            const resp = await salesApi.updateSaleProducts(formData);
            // console.log(resp.data, "resp sales")
            if (resp.data.code === 200) {
                message.success(resp.data.message);
                cbFunc();
                return resp.data;
            } else {
                return rejectWithValue(resp.data);
            }
        } catch (error) {
            rejectWithValue(error.resp.data);
        }
    }
);

//======================================================= get sale data using asyncthunk=====================================
export const getFlashSaleProductsData = createAsyncThunk(
    "sales/getflashsaleproducts",
    async (_, { rejectWithValue }) => {
        try {
            const resp = await salesApi.getFlashSaleProducts();
            // console.log(resp.data, "resp sales")
            if (resp.data.code === 200) {
                return resp.data.data;
            } else {
                return rejectWithValue(resp.data);
            }
        } catch (error) {
            rejectWithValue(error.resp.data);
        }
    }
);

//======================================================= get sale data using asyncthunk=====================================
export const getProductByUrlData = createAsyncThunk(
    "sales/getproductbyurl",
    async (formData, { rejectWithValue }) => {
        try {
            const resp = await salesApi.getProductByUrl(formData);
            if (resp.data.code === 200) {
                message.success(resp.data.success)
                return resp.data.data;
            } else {
                message.error(resp.data.failed)
                return rejectWithValue(resp.data);
            }
        } catch (error) {
            rejectWithValue(error.resp.data);
        }
    }
);

//======================================================= add sale data using asyncthunk=====================================
export const updateProductData = createAsyncThunk(
    "sales/updateproduct",
    async (formData, { rejectWithValue }) => {
        try {
            const resp = await salesApi.updateProduct(formData);
            if (resp.data.code === 200) {
                message.success(resp.data.success)
                return resp.data;
            } else {
                message.error(resp.data.failed)
                return rejectWithValue(resp.data);
            }
        } catch (error) {
            rejectWithValue(error.resp.data);
        }
    }
);



export const changeSaleDate = createAsyncThunk(
    "sales/changesaledate",
    async ({ formData, cbFunc }, { rejectWithValue }) => {
        try {
            const resp = await salesApi.changeDate(formData);
            // console.log(resp.data, "resp sales")
            if (resp.data.code === 200) {
                message.success(resp.data.message ? resp.data.message : "Date Change SuccessFully")
                return resp.data;
            } else {
                message.error(resp.data.message ? resp.data.message : "Date Change SuccessFully")
                return rejectWithValue(resp.data);
            }
        } catch (error) {
            rejectWithValue(error.resp.data);
        }
    }
);


//======================================================= addStoresPanel data=====================================

// export const sendNotificationData = createAsyncThunk(
//     "sales/notify",
//     async (formData, { rejectWithValue }) => {
//         try {
//             const resp = await salesApi.sendNotification(formData);
//             if (resp.data.code === 200) {
//                 message.success(resp.data.message ? resp.data.message : "store added successfully")
//                 return resp.data.message;
//             } else {
//                 message.error(resp.data.message ? resp.data.message : "store not added")
//                 return rejectWithValue(resp.data.message);
//             }
//         } catch (error) {
//             rejectWithValue(error.resp.data);
//         }
//     }
// );

// export const refreshHistoryData = createAsyncThunk(
//     "sales/refreshhistory",
//     async (p_id, { rejectWithValue }) => {
//         try {
//             const resp = await salesApi.refreshHistory(p_id);
//             if (resp.data.code === 200) {
//                 message.success(resp.data.message ? resp.data.message : "refresh updated")

//                 return resp.data.message;
//             } else {
//                 message.error(resp.data.message ? resp.data.message : "Not refresh")

//                 return rejectWithValue(resp.data);
//             }
//         } catch (error) {
//             rejectWithValue(error.resp.data);
//         }
//     }
// );


const saleSlice = createSlice({
    name: "sales",
    initialState,

    extraReducers: (builder) => {
        ///=================================getFlashSaleProductsData data===========================//////////////////////
        builder.addCase(getFlashSaleProductsData.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getFlashSaleProductsData.fulfilled, (state, action) => {
            state.loading = false;
            state.flashsaleProductsData = action.payload;
        });
        builder.addCase(getFlashSaleProductsData.rejected, (state, action) => {
            state.error = action.payload;
        });

        ///=================================changeSaleDate data===========================//////////////////////
        builder.addCase(changeSaleDate.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(changeSaleDate.fulfilled, (state, action) => {
            // state.loading = false;
            state.success = action.payload;
        });
        builder.addCase(changeSaleDate.rejected, (state, action) => {
            state.error = action.payload;
        });

        ///=================================getSaleProductsData data===========================//////////////////////
        builder.addCase(getSaleProductsData.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getSaleProductsData.fulfilled, (state, action) => {
            state.saleProductsData = action.payload;
        });
        builder.addCase(getSaleProductsData.rejected, (state, action) => {
            state.error = action.payload;
        });

        ///=================================updateSaleProductsData data===========================//////////////////////
        builder.addCase(updateSaleProductData.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(updateSaleProductData.fulfilled, (state, action) => {
            state.loading = false;
        });
        builder.addCase(updateSaleProductData.rejected, (state, action) => {
            state.error = action.payload;
        });

        ///=================================getProductByUrlData data===========================//////////////////////
        builder.addCase(getProductByUrlData.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getProductByUrlData.fulfilled, (state, action) => {
            state.productUrlData = action.payload;
            state.loading = false;
        });
        builder.addCase(getProductByUrlData.rejected, (state, action) => {
            state.error = action.payload;
            state.loading = false;
        });

        ///=================================updateProductData data===========================//////////////////////
        builder.addCase(updateProductData.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(updateProductData.fulfilled, (state, action) => {
            state.loading = false;
        });
        builder.addCase(updateProductData.rejected, (state, action) => {
            state.error = action.payload;
            state.loading = false;
        });


        ///=================================refreshHistoryData data===========================//////////////////////

        // builder.addCase(refreshHistoryData.pending, (state) => {
        //     state.loading = true;
        // });
        // builder.addCase(refreshHistoryData.fulfilled, (state, action) => {
        //     // state.loading = false;
        //     state.refreshHisData = action.payload;
        // });
        // builder.addCase(refreshHistoryData.rejected, (state, action) => {
        //     state.error = action.payload;
        // });
    },
});
// export const { setCategories } = notificationSlice.actions;
export default saleSlice.reducer;
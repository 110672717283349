/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Paper,
  Stack,
  Typography,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  ButtonGroup,
  Button,
  LinearProgress,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import Datatable from '../Datatable/Datatable';
import { addScrapCouponData, deleteScrapCouponData, getExpiredCouponsData, getExpiryAlertsData, getScrapedCouponsData, getUserCouponsData } from '../../redux/features/couponsSlice';
import { Popconfirm } from 'antd';
import { NavLink } from 'react-router-dom';
import dateConverter from '../../basicFunctions/dateConverter';
import { getAllStoresData } from '../../redux/features/storesSlice';

function ShowUserCoupon() {
  const dispatch = useDispatch()
  // type setter
  const [type, setType] = useState("scraped_coupons");

  const { allStoresData } = useSelector(state => state.stores)

  const getStorenameFromID = (id) => {
    for (let i = 0; i < allStoresData.length; i++) {
      if (allStoresData[i].site_id === id)
        return allStoresData[i].site_name;
    }
  }


  // redux states
  const { userCouponsData, scrapedCouponsData, expiredCouponsData, expiryAlertsData, loading } = useSelector(state => state.coupons)

  // User Coupon
  const userCouponsColumn = [
    {
      name: "site",
      label: "Site",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "site_id",
      label: "Site ID",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "coupon_code",
      label: "Coupon Code",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "times_used",
      label: "No. of times used in last 5 days",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "assigned_to",
      label: "Assigned to",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "action",
      label: "Action",
      options: {
        customBodyRender: (row) => {
          return (
            <ButtonGroup orientation="horizontal" size="small" variant="text" color="success">
              <Button color="success">
                <strong>Add</strong>
              </Button>
              <Button color="error">
                <strong>Remove</strong>
              </Button>
            </ButtonGroup>
          )
        },
        sort: false,
        filter: false,
      }
    },
  ]

  // Scraped Coupon
  const scrapedCouponsColumn = [
    {
      name: "code",
      label: "Action",
      options: {
        customBodyRender: (code, tableMeta) => {
          let formData = {}
          formData.coupon_code = code;
          formData.deal_url = tableMeta.rowData[1];
          formData.site_id = tableMeta.rowData[2];
          formData.store_name = getStorenameFromID(tableMeta.rowData[2]);
          formData.short_title = tableMeta.rowData[3];
          formData.title = tableMeta.rowData[4];
          formData.short_desc = tableMeta.rowData[6];
          formData.details = tableMeta.rowData[7];
          formData.reference = tableMeta.rowData[11];

          return (
            <ButtonGroup orientation="vertical" variant="text" color="success">

              <NavLink to="/coupons/add-coupon" state={{ couponData: formData, from: "scrapCoupon" }}>
                <Button color="success">
                  <strong>Add</strong>
                </Button>
              </NavLink>
              <Popconfirm
                placement="right"
                title="Delete Scraped coupon"
                description="Do you really want to delete this scrapped coupon?"
                okText="Yes"
                cancelText="No"
                onConfirm={(event) => handleScrapedCouponDelete(event, tableMeta.rowData)}
              >
                <Button color="error">
                  <strong>Remove</strong>
                </Button>
              </Popconfirm>
            </ButtonGroup>
          )
        },
        sort: false,
        filter: false,
      }
    },
    {
      name: "site",
      label: "Site",
      options: {
        customBodyRender: (link) => {
          return <a href={link} target="_blank" rel="noreferrer">{link}</a>
        },
        filter: true,
        sort: true,
      },
    },
    {
      name: "site_id",
      label: "Site ID",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "short_title",
      label: "Short Title",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "title",
      label: "Title",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "code",
      label: "Coupon Code",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "short_desc",
      label: "Short Description",
      options: {
        customBodyRender: (val) => <div dangerouslySetInnerHTML={{ __html: val }} />,
        filter: true,
        sort: false,
      },
    },
    {
      name: "description",
      label: "Description",
      options: {
        customBodyRender: (val) => <div dangerouslySetInnerHTML={{ __html: val }} />,
        filter: true,
        sort: false,
      },
    },
    {
      name: "entry_date",
      label: "Entry Date",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "user_note",
      label: "User Note",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "store_url",
      label: "Store URL",
      options: {
        customBodyRender: (link) => {
          return (
            <a href={link} target="_blank" rel="noreferrer">{link}</a>
          )
        },
        filter: true,
        sort: true,
      },
    },
    {
      name: "reference",
      label: "Source Website",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "assigned_to",
      label: "Assigned to",
      options: {
        filter: true,
        sort: true,
      },
    },
  ]

  // Expired Coupons
  const expiredCouponsColumn = [
    {
      name: 'Site_ID',
      label: 'Action',
      options: {
        customBodyRender: (id, tableMeta) => {
          const couponData = {
            site_id: tableMeta.rowData[0],
            coupon_id: tableMeta.rowData[1],
            store_name: tableMeta.rowData[3],
            deal_url: tableMeta.rowData[4],
            coupon_code: tableMeta.rowData[5],
            title: tableMeta.rowData[6],
            short_title: tableMeta.rowData[7],
            short_desc: tableMeta.rowData[8],
            details: tableMeta.rowData[9],
            reference: tableMeta.rowData[10],
            expiry_date: tableMeta.rowData[11],
            isVerified: tableMeta.rowData[12],
            weightage: tableMeta.rowData[13],
            promotion: tableMeta.rowData[14],
          }
          return (
            <NavLink to="/coupons/add-coupon" state={{ couponData }}>
              <Button
                variant="contained"
                size="small"
                startIcon={<EditIcon />}
              >
                <b>Edit</b>
              </Button>
            </NavLink>
          )
        },
        sort: false,
        filter: false,
      }
    },
    {
      name: 'Coupon_ID',
      label: 'Coupon Id',
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: 'Start_Date',
      label: 'Start Date',
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: 'merchant',
      label: 'Site',
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: 'homepage',
      label: 'URL',
      options: {
        customBodyRender: (link) => {
          return (
            <a href={link} target="_blank" rel="noreferrer">{link}</a>
          )
        },
        filter: true,
        sort: false,
      }
    },
    {
      name: 'Coupon_Code',
      label: 'Code',
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: 'Coupon_Title',
      label: 'Title',
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: 'Very_Short_Title',
      label: 'Short Title',
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: 'Coupon_Short_Description',
      label: 'Short Description',
      options: {
        customBodyRender: (val) => <div dangerouslySetInnerHTML={{ __html: val }} />,
        filter: true,
        sort: false,
      }
    },
    {
      name: 'Coupon_Full_Description',
      label: 'Description',
      options: {
        customBodyRender: (val) => <div dangerouslySetInnerHTML={{ __html: val }} />,
        filter: true,
        sort: false,
      }
    },
    {
      name: 'link',
      label: 'Link',
      options: {
        customBodyRender: (link) => {
          return (
            <a href={link} target="_blank" rel="noreferrer">{link}</a>
          )
        },
        filter: true,
        sort: true,
      }
    },
    {
      name: 'Expiry_Date',
      label: 'Expiry Date',
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: 'verified',
      label: 'Verified',
      options: {
        customBodyRender: (verified) => {
          if (verified) {
            return (
              <Stack flexWrap='wrap' alignContent='center'>
                <CheckCircleIcon color='success' />
              </Stack>
            )
          }
          else {
            return (
              <Stack flexWrap='wrap' alignContent='center'>
                <CancelIcon color='error' />
              </Stack>
            )
          }
        },
        filter: true,
        sort: true,
      }
    },
    {
      name: 'Weightage',
      label: 'Weightage',
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: 'Promotion_Level',
      label: 'Promotion',
      options: {
        filter: true,
        sort: true,
      }
    },
  ]

  // Expiry Alert
  const expiryAlertsColumn = [
    {
      name: "site",
      label: "Site",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "site_id",
      label: "Site ID",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "code",
      label: "Coupon Code",
      options: {
        filter: true,
        sort: true,
      },
    },
  ];

  // Handlers
  const handleScrapedCouponAdd = (e, rowData) => {
    const formData = {
      store_name: getStorenameFromID(rowData[2]),
      site_id: rowData[2],
      short_title: rowData[3] || " ",
      title: rowData[4],
      coupon_code: rowData[5],
      short_desc: rowData[6],
      details: rowData[7],
      expiry_date: dateConverter(rowData[8]),
      user_note: rowData[9],
      deal_url: rowData[10] || " ",
      isVerified: 1,
      category_ids: "",
      weightage: 1,
      promotion: 1,
      reference: rowData[11],
    }
    dispatch(addScrapCouponData(formData))
  }
  const handleScrapedCouponDelete = (e, rowData) => {
    let formData = { site_id: rowData[2], coupon_code: rowData[5] }
    dispatch(deleteScrapCouponData({ formData, cbFunc }))
  }

  const cbFunc = () => {
    dispatch(getUserCouponsData())
    dispatch(getScrapedCouponsData())
    dispatch(getExpiredCouponsData())
    dispatch(getExpiryAlertsData())
  }

  useEffect(() => {
    cbFunc()
    dispatch(getAllStoresData())
  }, [])

  return (
    <Paper elevation={5}>
      <Stack
        direction="row"
        spacing={3}
        p={4}
        alignItems="center"
      >
        <Typography variant="h6">
          <b>Select Type :</b>
        </Typography>
        <FormControl>
          {/* Radio Button Group to select coupon category*/}
          <RadioGroup
            name="user-coupons-radio-selector"
            row
            value={type}
            onChange={(event) => { setType(event.target.value) }}
          >
            <FormControlLabel value="user_coupons" control={<Radio />} label="User Coupons" />
            <FormControlLabel value="scraped_coupons" control={<Radio />} label="Scraped Coupons" />
            <FormControlLabel value="expired_coupons" control={<Radio />} label="Expired Coupons" />
            <FormControlLabel value="expiry_alerts" control={<Radio />} label="Expiry Alerts" />
          </RadioGroup>
        </FormControl>
      </Stack>
      {loading && <LinearProgress />}
      {type === "user_coupons" && <Datatable name="User Coupons" columns={userCouponsColumn} data={userCouponsData} />}
      {type === "scraped_coupons" && <Datatable name="Scraped Coupons" columns={scrapedCouponsColumn} data={scrapedCouponsData} />}
      {type === "expired_coupons" && <Datatable name="Expired Coupons" columns={expiredCouponsColumn} data={expiredCouponsData} />}
      {type === "expiry_alerts" && <Datatable name="Expiry Alerts" columns={expiryAlertsColumn} data={expiryAlertsData} />}
    </Paper>
  )
}

export default ShowUserCoupon
import React, { useEffect } from "react";
import { Button, LinearProgress } from "@mui/material";
import AddBoxIcon from "@mui/icons-material/AddBox";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import { NavLink } from "react-router-dom";
import Datatable from "../Datatable/Datatable";
import { Image, Popconfirm } from "antd";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  activateProductList,
  getProductList,
} from "../../redux/features/productsSlice";

function ManageProductPid() {
  const dispatch = useDispatch();
  const { productsListData } = useSelector((state) => ({ ...state.products }));
  useEffect(() => {
    let data = { page: 0, limit: 20 };
    dispatch(getProductList(data));
  }, []);

  const handleStatus = (formData) => {
    dispatch(activateProductList(formData));
    let data = { page: 0, limit: 20 };
    dispatch(getProductList(data));
  };
  const loading = true;
  const dataWithFinalUrl =
    productsListData &&
    productsListData?.map((product) => {
      const titleForUrl = product?.title
        ?.replace(/[^a-zA-Z0-9 ]/g, "-")
        .replace(/\s+/g, "-")
        .replace(/-+/g, "-")
        .replace(/-$/g, "");
      const finalUrl = `https://flipshope.com/price-comparison/${titleForUrl}`;
      return { ...product, final_url: finalUrl };
    });
  // console.log(dataWithFinalUrl, "dataWithFinalUrl");
  const columns = [
    {
      name: "fs_pid",
      label: "Action",
      options: {
        customBodyRender: (id) => {
          let productData =
            dataWithFinalUrl &&
            dataWithFinalUrl?.filter((row) => row?.fs_pid === id)[0];
          return (
            <NavLink to="/products/add-product" state={{ productData }}>
              <Button
                color="info"
                variant="contained"
                size="small"
                startIcon={<EditRoundedIcon />}
              >
                <strong>Edit</strong>
              </Button>
            </NavLink>
          );
        },
        filter: true,
        sort: true,
      },
    },
    {
      name: "fs_pid",
      label: "Flipshope Product Id",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "store_pid",
      label: "Store - PID - Stock",
      options: {
        customBodyRender: (data) => {
          let str = "";
          data?.map(
            (item) =>
              (str += `${item?.store_name} - ${item?.pid} - ${
                item?.in_stock ? "In Stock" : "Out of Stock"
              }<br />`)
          );
          return (
            <div
              dangerouslySetInnerHTML={{ __html: str }}
              style={{ whiteSpace: "nowrap" }}
            />
          );
        },
        filter: true,
        sort: true,
      },
    },
    {
      name: "title",
      label: "Title",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "img_url",
      label: "Image",
      options: {
        customBodyRender: (image) => {
          return <Image src={image} height={150} />;
        },
        filter: true,
        sort: true,
      },
    },
    {
      name: "description",
      label: "Description",
      options: {
        customBodyRender: (data) => {
          return (
            <p
              style={{
                margin: 0,
                width: 240,
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 6,
                overflow: "hidden",
              }}
            >
              {data}
            </p>
          );
        },
        filter: true,
        sort: true,
      },
    },
    {
      name: "mrp",
      label: "MRP",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "final_url",
      label: "final_url",
      options: {
        customBodyRender: (data) => {
          return (
            <a
              href={`https://flipshope.com/price-comparison/${
                data &&
                data
                  ?.replace(/[^a-zA-Z0-9 ]/g, "-")
                  .replace(/\s+/g, "-")
                  .replace(/-+/g, "-")
                  .replace(/-$/g, "")
              }`}
              style={{
                margin: 0,
                width: 240,
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 6,
                overflow: "hidden",
              }}
            >
              {`https://flipshope.com/price-comparison/${
                data &&
                data
                  ?.replace(/[^a-zA-Z0-9 ]/g, "-")
                  .replace(/\s+/g, "-")
                  .replace(/-+/g, "-")
                  .replace(/-$/g, "")
              }`}
            </a>
          );
        },
        filter: true,
        sort: true,
      },
    },
    {
      name: "active",
      label: "Status",
      options: {
        customBodyRender: (active, tableMeta) => {
          var data = {};
          // console.log(active, "active");
          data.fs_pid = tableMeta.rowData[1];
          data.active = active ? "0" : "1";
          if (active)
            return (
              <Popconfirm
                placement="topRight"
                title="Deactivate Store"
                description="Do you really want to deactivate the store?"
                okText="Yes"
                cancelText="No"
                onConfirm={() => handleStatus(data)}
              >
                <Button color="error" size="small" variant="outlined">
                  <b>Deactivate</b>
                </Button>
              </Popconfirm>
            );
          else
            return (
              <Popconfirm
                placement="topRight"
                title="Activate Store"
                description="Do you really want to activate the store?"
                okText="Yes"
                cancelText="No"
                onConfirm={() => handleStatus(data)}
              >
                <Button color="success" size="small" variant="outlined">
                  <b>Activate</b>
                </Button>
              </Popconfirm>
            );
        },
        filter: true,
        sort: true,
      },
    },
  ];
  return (
    <div>
      <NavLink to="/products/add-product">
        <Button
          variant="contained"
          color="success"
          startIcon={<AddBoxIcon />}
          sx={{ marginBottom: "15px" }}
        >
          <strong>Add New Product</strong>
        </Button>
      </NavLink>
      {/* {loading && <LinearProgress />} */}
      <Datatable
        name="Product IDs on Multiple Stores"
        columns={columns}
        data={dataWithFinalUrl && dataWithFinalUrl}
      />
    </div>
  );
}

export default ManageProductPid;

import axios from "axios";
import AppConstants from "../../constants/AppConstants";

const API = axios.create({ baseURL: AppConstants.baseURL });


//====================================================login flow =================================================/////

export const addTodoTaskRemind = (formData) => API.post("/admin/addtaskremind", formData); //======> addtaskremind
export const updateTodoTaskRemind = (formData) => API.post("/admin/updatetaskremind", formData); //======> updatetaskremind
export const deleteTodoTaskRemind = (formData) => API.post("/admin/deletetaskremind", formData); //======> deletetaskremind
export const snooze_todoTaskByUser = (formData) => API.post("/admin/snoozetodotask", formData); //======> snoozetodotask
export const update_todoStatus = (formData) => API.post("/admin/updatetodostatus", formData); //======> updatetodostatus


export const show_todoTaskByUsers = () => API.get("/admin/showtodotask");//======> showtodotask by user
export const showRecurrenceTask = (formData) => API.post('/admin/showrecurrencetask', formData); //========> showrecurrencedata for the table
export const showTodoTaskAdmin = (formData) => API.post('/admin/showtaskbyadmin', formData); //==========> showtaskbyadmin




/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
    Table,
    TableCell,
    TableRow,
    TextField,
    tableCellClasses,
    Button,
    TableBody,
    Paper,
    Stack,
    Typography,
    Toolbar,
    Autocomplete,
    TextareaAutosize,
} from "@mui/material";
import SaveIcon from '@mui/icons-material/Save';
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import ReplyIcon from '@mui/icons-material/Reply';
import { useDispatch, useSelector } from "react-redux";
import { getAllStoresData, addStoreSchema } from "../../redux/features/storesSlice";
import { Editor } from "react-draft-wysiwyg";
import { ContentState, convertFromHTML, EditorState } from "draft-js";
import { stateToHTML } from "draft-js-export-html";

export const nodesfromHtml = (html) => {
    const blocksFromHTML = convertFromHTML(html);
    const nodes = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
    );
    return EditorState.createWithContent(nodes);
};

function AddStoreSchema() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { state } = useLocation();
    const [updated, setUpdated] = useState(false);
    const getStoreNameFromId = (id) => {
        for (let i = 0; i < allStoresData.length; i++) {
            if (allStoresData[i].store_id === id) { return allStoresData[i].store_name; }
        }
    };

    const { allStoresData } = useSelector((state) => ({ ...state.stores }));
    const [storeSchemaData, setStoreSchemaData] = useState({
        store_id: state && state.data ? state.data.store_id : '',
        store_name: state && state.data ? getStoreNameFromId(state.data.store_id) : '',
        store_schema: state && state.data ? state.data.store_schema : ''
    });

    const [descriptionType, setDescriptionType] = useState("text");

    // Editor state
    const [editorState, setEditorState] = useState(state && state.data && state.data.store_schema ? nodesfromHtml(state.data.store_schema) : EditorState.createEmpty());

    const handleChange = (value) => {
        let id = "";
        allStoresData.filter((item) => (item.store_name === value) ? id = item.store_id : id);
        setStoreSchemaData({ ...storeSchemaData, store_name: value, store_id: id });
    };

    // Description type - text
    const handleEditorChange = (editorState) => {
        setEditorState(editorState);
        var html = stateToHTML(editorState.getCurrentContent());
        setStoreSchemaData({ ...storeSchemaData, store_schema: html });
    };

    const handledetailsChange = (event) => {
        setStoreSchemaData({
            ...storeSchemaData,
            [event.target.name]: event.target.value,
        });
        const state = nodesfromHtml(event.target.value);
        setEditorState(state);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        let data = {};
        data.id = state && state.data && state.data.id;
        data.store_id = storeSchemaData.store_id;
        data.store_name = storeSchemaData.store_name;
        data.store_schema = storeSchemaData.store_schema;
        dispatch(addStoreSchema(data));
        navigate('/stores/show-store-schema');
    };

    useEffect(() => {
        dispatch(getAllStoresData());
    }, []);

    useEffect(() => {
        if (state && state.data) setUpdated(true);
    }, []);

    return (
        <Paper elevation={3}>
            <form onSubmit={handleSubmit}>
                <Stack spacing={3} direction="column">
                    <Toolbar
                        mt={3}
                        sx={{
                            padding: "1.2rem",
                            display: "flex",
                            borderBottom: "2px solid var(--primary-color)",
                        }}
                    >
                        <Typography
                            sx={{
                                color: "var(--primary-color)",
                                fontWeight: "bold",
                                flex: "1 1 100%",
                            }}
                            variant="h5"

                        >
                            Add Store Schema
                        </Typography>
                        <NavLink to="/stores/show-store-schema">
                            <Button variant="contained" color="info" sx={{ whiteSpace: 'nowrap' }} startIcon={<ReplyIcon />}>
                                <strong>Show Stores Schema</strong>
                            </Button>
                        </NavLink>
                    </Toolbar>

                    <Table
                        size="small"
                        sx={{
                            width: "auto",
                            margin: "8px !important",
                            marginTop: "16px !important",
                            [`& .${tableCellClasses.root}`]: {
                                borderBottom: "none",
                            },
                        }}
                    >
                        <TableBody>
                            <TableRow>
                                <TableCell>
                                    <Typography variant="h6">
                                        <b>Store Name:</b>
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Autocomplete
                                        freeSolo
                                        size="small"
                                        id="user-combo-box"
                                        name="store_name"
                                        value={storeSchemaData.store_name}
                                        options={allStoresData && allStoresData.map((item) => item.store_name)}
                                        sx={{ width: 300 }}
                                        renderInput={(params) => (
                                            <TextField {...params} placeholder="Select Site" />
                                        )}
                                        onChange={(e, value) => handleChange(value)}
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Typography variant="h6">
                                        <b>Store Schema Description:</b>
                                    </Typography>
                                </TableCell>
                                <TableCell sx={{ width: "60%" }}>
                                    {descriptionType === "text" ? (
                                        <div
                                            style={{
                                                border: "1px solid black",
                                                height: "241px",
                                                overflow: "auto",
                                            }}
                                        >
                                            <Editor
                                                editorState={editorState}
                                                toolbarClassName="toolbarClassName"
                                                wrapperClassName="wrapperClassName"
                                                editorClassName="editorClassName"
                                                onEditorStateChange={handleEditorChange}
                                            />
                                        </div>
                                    ) : (
                                        <TextareaAutosize
                                            minRows={10}
                                            maxRows={10}
                                            type="text"
                                            value={storeSchemaData.store_schema}
                                            name="store_schema"
                                            onChange={handledetailsChange}
                                            style={{
                                                width: "100%",
                                                padding: "8px 12px",
                                                fontSize: "0.95rem",
                                            }}
                                        />
                                    )}
                                </TableCell>
                            </TableRow>
                            <TableRow >
                                <TableCell colSpan={2} className="text-center">
                                    <Button variant="contained" type="submit" color="info" startIcon={<SaveIcon />} sx={{ marginLeft: '15px' }}>
                                        <strong>Submit</strong>
                                    </Button>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Stack>
            </form>
        </Paper>
    )
}

export default AddStoreSchema;
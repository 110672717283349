/* eslint-disable react-hooks/exhaustive-deps */
//used to upload both banners and offers
import React, { useState } from "react";
import {
    Paper,
    Stack,
    Table,
    TableCell,
    TableRow,
    TextField,
    Typography,
    tableCellClasses,
    TableBody,
    Toolbar,
    Button,
    RadioGroup,
    FormControlLabel,
    Radio,
    Dialog,
    DialogTitle,
    Divider,
    DialogContent,
    DialogActions,
    ImageList,
    ImageListItem,
} from "@mui/material";
import { NavLink, useLocation } from "react-router-dom";
import ReplyIcon from '@mui/icons-material/Reply';
import ImageSearchIcon from '@mui/icons-material/ImageSearch';
import { addManageBannersAndOffers, getManageBannersAndOffersImages } from '../../../redux/features/websiteSlices/bannerofferSlice';
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { Image } from "antd";

function UploadBanner() {

    const dispatch = useDispatch();
    const { bannerofferData, addBannerOffer } = useSelector((state) => ({ ...state.bannerOffer }));
    let { state } = useLocation();
    const [type, setType] = useState(state && state.type);
    const [url, setUrl] = useState("");
    const [live, setLive] = useState(0);
    const [priority, setPriority] = useState();
    // select image modal
    const [selectImage, setSelectImage] = useState();
    const [selectImageUrl, setSelectImageUrl] = useState("");

    // Dialog input
    const [dialogOpen, setDialogOpen] = useState(false);

    const handleSelectImage = (id, url) => {
        setSelectImage(id);
        setSelectImageUrl(url);
    }

    // handle form submit
    const handleSubmit = (event) => {
        event.preventDefault();
        let data = {};
        data.type = type;
        data.img_id = selectImage;
        data.url = url;
        data.live = live;
        data.priority = priority;
        console.log(data);
        dispatch(addManageBannersAndOffers(data));
    };

    // handle for show images 
    const handleShow = () => {
        setDialogOpen(true);
    };

    const handleClose = () => {
        setDialogOpen(false);
    };

    useEffect(() => {
        dispatch(getManageBannersAndOffersImages({ type }));
    }, [type]);

    return (
        <>
            <Paper elevation={3}>
                <Stack spacing={3} direction="column">
                    <Toolbar
                        sx={{ padding: "1.2rem", borderBottom: "2px solid var(--primary-color)" }}
                    >
                        <Typography
                            sx={{
                                flex: "1 1 100%",
                                color: "var(--primary-color)",
                                fontWeight: "bold",
                            }}
                            variant="h5"
                            id="tableTitle"
                        >
                            Add New {(type === "Banners") ? "Banners" : "Offers"}
                        </Typography>
                        <NavLink to={`/websitedata/live-${type === 'Banners' ? 'banner' : 'offers'}`}>
                            <Button variant="contained" color="info" sx={{ whiteSpace: 'nowrap', marginRight: '5px' }} startIcon={<ReplyIcon />}>
                                <strong>Show {(type === 'Banners') ? "Banners" : "Offers"}</strong>
                            </Button>
                        </NavLink>
                    </Toolbar>
                    <form onSubmit={handleSubmit}>
                        <Table
                            size="small"
                            sx={{
                                width: "100%",
                                margin: "8px !important",
                                marginTop: "16px !important",
                                [`& .${tableCellClasses.root}`]: {
                                    borderBottom: "none",
                                },
                            }}
                        >
                            <TableBody>
                                <TableRow>
                                    <TableCell>
                                        <Typography variant="h6">
                                            <b>Select Image Type</b>
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <RadioGroup
                                            name="type"
                                            aria-labelledby="type"
                                            row
                                            value={type}
                                            onChange={(e) => setType(e.target.value)}
                                        >
                                            <FormControlLabel
                                                control={<Radio />}
                                                label="Banners"
                                                value="Banners"
                                            />
                                            <FormControlLabel
                                                control={<Radio />}
                                                label="Offers"
                                                value="Offers"
                                            />
                                        </RadioGroup>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        <Typography variant="h6">
                                            <b>Redirect URL:</b>
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            size="small"
                                            fullWidth
                                            placeholder="Enter URL"
                                            onChange={(e) => setUrl(e.target.value)}
                                            error={!url.startsWith("http")}
                                            helperText={url.startsWith("http") ? null : 'Please enter valid URL'}
                                        />
                                    </TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell>
                                        <Typography variant="h6">
                                            <b>Live:</b>
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        {/* <TextField
                                        size="small"
                                        fullWidth
                                        placeholder="Enter Product's Live Data"
                                    /> */}
                                        <RadioGroup
                                            name="live"
                                            aria-labelledby="live"
                                            row
                                            value={live}
                                            onChange={(e) => setLive(e.target.value)}
                                        >
                                            <FormControlLabel
                                                control={<Radio />}
                                                label="Yes"
                                                value={1}
                                            />
                                            <FormControlLabel
                                                control={<Radio />}
                                                label="No"
                                                value={0}
                                            />
                                        </RadioGroup>
                                    </TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell>
                                        <Typography variant="h6">
                                            <b>Priority:</b>
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            size="small"
                                            fullWidth
                                            placeholder="Enter Priority Number"
                                            onChange={(e) => setPriority(e.target.value)}
                                            inputProps={{
                                                type: "number",
                                                min: "0",
                                                step: "1",
                                            }}
                                        />
                                    </TableCell>
                                </TableRow>
                                {/* <TableRow>
                                    <TableCell>
                                        <Typography variant="h6">
                                            <b>Status (Choose):</b>
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Button variant="contained" disabled={toggleActive} color="success" sx={{ marginRight: '16px' }} onClick={() => setToggleActive(prev => !prev)}>
                                            <strong>Active</strong>
                                        </Button>
                                        <Button variant="contained" disabled={!toggleActive} color="error" onClick={() => setToggleActive(prev => !prev)}>
                                            <strong>Inactive</strong>
                                        </Button>
                                    </TableCell>
                                </TableRow> */}
                                <TableRow>
                                    <TableCell>
                                        <Typography variant="h6">
                                            <b>Show Image/{type === "Banners" ? "Banner" : "Offer"}:</b>
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Button
                                            variant="outlined"
                                            component="label"
                                            color="secondary"
                                            startIcon={<ImageSearchIcon />}
                                            onClick={handleShow}
                                        >
                                            <b>Show Images</b>
                                        </Button>
                                    </TableCell>
                                </TableRow>
                                {selectImageUrl && <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell>
                                        <Image src={selectImageUrl} preview style={{ height: 150, width: 280, objectFit: "contain" }} />
                                    </TableCell>
                                </TableRow>
                                }
                                <TableRow>
                                    <TableCell colSpan="2" className="text-center" >
                                        <Button type="submit" variant="contained" size="large" sx={{ marginTop: 2, width: '160px' }}>
                                            <b>Submit</b>
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </form>
                </Stack>
            </Paper>
            <Dialog open={dialogOpen} onClose={handleClose} fullWidth>
                <DialogTitle>
                    <b>Select Image</b>
                </DialogTitle>
                <Divider sx={{ borderColor: "black" }} />
                <DialogContent>
                    <ImageList cols={3} gap={8}>
                        {bannerofferData && bannerofferData.filter((item) => item.image_type === "Banners").map((item, index) =>
                            <ImageListItem key={index.toString()}>
                                <img
                                    src={`${item.img_url}?w=248&fit=crop&auto=format`}
                                    srcSet={`${item.img_url}?w=248&fit=crop&auto=format&dpr=2 2x`}
                                    alt={item.alt_text}
                                    loading="lazy"
                                    onClick={() => handleSelectImage(item.id, item.img_url)}
                                    className={item.id === selectImage ? 'border border-5 border-primary p-1' : 'border-0'}
                                />
                            </ImageListItem>
                        )}
                    </ImageList>
                    <ImageList cols={3} gap={8}>
                        {bannerofferData && bannerofferData.filter((item) => item.image_type === "Offers").map((item, index) =>
                            <ImageListItem key={index.toString()}>
                                <img
                                    src={`${item.img_url}`}
                                    alt={item.alt_text}
                                    loading="lazy"
                                    onClick={() => handleSelectImage(item.id, item.img_url)}
                                    className={item.id === selectImage ? 'border border-5 border-primary p-1' : 'border-0'}
                                />
                            </ImageListItem>
                        )}
                    </ImageList>
                </DialogContent>
                <DialogActions>
                    <Button color="error" size="large" onClick={handleClose} ><b>Cancel</b></Button>
                    <Button variant="contained" size="large" onClick={handleClose} ><b>Save</b></Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default UploadBanner;
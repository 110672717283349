/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Table,
  TableCell,
  TableRow,
  TextField,
  tableCellClasses,
  Button,
  TableBody,
  Paper,
  Stack,
  Typography,
  Toolbar,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";

import SaveIcon from "@mui/icons-material/Save";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import ReplyIcon from "@mui/icons-material/Reply";
import { useDispatch } from "react-redux";
import {
  getAllStoresData,
  addStoresPanel,
} from "../../redux/features/storesSlice";

function AddStore() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [storeData, setStoreData] = useState({
    store_id: state ? state.data.store_id : null,
    store_name: state ? state.data.store_name : "",
    store_url: state ? state.data.store_url : "",
    site_url_aac: state ? state.data.site_url_aac : "",
    store_page_url: state ? state.data.store_page_url : "",
    aff_url: state ? state.data.aff_url : "",
    aff_active: state ? state.data.aff_active : 0,
    img_url: state ? state.data.img_url : "",
  });

  const handleChange = (e) => {
    setStoreData({ ...storeData, [e.target.name]: e.target.value });
  };
  console.log(storeData, "storeData");
  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(addStoresPanel(storeData));
    navigate("/stores/manage-stores");
  };

  useEffect(() => {
    dispatch(getAllStoresData());
  }, []);

  return (
    <Paper elevation={3}>
      <form onSubmit={handleSubmit}>
        <Stack spacing={3} direction="column">
          <Toolbar
            mt={3}
            sx={{
              padding: "1.2rem",
              display: "flex",
              borderBottom: "2px solid var(--primary-color)",
            }}
          >
            <Typography
              sx={{
                color: "var(--primary-color)",
                fontWeight: "bold",
                flex: "1 1 100%",
              }}
              variant="h5"
            >
              Add Store
            </Typography>
            <NavLink to="/stores/manage-stores">
              <Button
                variant="contained"
                color="info"
                sx={{ whiteSpace: "nowrap" }}
                startIcon={<ReplyIcon />}
              >
                <strong>Show Stores</strong>
              </Button>
            </NavLink>
            {/* <Button variant="contained" type="submit" color="info" startIcon={<SaveIcon />} sx={{ marginLeft: '15px' }}>
                            <strong>Save</strong>
                        </Button> */}
          </Toolbar>

          <Table
            size="small"
            sx={{
              width: "auto",
              margin: "8px !important",
              marginTop: "16px !important",
              [`& .${tableCellClasses.root}`]: {
                borderBottom: "none",
              },
            }}
          >
            <TableBody>
              <TableRow>
                <TableCell>
                  <Typography variant="h6">
                    <b>Store Name:</b>
                  </Typography>
                </TableCell>
                <TableCell>
                  {/* <Autocomplete
                                        freeSolo
                                        size="small"
                                        id="user-combo-box"
                                        name="store_name"
                                        value={storeData.store_name}
                                        options={allStoresData && allStoresData.map((item) => item.store_name)}
                                        sx={{ width: 300 }}
                                        renderInput={(params) => (
                                            <TextField {...params} placeholder="Select Site" />
                                        )}
                                        onChange={(e, val) => handleAutoComplete(val)}

                                    /> */}
                  <TextField
                    size="small"
                    fullWidth
                    name="store_name"
                    placeholder="Enter Site Name"
                    value={storeData.store_name}
                    onChange={handleChange}
                  />
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  <Typography variant="h6">
                    <b>Store Page Url: </b>
                  </Typography>
                </TableCell>
                <TableCell>
                  <TextField
                    size="small"
                    fullWidth
                    name="store_page_url"
                    placeholder="Enter Site URL"
                    value={storeData.store_page_url}
                    onChange={handleChange}
                  />
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  <Typography variant="h6">
                    <b>Store Url: </b>
                  </Typography>
                </TableCell>
                <TableCell>
                  <TextField
                    size="small"
                    fullWidth
                    name="store_url"
                    placeholder="Enter Store URL"
                    value={storeData.store_url}
                    onChange={handleChange}
                  />
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  <Typography variant="h6">
                    <b>Store site Url for coupons: </b>
                  </Typography>
                </TableCell>
                <TableCell>
                  <TextField
                    size="small"
                    fullWidth
                    name="site_url_aac"
                    placeholder="Enter Store coupon Site url "
                    value={storeData.site_url_aac}
                    onChange={handleChange}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography variant="h6">
                    <b>Store Affiliate Url: </b>
                  </Typography>
                </TableCell>
                <TableCell>
                  <TextField
                    size="small"
                    fullWidth
                    name="aff_url"
                    placeholder="Enter Affiliae URL"
                    value={storeData.aff_url}
                    onChange={handleChange}
                  />
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  <Typography variant="h6">
                    <b>Affiliate Active </b>
                  </Typography>
                </TableCell>
                <TableCell>
                  {/* <TextField
                    size="small"
                    fullWidth
                    name="store_url"
                    placeholder="Enter Store URL"
                    value={storeData.store_url}
                    onChange={handleChange}
                  /> */}
                  <FormControl>
                    {/* <FormLabel id="demo-controlled-radio-buttons-group">Gender</FormLabel> */}
                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="aff_active"
                      value={storeData.aff_active}
                      onChange={handleChange}
                    >
                      <FormControlLabel
                        value={0}
                        control={<Radio />}
                        label="Deactvate Aff_url"
                      />
                      <FormControlLabel
                        value={1}
                        control={<Radio />}
                        label="Activate Aff_url"
                      />
                    </RadioGroup>
                  </FormControl>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography variant="h6">
                    <b>Store Image Url: </b>
                  </Typography>
                </TableCell>
                <TableCell>
                  <TextField
                    size="small"
                    fullWidth
                    placeholder="Enter Image URL"
                    name="img_url"
                    value={storeData.img_url}
                    onChange={handleChange}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={2} className="text-center">
                  <Button
                    variant="contained"
                    type="submit"
                    color="info"
                    startIcon={<SaveIcon />}
                    sx={{ marginLeft: "15px" }}
                    onClick={handleSubmit}
                  >
                    <strong>Save</strong>
                  </Button>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Stack>
      </form>
      <div className="mt-3">
        <h3> Instruction for add store with examples</h3>
        <ul>
          <li>
            <strong>Store Name :</strong> eg.- (Flipkart,Amazon,croma,Pizza
            Hut....)
          </li>
          <li>
            <strong>Store Page Url :</strong> eg.-
            (flipkart,amazon,croma,pizzahut....)
          </li>
          <li>
            <strong> Store Url :</strong> eg.-
            (https://www.flipkart.com/,https://www.amazon.in/,https://www.croma.com/....)
          </li>
          <li>
            <strong> Store Site url for auto apply coupon :</strong> eg.-
            (flipkart.com,amazon.in,croma.com....)
          </li>
          <li>
            <strong> Store Affiliate Url :</strong> eg.-
            (https://track.omguk.com/?PID=12185&AID=2317160)
          </li>
          <li>
            <strong>Store Image Url :</strong> eg.- (https://myntra-logo.com)
          </li>
        </ul>
      </div>
    </Paper>
  );
}

export default AddStore;

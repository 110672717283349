/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Datatable from "../../Datatable/Datatable";
import { Button, ButtonGroup } from "@mui/material";
import { NavLink } from "react-router-dom";
import AddBoxIcon from "@mui/icons-material/AddBox";
import { Popconfirm } from "antd";
import {
  getStoresForAutoApplyCoupon,
  activateDeactivateAacStores,
} from "../../../redux/features/storesSlice";

function ShowAacStores() {
  const dispatch = useDispatch();
  const { storeDetailsAac } = useSelector((state) => ({ ...state.stores }));
  const handleStatus = (formData) => {
    console.log(formData, "formData--");
    dispatch(activateDeactivateAacStores(formData));
    dispatch(getStoresForAutoApplyCoupon());
  };

  const columns = [
    {
      name: "id",
      label: "Action",
      options: {
        customBodyRender: (id) => {
          let data = storeDetailsAac.filter((row) => row.id === id)[0];
          return (
            <ButtonGroup orientation="vertical" variant="text" color="success">
              <NavLink to="/stores/add-aac-store" state={{ data }}>
                <Button color="success">
                  <strong>Edit</strong>
                </Button>
              </NavLink>
            </ButtonGroup>
          );
        },
        filter: true,
        sort: true,
      },
    },
    {
      name: "site_id",
      label: "Store ID",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "site",
      label: "site",
      options: {
        filter: true,
        sort: true,
      },
    },

    {
      name: "subsite",
      label: "Subsite",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "done",
      label: "done",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "aac",
      label: "aac",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "checked",
      label: "checked",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "note",
      label: "note",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "assigned_to",
      label: "assigned_to",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "coupon_url_match",
      label: "coupon_url_match",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "pns_siteSelCartInitCode",
      label: "pns_siteSelCartInitCode",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "order_url_match",
      label: "order_url_match",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "pns_sitePreApplyCodeAction",
      label: "pns_sitePreApplyCodeAction",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "pns_siteRemoveCodeAction",
      label: "pns_siteRemoveCodeAction",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "pns_siteBreakAfterRemove",
      label: "pns_siteBreakAfterRemove",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "pns_siteTimeBetweenPreApply",
      label: "pns_siteTimeBetweenPreApply",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "pns_siteSelCartCodeBox",
      label: "pns_siteSelCartCodeBox",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "pns_siteSelCartCodeSubmit",
      label: "pns_siteSelCartCodeSubmit",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "pns_siteTimeBetweenRemove",
      label: "pns_siteTimeBetweenRemove",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "pns_siteSelCartTotalPrice",
      label: "pns_siteSelCartTotalPrice",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "pns_siteSelApplyButton",
      label: "pns_siteSelApplyButton",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "pns_currency",
      label: "pns_currency",
      options: {
        filter: true,
        sort: true,
      },
    },

    {
      name: "active",
      label: "Status",
      options: {
        customBodyRender: (active, tableMeta) => {
          var data = {};
          data.id = tableMeta.rowData[0];
          data.site_id = tableMeta.rowData[1];
          data.active = active ? "0" : "1";
          if (active)
            return (
              <Popconfirm
                placement="topRight"
                title="Deactivate Store"
                description="Do you really want to deactivate the store?"
                okText="Yes"
                cancelText="No"
                onConfirm={() => handleStatus(data)}
              >
                <Button color="error" size="small" variant="outlined">
                  <b>Deactivate</b>
                </Button>
              </Popconfirm>
            );
          else
            return (
              <Popconfirm
                placement="topRight"
                title="Activate Store"
                description="Do you really want to activate the store?"
                okText="Yes"
                cancelText="No"
                onConfirm={() => handleStatus(data)}
              >
                <Button color="success" size="small" variant="outlined">
                  <b>Activate</b>
                </Button>
              </Popconfirm>
            );
        },
        filter: true,
        sort: true,
      },
    },
    {
      name: "datetime",
      label: "datetime",
      options: {
        filter: true,
        sort: true,
      },
    },
  ];

  useEffect(() => {
    dispatch(getStoresForAutoApplyCoupon());
  }, []);
  return (
    <div>
      <NavLink to="/stores/add-aac-store">
        <Button
          variant="contained"
          color="success"
          startIcon={<AddBoxIcon />}
          sx={{ marginBottom: "15px", marginRight: "10px" }}
        >
          <strong>Add New Store</strong>
        </Button>
      </NavLink>
      <Datatable
        name="Store Data"
        columns={columns}
        data={storeDetailsAac && storeDetailsAac}
      />
    </div>
  );
}

export default ShowAacStores;

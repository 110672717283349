/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import Navbar from "../navbar/Navbar";
import Sidebar from "../sidebar/Sidebar";
import { useDispatch } from "react-redux";
import { getAllCouponsData } from "../../redux/features/couponsSlice";
import { useAuth } from "../../context/authContext";

function Layout({ children }) {
  const { isAuthenticate } = useAuth();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllCouponsData());
  }, []);

  return (
    <>
      {isAuthenticate && <Navbar />}
      {isAuthenticate && <Sidebar />}
      <div id="main">{children}</div>
    </>
  );
}

export default Layout;

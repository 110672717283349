/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
    Table,
    TableCell,
    TableRow,
    TextField,
    tableCellClasses,
    Button,
    TableBody,
    Paper,
    Stack,
    Typography,
    Toolbar,
    FormControl,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    Radio,
    Autocomplete,
} from "@mui/material";

import SaveIcon from "@mui/icons-material/Save";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import ReplyIcon from "@mui/icons-material/Reply";
import { useDispatch, useSelector } from "react-redux";
import {
    addStoreDeeplinkAllowed,
} from "../../redux/features/storesSlice";
import { getStores_graph } from "../../redux/features/productsSlice";

function DeeplinkSupportStores() {
    const dispatch = useDispatch();
    // const navigate = useNavigate();
    // store_graph
    const { state } = useLocation();
    const { store_graph } = useSelector((state) => ({ ...state.products }));
    const [storeData, setStoreData] = useState({
        store_id: state ? state.data.store_id : null,
        store_name: state ? state.data.store_name : null,
        deeplink_support: state ? state.data.deeplink_support : 1,
    });

    const handleChange = (e) => {
        setStoreData({ ...storeData, [e.target.name]: e.target.value });
    };
    console.log(storeData, "storeData")
    const handleSubmit = (event) => {
        event.preventDefault();
        dispatch(addStoreDeeplinkAllowed(storeData));
        // navigate("/stores/manage-stores");
    };
    const handleAutoComplete = (val) => {
        // console.log(val, "val==========");
        for (let i = 0; i < store_graph.length; i++) {
            if (val === store_graph[i].store_name) {
                setStoreData({ ...storeData, store_id: store_graph[i].site_id, store_name: store_graph[i].store_name })
            }
        }
    }


    useEffect(() => {
        dispatch(getStores_graph());
    }, []);

    return (
        <Paper elevation={3}>
            <form onSubmit={handleSubmit}>
                <Stack spacing={3} direction="column">
                    <Toolbar
                        mt={3}
                        sx={{
                            padding: "1.2rem",
                            display: "flex",
                            borderBottom: "2px solid var(--primary-color)",
                        }}
                    >
                        <Typography
                            sx={{
                                color: "var(--primary-color)",
                                fontWeight: "bold",
                                flex: "1 1 100%",
                            }}
                            variant="h5"
                        >
                            Add/Show Deeplink Supported Stores
                        </Typography>
                    </Toolbar>

                    <Table
                        size="small"
                        sx={{
                            width: "auto",
                            margin: "8px !important",
                            marginTop: "16px !important",
                            [`& .${tableCellClasses.root}`]: {
                                borderBottom: "none",
                            },
                        }}
                    >
                        <TableBody>
                            <TableRow>
                                <TableCell>
                                    <Typography variant="h6">
                                        <b>Store Name:</b>
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Autocomplete
                                        freeSolo
                                        size="small"
                                        id="user-combo-box"
                                        name="store_name"
                                        value={storeData.store_name}
                                        options={store_graph && store_graph.map((item) => item.store_name)}
                                        sx={{ width: 300 }}
                                        renderInput={(params) => (
                                            <TextField {...params} placeholder="Select Site" />
                                        )}
                                        onChange={(e, val) => handleAutoComplete(val)}

                                    />
                                    {/* <TextField
                                        size="small"
                                        fullWidth
                                        name="store_name"
                                        placeholder="Enter Site Name"
                                        value={storeData.store_name}
                                        onChange={handleChange}
                                    /> */}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Typography variant="h6">
                                        <b>Affiliate Active </b>
                                    </Typography>
                                </TableCell>
                                <TableCell>

                                    <FormControl>
                                        {/* <FormLabel id="demo-controlled-radio-buttons-group">Gender</FormLabel> */}
                                        <RadioGroup
                                            aria-labelledby="demo-controlled-radio-buttons-group"
                                            name="deeplink_support"
                                            value={storeData.deeplink_support}
                                            onChange={handleChange}
                                        >
                                            <FormControlLabel value={1} control={<Radio />} label="Yes" />
                                            <FormControlLabel value={0} control={<Radio />} label="No" />
                                        </RadioGroup>
                                    </FormControl>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell colSpan={2} className="text-center">
                                    <Button
                                        variant="contained"
                                        type="submit"
                                        color="info"
                                        startIcon={<SaveIcon />}
                                        sx={{ marginLeft: "15px" }}
                                        onClick={handleSubmit}
                                    >
                                        <strong>Save</strong>
                                    </Button>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Stack>
            </form>
        </Paper>
    );
}

export default DeeplinkSupportStores;

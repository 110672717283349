import React, { useEffect, useRef, useState } from 'react'
import { Button, Chip, TextField } from '@mui/material'
import Datatable from '../../Datatable/Datatable'
import { useDispatch, useSelector } from 'react-redux'
import { getWishlistDataByUser } from '../../../redux/features/websiteSlices/pricesSlice'

const wishlistColumns = [
  {
    name: 'id',
    label: 'ID',
    options: {
      filter: true,
      sort: true,
    }
  },
  {
    name: 'type',
    label: 'Type',
    options: {
      filter: true,
      sort: true,
    }
  },
  {
    name: 'fuid',
    label: 'FUID',
    options: {
      filter: true,
      sort: true,
    }
  },
  {
    name: 'store_id',
    label: 'Store ID',
    options: {
      filter: true,
      sort: true,
    }
  },
  {
    name: 'store_name',
    label: 'Store Name',
    options: {
      filter: true,
      sort: true,
    }
  },
  {
    name: 'pid',
    label: 'PID',
    options: {
      filter: true,
      sort: true,
    }
  },
  {
    name: 'title',
    label: 'Title',
    options: {
      filter: true,
      sort: true,
    }
  },
  {
    name: 'description',
    label: 'Description',
    options: {
      filter: true,
      sort: true,
    }
  },
  {
    name: 'img_url',
    label: 'Image URL',
    options: {
      customBodyRender: (link) => {
        return (
          <a href={link} target="_blank" rel="noreferrer">{link}</a>
        )
      },
      filter: true,
      sort: true,
    }
  },
  {
    name: 'start_price',
    label: 'Start Price',
    options: {
      filter: true,
      sort: true,
    }
  },
  {
    name: 'last_notified_price',
    label: 'Last Notified Price',
    options: {
      filter: true,
      sort: true,
    }
  },
  {
    name: 'min_drop',
    label: 'Min. Drop',
    options: {
      filter: true,
      sort: true,
    }
  },
  {
    name: 'product_url',
    label: 'Product URL',
    options: {
      customBodyRender: (link) => {
        return (
          <a href={link} target="_blank" rel="noreferrer">{link}</a>
        )
      },
      filter: true,
      sort: true,
    }
  },
  {
    name: 'watch_for_days',
    label: 'Watch for Days',
    options: {
      filter: true,
      sort: true,
    }
  },
  {
    name: 'start_date',
    label: 'Start Date',
    options: {
      filter: true,
      sort: true,
    }
  },
  {
    name: 'expiry_date',
    label: 'Expiry Date',
    options: {
      filter: true,
      sort: true,
    }
  },
  {
    name: 'active',
    label: 'Active',
    options: {
      customBodyRender: (row) => {
        if (row === 1) {
          return (
            <Chip label='Active' sx={{ fontWeight: 500 }} color="success" variant="outlined" />
          )
        }
        else {
          return (
            <Chip label='Inactive' color="error" variant="outlined" />
          )
        }
      },
      filter: true,
      sort: true,
    }
  },

]

function ShowWishlist() {
  const dispatch = useDispatch();
  const { wishlistData, success, error } = useSelector((state) => ({ ...state.prices }));
  const [userId, setUserId] = useState("");

  const getUserWishlist = (e) => {
    e.preventDefault();
    let fuid = userId;
    let data = {};
    data.fuid = fuid;
    dispatch(getWishlistDataByUser(data));
  }


  return (
    <>
      <form onSubmit={getUserWishlist}>
        <div className='d-flex mb-5'>
          <TextField
            size="small"
            fullWidth
            name="user_id"
            placeholder="Enter User Id"
            style={{ background: "white", width: "50%" }}
            value={userId}
            onChange={(e) => setUserId(e.target.value)}
          />
          <Button variant="contained" type="submit" color="info" sx={{ marginLeft: '15px' }}>
            <strong>Get User Wishlist</strong>
          </Button>
        </div>
      </form>
      {wishlistData && wishlistData.length > 0 &&
        <Datatable name="Wishlist Data" columns={wishlistColumns} data={wishlistData && wishlistData} />
      }
    </>
  )
}

export default ShowWishlist
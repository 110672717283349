/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import {
    Paper,
    Stack,
    Toolbar,
    Typography,
    FormControl,
    FormControlLabel,
    RadioGroup,
    Radio,
    Autocomplete,
    TextField,
    Button,
    Divider,
    Table,
    TableBody,
    TableRow,
    TableCell,
    tableCellClasses,
    Chip,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useDispatch, useSelector } from 'react-redux';
import { getAllPanelUsersData } from '../../redux/features/authSlice';

function UserRoles() {
    const dispatch = useDispatch()

    // All Users
    const { allPanelUsers } = useSelector(state => state.userAuth)

    // Dialog input
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const handleClickOpen = () => {
        setDialogOpen(true);
    };
    const handleClose = () => {
        setDialogOpen(false);
    };
    // Dialog role selection
    const [selectedDialogModule, setSelectedDialogModule] = useState([])

    // MODULES DATA
    const additionalRoles = ["content_writer", "coupon_editor", "coupon_manager", "designer", "developer", "sale_manager"]

    // role radio
    const [userType, setUserType] = useState("name")

    // Value setter
    const [selectedUser, setSelectedUser] = useState("");

    // filtered roleData
    const [filteredUser, setFilteredUser] = useState([])

    // Search handler
    const handleSearch = () => {
        setFilteredUser(allPanelUsers.filter((data) => {
            if (data.name === selectedUser)
                return true
            else if (data.fp_uid === parseInt(selectedUser))
                return true
            else
                return false
        })[0])
    }

    useEffect(() => {
        dispatch(getAllPanelUsersData())
    }, [])

    return (
        <Paper elevation={3}>
            <Stack spacing={3} direction="column">
                <Toolbar
                    mt={3}
                    sx={{ padding: "1.2rem", borderBottom: "2px solid var(--primary-color)" }}
                >
                    <Typography
                        sx={{
                            color: "var(--primary-color)",
                            fontWeight: "bold",
                        }}
                        variant="h5"
                    >
                        User Roles
                    </Typography>
                </Toolbar>
                <Stack
                    direction="column"
                    spacing={3}
                    px={4}
                    pb={5}
                    justifyContent="center"
                    alignItems="center"
                >
                    <Stack direction="row" spacing={4} alignItems="center" >
                        <Typography variant="h6">
                            <b>Search By :</b>
                        </Typography>
                        <FormControl>
                            {/* Radio Button Group to toggle between role_name and role_id*/}
                            <RadioGroup
                                name="role-type-selector"
                                row
                                value={userType}
                                onChange={(event) => { setUserType(event.target.value) }}
                            >
                                <FormControlLabel value="name" control={<Radio />} label="User Name" />
                                <FormControlLabel value="id" control={<Radio />} label="User Id" />
                            </RadioGroup>
                        </FormControl>
                    </Stack>
                    <Stack direction="row" spacing={2} alignItems="center" >

                        <Autocomplete
                            size="small"
                            freeSolo
                            id="user-name-combo-box"
                            value={selectedUser}
                            options={allPanelUsers.map(user => (
                                userType === "name" ? user.name : user.fp_uid.toString()
                            ))}
                            sx={{ width: 300 }}
                            renderInput={(params) => (
                                <TextField {...params}
                                    placeholder={userType === "name" ? "Select User Name" : "Select User Id"}
                                />
                            )}
                            onChange={(e, val) => setSelectedUser(val)}
                        />

                        <Button
                            // size="large"
                            variant="outlined"
                            sx={{
                                textTransform: 'initial',
                                borderWidth: "2px",
                                '&:hover': {
                                    borderWidth: "2px",
                                }
                            }}
                            onClick={handleSearch}
                        ><b>Search</b></Button>
                    </Stack>
                </Stack>
            </Stack>
            <Divider flexItem sx={{ borderColor: 'black', }} />
            {filteredUser?.fp_uid &&
                <Stack mt={2}>
                    <Toolbar
                        sx={{ padding: "1.2rem", borderBottom: "2px solid var(--primary-color)" }}
                    >
                        <Typography variant="h5" sx={{ flex: "1 1 100%", color: "var(--primary-color)" }} >
                            <b>Result</b>
                        </Typography>
                        <Stack direction='row' spacing={2}>
                            <Button variant="contained" color="info" startIcon={< AddIcon />}
                                onClick={handleClickOpen}
                                sx={{ whiteSpace: 'nowrap' }}>
                                <strong>Add New</strong>
                            </Button>
                            <Button variant="contained" color="error" startIcon={< DeleteIcon />} sx={{ whiteSpace: 'nowrap' }}>
                                <strong>Remove Role</strong>
                            </Button>
                        </Stack>
                    </Toolbar>
                    <Table sx={{
                        width: "auto",
                        margin: "16px !important",
                        [`& .${tableCellClasses.root}`]: {
                            borderBottom: "none",
                            fontSize: '1.2rem',
                        },
                        "& .MuiChip-label": {
                            fontSize: '1rem',
                        }
                    }}>
                        <TableBody>
                            <TableRow>
                                <TableCell><b>User ID</b></TableCell>
                                <TableCell><b>{filteredUser?.fp_uid}</b></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell><b>User Name</b></TableCell>
                                <TableCell><b>{filteredUser?.name}</b></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell><b>Primary Role</b></TableCell>
                                <TableCell><b>{filteredUser?.role}</b></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell><b>Additional Roles</b></TableCell>
                                <TableCell>
                                    <b>
                                        <Stack direction="row" spacing={2}>
                                            {filteredUser?.additional_roles?.map(item =>
                                                <Chip key={item}
                                                    label={item}
                                                    icon={<AccountCircleIcon />}
                                                />
                                            )}
                                        </Stack>
                                    </b>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                    <Dialog open={dialogOpen} onClose={handleClose} fullWidth>
                        <DialogTitle><b>Add New Additional Role</b></DialogTitle>
                        <Divider sx={{ borderColor: 'black' }} />
                        <DialogContent>
                            <Stack direction='row' spacing={2} alignItems='center' mb={3}>
                                <Typography variant="b" sx={{ whiteSpace: 'nowrap' }}>
                                    Current Roles :
                                </Typography>
                                <Stack direction="row" spacing={2}>
                                    {filteredUser?.additional_roles?.map(item =>
                                        <Chip key={item} label={item} />
                                    )}
                                </Stack>
                            </Stack>
                            <Stack direction='row' spacing={2} alignItems='center'>
                                <Typography variant="b" sx={{ whiteSpace: 'nowrap' }}>
                                    New Additional Role
                                </Typography>
                                <Autocomplete
                                    size="small"
                                    freeSolo
                                    id="role-name-combo-box"
                                    value={selectedDialogModule}
                                    options={additionalRoles?.filter(item => { return !filteredUser?.additional_roles?.includes(item) })}
                                    sx={{ width: 300 }}
                                    renderInput={(params) => (
                                        <TextField {...params}
                                            placeholder="Select from Available Roles" />
                                    )}
                                    onChange={(e, val) => setSelectedDialogModule(val)}
                                />
                            </Stack>
                        </DialogContent>
                        <Divider />
                        <DialogActions>
                            <Button variant="outlined" color="error" onClick={handleClose} ><b>Cancel</b></Button>
                            <Button variant="contained" onClick={handleClose} ><b>Add</b></Button>
                        </DialogActions>
                    </Dialog>
                </Stack>
            }
        </Paper>
    )
}

export default UserRoles
import { createSlice } from "@reduxjs/toolkit";

// Initial state with only 'fetchAgain'
const initialState = {
  fetchAgain: false, // This will be used to trigger a re-fetch of data
};

//========================= Redux Slice =======================================
const dealsAppSlice = createSlice({
  name: "dealsApp",
  initialState,
  reducers: {
    // Action to toggle the 'fetchAgain' state
    setFetchAgain: (state, action) => {
      state.fetchAgain = action.payload;
    },
  },
});

// Export the action and the reducer
export const { setFetchAgain } = dealsAppSlice.actions;
export default dealsAppSlice.reducer;

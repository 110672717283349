import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import * as storeSalesApi from "../../api/websiteAPI/storeSalesApi";

// Initial State
const initialState = {
  storeSalesData: [],
  imgData: [],
  shortLinkData: "",
  loading: false,
  all_shortLinkData: [],
  error: "",
  success: "",
};
//============================== getStoreSales ==============================//
export const getStoreSalesData = createAsyncThunk(
  "/storeSales/getStoreSalesData",
  async (_, { rejectWithValue }) => {
    try {
      const resp = await storeSalesApi.getStoreSales();
      if (resp.data.code === 200) {
        // message.success(resp.data.success);
        return resp.data.data;
      } else {
        console.log(resp.data.message);
        return rejectWithValue(resp.data.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

//============================== addStoreSales ==============================//
export const addStoreSales = createAsyncThunk(
  "/storeSales/addStoreSales",
  async (formData, { rejectWithValue }) => {
    try {
      const resp = await storeSalesApi.addStoreSales(formData);
      if (resp.data.code === 200) {
        message.success(
          resp.data.message ? resp.data.message : "Job Posted successfully"
        );
        return resp.data.data;
      } else {
        message.error(
          resp.data.message ? resp.data.message : "Something went wrong"
        );
        return rejectWithValue(resp.data.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

//============================== activeStoreSales ==============================//
export const activeStoreSales = createAsyncThunk(
  "/storeSales/activeStoreSales",
  async ({ formData, cbFunc }, { rejectWithValue }) => {
    try {
      const resp = await storeSalesApi.activeStoreSales(formData);
      if (resp.data.code === 200) {
        message.success(
          resp.data.message
            ? resp.data.message
            : "Action performed successfully"
        );
        cbFunc();
        return resp.data.data;
      } else {
        message.error(
          resp.data.message ? resp.data.message : "Something went wrong"
        );
        cbFunc();
        return rejectWithValue(resp.data.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

//============================== getStoreSalesImages ==============================//
export const getStoreSalesImageData = createAsyncThunk(
  "/storeSales/getStoreSalesImages",
  async (_, { rejectWithValue }) => {
    try {
      const resp = await storeSalesApi.getStoreSalesImage();
      if (resp.data.code === 200) {
        // message.success(resp.data.message);
        return resp.data.data;
      } else {
        // message.error(resp.data.message);
        return rejectWithValue(resp.data.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

export const getShortenUrl = createAsyncThunk(
  "/webdata/getshortenlink",
  async (formData, { rejectWithValue }) => {
    try {
      const resp = await storeSalesApi.getShortenUrl(formData);
      // console.log(resp.data);
      if (resp.data.code === 200) {
        message.success(resp.data.message);
        return resp.data.data;
      } else {
        message.error(resp.data.message);
        return rejectWithValue(resp.data.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

export const getAllShortenUrl = createAsyncThunk(
  "/webdata/getallshortlink",
  async (formData, { rejectWithValue }) => {
    try {
      const resp = await storeSalesApi.getAllShortenUrlApi(formData);
      console.log(resp.data);
      if (resp.data.code === 200) {
        message.success(resp.data.message);
        return resp.data.data;
      } else {
        message.error(resp.data.message);
        return rejectWithValue(resp.data.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

// SLice
const storeSalesSlice = createSlice({
  name: "StoreSales",
  initialState,
  extraReducers: (builder) => {
    //=================================getStoreSales Data===========================//
    builder.addCase(getStoreSalesData.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getStoreSalesData.fulfilled, (state, action) => {
      state.storeSalesData = action.payload;
      state.loading = false;
    });
    builder.addCase(getStoreSalesData.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    //=================================addStoreSales Data===========================//
    builder.addCase(addStoreSales.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(addStoreSales.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(addStoreSales.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    //=================================activeStoreSales Data===========================//
    builder.addCase(activeStoreSales.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(activeStoreSales.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(activeStoreSales.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    //=================================getStoreSalesImages Data===========================//
    builder.addCase(getStoreSalesImageData.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getStoreSalesImageData.fulfilled, (state, action) => {
      state.imgData = action.payload;
      state.loading = false;
    });
    builder.addCase(getStoreSalesImageData.rejected, (state, action) => {
      state.error = action.payload;
      state.loading = false;
    });

    //=================================getShortenUrl Data===========================//
    builder.addCase(getShortenUrl.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getShortenUrl.fulfilled, (state, action) => {
      state.loading = false;
      state.shortLinkData = action.payload;
    });
    builder.addCase(getShortenUrl.rejected, (state, action) => {
      state.error = action.payload;
      state.loading = false;
    });

    //=================================getAllShortenUrl Data===========================//
    builder.addCase(getAllShortenUrl.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getAllShortenUrl.fulfilled, (state, action) => {
      state.loading = false;
      state.all_shortLinkData = action.payload;
    });
    builder.addCase(getAllShortenUrl.rejected, (state, action) => {
      state.error = action.payload;
      state.loading = false;
      state.all_shortLinkData = [];
    });
  },
});

export default storeSalesSlice.reducer;

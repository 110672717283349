import React, { useState } from "react";
import {
  Paper,
  Stack,
  Box,
  Table,
  TableCell,
  TableRow,
  Typography,
  tableCellClasses,
  Radio,
  RadioGroup,
  FormControlLabel,
  TableBody,
  Toolbar,
  Button,
  Checkbox,
  FormGroup,
  Divider,
  TextareaAutosize
} from "@mui/material";
import UploadIcon from "@mui/icons-material/Upload";

function UpdateSaleFile() {
  // handle form submit
  const handleSubmit = (event) => {
    event.preventDefault();
    // console.log(couponData);
  };

  // form type handler
  const [formType, setFormType] = useState('all');


  return (
    <Paper elevation={3}>
      <form
        onSubmit={handleSubmit}
      >
        <Stack spacing={3} direction="column">
          <Toolbar
            sx={{ padding: "1.2rem !important", borderBottom: "2px solid var(--primary-color)" }}
          >
            <Typography
              sx={{
                color: "var(--primary-color)",
                fontWeight: "bold",
              }}
              variant="h5"
              id="tableTitle"
            >
              Upload File to Cloud
            </Typography>
          </Toolbar>
          <Table
            size="small"
            sx={{
              width: "auto",
              margin: "8px !important",
              marginTop: "16px !important",
              [`& .${tableCellClasses.root}`]: {
                borderBottom: "none",
              },
            }}
          >
            <TableBody>
              <TableRow>
                <TableCell>
                  <Typography variant="h6">
                    <b>Select Type</b>
                  </Typography>
                </TableCell>
                <TableCell>
                  <RadioGroup
                    name="type"
                    aria-labelledby="type"
                    row
                    value={formType}
                    onChange={(e) => setFormType(e.target.value)}
                  >
                    <FormControlLabel
                      control={<Radio />}
                      label="All"
                      value="all"
                    />
                    <FormControlLabel
                      control={<Radio />}
                      label="Google"
                      value="google"
                    />
                    <FormControlLabel
                      control={<Radio />}
                      label="CDN1"
                      value="cdn1"
                    />
                  </RadioGroup>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  <Typography variant="h6">
                    <b>Select File Type</b>
                  </Typography>

                </TableCell>
                <TableCell>
                  <FormGroup row>
                    <FormControlLabel control={<Checkbox />} label="Saledata.json" />
                    <FormControlLabel control={<Checkbox />} label="Amazon.json" />
                    <FormControlLabel control={<Checkbox />} label="Others" />
                  </FormGroup>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={2}>
                  <Box className="d-block mx-auto mt-2"
                    sx={{
                      width: "fit-content",
                    }}>
                    <Button size="large" variant="contained" type="submit" color="info" startIcon={<UploadIcon />}>
                      <strong>Upload</strong>
                    </Button>
                  </Box>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Stack>
      </form>
      <Stack spacing={2} mx={3} mt={2} pb={2}>
        <Divider flexItem sx={{ borderColor: "black" }} />
        <Box>
          <Typography variant="h6">
            <b>Response (JSON)</b>
          </Typography>
          <TextareaAutosize
            minRows={3}
            maxRows={6.25}
            placeholder="JSON Response will be shown here...."
            InputProps={{ readOnly: true, }}
            value={""}
            style={{ width: "100%", marginTop: '8px', padding: "8px 12px", fontSize: '1rem' }}
          />
        </Box>
        <Divider flexItem sx={{ borderColor: "black" }} />
        <Box>
          <Typography variant="subtitle1">
            <b>Public Link - </b>https://storage.googleapis.com/[BUCKET_NAME]/[OBJECT_NAME]
          </Typography>
          <Typography variant="subtitle1">
            <b>Note : </b>We can use this link only if object or the whole bucket has made public, which in our case has already made bucket public
          </Typography>

        </Box>
      </Stack>
    </Paper>
  )
}

export default UpdateSaleFile
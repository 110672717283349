import React from 'react'
import { Chart as ChartJS, Tooltip, BarElement, CategoryScale, LinearScale } from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
    Tooltip,
    CategoryScale,
    LinearScale,
    BarElement
);

const BarChart = (props) => {
    return (
        <div>
            <Bar
                data={props.data}
                height={400}
                options={{
                    maintainAspectRatio: false,
                    responsive: true,
                    scales: {
                        y: {
                            display: true
                        }
                    }
                }}
            />
        </div>
    )
}

export default BarChart;
import React from "react";
import {
    Table,
    TableCell,
    TableRow,
    TextField,
    tableCellClasses,
    Button,
    TableBody,
    Paper,
    Stack,
    Typography,
    Toolbar,
} from "@mui/material";
import SaveIcon from '@mui/icons-material/Save';
import { NavLink } from "react-router-dom";
import ReplyIcon from '@mui/icons-material/Reply';

function AddWebsiteImage() {
    // Form handler
    const handleSubmit = (event) => {
        event.preventDefault();
    };
    return (
        <Paper elevation={3}>

            <form onSubmit={handleSubmit}>
                <Stack spacing={3} direction="column">
                    <Toolbar
                        mt={3}
                        sx={{
                            padding: "1.2rem",
                            display: "flex",
                            borderBottom: "2px solid var(--primary-color)",
                        }}
                    >
                        <Typography
                            sx={{
                                color: "var(--primary-color)",
                                fontWeight: "bold",
                                flex: "1 1 100%",
                            }}
                            variant="h5"

                        >
                            Add New Image
                        </Typography>
                        <NavLink to="/websitedata/show-website-image">
                            <Button variant="contained" color="info" sx={{ whiteSpace: 'nowrap' }} startIcon={<ReplyIcon />}>
                                <strong>Show Website Image</strong>
                            </Button>
                        </NavLink>
                        <Button variant="contained" type="submit" color="info" startIcon={<SaveIcon />} sx={{ marginLeft: '15px' }}>
                            <strong>Save</strong>
                        </Button>
                    </Toolbar>

                    <Table
                        size="small"
                        sx={{
                            width: "auto",
                            margin: "8px !important",
                            marginTop: "16px !important",
                            [`& .${tableCellClasses.root}`]: {
                                borderBottom: "none",
                            },
                        }}
                    >
                        <TableBody>

                            <TableRow>
                                <TableCell>
                                    <Typography variant="h6">
                                        <b>Type ID: </b>
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        size="small"
                                        fullWidth
                                        placeholder="Enter Type ID"
                                    />
                                </TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell>
                                    <Typography variant="h6">
                                        <b>Image Type: </b>
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        size="small"
                                        fullWidth
                                        placeholder="Enter Image Type"
                                    />
                                </TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell>
                                    <Typography variant="h6">
                                        <b>Store Name: </b>
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        size="small"
                                        fullWidth
                                        placeholder="Enter Store Name"
                                    />
                                    {/* <Autocomplete
                                        freeSolo
                                        size="small"
                                        id="user-combo-box"
                                        name="store_name"
                                        value={imageData.store_name}
                                        options={allStoresData && allStoresData.map((item) => item.store_name)}
                                        sx={{ width: 300 }}
                                        renderInput={(params) => (
                                            <TextField {...params} placeholder="Select Site" />
                                        )}
                                        onChange={(e, val) => handleAutoComplete(val)}

                                    /> */}
                                </TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell>
                                    <Typography variant="h6">
                                        <b>Title: </b>
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        size="small"
                                        fullWidth
                                        placeholder="Enter Image Title"
                                    />
                                </TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell>
                                    <Typography variant="h6">
                                        <b>Alternate Text: </b>
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        size="small"
                                        fullWidth
                                        placeholder="Enter Alternate Text"
                                    />
                                </TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell>
                                    <Typography variant="h6">
                                        <b>Image URL: </b>
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        size="small"
                                        fullWidth
                                        placeholder="Enter Image URL"
                                    />
                                </TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell>
                                    <Typography variant="h6">
                                        <b>Width: </b>
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        size="small"
                                        fullWidth
                                        placeholder="Enter Image Width"
                                    />
                                </TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell>
                                    <Typography variant="h6">
                                        <b>Height: </b>
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        size="small"
                                        fullWidth
                                        placeholder="Enter Image Height"
                                    />
                                </TableCell>
                            </TableRow>

                        </TableBody>
                    </Table>
                </Stack>
            </form>
        </Paper>
    )
}

export default AddWebsiteImage
// Note add tags removed from here for any future refecence please refer the add tags component
import React, { useEffect, useState } from "react";
import {
    Table,
    TableCell,
    TableRow,
    TextField,
    tableCellClasses,
    Button,
    TableBody,
    Paper,
    Stack,
    Typography,
    Toolbar,
    Radio,
    FormControlLabel,
    FormControl,
    RadioGroup,
    Dialog,
    DialogTitle,
    Divider,
    DialogContent,
    ImageList,
    ImageListItem,
    DialogActions,
} from "@mui/material";
import SaveIcon from '@mui/icons-material/Save';
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import ReplyIcon from '@mui/icons-material/Reply';
import ImageSearchIcon from '@mui/icons-material/ImageSearch';
// import AddTags from "./AddTags";
import { useDispatch, useSelector } from "react-redux";
import { getAllCategoryImagesPanel, addStoreCategoryPanel } from '../../../redux/features/storesSlice';



const AddStoreCategory = () => {
    const dispatch = useDispatch();
    const { allcatImageData } = useSelector((state) => ({ ...state.stores }));
    // console.log(allcatImageData, "allcatImageData==============>");
    const { state } = useLocation();
    // const [image, setImage] = useState();
    const [type, setType] = useState(state && state.type ? state.type : "add_category");
    // select image modal
    const [selectImage, setSelectImage] = useState();
    const [catName, setCatName] = useState("");
    const handleSubmit = (e) => {
        var data = {};
        data.type = type;
        data.cat_name = catName;
        data.img_id = selectImage;
        e.preventDefault();
        dispatch(addStoreCategoryPanel(data));
        console.log("category addeded successfully");
    };

    // Dialog input
    const [dialogOpen, setDialogOpen] = useState(false);

    // const onImageSelect = (e) => {
    //     console.log(e.target.files && e.target.files[0])
    //     setImage(e.target.files[0]);
    //     // message.success("image added")
    // };
    const handleClose = () => {
        setDialogOpen(false);
    };

    // handle for show images 
    const handleShow = () => {
        setDialogOpen(true);
    };

    useEffect(() => {
        dispatch(getAllCategoryImagesPanel());
    }, []);


    return (
        <Paper elevation={3}>
            <Stack spacing={3} direction="column">
                <Toolbar
                    mt={3}
                    sx={{
                        padding: "1.2rem",
                        display: "flex",
                        borderBottom: "2px solid var(--primary-color)",
                    }}
                >
                    <Typography
                        sx={{
                            color: "var(--primary-color)",
                            fontWeight: "bold",
                            flex: "1 1 100%",
                        }}
                        variant="h5"
                    >
                        Add Category
                    </Typography>
                    <NavLink to="/stores/manage-store-category">
                        <Button variant="contained" color="info" sx={{ whiteSpace: 'nowrap' }} startIcon={<ReplyIcon />}>
                            <strong>Show Category</strong>
                        </Button>
                    </NavLink>
                </Toolbar>

                <Table
                    size="small"
                    sx={{
                        width: "auto",
                        margin: "8px !important",
                        marginTop: "16px !important",
                        [`& .${tableCellClasses.root}`]: {
                            borderBottom: "none",
                        },
                    }}
                >
                    <TableBody>
                        <TableRow>
                            <TableCell>
                                <Typography variant="h6">
                                    <b>Category Name:</b>
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <TextField
                                    size="small"
                                    fullWidth
                                    name="store_name"
                                    placeholder="Enter Category"
                                    value={catName}
                                    onChange={(e) => setCatName(e.target.value)}
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <Typography variant="h6">
                                    <b>Show Image </b>
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Button
                                    variant="outlined"
                                    component="label"
                                    color="secondary"
                                    startIcon={<ImageSearchIcon />}
                                    onClick={handleShow}
                                >
                                    <b>Show Images</b>
                                </Button>
                            </TableCell>
                        </TableRow>
                        <TableRow >
                            <TableCell colSpan={2} className="text-center">
                                <Button variant="contained" type="submit" color="info" startIcon={<SaveIcon />} sx={{ marginLeft: '15px' }} onClick={handleSubmit}>
                                    <strong>submit</strong>
                                </Button>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </Stack>

            <Dialog open={dialogOpen} onClose={handleClose} fullWidth>
                <DialogTitle>
                    <b>Select Image</b>
                </DialogTitle>
                <Divider sx={{ borderColor: "black" }} />
                <DialogContent>
                    <ImageList cols={3} gap={8}>
                        {allcatImageData && allcatImageData.filter((item) => item.image_type === "Category").map((item, index) =>
                            <ImageListItem key={index.toString()}>
                                <img
                                    src={`${item.img_url}?w=248&fit=crop&auto=format`}
                                    srcSet={`${item.img_url}?w=248&fit=crop&auto=format&dpr=2 2x`}
                                    alt={item.alt_text}
                                    loading="lazy"
                                    onClick={() => setSelectImage(item.id)}
                                    className={item.id === selectImage ? 'border border-5 border-primary p-1' : 'border-0'}
                                />
                            </ImageListItem>
                        )}
                    </ImageList>
                </DialogContent>
                <DialogActions>
                    <Button color="error" size="large" onClick={handleClose} ><b>Cancel</b></Button>
                    <Button variant="contained" size="large" onClick={handleClose} ><b>Save</b></Button>
                </DialogActions>
            </Dialog>
        </Paper >
    )
}
export default AddStoreCategory;
import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Button,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableRow,
  TextareaAutosize,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import ReplyIcon from "@mui/icons-material/Reply";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { useLocation, useNavigate } from "react-router";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  addJobPosting,
  getJobPostCategoryData,
} from "../../redux/features/websiteSlices/jobPostingSlice";

const companyNameData = ["Flipshope.com", "Zonet Technologies Pvt. Ltd."];
const jobTypeData = ["Full Time", "Part Time", "Internship", "Freelance"];
const workPlaceData = ["On Site", "Remote", "Hybrid"];

function AddJobPosting() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { jobCategoryData } = useSelector((state) => state.jobPost);
  // FormData
  const [formData, setFormData] = useState({
    id: state && state.jobData ? state.jobData.id : null,
    job_id: state && state.jobData ? state.jobData.job_id : null,
    job_name: state && state.jobData ? state.jobData.job_name : "",
    company_name:
      state && state.jobData ? state.jobData.company_name : "Flipshope.com",
    title: state && state.jobData ? state.jobData.title : "",
    description: state && state.jobData ? state.jobData.description : "",
    job_type: state && state.jobData ? state.jobData.job_type : "Full Time",
    experience: state && state.jobData ? state.jobData.experience : "",
    education_qualification:
      state && state.jobData ? state.jobData.education_qualification : "",
    job_responsibility:
      state && state.jobData ? state.jobData.job_responsibility : "",
    skills: state && state.jobData ? state.jobData.skills : "",
    attributes: state && state.jobData ? state.jobData.attributes : "",
    location:
      state && state.jobData ? state.jobData.location : "Jaipur, Rajasthan",
    workplace_type:
      state && state.jobData ? state.jobData.workplace_type : "On Site",
    link: state && state.jobData ? state.jobData.link : "",
    deadline_date:
      state && state.jobData ? dayjs(state.jobData.deadline_date) : dayjs(),
  });

  // handle value change
  const handleChange = (key) => (event) => {
    setFormData({ ...formData, [key]: event.target.value });
  };

  // Submit handler
  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log(formData);
    dispatch(addJobPosting(formData));
    navigate("/job-posting/manage");
    // reset formData
    // setFormData({
    //     company_name: "Flipshope",
    //     title: "",
    //     description: "",
    //     job_type: "Full Time",
    //     experience: "",
    //     location: "Jaipur, Rajasthan",
    //     workplace_type: "On Site",
    //     deadline_date: dayjs(),
    // })
  };

  useEffect(() => {
    dispatch(getJobPostCategoryData());
  }, []);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Paper elevation={3}>
        <form onSubmit={handleSubmit}>
          <Stack spacing={3} direction="column">
            <Toolbar
              mt={3}
              sx={{
                padding: "1.2rem",
                borderBottom: "2px solid var(--primary-color)",
              }}
            >
              <Typography
                sx={{
                  flex: "1 1 100%",
                  color: "var(--primary-color)",
                  fontWeight: "bold",
                }}
                variant="h5"
              >
                Add New Job Posting
              </Typography>
              <NavLink to="/job-posting/manage">
                <Button
                  variant="contained"
                  color="info"
                  sx={{ whiteSpace: "nowrap" }}
                  startIcon={<ReplyIcon />}
                >
                  <strong>Show Job Postings</strong>
                </Button>
              </NavLink>
            </Toolbar>
            <Table
              size="small"
              sx={{
                width: "auto",
                margin: "8px !important",
                marginTop: "16px !important",
                [`& .${tableCellClasses.root}`]: {
                  borderBottom: "none",
                },
              }}
            >
              <TableBody>
                <TableRow>
                  <TableCell>
                    <Typography variant="h6">
                      <b>Select Company</b>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Autocomplete
                      freeSolo
                      disableClearable
                      size="small"
                      options={companyNameData}
                      value={formData.company_name}
                      sx={{ width: 300 }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Select Company Name"
                        />
                      )}
                      onChange={(e, newVal) =>
                        setFormData({ ...formData, company_name: newVal })
                      }
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Typography variant="h6">
                      <b>Select Job Category</b>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Autocomplete
                      freeSolo
                      disableClearable
                      size="small"
                      options={
                        jobCategoryData &&
                        jobCategoryData.map((cat) => cat?.job_name)
                      }
                      value={formData.job_name}
                      sx={{ width: 300 }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Select Job Category"
                        />
                      )}
                      onChange={(event, newVal) => {
                        // Find the job object in jobCategoryData by job_name
                        const selectedJob = jobCategoryData.find(
                          (job) => job.job_name === newVal
                        );
                        // console.log(selectedJob, "selectedJob");
                        // Set the job_id in formData. If selectedJob is undefined, set job_id to null or ''
                        setFormData({
                          ...formData,
                          job_id: selectedJob ? selectedJob.id : null,
                          job_name: newVal, // Assuming you still want to update the job_name in your formData
                        });
                      }}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Typography variant="h6">
                      <b>Job Title</b>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <TextField
                      size="small"
                      fullWidth
                      placeholder="*Enter job title"
                      value={formData.title}
                      onChange={handleChange("title")}
                    />
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>
                    <Typography variant="h6">
                      <b>Job Description</b>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <TextareaAutosize
                      minRows={1}
                      maxRows={5}
                      placeholder="*Enter job description"
                      value={formData.description}
                      onChange={handleChange("description")}
                      style={{
                        width: "100%",
                        padding: "8px 12px",
                        fontSize: "1rem",
                      }}
                    />
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>
                    <Typography variant="h6">
                      <b>Select Job Type</b>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Autocomplete
                      freeSolo
                      disableClearable
                      size="small"
                      options={jobTypeData}
                      value={formData.job_type}
                      sx={{ width: 300 }}
                      renderInput={(params) => (
                        <TextField {...params} placeholder="*Select Job Type" />
                      )}
                      onChange={(e, newVal) =>
                        setFormData({ ...formData, job_type: newVal })
                      }
                    />
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>
                    <Typography variant="h6">
                      <b>Experience Required</b>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <TextField
                      size="small"
                      fullWidth
                      placeholder="*Enter experience required"
                      value={formData.experience}
                      onChange={handleChange("experience")}
                    />
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>
                    <Typography variant="h6">
                      <b>Education Qualification</b>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <TextField
                      size="small"
                      fullWidth
                      placeholder="Enter Educational Qualifications required"
                      value={formData.education_qualification}
                      onChange={handleChange("education_qualification")}
                    />
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>
                    <Typography variant="h6">
                      <b>Job Responsibilities</b>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <TextareaAutosize
                      minRows={1}
                      maxRows={5}
                      placeholder="Enter job responsibility"
                      value={formData.job_responsibility}
                      onChange={handleChange("job_responsibility")}
                      style={{
                        width: "100%",
                        padding: "8px 12px",
                        fontSize: "1rem",
                      }}
                    />
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>
                    <Typography variant="h6">
                      <b>Skills Required</b>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <TextareaAutosize
                      minRows={1}
                      maxRows={5}
                      placeholder="*Enter skills required"
                      value={formData.skills}
                      onChange={handleChange("skills")}
                      style={{
                        width: "100%",
                        padding: "8px 12px",
                        fontSize: "1rem",
                      }}
                    />
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>
                    <Typography variant="h6">
                      <b>Candidate Attributes</b>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <TextField
                      size="small"
                      fullWidth
                      placeholder="Enter candidate attributes required"
                      value={formData.attributes}
                      onChange={handleChange("attributes")}
                    />
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>
                    <Typography variant="h6">
                      <b>Work Place Type</b>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Autocomplete
                      freeSolo
                      disableClearable
                      size="small"
                      options={workPlaceData}
                      value={formData.workplace_type}
                      sx={{ width: 300 }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Select Workplace Type"
                        />
                      )}
                      onChange={(e, newVal) =>
                        setFormData({ ...formData, workplace_type: newVal })
                      }
                    />
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>
                    <Typography variant="h6">
                      <b>Location</b>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <TextField
                      size="small"
                      fullWidth
                      placeholder="*Enter job location"
                      value={formData.location}
                      onChange={handleChange("location")}
                    />
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>
                    <Typography variant="h6">
                      <b>Google Form Link</b>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <TextField
                      size="small"
                      fullWidth
                      placeholder="*Enter Google form link"
                      value={formData.link}
                      onChange={handleChange("link")}
                    />
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>
                    <Typography variant="h6">
                      <b>Deadline</b>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <DatePicker
                      value={formData.deadline_date}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          placeholder="Select deadline date for application"
                        />
                      )}
                      onChange={(val) =>
                        setFormData({ ...formData, deadline_date: val })
                      }
                      // onChange={(val) => handleDatePicker(val)}
                    />
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell colSpan="2" className="text-center">
                    <Button
                      type="submit"
                      variant="contained"
                      size="large"
                      sx={{ marginTop: 2, width: "160px" }}
                    >
                      <b>Submit</b>
                    </Button>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Stack>
        </form>
      </Paper>
    </LocalizationProvider>
  );
}

export default AddJobPosting;

import axios from "axios";
import AppConstants from "../../constants/AppConstants";

const API = axios.create({ baseURL: AppConstants.baseURL });


/* Download History */


export const getDownloadsData = (type) => API.get(`/downloads/downloadlist?type=${type}`);
export const getDownloadsDataByDate = (formData) => API.post("/downloads/downloadlistbydate", formData);


///================================================all category list===========================================/////
// export const adddealsweb = (formData) => API.post("/deals/adddealssweb", formData);


// ///================================================store wise coupons list===========================================/////
// export const updateDealsWeb = (formData) => API.get("/deals/updatedealsweb", formData);
// export const activateDeactivateDealsWeb = (formData) => API.get("/deals/activatedealsweb", formData);

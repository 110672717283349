import React from "react";
import { Box, Divider, Typography, Chip } from "@mui/material";

function Footer() {
  const year = new Date().getFullYear()
  return (
    <Box id="footer" className="text-center">
      <Divider>
        <Chip label={`\u00A9 ${year} Flipshope`} sx={{
          fontWeight: 'bold'
        }} />
      </Divider>
      <Typography variant='body2' mt={1}>Flipshope Admin Panel</Typography>
    </Box>
  );
}

export default Footer;

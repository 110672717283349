import React from "react";
import Button from "@mui/material/Button";

const CustomButton = ({
  size = "medium",
  variant = "contained",
  href = "",
  id = "",
  className = "",
  target = "_self",
  handleClick = () => {},
  value = "",
  icon: Icon = null,
}) => {
  // console.log(handleClick,"handleclick")
  return (
    <Button
      variant={variant}
      href={href}
      size={size}
      target={target}
      id={id}
      className={className}
      onClick={() => {
        handleClick();
      }}
      sx={{ display: "flex", gap: "5px" }}
    >
      {Icon && <Icon />}
      {value}
    </Button>
  );
};

export default CustomButton;

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Table,
    TableBody,
    TableCell,
    TableRow,
    Paper,
    Stack,
    Typography,
    Toolbar,
    Box,
    Card,
    MenuItem,
    Select,
    FormControl,
    Button
} from "@mui/material";
import { showRecurrenceTaskPanel, deleteTodoTaskReminder, updateTodoTaskReminder } from "../../redux/features/adminSlice";
import { useNavigate } from 'react-router';

const ShowRecurrenceTask = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { recurrenceResults } = useSelector((state) => ({ ...state.admin }));

    useEffect(() => {
        dispatch(showRecurrenceTaskPanel());
    }, []);
    function dateConverter(str) {
        // console.log(str, "str")
        var date = new Date(str),
            mnth = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2);
        let hours = ("0" + date.getHours()).slice(-2);
        let minutes = ("0" + date.getMinutes()).slice(-2);
        let seconds = ("0" + date.getSeconds()).slice(-2);
        let year = date.getFullYear();
        return `${year}-${mnth}-${day} ${hours}:${minutes}:${seconds}`
    };
    const [status, setStatus] = useState();

    const cbFunc = () => {
        showRecurrenceTaskPanel();
    };

    const handleChange = (event, val) => {
        setStatus(event.target.value);
        let data = {};
        data.id = val;
        data.status = event.target.value;
        dispatch(updateTodoTaskReminder(data, cbFunc));
    };

    const handleDelete = (value1, value2) => {
        let formData = {};
        formData.id = value1;
        formData.recurrence = value2;
        dispatch(deleteTodoTaskReminder(formData));
        dispatch(showRecurrenceTaskPanel());
    };

    const handleUpdateTask = (val) => {
        let data = {};
        data.active = val.active;
        data.assinged_by = val.assigned_by;
        data.assigned_to = JSON.parse(val.assigned_to);
        data.created_at = val.created_at;
        data.deadline_days = val.deadline_days;
        data.description = val.description;
        data.id = val.id;
        data.name = val.name;
        data.recurrence = val.recurrence;
        data.team_id = JSON.parse(val.team_id);
        data.title = val.title;
        dispatch(updateTodoTaskReminder(data));
        navigate('/todo/create-new-task', {state: data});
      };

    return (
    <Paper elevation={3} >
        <Stack spacing={3} direction="column">
        <Toolbar
          mt={3}
          sx={{ padding: "1.2rem", borderBottom: "2px solid var(--primary-color)" }}
        >
          <Typography
            sx={{
              color: "var(--primary-color)",
              fontWeight: "bold",
            }}
            variant="h5"
            id="tableTitle"
          >
            Show Recurrence Task by Admin to User
          </Typography>
        </Toolbar>
        <Box p={4} pt={1} className='d-grid' sx={{ gridTemplateColumns: 'repeat(auto-fill,minmax(300px,1fr))', gap: '24px' }}>
          {recurrenceResults && recurrenceResults.map((item) => {
            if (item.status != "done") {
              return (
                <Card sx={{ minWidth: "fit-content", border: '2px solid var(--bs-border-color)' }}>
                  <Table aria-label="assigned-task-table">
                    <TableBody>

                      <TableRow >
                        <TableCell>Assinged By:</TableCell>
                        <TableCell>
                          <Typography textTransform="capitalize">{item.name} (uid: {item.assigned_by})</Typography>
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>Task Title:</TableCell>
                        <TableCell>{item.title}</TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>Task Descriptions:</TableCell>
                        <TableCell>{item.description}</TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>Task ID:</TableCell>
                        <TableCell>{item.id}</TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>Task Recurrence Number:</TableCell>
                        <TableCell>{item.recurrence}</TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>Task Created at:</TableCell>
                        <TableCell>{item.created_at ? dateConverter(item.created_at) : 'no data found'}</TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>Task Assinged To Users List:</TableCell>
                        <TableCell>{item.assigned_to && item.assigned_to != null ? JSON.parse(item.assigned_to).toString() : "No user assinged task"}</TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>Task Assinged To Team List:</TableCell>
                        <TableCell>{item.team_id && item.team_id != null ? JSON.parse(item.team_id).toString() : "No task assinged to team"}</TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>Task Deadline Days:</TableCell>
                        <TableCell>{item.deadline_days ? item.deadline_days : ""}</TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>Active:</TableCell>
                        <TableCell>{item.active}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell style={{display: "flex", justifyContent: "space-between"}}>
                          <Button
                            variant="contained"
                            size="small"
                            color="primary"
                            onClick={() => handleUpdateTask(item)}
                          >
                            <strong>Edit</strong>
                          </Button>
                          <Button
                            variant="contained"
                            size="small"
                            color="error"
                            onClick={() => handleDelete(item.id, item.recurrence)}
                          >
                            <strong>Delete</strong>
                          </Button>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Card>
              )
            }
          })}
        </Box>
      </Stack>
    </Paper>
)
}

export default ShowRecurrenceTask
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import * as dealsApi from "../api/dealApi";

const initialState = {
  allDealsWebData: [],
  allDealsPanelData: [],
  priceGraphData: [],
  allDealsProcessData: [],
  loading: false,
  limit: 1000,
  totalDealsCount: "",
  error: "",
  success: "",
};

//======================================================= deals data using asyncthunk=====================================

export const getAllDealsWebData = createAsyncThunk(
  "deals/allDealsWeb",
  async (_, { rejectWithValue }) => {
    try {
      const resp = await dealsApi.getAllDealsWeb();
      if (resp.data.code === 200) {
        message.success(
          resp.data.message ? resp.data.message : "Fetched Website Deals"
        );
        return resp.data.data;
      } else {
        message.error(
          resp.data.message ? resp.data.message : "Something went wrong"
        );
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

export const getAllDealsPanelData = createAsyncThunk(
  "deals/allDealsPanel",
  async (_, { rejectWithValue }) => {
    try {
      const resp = await dealsApi.getAllDealsPanel();
      if (resp.data.code === 200) {
        // message.success(resp.data.message ? resp.data.message : "Fetched Live Deals")
        return resp.data;
      } else {
        message.error(
          resp.data.message ? resp.data.message : "Something went wrong"
        );
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

export const deactiveDealsData = createAsyncThunk(
  "deals/deactivedeal",
  async (formData, { rejectWithValue }) => {
    try {
      const resp = await dealsApi.deactiveDeals(formData);
      if (resp.data.code === 200) {
        message.success(
          resp.data.message ? resp.data.message : "Deactivate Live Deals"
        );
        // return resp.data;
      } else {
        message.error(
          resp.data.message ? resp.data.message : "Something went wrong"
        );
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

export const SendFirebaseNotificationDeals = createAsyncThunk(
  "deals/sendnotifificationdeals",
  async (formData, { rejectWithValue }) => {
    try {
      // console.log(formData, "formData");
      const resp = await dealsApi.sendNotificationDealsAPi(formData);
      if (resp.data.code === 200) {
        message.success(
          resp.data.message
            ? resp.data.message
            : "Notification sent successfully"
        );
        // return resp.data;
      } else {
        message.error(
          resp.data.message ? resp.data.message : "Notification sending failed"
        );
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);
// price graph
export const productPriceGraphData = createAsyncThunk(
  "deals/productPriceGraph",
  async (formData, { rejectWithValue }) => {
    try {
      const resp = await dealsApi.productPriceGraph(formData);
      if (resp.data.code === 200) {
        return resp.data.data;
      } else {
        console.log(resp.data.message);
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

//============================= Live Deals for Process =============================//
export const getAllDealsProcessData = createAsyncThunk(
  "deals/allDealsProcess",
  async (_, { rejectWithValue }) => {
    try {
      const resp = await dealsApi.getLiveDealsForProcess();
      if (resp.data.code === 200) {
        return resp.data;
      } else {
        message.error(
          resp.data.message ? resp.data.message : "Data fetching failed"
        );
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

export const updateDealsProcessData = createAsyncThunk(
  "deals/updateDealsProcess",
  async (formData, { rejectWithValue }) => {
    try {
      const resp = await dealsApi.updateLiveDealsForProcess(formData);
      if (resp.data.code === 200) {
        message.success(
          resp.data.message
            ? resp.data.message
            : "Updated Live Deals for Process"
        );
        return resp.data;
      } else {
        message.error(
          resp.data.message ? resp.data.message : "Something went wrong"
        );
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

//============================= Live Deals for Web =============================//
export const addDealsWeb = createAsyncThunk(
  "deals/addDealsWeb",
  async (formData, { rejectWithValue }) => {
    try {
      const resp = await dealsApi.adddealsweb(formData);
      if (resp.data.code === 200) {
        message.success(
          resp.data.message ? resp.data.message : "Deal added successfully"
        );
        return resp.data.message;
      } else {
        message.error(
          resp.data.message ? resp.data.message : "Something went wrong"
        );
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

export const updateDealsWeb = createAsyncThunk(
  "deals/updateDealsWeb",
  async (formData, { rejectWithValue }) => {
    try {
      const resp = await dealsApi.updateDealsWeb(formData);
      if (resp.data.code === 200) {
        message.success(resp.data.message ? resp.data.message : "Deal Updated");
        return resp.data.message;
      } else {
        message.error(
          resp.data.message ? resp.data.message : "Something went wrong"
        );
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

export const activateDeactivateDealsWeb = createAsyncThunk(
  "deals/activateDeactivateDealsWeb",
  async (formData, { rejectWithValue }) => {
    try {
      const resp = await dealsApi.activateDeactivateDealsWeb(formData);
      if (resp.data.code === 200) {
        message.success(resp.data.message ? resp.data.message : "Deal changed");
        return resp.data.message;
      } else {
        message.error(
          resp.data.message ? resp.data.message : "Something went wrong"
        );
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

const dealsSlice = createSlice({
  name: "deals",
  initialState,
  reducers: {
    setLimit: (state, action) => {
      // console.log(action.payload, "actionpaylaod")
      state.limit = action.payload;
    },
  },

  extraReducers: (builder) => {
    //=================================getAllDealsPanelData data===========================//
    builder.addCase(getAllDealsPanelData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllDealsPanelData.fulfilled, (state, action) => {
      state.loading = false;
      state.allDealsPanelData = action.payload.data;
      // state.totalDealsCount = action.payload.totalCounts;
    });
    builder.addCase(getAllDealsPanelData.rejected, (state, action) => {
      state.error = action.payload;
    });

    //=================================deactiveDealsData data===========================//
    builder.addCase(deactiveDealsData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deactiveDealsData.fulfilled, (state, action) => {
      state.loading = false;
      // state.totalDealsCount = action.payload.totalCounts;
    });
    builder.addCase(deactiveDealsData.rejected, (state, action) => {
      state.error = action.payload;
    });

    //=================================productPriceGraphData===========================//
    builder.addCase(productPriceGraphData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(productPriceGraphData.fulfilled, (state, action) => {
      state.loading = false;
      state.priceGraphData = action.payload;
    });
    builder.addCase(productPriceGraphData.rejected, (state, action) => {
      state.error = action.payload;
    });

    //=================================getAllDealsProcessData===========================//
    builder.addCase(getAllDealsProcessData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllDealsProcessData.fulfilled, (state, action) => {
      state.allDealsProcessData = action.payload.data;
      state.loading = false;
    });
    builder.addCase(getAllDealsProcessData.rejected, (state, action) => {
      state.error = action.payload;
    });

    //=================================updateDealsProcessData===========================//
    builder.addCase(updateDealsProcessData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateDealsProcessData.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(updateDealsProcessData.rejected, (state, action) => {
      state.error = action.payload;
    });

    //=================================getAllDealsWebData data===========================//
    builder.addCase(getAllDealsWebData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllDealsWebData.fulfilled, (state, action) => {
      state.loading = false;
      state.allDealsWebData = action.payload;
    });
    builder.addCase(getAllDealsWebData.rejected, (state, action) => {
      state.error = action.payload;
    });

    //=================================addDealsWeb data===========================//
    builder.addCase(addDealsWeb.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addDealsWeb.fulfilled, (state, action) => {
      state.loading = false;
      state.success = action.payload;
    });
    builder.addCase(addDealsWeb.rejected, (state, action) => {
      state.error = action.payload;
    });

    //=================================activateDeactivateDealsWeb data===========================//
    builder.addCase(activateDeactivateDealsWeb.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(activateDeactivateDealsWeb.fulfilled, (state, action) => {
      state.loading = false;
      state.success = action.payload;
    });
    builder.addCase(activateDeactivateDealsWeb.rejected, (state, action) => {
      state.error = action.payload;
    });

    //=================================updateDealsWeb data===========================//
    builder.addCase(updateDealsWeb.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateDealsWeb.fulfilled, (state, action) => {
      state.loading = false;
      state.success = action.payload;
    });
    builder.addCase(updateDealsWeb.rejected, (state, action) => {
      state.error = action.payload;
    });
  },
});
export const { setLimit } = dealsSlice.actions;
export default dealsSlice.reducer;

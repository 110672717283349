import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as productListApi from "../api/productsApi";
import { message } from "antd";
const initialState = {
  productsListData: [],
  store_graph: [],
  loading: false,
  error: "",
  success: "",
};

//================================ getProductList using asyncthunk======================
export const getProductList = createAsyncThunk(
  "products/getprodlist",
  async (formData, { rejectWithValue }) => {
    try {
      const resp = await productListApi.getProductListApi(formData);
      if (resp.data.code === 200) {
        return resp.data.data;
      } else {
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

//================================ getStores_graph using asyncthunk======================
export const getStores_graph = createAsyncThunk(
  "products/storegraphs",
  async (_, { rejectWithValue }) => {
    try {
      const resp = await productListApi.getStores_graphApi();
      if (resp.data.code === 200) {
        return resp.data.data;
      } else {
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

//================================ addProductList data=====================================

export const addProductList = createAsyncThunk(
  "products/addprodlist",
  async (formData, { rejectWithValue }) => {
    try {
      const resp = await productListApi.addProductListApi(formData);
      if (resp.data.code === 201) {
        message.success(
          resp.data.message ? resp.data.message : "store added successfully"
        );
        return resp.data.message;
      } else {
        message.error(
          resp.data.message ? resp.data.message : "store not added"
        );
        return rejectWithValue(resp.data.message);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

export const activateProductList = createAsyncThunk(
  "products/refreshhistory",
  async (formData, { rejectWithValue }) => {
    try {
      const resp = await productListApi.activateProductListApi(formData);
      if (resp.data.code === 200) {
        message.success(
          resp.data.message ? resp.data.message : "refresh updated"
        );
        return resp.data.message;
      } else {
        message.error(resp.data.message ? resp.data.message : "Not refresh");

        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

const productsSlice = createSlice({
  name: "products",
  initialState,

  extraReducers: (builder) => {
    ///==========================getProductListdata===========================//////////////////////

    builder.addCase(getProductList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getProductList.fulfilled, (state, action) => {
      // console.log(action.payload,"action payload in gat products=======")
      state.productsListData = action.payload;
      state.loading = false;
    });
    builder.addCase(getProductList.rejected, (state, action) => {
      state.error = action.payload;
    });

    ///==========================getStores_graph===========================//////////////////////

    builder.addCase(getStores_graph.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getStores_graph.fulfilled, (state, action) => {
      // console.log(action.payload,"actionPayload ====")
      state.store_graph = action.payload;
      state.loading = false;
    });
    builder.addCase(getStores_graph.rejected, (state, action) => {
      state.error = action.payload;
    });

    ///==========================addProductList===================//////////////////////

    builder.addCase(addProductList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addProductList.fulfilled, (state, action) => {
      // state.loading = false;
      state.success = action.payload;
    });
    builder.addCase(addProductList.rejected, (state, action) => {
      state.error = action.payload;
    });

    ///===================activateProductList=======================//////////////////////

    builder.addCase(activateProductList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(activateProductList.fulfilled, (state, action) => {
      // state.loading = false;
      state.refreshHisData = action.payload;
    });
    builder.addCase(activateProductList.rejected, (state, action) => {
      state.error = action.payload;
    });
  },
});
// export const { setCategories } = productsSlice.actions;
export default productsSlice.reducer;

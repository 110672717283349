// import React, { createContext, useState, useEffect, useCallback } from 'react';
// import { Outlet, useNavigate } from 'react-router-dom';
// import axios from 'axios';
// import { message } from 'antd';
// import AppConstants from '../constants/AppConstants';

// const AuthContext = createContext();

// const api = axios.create({
//     baseURL: AppConstants.baseURL,
//     withCredentials: true,
// });

// export const getUser = async () => {
//     try {
//         const response = await api.get('/authpanel/profile');
//         if (response.data.code === 200) {
//             return { status: 'SIGNED_IN', user: response.data.data };
//         } else {
//             return { status: 'SIGNED_OUT', user: null };
//         }
//     } catch (error) {
//         return { status: 'SIGNED_OUT', user: null };
//     }
// };

// export const AuthProvider = ({ myAuth, children }, props) => {
//     const auth = myAuth || { status: 'SIGNED_OUT', user: null };
//     const [users, setUsers] = useState(null);

//     const login = useCallback(async (formData) => {
//         try {
//             const resp = await api.post('/authpanel/login', formData);
//             if (resp.data.code === 200) {
//                 const { data } = await api.get('/authpanel/profile');
//                 if (data.code === 200) {
//                     message.success(resp.data.message || 'Logged In successfully');
//                     setUsers(data.data);
//                 } else {
//                     message.error(resp.data.message || 'Something Went Wrong');
//                 }
//             } else {
//                 message.error(resp.data.message || 'Something Went Wrong');
//             }
//         } catch (error) {
//             message.error(error.message);
//         }
//     }, []);

//     const signupUser = useCallback(async (signupData) => {
//         try {
//             const resp = await api.post('/authpanel/signup', signupData);
//             if (resp.data.code === 200) {
//                 message.success(resp.data.message || 'Registration successful');
//             } else {
//                 message.error(resp.data.message || 'Something Went Wrong');
//             }
//         } catch (error) {
//             message.error(error.message);
//         }
//     }, []);

//     const logout = useCallback(async () => {
//         const history = window.location.href.split('/').slice(3).join('/');
//         localStorage.setItem('history', history);
//         try {
//             const resp = await api.post('/authpanel/logout');
//             if (resp.data.code === 200) {
//                 message.success(resp.data.message || 'Logout successful');
//                 setUsers(null);
//             } else {
//                 message.error(resp.data.message || 'Error logging out');
//             }
//         } catch (error) {
//             message.error(error.message || 'Something Went Wrong');
//         }
//     });

//     // useEffect(() => {
//     //     async function getUserData() {
//     //         const userData = await getUser();
//     //         if (userData.status === 'SIGNED_IN') {
//     //             setUsers(userData.user);
//     //         }
//     //     }
//     //     getUserData();
//     // }, []);

//     return (
//         <AuthContext.Provider value={{
//             isAuthenticate: !!users || !!auth.user,
//             userData: users || auth.user,
//             logout,
//             login,
//             signupUser
//         }} {...children} />
//     );
// };

// export const useAuth = () => React.useContext(AuthContext);
// export const AuthConsumer = AuthContext.Consumer;


/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useState, useEffect, useCallback } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { message } from 'antd';
import * as authApi from '../redux/api/authApi';
import AppConstants from '../constants/AppConstants';

const AuthContext = createContext();


export const getUser = async () => {
    try {
        const response = await axios.get(`${AppConstants.baseURL}/authpanel/profile`, { withCredentials: true });
        if (response.data.code === 200) {
            return { status: 'SIGNED_IN', user: response.data.data };
        } else {
            return { status: 'SIGNED_OUT', user: null };
        }
    } catch (error) {
        return { status: 'SIGNED_OUT', user: null };
    }
};

export const AuthProvider = (props) => {
    const { myAuth = { status: 'SIGNED_OUT', user: null } } = props;
    const [users, setUsers] = useState(false);

    const login = useCallback(async (formData) => {
        try {
            const resp = await axios({
                method: 'post',
                url: `${AppConstants.baseURL}/authpanel/login`,
                data: formData,
                withCredentials: true,
            });
            if (resp.data.code === 200) {
                const { data } = await authApi.getUserProfile();
                if (data.code === 200) {
                    message.success(resp.data.message || 'Logged In successfully');
                    setUsers(data.data);
                } else {
                    message.error(resp.data.message || 'Something Went Wrong');
                }
            } else {
                message.error(resp.data.message || 'Something Went Wrong');
            }
        } catch (error) {
            message.error(error.message);
        }
    }, []);

    const signupUser = useCallback(async (signupData) => {
        try {
            const resp = await axios({
                method: 'post',
                url: `${AppConstants.baseURL}/authpanel/signup`,
                data: signupData,
                withCredentials: true,
            });
            if (resp.data.code === 200) {
                message.success(resp.data.message || 'Registration successful');
            } else {
                message.error(resp.data.message || 'Something Went Wrong');
            }
        } catch (error) {
            message.error(error.message);
        }
    }, []);
    
    const logout = useCallback(async () => {
        const history = window.location.href.split('/').slice(3).join('/');
        localStorage.setItem('history', history);
        try {
            const resp = await axios.post(`${AppConstants.baseURL}/authpanel/logout`, { withCredentials: true });
            if (resp.data.code === 200) {
                message.success(resp.data.message || 'Logout successful');
                setUsers(false);
            } else {
                message.error(resp.data.message || 'Error logging out');
            }
        } catch (error) {
            message.error(error.message || 'Something Went Wrong');
        }
    });

    // useEffect(() => {
    //     const fetchUser = async () => {
    //         const { user } = await getUser();
    //         setUsers(user);
    //     };
    //     fetchUser();
    // }, []);

    const isAuthenticate = !!users || !!myAuth.user;
    const userData = users || myAuth.user;
    const authContextValue = { isAuthenticate, userData, logout, login, signupUser };

    return <AuthContext.Provider value={authContextValue} {...props} />;
};

export const useAuth = () => React.useContext(AuthContext);
export const AuthConsumer = AuthContext.Consumer;


export const PrivateRoutes = () => {
    const { isAuthenticate } = useAuth();
    const navigate = useNavigate();
    useEffect(() => {
        if (!isAuthenticate || isAuthenticate === false) {
            navigate('/login', { replace: true, });
        }
    }, [isAuthenticate, navigate]);

    return (
        <Outlet />
    );
};


export const LoginRoutes = () => {
    const { isAuthenticate } = useAuth();
    const navigate = useNavigate();

    const history = localStorage.getItem('history')

    useEffect(() => {
        if (isAuthenticate) {
            if (history || history === null || history === undefined) navigate(`/`, { replace: true, });
            else navigate(`/${history}`, { replace: true, })
        }
    }, [isAuthenticate, navigate]);

    return (
        <Outlet />
    )
}



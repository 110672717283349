/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Datatable from "../Datatable/Datatable";
import { Button, ButtonGroup } from "@mui/material";
import { NavLink } from "react-router-dom";
import AddBoxIcon from "@mui/icons-material/AddBox";
import { Popconfirm } from "antd";
import {
  getAllStoresData,
  activateDeactivateStoresPanel,
} from "../../redux/features/storesSlice";

function ShowStores() {
  const dispatch = useDispatch();
  const { allStoresData } = useSelector((state) => ({ ...state.stores }));
  const handleStatus = (formData) => {
    dispatch(activateDeactivateStoresPanel(formData));
    dispatch(getAllStoresData());
  };

  const columns = [
    {
      name: "store_id",
      label: "Action",
      options: {
        customBodyRender: (id) => {
          let data = allStoresData.filter((row) => row.store_id === id)[0];
          return (
            <ButtonGroup orientation="vertical" variant="text" color="success">
              <NavLink to="/stores/add-store" state={{ data }}>
                <Button color="success">
                  <strong>Edit</strong>
                </Button>
              </NavLink>
              {/* <Popconfirm
                                placement="right"
                                title="Delete Store"
                                description="Do you really want to delete the store?"
                                okText="Yes"
                                cancelText="No"
                                onConfirm={() => console.log(id)}
                            >
                                <Button color="error">
                                    <strong>Delete</strong>
                                </Button>
                            </Popconfirm> */}
            </ButtonGroup>
          );
        },
        filter: true,
        sort: true,
      },
    },
    {
      name: "store_name",
      label: "Store Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "store_id",
      label: "Store ID",
      options: {
        filter: true,
        sort: true,
      },
    },
    // {
    //     name: "store_page_url",
    //     label: "Store Page URL",
    //     options: {
    //         customBodyRender: (link) => {
    //             return (
    //                 <a href={link} target="_blank" rel="noreferrer">{link}</a>
    //             )
    //         },
    //         filter: true,
    //         sort: true,
    //     }
    // },
    {
      name: "store_url",
      label: "Store URL",
      options: {
        customBodyRender: (link) => {
          return (
            <a href={link} target="_blank" rel="noreferrer">
              {link}
            </a>
          );
        },
        filter: true,
        sort: true,
      },
    },
    {
      name: "site_url_aac",
      label: "Site Coupon Url",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "img_url",
      label: "Image",
      options: {
        customBodyRender: (link) => {
          return (
            // <a href={link} target="_blank" rel="noreferrer">{link}</a>
            <img
              src={link}
              alt="store_image"
              style={{ width: "100px", height: "auto" }}
            />
          );
        },
        filter: true,
        sort: true,
      },
    },
    {
      name: "aff_url",
      label: "Affiliate URL",
      options: {
        customBodyRender: (link) => {
          return (
            <a href={link} target="_blank" rel="noreferrer">
              {link}
            </a>
          );
        },
        filter: true,
        sort: true,
      },
    },
    {
      name: "aff_active",
      label: "Affiliate Status",
      options: {
        customBodyRender: (aff_active, tableMeta) => {
          var data = {};
          data.store_id = tableMeta.rowData[2];
          data.aff_active = aff_active ? "0" : "1";
          if (aff_active)
            return (
              <Popconfirm
                placement="topRight"
                title="Deactivate Affiliate Url"
                description="Do you really want to deactivate the Affiliate Url?"
                okText="Yes"
                cancelText="No"
                onConfirm={() => handleStatus(data)}
              >
                <Button color="error" size="small" variant="outlined">
                  <b>Deactivate Aff_url</b>
                </Button>
              </Popconfirm>
            );
          else
            return (
              <Popconfirm
                placement="topRight"
                title="Activate Affiliate Url"
                description="Do you really want to activate the Affiliate Url?"
                okText="Yes"
                cancelText="No"
                onConfirm={() => handleStatus(data)}
              >
                <Button color="success" size="small" variant="outlined">
                  <b>Activate Aff_url</b>
                </Button>
              </Popconfirm>
            );
        },
        filter: true,
        sort: true,
      },
    },
    {
      name: "active",
      label: "Status",
      options: {
        customBodyRender: (active, tableMeta) => {
          var data = {};
          data.store_id = tableMeta.rowData[2];
          data.active = active ? "0" : "1";
          if (active)
            return (
              <Popconfirm
                placement="topRight"
                title="Deactivate Store"
                description="Do you really want to deactivate the store?"
                okText="Yes"
                cancelText="No"
                onConfirm={() => handleStatus(data)}
              >
                <Button color="error" size="small" variant="outlined">
                  <b>Deactivate</b>
                </Button>
              </Popconfirm>
            );
          else
            return (
              <Popconfirm
                placement="topRight"
                title="Activate Store"
                description="Do you really want to activate the store?"
                okText="Yes"
                cancelText="No"
                onConfirm={() => handleStatus(data)}
              >
                <Button color="success" size="small" variant="outlined">
                  <b>Activate</b>
                </Button>
              </Popconfirm>
            );
        },
        filter: true,
        sort: true,
      },
    },
    {
      name: "created",
      label: "Created",
      options: {
        filter: true,
        sort: true,
      },
    },
  ];

  useEffect(() => {
    dispatch(getAllStoresData());
  }, []);
  return (
    <div>
      <NavLink to="/stores/add-store">
        <Button
          variant="contained"
          color="success"
          startIcon={<AddBoxIcon />}
          sx={{ marginBottom: "15px", marginRight: "10px" }}
        >
          <strong>Add New Store</strong>
        </Button>
      </NavLink>
      <Datatable
        name="Store Data"
        columns={columns}
        data={allStoresData && allStoresData}
      />
    </div>
  );
}

export default ShowStores;

import axios from "axios";
import AppConstants from "../../constants/AppConstants";

const API = axios.create({ baseURL: AppConstants.baseURL });

///==============================deals for you==================================/////
export const getAllDealsPanel = () => API.post("/deals/livedeals");
export const deactiveDeals = (formData) =>
  API.post("/deals/deactivedeal", formData);
export const sendNotificationDealsAPi = (formData) =>
  API.post("/deals/sendnotifificationdeals", formData);

export const getAllDealsWeb = () => API.get("/deals/getdealsweb");

///==================================deals for process====================================/////
export const getLiveDealsForProcess = () =>
  API.post("/deals/livedealsprocessed");
export const updateLiveDealsForProcess = (formData) =>
  API.post("/deals/updatelivedealsprocessed", formData);

///===============================price graph==================================/////
export const productPriceGraph = (formData) =>
  API.post("/deals/productpricegraph", formData);

///=================================all category list==================================/////
export const adddealsweb = (formData) =>
  API.post("/deals/adddealsweb", formData);

///=======================================store wise coupons list===========================================/////
export const updateDealsWeb = (formData) =>
  API.post("/deals/updatedealsweb", formData);
export const activateDeactivateDealsWeb = (formData) =>
  API.post("/deals/activatedealsweb", formData);

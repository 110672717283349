import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as storesApi from "../api/storesApi";
import { message } from "antd";
const initialState = {
  //store data
  allStoresData: [],
  allCategoryData: [],
  catArrData: [],
  addtrendingStoreData: [],
  trendingStoreData: [],
  //store category data
  allcatData: [],
  allcatImageData: [],
  allStoreCatData: [],
  addStoreCategoryData: [],
  storeCategories: [],
  //store description data
  storeDescriptionData: [],
  //store schema data
  getStoreSchemaData: [],
  storeDetailsAac: [],
  //common data
  loading: false,
  error: "",
  success: "",
};

//======================================================= get deals data using asyncthunk=====================================

export const getAllStoresData = createAsyncThunk(
  "stores/allstore",
  async (_, { rejectWithValue }) => {
    try {
      const resp = await storesApi.getAllStores();
      if (resp.data.code === 200) {
        return resp.data.data;
      } else {
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

//======================================================= get categoryList data=====================================

// export const getCategoryList = createAsyncThunk(
//     "stores/getcategorylist",
//     async (_, { rejectWithValue }) => {
//         try {
//             const resp = await storesApi.categoryList();
//             if (resp.data.code === 200) {
//                 return resp.data.data;
//             } else {
//                 console.log(resp.data.message)
//                 return rejectWithValue(resp.data.message);
//             }
//         } catch (error) {
//             rejectWithValue(error.resp.data);
//         }
//     }
// );

//======================================================= addStoresPanel data=====================================

export const addStoresPanel = createAsyncThunk(
  "stores/addstoresweb",
  async (formData, { rejectWithValue }) => {
    try {
      const resp = await storesApi.addStores(formData);
      if (resp.data.code === 200) {
        message.success(
          resp.data.message ? resp.data.message : "store added successfully"
        );
        return resp.data.message;
      } else {
        message.error(
          resp.data.message ? resp.data.message : "store not added"
        );
        return rejectWithValue(resp.data.message);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

export const activateDeactivateStoresPanel = createAsyncThunk(
  "stores/activatestoresweb",
  async (formData, { rejectWithValue }) => {
    try {
      console.log(formData, "formData===========>");
      const resp = await storesApi.activateDeactivateStores(formData);
      if (resp.data.code === 200) {
        message.success(
          resp.data.message ? resp.data.message : "store updated"
        );
        return resp.data.message;
      } else {
        message.error(
          resp.data.message ? resp.data.message : "store not updated"
        );
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

export const addTrendingStoresPanel = createAsyncThunk(
  "stores/addtrendstoress",
  async (formData, { rejectWithValue }) => {
    try {
      const resp = await storesApi.addTrendingStores(formData);
      if (resp.data.code === 200) {
        message.success(
          resp.data.message ? resp.data.message : "trending store updated"
        );
        return resp.data.message;
      } else {
        message.error(
          resp.data.message ? resp.data.message : "trending store updated"
        );

        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

export const getTrendingStoresPanel = createAsyncThunk(
  "stores/getalltrendstores",
  async (_, { rejectWithValue }) => {
    try {
      const resp = await storesApi.getTrendingStores();
      if (resp.data.code === 200) {
        // message.success(resp.data.message ? resp.data.message : "trending store rendered")
        return resp.data.data;
      } else {
        // message.error(resp.data.message ? resp.data.message : "trending store rendered")

        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

export const activateDeactivateTrendingStoresPanel = createAsyncThunk(
  "stores/activatetrendstores",
  async (formData, { rejectWithValue }) => {
    try {
      const resp = await storesApi.activateDeactivateTrendingStores(formData);
      if (resp.data.code === 200) {
        message.success(
          Number(formData.active)
            ? "Activated Successfully"
            : "Deactivated Successfully"
        );
        return resp.data.data;
      } else {
        message.error(resp.data.message && resp.data.message);
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

// ==========================================store Categoty Data======================================

export const getAllCategoryPanel = createAsyncThunk(
  "stores/getallcat",
  async (_, { rejectWithValue }) => {
    try {
      const resp = await storesApi.getAllCategory();
      if (resp.data.code === 200) {
        // message.success(resp.data.message ? resp.data.message : "Store Category Rendered");
        return resp.data.data;
      } else {
        // message.error(resp.data.message ? resp.data.message : "Something went wrong!");
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

export const activateDeactivateCategoryPanel = createAsyncThunk(
  "stores/activecat",
  async (formData, { rejectWithValue }) => {
    try {
      console.log(formData);
      const resp = await storesApi.activateDeactivateCategory(formData);
      if (resp.data.code === 200) {
        message.success(resp.data.message && resp.data.message);
        return resp.data.message;
      } else {
        message.error(
          resp.data.message ? resp.data.message : "Something went wrong"
        );
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

export const getAllCategoryImagesPanel = createAsyncThunk(
  "stores/getallcatimage",
  async (_, { rejectWithValue }) => {
    try {
      const resp = await storesApi.getAllCategoryImages();
      if (resp.data.code === 200) {
        // message.success(resp.data.message ? resp.data.message : "Store Category Images Rendered");
        return resp.data.data;
      } else {
        // message.error(resp.data.message ? resp.data.message : "Something went wrong!");
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

export const getStoreCategoryDataPanel = createAsyncThunk(
  "stores/getstorecat",
  async (_, { rejectWithValue }) => {
    try {
      const resp = await storesApi.getStoreCategoryData();
      if (resp.data.code === 200) {
        // message.success(resp.data.message ? resp.data.message : "All Store Category Data Rendered");
        return resp.data.data;
      } else {
        // message.error(resp.data.message ? resp.data.message : "Something went wrong!");
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

export const addStoreCategoryPanel = createAsyncThunk(
  "stores/addstorecat",
  async (formData, { rejectWithValue }) => {
    try {
      const resp = await storesApi.addStoreCategory(formData);
      if (resp.data.code === 200) {
        message.success(
          resp.data.success
            ? resp.data.success
            : "Store category added successfully "
        );
        return resp.data.data;
      } else {
        message.error(
          resp.data.message ? resp.data.message : "Something went wrong!"
        );
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

export const activateDeactivateStoreCategoryPanel = createAsyncThunk(
  "stores/activestorecat",
  async (formData, { rejectWithValue }) => {
    try {
      const resp = await storesApi.activateDeactivateStoreCategory(formData);
      if (resp.data.code === 200) {
        message.success(
          resp.data.success
            ? resp.data.success
            : "Store Category Activate/Deactivate successfully "
        );
        return resp.data.data;
      } else {
        message.error(
          resp.data.message ? resp.data.message : "Something went wrong!"
        );
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

//============================Store Description============================//
export const getStoreDescriptionData = createAsyncThunk(
  "stores/getstoredesc",
  async (_, { rejectWithValue }) => {
    try {
      const resp = await storesApi.getStoreDescription();
      if (resp.data.code === 200) {
        // message.success(resp.data.message ? resp.data.message : "All Store Category Data Rendered");
        return resp.data.data;
      } else {
        // message.error(resp.data.message ? resp.data.message : "Something went wrong!");
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

export const addStoreDescriptionData = createAsyncThunk(
  "stores/addstoredesc",
  async (formData, { rejectWithValue }) => {
    try {
      const resp = await storesApi.addStoreDescription(formData);
      if (resp.data.code === 200) {
        message.success(
          resp.data.success
            ? resp.data.success
            : "Store description added successfully "
        );
        return resp.data.data;
      } else {
        message.error(
          resp.data.message ? resp.data.message : "Something went wrong!"
        );
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

export const activateStoreDescriptionData = createAsyncThunk(
  "stores/activestoredesc",
  async ({ formData, cbFunc }, { rejectWithValue }) => {
    try {
      const resp = await storesApi.activateStoreDescription(formData);
      if (resp.data.code === 200) {
        message.success(
          resp.data.success
            ? resp.data.success
            : "Store Description Activate/Deactivate successfully "
        );
        cbFunc();
        return resp.data.data;
      } else {
        message.error(
          resp.data.message ? resp.data.message : "Something went wrong!"
        );
        cbFunc();
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

export const addStoreSchema = createAsyncThunk(
  "stores/addstoreschema",
  async (formData, { rejectWithValue }) => {
    try {
      const resp = await storesApi.addStoreSchemaApi(formData);
      if (resp.data.code === 200) {
        message.success(
          resp.data.success ? resp.data.success : "Added successfully!"
        );
        // return resp.data.data;
      } else {
        message.error(
          resp.data.message
            ? resp.data.message
            : "Not Added / Something went wrong!"
        );
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

export const activateStoreSchema = createAsyncThunk(
  "stores/activestoreschema",
  async (formData, { rejectWithValue }) => {
    try {
      const resp = await storesApi.activateStoreSchemaApi(formData);
      if (resp.data.code === 200) {
        message.success(
          resp.data.success
            ? resp.data.success
            : "Activate/Deactivate successfully!"
        );
        // return resp.data.data;
      } else {
        message.error(
          resp.data.message ? resp.data.message : "Something went wrong!"
        );
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

//===========================================activateStoreSchema==============================

export const addStoreDeeplinkAllowed = createAsyncThunk(
  "stores/addstoredeepallow",
  async (formData, { rejectWithValue }) => {
    try {
      const resp = await storesApi.addStoreDeeplinkAllowedApi(formData);
      if (resp.data.code === 200) {
        message.success(
          resp.data.success ? resp.data.success : "Deeplink Allowed!"
        );
        // return resp.data.data;
      } else {
        message.error(
          resp.data.message ? resp.data.message : "Something went wrong!"
        );
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);
export const getStoreSchema = createAsyncThunk(
  "stores/getstoreschema",
  async (_, { rejectWithValue }) => {
    try {
      const resp = await storesApi.getStoreSchemaApi();
      if (resp.data.code === 200) {
        return resp.data.data;
      } else {
        message.error(
          resp.data.message ? resp.data.message : "Something went wrong!"
        );
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

//============================== addStoresForAutoApplyCoupon ==============================//
export const addStoresForAutoApplyCoupon = createAsyncThunk(
  "/storeSales/addaacstoredetails",
  async (formData, { rejectWithValue }) => {
    try {
      const resp = await storesApi.addStoresForAutoApplyCouponApi(formData);
      if (resp.data.code === 200) {
        message.success(
          resp.data.message ? resp.data.message : "Job Posted successfully"
        );
        return resp.data.data;
      } else {
        message.error(
          resp.data.message ? resp.data.message : "Something went wrong"
        );
        return rejectWithValue(resp.data.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

//============================== activateDeactivateAacStores ==============================//
export const activateDeactivateAacStores = createAsyncThunk(
  "/storeSales/activateaacstoredetails",
  async ({ formData, cbFunc }, { rejectWithValue }) => {
    try {
      const resp = await storesApi.activateDeactivateAacStoresApi(formData);
      if (resp.data.code === 200) {
        message.success(
          resp.data.message
            ? resp.data.message
            : "Action performed successfully"
        );
        cbFunc();
        return resp.data.data;
      } else {
        message.error(
          resp.data.message ? resp.data.message : "Something went wrong"
        );
        cbFunc();
        return rejectWithValue(resp.data.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

//============================== getStoresForAutoApplyCoupon ==============================//
export const getStoresForAutoApplyCoupon = createAsyncThunk(
  "/storeSales/getaacstoredetails",
  async (_, { rejectWithValue }) => {
    try {
      const resp = await storesApi.getStoresForAutoApplyCouponApi();
      if (resp.data.code === 200) {
        // message.success(resp.data.message);
        return resp.data.data;
      } else {
        // message.error(resp.data.message);
        return rejectWithValue(resp.data.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

const storesSlice = createSlice({
  name: "stores",
  initialState,
  reducers: {
    setStoreCategories: (state, action) => {
      state.storeCategories = action.payload;
    },
  },

  extraReducers: (builder) => {
    ///=================================getAllStoresData data===========================//////////////////////

    builder.addCase(getAllStoresData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllStoresData.fulfilled, (state, action) => {
      // state.loading = false;
      state.allStoresData = action.payload;
    });
    builder.addCase(getAllStoresData.rejected, (state, action) => {
      state.error = action.payload;
    });

    // ===================================activateDeactivateStoresPanel===========
    builder.addCase(activateDeactivateStoresPanel.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      activateDeactivateStoresPanel.fulfilled,
      (state, action) => {
        state.loading = false;
      }
    );
    builder.addCase(activateDeactivateStoresPanel.rejected, (state, action) => {
      state.error = action.payload;
    });

    ///=======================addStoresPanel data===========================//////////////////

    builder.addCase(addStoresPanel.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addStoresPanel.fulfilled, (state, action) => {
      // state.loading = false;
      state.success = action.payload;
    });
    builder.addCase(addStoresPanel.rejected, (state, action) => {
      state.error = action.payload;
    });

    ///=================================addStoreDeeplinkAllowed data===========================//////////////////////

    builder.addCase(addStoreDeeplinkAllowed.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addStoreDeeplinkAllowed.fulfilled, (state, action) => {
      state.loading = false;
      state.success = action.payload;
    });
    builder.addCase(addStoreDeeplinkAllowed.rejected, (state, action) => {
      state.error = action.payload;
    });

    ///=================================getCategoryList data===========================//////////////////////

    // builder.addCase(getCategoryList.pending, (state) => {
    //     state.loading = true;
    // });
    // builder.addCase(getCategoryList.fulfilled, (state, action) => {
    //     state.loading = false;
    //     state.allCategoryData = action.payload;
    // });
    // builder.addCase(getCategoryList.rejected, (state, action) => {
    //     state.error = action.payload;
    // });

    // ==================================addtrendingstore==================================
    builder.addCase(addTrendingStoresPanel.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addTrendingStoresPanel.fulfilled, (state, action) => {
      state.loading = false;
      state.addtrendingStoreData = action.payload;
    });
    builder.addCase(addTrendingStoresPanel.rejected, (state, action) => {
      state.error = action.payload;
    });

    // ==================================gettrendingstore==================================
    builder.addCase(getTrendingStoresPanel.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getTrendingStoresPanel.fulfilled, (state, action) => {
      state.loading = false;
      state.trendingStoreData = action.payload;
    });
    builder.addCase(getTrendingStoresPanel.rejected, (state, action) => {
      state.error = action.payload;
    });

    // ==============================activatetrendndingstore================
    builder.addCase(activateDeactivateTrendingStoresPanel.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      activateDeactivateTrendingStoresPanel.fulfilled,
      (state, action) => {
        state.loading = false;
      }
    );
    builder.addCase(
      activateDeactivateTrendingStoresPanel.rejected,
      (state, action) => {
        state.error = action.payload;
      }
    );

    // =================================STORE CATEGORY DATA===========================

    // ================================getAllCategoryPanel=============================
    builder.addCase(getAllCategoryPanel.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllCategoryPanel.fulfilled, (state, action) => {
      state.loading = false;
      state.allcatData = action.payload;
    });
    builder.addCase(getAllCategoryPanel.rejected, (state, action) => {
      state.error = action.payload;
    });

    // ================================activateDeactivateCategoryPanel=============================
    builder.addCase(activateDeactivateCategoryPanel.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      activateDeactivateCategoryPanel.fulfilled,
      (state, action) => {
        state.loading = false;
        state.allcatData = action.payload;
      }
    );
    builder.addCase(
      activateDeactivateCategoryPanel.rejected,
      (state, action) => {
        state.error = action.payload;
      }
    );

    // ================================getAllCategoryImagesPanel=============================
    builder.addCase(getAllCategoryImagesPanel.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllCategoryImagesPanel.fulfilled, (state, action) => {
      state.loading = false;
      state.allcatImageData = action.payload;
    });
    builder.addCase(getAllCategoryImagesPanel.rejected, (state, action) => {
      state.error = action.payload;
    });

    // ================================getStoreCategoryDataPanel=============================
    builder.addCase(getStoreCategoryDataPanel.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getStoreCategoryDataPanel.fulfilled, (state, action) => {
      state.loading = false;
      state.allStoreCatData = action.payload;
    });
    builder.addCase(getStoreCategoryDataPanel.rejected, (state, action) => {
      state.error = action.payload;
    });

    // ================================addStoreCategoryPanel=============================
    builder.addCase(addStoreCategoryPanel.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addStoreCategoryPanel.fulfilled, (state, action) => {
      state.loading = false;
      state.addStoreCategoryData = action.payload;
    });
    builder.addCase(addStoreCategoryPanel.rejected, (state, action) => {
      state.error = action.payload;
    });

    // ================================activateDeactivateStoreCategoryPanel================
    builder.addCase(activateDeactivateStoreCategoryPanel.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      activateDeactivateStoreCategoryPanel.fulfilled,
      (state, action) => {
        state.loading = false;
      }
    );
    builder.addCase(
      activateDeactivateStoreCategoryPanel.rejected,
      (state, action) => {
        state.error = action.payload;
      }
    );

    // ================================getStoreDescriptionDataPanel=============================
    builder.addCase(getStoreDescriptionData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getStoreDescriptionData.fulfilled, (state, action) => {
      state.storeDescriptionData = action.payload;
      state.loading = false;
    });
    builder.addCase(getStoreDescriptionData.rejected, (state, action) => {
      state.error = action.payload;
    });
    // ================================addStoreDescriptionData=============================
    builder.addCase(addStoreDescriptionData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addStoreDescriptionData.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(addStoreDescriptionData.rejected, (state, action) => {
      state.error = action.payload;
    });
    // ================================activateStoreDescriptionDataPanel=============================
    builder.addCase(activateStoreDescriptionData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(activateStoreDescriptionData.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(activateStoreDescriptionData.rejected, (state, action) => {
      state.error = action.payload;
    });

    // ================================addStoreSchema=============================
    builder.addCase(addStoreSchema.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addStoreSchema.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(addStoreSchema.rejected, (state, action) => {
      state.error = action.payload;
    });

    // ================================activateStoreSchema=============================
    builder.addCase(activateStoreSchema.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(activateStoreSchema.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(activateStoreSchema.rejected, (state, action) => {
      state.error = action.payload;
    });

    // ================================getStoreSchema=============================
    builder.addCase(getStoreSchema.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getStoreSchema.fulfilled, (state, action) => {
      state.getStoreSchemaData = action.payload;
      state.loading = false;
    });
    builder.addCase(getStoreSchema.rejected, (state, action) => {
      state.error = action.payload;
    });

    // ================================addStoresForAutoApplyCoupon=============================
    builder.addCase(addStoresForAutoApplyCoupon.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addStoresForAutoApplyCoupon.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(addStoresForAutoApplyCoupon.rejected, (state, action) => {
      state.error = action.payload;
    });

    // ================================activateDeactivateAacStores=============================
    builder.addCase(activateDeactivateAacStores.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(activateDeactivateAacStores.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(activateDeactivateAacStores.rejected, (state, action) => {
      state.error = action.payload;
    });

    // ================================getStoresForAutoApplyCoupon=============================
    builder.addCase(getStoresForAutoApplyCoupon.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getStoresForAutoApplyCoupon.fulfilled, (state, action) => {
      state.storeDetailsAac = action.payload;
      state.loading = false;
    });
    builder.addCase(getStoresForAutoApplyCoupon.rejected, (state, action) => {
      state.error = action.payload;
    });
  },
});
export const { setStoreCategories } = storesSlice.actions;
export default storesSlice.reducer;

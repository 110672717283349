/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Paper,
  Stack,
  Table,
  TableCell,
  TableRow,
  TextField,
  Typography,
  tableCellClasses,
  TableBody,
  Toolbar,
  Button,
  TextareaAutosize,
  Autocomplete,
  Dialog,
  DialogTitle,
  DialogContent,
  Divider,
  ImageList,
  ImageListItem,
} from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import ReplyIcon from "@mui/icons-material/Reply";
import ImageSearchIcon from "@mui/icons-material/ImageSearch";
import dayjs from "dayjs";
import { getAllStoresData } from "../../../redux/features/storesSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  addStoreSales,
  getStoreSalesImageData,
} from "../../../redux/features/websiteSlices/storeSalesSlice";
import { Image } from "antd";

function AddSales() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { state } = useLocation();
  const { allStoresData } = useSelector((state) => state.stores);
  const { imgData } = useSelector((state) => state.storeSales);

  const [dialogOpen, setDialogOpen] = useState(false);

  // handle for show images
  const handleShow = () => {
    setDialogOpen(true);
  };
  const handleClose = () => {
    setDialogOpen(false);
  };

  // storeName from storeId
  const handleStoreNameFromId = (id) => {
    for (let i = 0; i < allStoresData.length; i++) {
      if (id === allStoresData[i].store_id) return allStoresData[i].store_name;
    }
  };

  // imageUrl from imageId
  const handleImageUrlFromId = (id) => {
    for (let i = 0; i < imgData.length; i++) {
      if (id === imgData[i].id) return imgData[i].img_url;
    }
  };

  // FormData to submit
  const [formData, setFormData] = useState({
    id: state && state.formData ? state.formData.id : null,
    store_id: state && state.formData ? state.formData.store_id : "",
    store_name:
      state && state.formData
        ? handleStoreNameFromId(state.formData.store_id)
        : "",
    description: state && state.formData ? state.formData.description : "",
    url: state && state.formData ? state.formData.url : "",
    img_id: state && state.formData ? state.formData.img_id : "",
    img_url:
      state && state.formData
        ? handleImageUrlFromId(state.formData.img_id)
        : "",
    start_date: state && state.formData ? state.formData.start_date : dayjs(),
    end_date: state && state.formData ? state.formData.end_date : dayjs(),
  });

  // handle change
  const handleChange = (key) => (event) => {
    setFormData({ ...formData, [key]: event.target.value });
  };

  // Store selector
  const handleAutoComplete = (name) => {
    if (allStoresData.length > 0) {
      for (var i = 0; i < allStoresData.length; i++) {
        if (name === allStoresData[i].store_name) {
          setFormData({
            ...formData,
            store_name: allStoresData[i].store_name,
            store_id: allStoresData[i].store_id,
          });
        }
      }
    }
  };

  // handle form submit
  const handleSubmit = async (event) => {
    event.preventDefault();
    // console.table(formData)
    await dispatch(addStoreSales(formData));
    navigate("/websitedata/show-sales");
  };

  useEffect(() => {
    dispatch(getAllStoresData());
    dispatch(getStoreSalesImageData());
  }, []);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Paper elevation={3}>
        <form onSubmit={handleSubmit}>
          <Stack spacing={3} direction="column">
            <Toolbar
              mt={3}
              sx={{
                padding: "1.2rem",
                borderBottom: "2px solid var(--primary-color)",
              }}
            >
              <Typography
                sx={{
                  flex: "1 1 100%",
                  color: "var(--primary-color)",
                  fontWeight: "bold",
                }}
                variant="h5"
                id="tableTitle"
              >
                Add New Sale
              </Typography>
              <NavLink to="/websitedata/show-sales">
                <Button
                  variant="contained"
                  color="info"
                  sx={{ whiteSpace: "nowrap" }}
                  startIcon={<ReplyIcon />}
                >
                  <strong>Show Sales</strong>
                </Button>
              </NavLink>
            </Toolbar>
            <Table
              size="small"
              sx={{
                width: "auto",
                margin: "8px !important",
                marginTop: "16px !important",
                [`& .${tableCellClasses.root}`]: {
                  borderBottom: "none",
                },
              }}
            >
              <TableBody>
                <TableRow>
                  <TableCell>
                    <Typography variant="h6">
                      <b>Store Name</b>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    {/* <TextField
                                            size="small"
                                            fullWidth
                                            placeholder="Select Store Name"
                                        /> */}
                    <Autocomplete
                      freeSolo
                      size="small"
                      id="user-combo-box"
                      name="store_name"
                      value={formData.store_name}
                      options={
                        allStoresData &&
                        allStoresData.map((item) => item.store_name)
                      }
                      sx={{ width: 300 }}
                      renderInput={(params) => (
                        <TextField {...params} placeholder="Select Site" />
                      )}
                      onChange={(e, val) => handleAutoComplete(val)}
                    />
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>
                    <Typography variant="h6">
                      <b>Description</b>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <TextareaAutosize
                      minRows={1}
                      maxRows={4}
                      placeholder="Enter Description"
                      style={{
                        width: "100%",
                        padding: "8px 12px",
                        fontSize: "1rem",
                      }}
                      value={formData.description}
                      onChange={handleChange("description")}
                    />
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>
                    <Typography variant="h6">
                      <b>URL</b>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <TextField
                      size="small"
                      fullWidth
                      placeholder="Enter URL"
                      value={formData.url}
                      onChange={handleChange("url")}
                    />
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>
                    <Typography variant="h6">
                      <b>Select Image</b>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="outlined"
                      component="label"
                      color="secondary"
                      startIcon={<ImageSearchIcon />}
                      onClick={handleShow}
                    >
                      <b>Show Images</b>
                    </Button>
                  </TableCell>
                </TableRow>

                {formData.img_url && (
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell>
                      <Image
                        src={formData.img_url}
                        preview
                        style={{
                          height: 156,
                          width: 200,
                          objectFit: "contain",
                        }}
                      />
                    </TableCell>
                  </TableRow>
                )}

                <TableRow>
                  <TableCell>
                    <Typography variant="h6">
                      <b>Start Date</b>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <DateTimePicker
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          placeholder="Select Start Date"
                        />
                      )}
                      value={formData.start_date}
                      onChange={(val) =>
                        setFormData({ ...formData, start_date: val })
                      }
                    />
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>
                    <Typography variant="h6">
                      <b>End Date</b>
                    </Typography>
                  </TableCell>
                  <TableCell className="d-flex">
                    <DateTimePicker
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          placeholder="Select End Date"
                        />
                      )}
                      value={formData.end_date}
                      onChange={(val) =>
                        setFormData({ ...formData, end_date: val })
                      }
                    />
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell colSpan="2" className="text-center">
                    <Button
                      type="submit"
                      variant="contained"
                      size="large"
                      sx={{ marginTop: 2, width: "160px" }}
                    >
                      <b>Submit</b>
                    </Button>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Stack>
        </form>
      </Paper>
      <Dialog
        open={dialogOpen}
        onClose={handleClose}
        fullWidth
        maxWidth=""
        sx={{
          "& .MuiDialog-paper": {
            width: "70%",
            height: "80%",
          },
        }}
      >
        <DialogTitle>
          <b>Select Image</b>
        </DialogTitle>
        <Divider sx={{ borderColor: "black" }} />
        <DialogContent>
          <ImageList cols={4} gap={16}>
            {imgData &&
              imgData?.map((img, index) => (
                <ImageListItem
                  key={index.toString()}
                  sx={{
                    "& .MuiImageListItem-img": {
                      objectFit: "contain !important",
                    },
                  }}
                >
                  <img
                    src={img.img_url}
                    alt={img.alt_text}
                    loading="lazy"
                    onClick={() => {
                      setFormData({
                        ...formData,
                        img_id: img.id,
                        img_url: handleImageUrlFromId(img.id),
                      });
                      handleClose();
                    }}
                    className={
                      img.id === formData.img_id
                        ? "border border-5 border-primary p-1"
                        : "border-0"
                    }
                  />
                </ImageListItem>
              ))}
          </ImageList>
        </DialogContent>
      </Dialog>
    </LocalizationProvider>
  );
}

export default AddSales;

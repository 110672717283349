import {
  Autocomplete,
  Button,
  FormControlLabel,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  TextareaAutosize,
  Toolbar,
  Typography,
  tableCellClasses,
} from "@mui/material";
import { ContentState, convertFromHTML, EditorState } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import {
  addStoreDescriptionData,
  getAllStoresData,
} from "../../redux/features/storesSlice";
import { NavLink } from "react-router-dom";
import ReplyIcon from "@mui/icons-material/Reply";

export const nodesfromHtml = (html) => {
  const blocksFromHTML = convertFromHTML(html);
  const nodes = ContentState.createFromBlockArray(
    blocksFromHTML.contentBlocks,
    blocksFromHTML.entityMap
  );
  return EditorState.createWithContent(nodes);
};

function AddStoreDescription() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  // Editor state
  const [editorState, setEditorState] = useState(
    state && state.data && state.data.description
      ? nodesfromHtml(state.data.description)
      : EditorState.createEmpty()
  );

  // redux data
  const { allStoresData } = useSelector((state) => ({ ...state.stores }));

  // description type
  const [descriptionType, setDescriptionType] = useState("html");

  const [storeDescriptionData, setStoreDescriptionData] = useState({
    store_id: state && state.data && state.data.store_id ? state.data.store_id : "",
    store_name: state && state.data && state.data.store_name ? state.data.store_name : "",
    seo_title: state && state.data && state.data.seo_title ? state.data.seo_title : "",
    seo_description: state && state.data && state.data.seo_description ? state.data.seo_description : "",
    h1_title: state && state.data && state.data.h1_title ? state.data.h1_title : "",
    related_desc: state && state.data && state.data.related_desc ? state.data.related_desc : "", //related description
    description: state && state.data && state.data.description ? state.data.description : " ",
  });

  // Store handler
  const handleStore = (val) => {
    for (let i = 0; i < allStoresData.length; i++) {
      if (allStoresData[i].store_name === val)
        setStoreDescriptionData({
          ...storeDescriptionData,
          store_id: allStoresData[i].store_id,
          store_name: allStoresData[i].store_name,
        });
    }
  };

  // Description type - html
  const handledetailsChange = (event) => {
    setStoreDescriptionData({
      ...storeDescriptionData,
      [event.target.name]: event.target.value,
    });
    // const state = nodesfromHtml(event.target.value);
    // setEditorState(state);
  };

  // Description type - text
  const handleEditorChange = (editorState) => {
    setEditorState(editorState);
    var html = stateToHTML(editorState.getCurrentContent());
    setStoreDescriptionData({ ...storeDescriptionData, description: html });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(addStoreDescriptionData(storeDescriptionData));
    navigate("/stores/manage-stores-description");
  };

  useEffect(() => {
    dispatch(getAllStoresData());
  }, []);

  return (
    <Paper elevation={3} sx={{ minWidth: "fit-content" }}>
      <form onSubmit={handleSubmit}>
        <Stack spacing={3} direction="column">
          <Toolbar
            mt={3}
            sx={{
              padding: "1.2rem",
              borderBottom: "2px solid var(--primary-color)",
            }}
          >
            <Typography
              sx={{
                flex: "1 1 100%",
                color: "var(--primary-color)",
                fontWeight: "bold",
              }}
              variant="h5"
              id="tableTitle"
            >
              Add Store Description
            </Typography>
            <NavLink to="/stores/manage-stores-description">
              <Button
                variant="contained"
                color="info"
                sx={{ whiteSpace: "nowrap" }}
                startIcon={<ReplyIcon />}
              >
                <strong>Show Store Description</strong>
              </Button>
            </NavLink>
          </Toolbar>
          <Table
            size="small"
            sx={{
              width: "auto",
              margin: "8px !important",
              marginTop: "16px !important",
              [`& .${tableCellClasses.root}`]: {
                borderBottom: "none",
              },
            }}
          >
            <TableBody>
              <TableRow>
                <TableCell>
                  <Typography variant="h6">
                    <b>Select Store</b>
                  </Typography>
                </TableCell>
                <TableCell>
                  <Autocomplete
                    // disablePortal
                    freeSolo
                    disableClearable
                    size="small"
                    options={
                      allStoresData
                        ? allStoresData.map((item) => item.store_name)
                        : []
                    }
                    value={storeDescriptionData.store_name}
                    sx={{ width: 300 }}
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Select Store Name" />
                    )}
                    onChange={(e, val) => handleStore(val)}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography variant="h6">
                    <b>SEO Title: </b>
                  </Typography>
                </TableCell>
                <TableCell>
                  <TextField
                    size="small"
                    fullWidth
                    name="seo_title"
                    placeholder="Enter Seo Title"
                    value={storeDescriptionData.seo_title}
                    onChange={handledetailsChange}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography variant="h6">
                    <b>SEO Desctiption: </b>
                  </Typography>
                </TableCell>
                <TableCell>
                  <TextField
                    size="small"
                    fullWidth
                    name="seo_description"
                    placeholder="Enter Seo Description"
                    value={storeDescriptionData.seo_description}
                    onChange={handledetailsChange}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography variant="h6">
                    <b>H1 Title: </b>
                  </Typography>
                </TableCell>
                <TableCell>
                  <TextField
                    size="small"
                    fullWidth
                    name="h1_title"
                    placeholder="Enter H1 Title"
                    value={storeDescriptionData.h1_title}
                    onChange={handledetailsChange}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography variant="h6">
                    <b>Related Description: </b>
                  </Typography>
                </TableCell>
                <TableCell>
                  <TextareaAutosize
                    minRows={1}
                    maxRows={4}
                    name="related_desc"
                    placeholder="Enter Related Description"
                    value={storeDescriptionData.related_desc}
                    onChange={handledetailsChange}
                    style={{ width: "100%", padding: "8px 12px", fontSize: '1rem' }}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography variant="h6">
                    <b>Select Description Type</b>
                  </Typography>
                </TableCell>
                <TableCell>
                  <RadioGroup
                    name="descriptionType"
                    aria-labelledby="description-type"
                    row
                    value={descriptionType}
                    onChange={(e) => setDescriptionType(e.target.value)}
                  >
                    <FormControlLabel
                      control={<Radio />}
                      label="Text"
                      value="text"
                    />
                    <FormControlLabel
                      control={<Radio />}
                      label="HTML"
                      value="html"
                    />
                  </RadioGroup>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography variant="h6">
                    <b> Store Description</b>
                  </Typography>
                </TableCell>
                <TableCell sx={{ width: "60%" }}>
                  {descriptionType === "text" ? (
                    <div
                      style={{
                        border: "1px solid black",
                        height: "241px",
                        overflow: "auto",
                      }}
                    >
                      <Editor
                        editorState={editorState}
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="wrapperClassName"
                        editorClassName="editorClassName"
                        onEditorStateChange={handleEditorChange}
                      />
                    </div>
                  ) : (
                    <TextareaAutosize
                      minRows={10}
                      maxRows={10}
                      type="text"
                      value={storeDescriptionData.description}
                      name="description"
                      onChange={handledetailsChange}
                      style={{
                        width: "100%",
                        padding: "8px 12px",
                        fontSize: "0.95rem",
                      }}
                    />
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan="2" className="text-center">
                  <Button
                    type="submit"
                    variant="contained"
                    size="large"
                    sx={{ marginTop: 5, width: "160px" }}
                  >
                    <b>Submit</b>
                  </Button>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Stack>
      </form>
    </Paper>
  );
}

export default AddStoreDescription;

import axios from "axios";
import AppConstants from "../../../constants/AppConstants";

const API = axios.create({ baseURL: AppConstants.baseURL });

export const getStoreSales = () => API.post("/webdata/getstoresales");
export const addStoreSales = (formData) =>
  API.post("/webdata/addstoresales", formData);
export const activeStoreSales = (formData) =>
  API.post("/webdata/activestoresales", formData);
export const getStoreSalesImage = () =>
  API.post("/webdata//getstoresalesimage");

export const getShortenUrl = (formData) =>
  API.post("/webdata/getshortenlink", formData);

export const getAllShortenUrlApi = (formData) =>
  API.post("/webdata/getallshortlink", formData);

import axios from "axios";
import AppConstants from "../../constants/AppConstants";

const API = axios.create({ baseURL: AppConstants.baseURL });


//====================================================signup flow =================================================/////

export const signup = (formData) => API.post("/authpanel/signup", formData); //======> signup



//====================================================login flow =================================================/////

export const loginWithPassword = (formData) => API.post("/authpanel/login", formData); //======> login with password

//=====================================userProfile process===============================//

export const getUserProfile = () => API.get("/authpanel/profile");


//====================================================forgot passowrd flow =================================================/////
export const changePassword = (formData) => API.post("/authpanel/changepassword", formData);

// module permisiion======================================================================================================

export const modulePermissions = (formData) => API.post("/authpanel/addmodulepermission", formData);

export const modulesByUserData = () => API.get("/authpanel/modulesbyuser",);

export const addRolesModules = (formData) => API.post("/authpanel/addroles", formData);

export const showRoles = (formData) => API.get("/authpanel/showroles", formData);

export const showuserTypeModulesData = (formData) => API.get("/authpanel/showmoduleusertype", formData);

export const getAllPanelUsers = (formData) => API.get("/authpanel/allpanelusers", formData);

export const userManagementRolesData = (formData) => API.post("/authpanel/userallpermission", formData);

//================================== Add New Role ==================================//
export const addNewRole = (formData) => API.post("/authpanel/addroles", formData);
export const activeRole = (formData) => API.post("/authpanel/deleterole", formData);


export const updatePanelUser = (formData) => API.post("/authpanel/updatepanelusers", formData);

export const addNewModules = (formData) => API.post("/authpanel/newmodules", formData);

export const showAllModules = () => API.get("/authpanel/showallmodules");

export const activateModules = (formData) => API.post("/authpanel/activemodules", formData);


import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as downloadsApi from "../api/downloadsApi";

const initialState = {
    downloadListData: [],
    status_data: [],
    chart_data: [],
    table_data: [],
    loading: false,
    error: "",
    success: "",
};

//======================================================= deals data using asyncthunk=====================================

export const getDownloadListData = createAsyncThunk(
    "downloads/downloadlist",
    async (type, { rejectWithValue }) => {
        try {
            console.log(`DownloadSlice - ${type}`);
            const resp = await downloadsApi.getDownloadsData(type);
            if (resp.data) {
                // console.log(resp.data.data[0], "data from download res")
                return resp.data.data[0];
            } else {
                console.log(resp.data.message)
                return rejectWithValue(resp.data);
            }
        } catch (error) {
            rejectWithValue(error.resp.data);
        }
    }
);



export const downloadsListByDate = createAsyncThunk(
    "deals/addDealsPanel",
    async (formData, { rejectWithValue }) => {
        try {
            const resp = await downloadsApi.getDownloadsDataByDate(formData);
            if (resp.data) {
                console.log(resp.data)
                return resp.data.data[0];
            } else {
                return rejectWithValue(resp.data);
            }
        } catch (error) {
            rejectWithValue(error.resp.data);
        }
    }
);

// export const updateDealsPanel = createAsyncThunk(
//     "deals/updateDealsPanel",
//     async (formData, { rejectWithValue }) => {
//         try {
//             const resp = await dealsApi.updateDealsWeb(formData);
//             if (resp.data.code == 200) {
//                 return resp.data.message;
//             } else {
//                 return rejectWithValue(resp.data);
//             }
//         } catch (error) {
//             rejectWithValue(error.resp.data);
//         }
//     }
// );

// export const activateDeactivateDealsPanel = createAsyncThunk(
//     "deals/activateDeactivateStores",
//     async (formData, { rejectWithValue }) => {
//         try {
//             const resp = await dealsApi.activateDeactivateDealsWeb(formData);
//             if (resp.data.code == 200) {
//                 return resp.data.message;
//             } else {
//                 console.log(resp.data.message)
//                 return rejectWithValue(resp.data);
//             }
//         } catch (error) {
//             rejectWithValue(error.resp.data);
//         }
//     }
// );


const downloadsSlice = createSlice({
    name: "prices",
    initialState,
    // reducers: {
    //     setCategories: (state, action) => {
    //         state.catArrData = action.payload
    //     }
    // },

    extraReducers: (builder) => {
        ///=================================getDownloadListData data===========================//////////////////////

        builder.addCase(getDownloadListData.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getDownloadListData.fulfilled, (state, action) => {
            // console.log(action.payload, "action")
            state.loading = false;
            state.status_data = action.payload.statusData;
            state.chart_data = action.payload.chart_data;
            state.table_data = action.payload.table_data
        });
        builder.addCase(getDownloadListData.rejected, (state, action) => {
            state.error = action.payload;
        });


        ///=================================DownloadsListByDate data===========================//////////////////////

        builder.addCase(downloadsListByDate.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(downloadsListByDate.fulfilled, (state, action) => {
            // console.log(action.payload, "action")
            state.loading = false;
            state.status_data = action.payload.statusData;
            state.chart_data = action.payload.chart_data;
        });
        builder.addCase(downloadsListByDate.rejected, (state, action) => {
            state.error = action.payload;
        });


        ///=================================activateDeactivateDealsPanel data===========================//////////////////////

        // builder.addCase(activateDeactivateDealsPanel.pending, (state) => {
        //     state.loading = true;
        // });
        // builder.addCase(activateDeactivateDealsPanel.fulfilled, (state, action) => {
        //     state.loading = false;
        //     state.success = action.payload;
        // });
        // builder.addCase(activateDeactivateDealsPanel.rejected, (state, action) => {
        //     state.error = action.payload;
        // });

        ///=================================updateDealsPanel data===========================//////////////////////

        // builder.addCase(updateDealsPanel.pending, (state) => {
        //     state.loading = true;
        // });
        // builder.addCase(updateDealsPanel.fulfilled, (state, action) => {
        //     state.loading = false;
        //     state.success = action.payload;
        // });
        // builder.addCase(updateDealsPanel.rejected, (state, action) => {
        //     state.error = action.payload;
        // });
    },
});
// export const { setCategories } = storesSlice.actions;
export default downloadsSlice.reducer;
import React, { useEffect, useState } from "react";
import Datatable from "../../Datatable/Datatable";
import { Button, LinearProgress, Box } from "@mui/material";
import { NavLink } from "react-router-dom";
import AddBoxIcon from "@mui/icons-material/AddBox";
import { useDispatch, useSelector } from "react-redux";
import { getAllShortenUrl } from "../../../redux/features/websiteSlices/storeSalesSlice";
import { DateRangePicker } from "react-date-range";
import { addDays } from "date-fns";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css";
// Removed the import for DemoContainer
// Consider creating a dateConverter or import it if it's missing

function ShowShortLink() {
  const dispatch = useDispatch();
  const { loading, all_shortLinkData } = useSelector(
    (state) => state.storeSales
  );

  // datatable columns
  const columns = [
    // {
    //   name: "id",
    //   label: "Action",
    //   options: {
    //     customBodyRender: (id, tableMeta) => {
    //       const dealData = {
    //         id: id,
    //         store_id: tableMeta.rowData[1],
    //         store_name: tableMeta.rowData[2],
    //         pid: tableMeta.rowData[3],
    //         deal_url: tableMeta.rowData[4],
    //         title: tableMeta.rowData[5],
    //         description: tableMeta.rowData[6],
    //         cashback_upto: tableMeta.rowData[7],
    //         img_url: tableMeta.rowData[8],
    //         actual_price: tableMeta.rowData[9],
    //         disc_price: tableMeta.rowData[10],
    //         start_date: tableMeta.rowData[12],
    //         end_date: tableMeta.rowData[13],
    //       };
    //       return (
    //         <NavLink to="/websitedata/add-deal" state={{ dealData }}>
    //           <Button size="small" color="success" variant="outlined">
    //             <strong>Edit</strong>
    //           </Button>
    //         </NavLink>
    //       );
    //     },
    //     filter: true,
    //     sort: true,
    //   },
    // },
    {
      name: "code",
      label: "Code",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "url",
      label: "URL",
      options: {
        customBodyRender: (link) => (
          <a href={link} target="_blank" rel="noreferrer">
            {link}
          </a>
        ),
        filter: true,
        sort: true,
      },
    },
    {
      name: "date_created",
      label: "Created Date",
      options: {
        customBodyRender: (date) => (
          // Make sure to define or import dateConverter
          <p className="mb-0">{date}</p> // Temporarily removed dateConverter for demonstration
        ),
        filter: true,
        sort: true,
      },
    },
  ];

  const [dateRange, setDateRange] = useState([
    {
      startDate: addDays(new Date(), -8),
      endDate: addDays(new Date(), -1),
      key: "selection",
    },
  ]);

  // Date converter
  const dateConverter = (date) => {
    const arr = date.toString().split(" ");
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const date_str =
      arr[3] + "-" + (months.indexOf(arr[1]) + 1).toString() + "-" + arr[2];
    return date_str;
  };

  const [apiDate, setApiDate] = useState({
    from: dateConverter(dateRange[0].startDate),
    to: dateConverter(dateRange[0].endDate),
  });

  const handleDateRangeChange = (item) => {
    setDateRange([
      {
        startDate: item.selection.startDate,
        endDate: item.selection.endDate,
        key: "selection",
      },
    ]);
    setApiDate({
      from: dateConverter(item.selection.startDate),
      to: dateConverter(item.selection.endDate),
    });
  };

  useEffect(() => {
    let data = { start_date: apiDate.from, end_date: apiDate.to };
    dispatch(getAllShortenUrl(data));
  }, [dispatch]); // Add dispatch to the dependencies array if you use it inside useEffect
  // console.log(apiDate, "dateRange");
  return (
    <>
      <NavLink to="/websitedata/short-link">
        <Button
          variant="contained"
          color="success"
          startIcon={<AddBoxIcon />}
          sx={{ marginBottom: "15px" }}
        >
          <strong>Create Short Link</strong>
        </Button>
      </NavLink>
      <Box>
        <DateRangePicker
          ranges={dateRange}
          showSelectionPreview={true}
          moveRangeOnFirstSelection={false}
          months={2}
          direction="horizontal"
          onChange={(item) => handleDateRangeChange(item)}
        />
      </Box>
      {loading && <LinearProgress />}
      <Datatable
        name="Short Links Data"
        columns={columns}
        data={all_shortLinkData}
      />
    </>
  );
}

export default ShowShortLink;

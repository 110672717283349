import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { Button } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import AddBoxIcon from "@mui/icons-material/AddBox";
import Datatable from "../Datatable/Datatable";
import { Popconfirm } from "antd";
import {
  activateStoreDescriptionData,
  getAllStoresData,
  getStoreDescriptionData,
} from "../../redux/features/storesSlice";
import { useDispatch, useSelector } from "react-redux";

function ManageStoreDescription() {
  const dispatch = useDispatch();

  // redux data
  const { allStoresData, storeDescriptionData } = useSelector((state) => state.stores);
  const [isExpanded, setIsExpanded] = useState("");

  const toggleExpansion = (e, details) => {
    setIsExpanded(details);
  };

  const handleReadLess = () => {
    setIsExpanded("");
  };

  const getStoreNameFromId = (id) => {
    for (let i = 0; i < allStoresData.length; i++) {
      if (allStoresData[i].store_id === id) return allStoresData[i].store_name;
    }
  };

  const cbFunc = () => {
    dispatch(getStoreDescriptionData());
  };

  const handleStatus = (formData) => {
    dispatch(activateStoreDescriptionData({ formData, cbFunc }));
  };

  const columns = [
    {
      name: "store_id",
      label: "Action",
      options: {
        customBodyRender: (id) => {
          let temp = storeDescriptionData.filter(
            (row) => row.store_id === id
          )[0];
          let data = { ...temp, store_name: getStoreNameFromId(temp.store_id) };
          return (
            <NavLink to="/stores/add-store-description" state={{ data }}>
              <Button color="info" variant="outlined" startIcon={<EditIcon />}>
                <strong>Edit</strong>
              </Button>
            </NavLink>
          );
        },
        filter: true,
        sort: true,
      },
    },
    {
      name: 'store_id',
      label: 'Store ID',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: "store_id",
      label: "Store Name",
      options: {
        customBodyRender: (id) => {
          return getStoreNameFromId(id);
        },
        filter: true,
        sort: true,
      },
    },
    {
      name: "seo_title",
      label: "SEO Title",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "seo_description",
      label: "SEO Description",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "h1_title",
      label: "H1 Title",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "related_desc",
      label: "Related Description",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "description",
      label: "Description",
      options: {
        customBodyRender: (details) => {
          return (
            <>
              {
                isExpanded === details ?

                  <>
                    <p
                      style={{ padding: 0 }}
                      dangerouslySetInnerHTML={{ __html: details }}

                    >
                    </p>
                    <Button onClick={handleReadLess}>Read Less...</Button>
                  </>
                  :
                  <>
                    <p
                      style={{ padding: 0 }}
                      dangerouslySetInnerHTML={{ __html: details.slice(0, 100) }}

                    >
                    </p>
                    <Button onClick={(e) => toggleExpansion(e, details)}>Read More...</Button>
                  </>
              }
            </>

          );
        },
        filter: true,
        sort: true,
      },
    },
    {
      name: "active",
      label: "Status",
      options: {
        customBodyRender: (active, tableMeta) => {
          var data = {};
          data.store_id = tableMeta.rowData[0];
          data.active = active ? "0" : "1";
          if (active)
            return (
              <Popconfirm
                placement="topRight"
                title="Deactivate Store"
                description="Do you really want to deactivate the store?"
                okText="Yes"
                cancelText="No"
                onConfirm={() => handleStatus(data)}
              >
                <Button color="error" size="small" variant="outlined">
                  <b>Deactivate</b>
                </Button>
              </Popconfirm>
            );
          else
            return (
              <Popconfirm
                placement="topRight"
                title="Activate Store"
                description="Do you really want to activate the store?"
                okText="Yes"
                cancelText="No"
                onConfirm={() => handleStatus(data)}
              >
                <Button color="success" size="small" variant="outlined">
                  <b>Activate</b>
                </Button>
              </Popconfirm>
            );
        },
        filter: true,
        sort: true,
      },
    },
  ];

  useEffect(() => {
    dispatch(getAllStoresData());
    cbFunc();
  }, []);

  return (
    <>
      <NavLink to="/stores/add-store-description">
        <Button
          variant="contained"
          color="success"
          startIcon={<AddBoxIcon />}
          sx={{ marginBottom: "15px", marginRight: "10px" }}
        >
          <strong>Add Store Description</strong>
        </Button>
      </NavLink>
      <Datatable
        name="Store Description"
        columns={columns}
        data={storeDescriptionData ?? []}
      />
    </>
  );
}

export default ManageStoreDescription;

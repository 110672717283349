import CreateForm from "./Form";
import { useEffect, useState } from "react";
import CustomInput from "./Form/CustomInput";
import SearchIcon from "@mui/icons-material/Search";
import { toast } from "react-toastify";
import { Paper } from "@mui/material";
import { useLocation } from "react-router-dom";
import "./CreateForm.css";
import axios from "../../api/axios";
const AddDealsApp = () => {
  const GET_DEALS_URL = "/api/deals/getdealsapp";
  const [link, setLink] = useState();
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const rowData = location?.state?.fullRowData;

  const handleChange = (event) => {
    setLink(event.target.value);
  };
  const [dealsData, setDealsData] = useState({});
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (link) {
        setLoading(true);
        const response = await axios.post(GET_DEALS_URL, {
          url: link,
        });
        setDealsData(response?.data?.data);
        // setGraphData(response.data.graph_data)
        // console.log(response, "response");
      }
    } catch (error) {
      console.log(error);
      toast.error(
        error.response?.data?.error
          ? error.response?.data?.error
          : error.response?.data?.message
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (rowData) {
      setDealsData(rowData);
    }
  }, []);

  const handleSearchButtonClick = (e) => {
    handleSubmit(e);
  };
  console.log(dealsData, "deals data");
  return (
    <div>
      {!rowData && (
        <div className="">
          <form onSubmit={handleSubmit} className="formLink">
            <Paper
              elevation={0}
              sx={{ width: "100%", padding: "16px", marginBottom: "16px" }}
            >
              {" "}
              <CustomInput
                className="width-full"
                type="search"
                handelChange={handleChange}
                label="Past Link"
                variant={"standard"}
                value={link}
                withButton={true}
                icon={<SearchIcon />}
                handelButtonClick={handleSearchButtonClick}
                loading={loading}
                // setLink={setLink}
              />
            </Paper>
          </form>
        </div>
      )}

      <div className="mt-4">
        <CreateForm dealsData={dealsData} setLink={setLink} rowData={rowData} />
      </div>
    </div>
  );
};
export default AddDealsApp;

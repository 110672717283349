/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
    Paper,
    Stack,
    Table,
    TableCell,
    TableRow,
    TextField,
    Typography,
    tableCellClasses,
    TableBody,
    Toolbar,
    Button,
    Divider,
} from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useDispatch } from "react-redux";
import dayjs from "dayjs";
import { updateProductData } from "../../../redux/features/salesSlice";

function Amazon({ site, productUrlData }) {
    const dispatch = useDispatch()

    const [saleData, setSaleData] = useState({
        ppid: null,
        vcode: "All",
        color: "All Colors",
        ccss: "#FFFFFF",
        match_url: "",
    })
    // handle value changes
    const handleChange = (key) => (event) => {
        setSaleData({ ...saleData, [key]: event.target.value });
    };

    // handle form submit
    const handleSubmit = (event) => {
        event.preventDefault();
        let formData = {}
        formData.site = site;
        formData.main = {
            url: saleData.url,
            pname: saleData.pname,
            porder: saleData.porder,
            pdate: saleData.pdate
        }
        formData.variants = {
            purl: saleData.purl,
            ppid: saleData.ppid?.split(","),
            color: saleData.color,
            ccss: saleData.ccss,
            vcode: saleData.vcode,
            match_url: saleData.match_url
        }

        // console.log(formData)
        dispatch(updateProductData(formData))

    };

    useEffect(() => {
        let data = {}
        data.url = productUrlData && productUrlData[0] && productUrlData[0].main && productUrlData[0].main[0].url
        data.pname = productUrlData && productUrlData[0] && productUrlData[0].main && productUrlData[0].main[0].title
        data.porder = productUrlData && productUrlData[0] && productUrlData[0].main && productUrlData[0].main[0].order
        data.pdate = productUrlData && productUrlData[0] && productUrlData[0].main && productUrlData[0].main[0].date
        data.purl = productUrlData && productUrlData[0] && productUrlData[0].variants && productUrlData[0].variants[0].url

        setSaleData({ ...saleData, ...data })
    }, [productUrlData])

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Paper elevation={3}>
                <form onSubmit={handleSubmit}>
                    <Stack direction="column" spacing={1}>
                        <Toolbar
                            sx={{ padding: "1.2rem", paddingBottom: "0" }}
                        >
                            <Typography
                                sx={{
                                    color: "var(--primary-color)",
                                    fontWeight: "bold",
                                }}
                                variant="h5"
                                component="u"
                            >
                                Product Information
                            </Typography>
                        </Toolbar>
                        <Table
                            size="small"
                            sx={{
                                width: "auto",
                                margin: "8px !important",
                                marginTop: "0 !important",
                                [`& .${tableCellClasses.root}`]: {
                                    borderBottom: "none",
                                },
                            }}
                        >
                            <TableBody>
                                <TableRow>
                                    <TableCell>
                                        <Typography variant="h6">
                                            <b>Product URL</b>
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            size="small"
                                            fullWidth
                                            placeholder="Product URL"
                                            value={saleData.url}
                                            onChange={handleChange('url')}
                                        />
                                    </TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell>
                                        <Typography variant="h6">
                                            <b>Product Name</b>
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            size="small"
                                            fullWidth
                                            placeholder="Product Name"
                                            value={saleData.pname}
                                            onChange={handleChange('pname')}
                                        />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        <Typography variant="h6">
                                            <b>Product Order</b>
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            size="small"
                                            fullWidth
                                            placeholder="Product Order"
                                            value={saleData.porder}
                                            onChange={handleChange('porder')}
                                        />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        <Typography variant="h6">
                                            <b>Sale Date</b>
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <DateTimePicker
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    size="small"
                                                />
                                            )}
                                            value={saleData.pdate}
                                            onChange={(e, val) => handleChange('pdate')}
                                        />
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>

                        <Divider flexItem sx={{ borderColor: "black" }} />

                        <Toolbar>
                            <Typography
                                sx={{
                                    color: "var(--primary-color)",
                                    fontWeight: "bold",
                                }}
                                variant="h5"
                                component="u"
                            >
                                Variant Information
                            </Typography>
                        </Toolbar>
                        <Table
                            size="small"
                            sx={{
                                width: "auto",
                                margin: "8px !important",
                                marginTop: "0px !important",
                                [`& .${tableCellClasses.root}`]: {
                                    borderBottom: "none",
                                },
                            }}
                        >
                            <TableBody>
                                <TableRow>
                                    <TableCell className="col-6">
                                        <TextField
                                            size="small"
                                            fullWidth
                                            placeholder="URL"
                                            value={saleData.purl}
                                            onChange={handleChange('purl')}
                                        />
                                    </TableCell>
                                    <TableCell className="col-2">
                                        <TextField
                                            size="small"
                                            fullWidth
                                            placeholder="Variant Code"
                                            value={saleData.vcode}
                                            onChange={handleChange('vcode')}
                                        />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        <TextField
                                            size="small"
                                            fullWidth
                                            placeholder="Match URL, Insert multiple url in form (node==a)|(node==b)|(c)"
                                            value={saleData.match_url}
                                            onChange={handleChange('match_url')}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            size="small"
                                            fullWidth
                                            placeholder="Color"
                                            value={saleData.color}
                                            onChange={handleChange('color')}
                                        />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        <TextField
                                            size="small"
                                            fullWidth
                                            placeholder="Product urls seperated by comma"
                                            value={saleData.ppid}
                                            onChange={handleChange('ppid')}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            size="small"
                                            fullWidth
                                            placeholder="Color Code"
                                            value={saleData.ccss}
                                            onChange={handleChange('ccss')}
                                        />
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                        <Stack alignItems="center">
                            <Button
                                variant="contained"
                                type="submit"
                                size="large"
                                className="d-block mx-auto mb-3 w-25"
                                onClick={handleSubmit}
                            >
                                <strong>Submit</strong>
                            </Button>
                        </Stack>
                    </Stack>
                </form>
            </Paper>
        </LocalizationProvider>
    )
}

export default Amazon;

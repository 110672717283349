/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import Datatable from '../Datatable/Datatable';
import { Button } from "@mui/material";
import { NavLink } from 'react-router-dom';
import AddBoxIcon from '@mui/icons-material/AddBox';
import EditIcon from '@mui/icons-material/Edit';
import { getStoreSchema, activateStoreSchema, getAllStoresData } from "../../redux/features/storesSlice";
import { Popconfirm } from 'antd';

function ShowStoreSchema() {
    const dispatch = useDispatch();
    const [isExpanded, setIsExpanded] = useState("");
    const { getStoreSchemaData, allStoresData } = useSelector((state) => ({ ...state.stores }));
    const handleStatus = (formData) => {
        dispatch(activateStoreSchema(formData));
        dispatch(getStoreSchema());
    };

    const toggleExpansion = (e, details) => {
        setIsExpanded(details);
    };

    const handleReadLess = () => {
        setIsExpanded("");
    };

    const getStoreNameFromId = (id) => {
        for (let i = 0; i < allStoresData.length; i++) {
            if (allStoresData[i].store_id === id) { return allStoresData[i].store_name; }
        }
    };

    const columns = [
        {
            name: "edit",
            label: 'Edit',
            options: {
                customBodyRender: (id, tableMeta) => {
                    let data = {}
                    data.id = getStoreSchemaData.filter((item) => item.store_id == tableMeta.rowData[2])[0].id;
                    data.store_id = tableMeta.rowData[2];
                    data.store_name = getStoreNameFromId(tableMeta.rowData[2]);
                    data.store_schema = tableMeta.rowData[3];
                    return (
                        <NavLink to="/stores/add-store-schema" state={{ data }}>
                            <Button color="info" size="small" startIcon={<EditIcon />}>
                                <strong>Edit</strong>
                            </Button>
                        </NavLink>
                    )
                },
                filter: false,
                sort: false,
            }
        },
        {
            name: "store_id",
            label: "Store ID",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "store_id",
            label: "Store Name",
            options: {
                customBodyRender: (id) => {
                    return getStoreNameFromId(id);
                },
                filter: true,
                sort: true,
            }
        },
        {
            name: "store_schema",
            label: "Description",
            options: {
                customBodyRender: (details) => {
                    return (
                        <>
                            {
                                isExpanded === details ?

                                    <>
                                        <p
                                            style={{ padding: 0 }}
                                            dangerouslySetInnerHTML={{ __html: details }}

                                        >
                                        </p>
                                        <Button onClick={handleReadLess}>Read Less...</Button>
                                    </>
                                    :
                                    <>
                                        <p
                                            style={{ padding: 0 }}
                                            dangerouslySetInnerHTML={{ __html: details.slice(0, 100) }}

                                        >
                                        </p>
                                        <Button onClick={(e) => toggleExpansion(e, details)}>Read More...</Button>
                                    </>
                            }
                        </>

                    );
                },
                filter: true,
                sort: true,
            },
        },
        {
            name: "active",
            label: "Status",
            options: {
                customBodyRender: (active, tableMeta) => {
                    var data = {};
                    data.store_id = tableMeta.rowData[1];
                    data.active = active ? "0" : "1";
                    if (active)
                        return (
                            <Popconfirm
                                placement="topRight"
                                title="Deactivate Store"
                                description="Do you really want to deactivate the store?"
                                okText="Yes"
                                cancelText="No"
                                onConfirm={() => handleStatus(data)}
                            >
                                <Button color="error" size="small" variant="outlined">
                                    <b>Deactivate</b>
                                </Button>
                            </Popconfirm>
                        );
                    else
                        return (
                            <Popconfirm
                                placement="topRight"
                                title="Activate Store"
                                description="Do you really want to activate the store?"
                                okText="Yes"
                                cancelText="No"
                                onConfirm={() => handleStatus(data)}
                            >
                                <Button color="success" size="small" variant="outlined">
                                    <b>Activate</b>
                                </Button>
                            </Popconfirm>
                        );
                },
                filter: true,
                sort: true,
            },
        },
    ];

    useEffect(() => {
        dispatch(getStoreSchema());
        dispatch(getAllStoresData());
    }, []);

    return (
        <div>
            <NavLink to="/stores/add-store-schema">
                <Button variant="contained" color="success" startIcon={<AddBoxIcon />} sx={{ marginBottom: "15px", marginRight: "10px" }}>
                    <strong>Add New Store Schema</strong>
                </Button>
            </NavLink>
            <Datatable
                name='Trending Store Data'
                columns={columns}
                data={getStoreSchemaData && getStoreSchemaData}
            />
        </div>
    )
}

export default ShowStoreSchema;
import React, { useState, useEffect } from "react";
import {
    Table,
    TableCell,
    TableRow,
    TextField,
    tableCellClasses,
    Button,
    TableBody,
    Paper,
    Stack,
    Typography,
    Toolbar,
    Chip,
    TextareaAutosize,
    Autocomplete,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import axios from "axios";
import AppConstants from "../../constants/AppConstants";
import { useLocation } from "react-router";
import UserDatatable from "./UserDatatable";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



export default function UserDeatils() {
    const { state } = useLocation();
    // user assignment
    // console.log(state, "state");

    const [name, setName] = useState("");
    const [userId, setUserId] = useState("");
    const [email, setEmail] = useState("");
    const [mobile, setMobile] = useState("");
    const [device, setDevice] = useState("");
    const [alluser, setAlluser] = useState([]);

    let baseUrl = AppConstants.baseURL;
    console.log(baseUrl, 'baseUrl');

    const notify = (value) =>
        toast.info(value, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });


    const handleSubmit = (event) => {
        event.preventDefault();
        const userdata = {
            name,
            userId,
            email,
            mobile,
            device,
        };

        axios.post(`${baseUrl}` + '/authpanel/userdetails', { userdata }).then(({ data }) => {
            console.log(data,"dataqw");
            console.log(data.message, "data from users");
            setName(" ");
            setUserId(" ");
            setEmail(" ");
            setMobile(" ");
            const formattedUsers = data.data.map((user, index) => ({
                id: index + 1,
                uid: user.fuid,
                name: user.name,
                email: user.email,
                mobile: user.mobile,
                userInfo: user.userInfo,
                ip: user.ip,
            }));
            setAlluser(formattedUsers);
            notify(data.message);
        }).catch((error) => {
            console.log(error, "errorblog");
        })

    };


    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Paper elevation={3}>
                <div>
                    <form onSubmit={handleSubmit}>
                        <Stack spacing={3} direction="column">
                            <Toolbar
                                mt={3}
                                sx={{
                                    padding: "1.2rem",
                                    borderBottom: "2px solid var(--primary-color)",
                                }}
                            >
                                <Typography
                                    sx={{
                                        color: "var(--primary-color)",
                                        fontWeight: "bold",
                                    }}
                                    variant="h5"
                                    id="tableTitle"
                                >
                                    User Details
                                </Typography>
                            </Toolbar>
                            <Table
                                size="small"
                                sx={{
                                    width: "auto",
                                    margin: "8px !important",
                                    marginTop: "16px !important",
                                    [`& .${tableCellClasses.root}`]: {
                                        borderBottom: "none",
                                    },
                                }}
                            >
                                <TableBody>

                                    <TableRow>
                                        <TableCell>
                                            <Typography variant="h6">
                                                <b>Enter Name</b>
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                size="small"
                                                placeholder="Enter Name"
                                                fullWidth
                                                required
                                                value={name}
                                                onChange={(e) => { setName(e.target.value) }}
                                            />
                                        </TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell>
                                            <Typography variant="h6">
                                                <b>Enter UserId</b>
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                size="small"
                                                placeholder="Enter UserId"
                                                fullWidth
                                                required
                                                value={userId}
                                                onChange={(e) => { setUserId(e.target.value) }}
                                            />
                                        </TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell>
                                            <Typography variant="h6">
                                                <b>Enter Email</b>
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                size="small"
                                                placeholder="Enter Email"
                                                fullWidth
                                                required
                                                value={email}
                                                onChange={(e) => { setEmail(e.target.value) }}
                                            />
                                        </TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell>
                                            <Typography variant="h6">
                                                <b>Enter Mobile</b>
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                size="small"
                                                placeholder="Enter Mobile"
                                                fullWidth
                                                required
                                                value={mobile}
                                                onChange={(e) => { setMobile(e.target.value) }}
                                            />
                                        </TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell>
                                            <Typography variant="h6">
                                                <b>Enter Device</b>
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                size="small"
                                                placeholder="Enter Device"
                                                fullWidth
                                                required
                                                value={device}
                                                onChange={(e) => { setDevice(e.target.value) }}
                                            />
                                        </TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell colSpan={2}>
                                            <Button
                                                variant="contained"
                                                type="submit"
                                                size="large"
                                                className="d-block mx-auto mt-4 mb-3"
                                                onClick={handleSubmit}
                                            >
                                                <strong>Submit</strong>
                                            </Button>
                                        </TableCell>
                                    </TableRow>

                                </TableBody>
                            </Table>
                        </Stack>

                    </form>
                    <ToastContainer />
                </div>
           
            <UserDatatable rows={alluser} />
        </Paper>

        </LocalizationProvider >
    );
}



/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import {
  TableHead,
  TableBody,
  Table,
  TableCell,
  TableRow,
  Paper,
  Stack,
  Toolbar,
  Typography,
  Box
} from '@mui/material'
import Datatable from '../Datatable/Datatable';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { employeeTrackTableData, getCouponCountData, getScrapCountData } from '../../redux/features/couponsSlice';

//for pivot table 
// import { PivotViewComponent } from '@syncfusion/ej2-react-pivotview';

// Columns
const column = [
  {
    name: 'name',
    label: 'Name',
    options: {
      filter: true,
      sort: true,
    }
  },
  {
    name: 'merchant',
    label: 'Store',
    options: {
      filter: true,
      sort: true,
    }
  },
  {
    name: 'coupon_code',
    label: 'Coupon Code',
    options: {
      filter: true,
      sort: true,
    }
  },
  {
    name: 'time',
    label: 'Date',
    options: {
      filter: true,
      sort: true,
    }
  },
  {
    name: 'type',
    label: 'DB Type',
    options: {
      filter: true,
      sort: true,
    }
  },
  {
    name: 'isAdd',
    label: 'Action',
    options: {
      customBodyRender: (add) => {
        if (add === 1) { return "Added" }
        else { return "Deleted" }
      },
      filter: true,
      sort: true,
    }
  },
];

function CouponStats() {
  const dispatch = useDispatch()
  const { loading, couponCountData, scrapCountData, empTrackTableData } = useSelector(state => state.coupons)

  useEffect(() => {
    dispatch(getCouponCountData())
    dispatch(getScrapCountData())
    dispatch(employeeTrackTableData())
  }, [])


  return (
    <Paper elevation={3} >
      <Stack spacing={3} direction="column">
        <Toolbar
          mt={3}
          sx={{ padding: "1.2rem", borderBottom: "2px solid var(--primary-color)" }}
        >
          <Typography
            sx={{
              flex: "1 1 100%",
              color: "var(--primary-color)",
              fontWeight: "bold",
            }}
            variant="h5"
            id="tableTitle"
          >
            Coupon Statistics
          </Typography>
        </Toolbar>

        {/*Table number 1 */}
        <Table>
          <TableBody>
            <TableRow>
              <TableCell colSpan={4} >
                <Typography variant="h6" className="d-flex justify-content-center align-items-center"><b>Main Coupon DB</b></Typography>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell width="25%" className='text-center' sx={{ borderRight: '2px solid black !important' }} >
                <Box className='text-center'>
                  <FileDownloadOutlinedIcon />
                  <b>Added By Coupomated</b>
                  <h1>{loading && "-"}{couponCountData[0] && couponCountData[0].Coupomated}</h1>
                </Box>
              </TableCell>

              <TableCell width="25%" sx={{ borderRight: '2px solid black !important' }} >
                <Box className='text-center'>
                  <FileDownloadOutlinedIcon />
                  <b>Added By Flipshope</b>
                  <h1>{loading && "-"}{couponCountData[0] && couponCountData[0].flipshope}</h1>
                </Box>
              </TableCell>

              <TableCell width="25%" sx={{ borderRight: '2px solid black !important' }} >
                <Box className='text-center'>
                  <FileDownloadOutlinedIcon />
                  <b>Today Added</b>
                  <h1>{loading && "-"}{couponCountData[0] && couponCountData[0].TodayAdded}</h1>
                </Box>
              </TableCell>

              <TableCell width='25%'>
                <Box className='text-center'>
                  <FileDownloadOutlinedIcon />
                  <b>Total</b>
                  <h1>{loading && "-"}{couponCountData[0] && couponCountData[0].TodayAdded}</h1>
                </Box>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>

        {/*Table number 2 */}
        <Table>
          <TableBody>
            <TableRow>
              <TableCell colSpan={4} >
                <Typography variant="h6" className="d-flex justify-content-center align-items-center"><b>Scrap Coupon DB</b></Typography>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell width="25%" sx={{ borderRight: '2px solid black !important' }} >
                <Box className='text-center'>
                  <FileDownloadOutlinedIcon />
                  <b>Today Added</b>
                  <h1>{loading && "-"}{scrapCountData[0] && scrapCountData[0].TodayScraped}</h1>
                </Box>
              </TableCell>

              <TableCell width="25%" sx={{ borderRight: '2px solid black !important' }} >
                <Box className='text-center'>
                  <FileDownloadOutlinedIcon />
                  <b>Yesterday Added</b>
                  <h1>{loading && "-"}{scrapCountData[0] && scrapCountData[0].YesterdayScraped}</h1>
                </Box>
              </TableCell>

              <TableCell width="25%" sx={{ borderRight: '2px solid black !important' }} >
                <Box className='text-center'>
                  <FileDownloadOutlinedIcon />
                  <b>Today Action taken</b>
                  <h1 style={{ color: "green" }}>{scrapCountData[0] && scrapCountData[0].TodayActionTaken}</h1>
                </Box>
              </TableCell>

              <TableCell width='25%'>
                <Box className='text-center'>
                  <FileDownloadOutlinedIcon />
                  <b>Total Left</b>
                  <h1 style={{ color: "red" }}>{scrapCountData[0] && scrapCountData[0].TotalLeft}</h1>
                </Box>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>

        {/*pivot table*/}
        {/* <Box>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell colSpan={4}>
                  <PivotViewComponent></PivotViewComponent>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Box> */}

        <Datatable
          name="Employee Coupon Track"
          columns={column}
          data={empTrackTableData && empTrackTableData}
        />

      </Stack>
    </Paper>
  )
}

export default CouponStats
import axios from "axios";
import AppConstants from "../../constants/AppConstants";

const API = axios.create({ baseURL: AppConstants.baseURL });

///================================================get stores data===========================================/////
export const getAllStores = async () =>
  await API.get("/webdata/getallstoresweb");

///================================================add store data===========================================/////
export const addStores = async (formData) =>
  await API.post("/webdata/addstoressweb", formData);

///================================================aactivate stores===========================================/////
export const activateDeactivateStores = async (formData) =>
  await API.post("/webdata/activatestoresweb", formData);

///===============================================get=TrendSTore data===========================================/////
export const getTrendingStores = async () =>
  await API.get("/webdata/getalltrendstores");

///================================================add trend stores===========================================/////
export const addTrendingStores = async (formData) =>
  await API.post("/webdata/addtrendstoress", formData);

///================================================activate trend stores===========================================/////
export const activateDeactivateTrendingStores = async (formData) =>
  await API.post("/webdata/activatetrendstores", formData);

// ==========================================STORE CATEGORY===============================

// ===================================================getstoreCategory==============================================
export const getAllCategory = async () => await API.get("/webdata/getallcat");
export const activateDeactivateCategory = async (formData) =>
  await API.post("/webdata/activecat", formData);

// ====================================getAllCategoryImages==============================
export const getAllCategoryImages = async () =>
  await API.get("/webdata/getallcatimage");

// ==============================================getStoreCategoryData========================
export const getStoreCategoryData = async () =>
  await API.get("/webdata/getstorecat");

// ===========================================addStoreCategory==============================
export const addStoreCategory = async (formData) =>
  await API.post("/webdata/addstorecat", formData);

// ===========================================activateDeactivateStoreCategory====================
export const activateDeactivateStoreCategory = async (formData) =>
  await API.post("/webdata/activestorecat", formData);

//==============================================getStoreDescription========================
export const getStoreDescription = async () =>
  await API.get("/webdata/getstoredesc");

//===========================================addStoreDescription==============================
export const addStoreDescription = async (formData) =>
  await API.post("/webdata/addstoredesc", formData);

//===========================================activateStoreDescription====================
export const activateStoreDescription = async (formData) =>
  await API.post("/webdata/activestoredesc", formData);

//===========================================addStoreSchema==============================
export const addStoreSchemaApi = async (formData) =>
  await API.post("/webdata/addstoreschema", formData);

//===========================================activateStoreSchema==============================
export const activateStoreSchemaApi = async (formData) =>
  await API.post("/webdata/activestoreschema", formData);

//===========================================activateStoreSchema==============================
export const addStoreDeeplinkAllowedApi = async (formData) =>
  await API.post("/webdata/addstoredeepallow", formData);

//===========================================getStoreSchema==============================
export const getStoreSchemaApi = async () =>
  await API.get("/webdata/getstoreschema");

//===========================================addStoresForAutoApplyCouponApi==============================
export const addStoresForAutoApplyCouponApi = async (formData) =>
  await API.post("/webdata/addaacstoredetails", formData);

//============================activateDeactivateAacStores==============================
export const activateDeactivateAacStoresApi = async (formData) =>
  await API.post("/webdata/activateaacstoredetails", formData);

//===========================================getStoresForAutoApplyCouponApi==============================
export const getStoresForAutoApplyCouponApi = async () =>
  await API.get("/webdata/getaacstoredetails");

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import { useAuth } from "../../context/authContext";
import { getModulesByUserData } from "../../redux/features/authSlice";
import Footer from "../footer/Footer";

const Sidebar = () => {
  const dispatch = useDispatch();
  const { modulesByUserData } = useSelector((state) => ({ ...state.userAuth }));
  const { logout } = useAuth();
  // const navigate = useNavigate();

  // Sidebar links

  const handleLogout = async () => {
    // e.preventDefault();
    await logout();
    window.location.reload();
    // navigate("/login");
  };
  useEffect(() => {
    dispatch(getModulesByUserData());
  }, []);

  // console.log(modulesByUserData, "modulesByUserData")
  return (
    <aside id="sidebar" className="sidebar">
      <ul className="sidebar-nav" id="sidebar-nav">
        {modulesByUserData &&
          modulesByUserData.map((row) =>
            row.sub_module === null ? (
              <li className="nav-item" key={row.module_url && row.module_url}>
                <NavLink
                  to={`${row.module_url && row.module_url}`}
                  className="nav-link"
                >
                  <i className={`bi ${row.icon && row.icon}`}></i>
                  <span className="text-capitalize">
                    {row.module_name && row.module_name}
                  </span>
                </NavLink>
              </li>
            ) : (
              <li
                className="nav-item"
                key={`dropdown-${"xyz" + row.module_id}`}
              >
                <a
                  className="nav-link collapsed"
                  data-bs-target={`#${"xyz" + row.module_id}-nav`}
                  data-bs-toggle="collapse"
                >
                  <i className={`bi ${row.icon && row.icon}`}></i>
                  <span className="text-capitalize">
                    {row.module_name && row.module_name}
                  </span>
                  <i className="bi bi-chevron-down ms-auto"></i>
                </a>
                <ul
                  id={`${"xyz" + row.module_id}-nav`}
                  className="nav-content collapse "
                  data-bs-parent="#sidebar-nav"
                >
                  {row.sub_module &&
                    row.sub_module.map((item) => (
                      <li key={item.sub_module_url}>
                        <NavLink
                          to={`${item.sub_module_url && item.sub_module_url}`}
                          className="nav-link"
                        >
                          <i className="bi bi-caret-right-fill"></i>
                          <span className="text-capitalize">
                            {item.sub_module_name && item.sub_module_name}
                          </span>
                        </NavLink>
                      </li>
                    ))}
                </ul>
              </li>
            )
          )}
        <li className="nav-item" key={"login"}>
          <a onClick={() => handleLogout()} className="nav-link">
            <i className={`bi ${"bi-box-arrow-right"}`}></i>
            <span className="text-capitalize">{"logout"}</span>
          </a>
        </li>
      </ul>
      <Footer />
    </aside>
  );
};

export default Sidebar;


import { DataGrid } from '@mui/x-data-grid';
export default function UserDatatable({ rows }) {
    const columns = [
      
        { field: 'id', headerName: 'Sl', width: 70 },
        { field: 'uid', headerName: 'Uid', width: 130 },
        { field: 'name', headerName: 'Name', width: 130 },
        { field: 'email', headerName: 'email', width: 250 },
        { field: 'mobile', headerName: 'mobile', width: 150 },
        { field: 'userInfo', headerName: 'userInfo', width: 290 },
        { field: 'ip', headerName: 'ip', width: 130 },
    ];

    return (
        <div style={{ paddingTop: "3%", display: 'flex', gap: "20%", textAlign: "center" }}>
            
                <div style={{ height: 400, width: '100%',marginLeft:'25px', marginRight:'25px' }}>
                    <DataGrid
                        rows={rows}
                        disableRowSelectionOnClick
                        columns={columns}
                        initialState={{
                            pagination: {
                                paginationModel: { page: 0, pageSize: 5 },
                            },
                        }}
                        pageSizeOptions={[5, 10]}
                        checkboxSelection
                    />
                </div>
            
        </div>
    );
}

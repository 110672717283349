/* eslint-disable react-hooks/exhaustive-deps */
import { Button, ButtonGroup, LinearProgress } from '@mui/material'
import ReplyRoundedIcon from '@mui/icons-material/ReplyRounded';
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { getAllDealsProcessData, updateDealsProcessData } from '../../redux/features/dealsSlice'
import Datatable from '../Datatable/Datatable'
import PIDGraph from './PIDGraph';

function LiveDealsProcessed() {
    const dispatch = useDispatch()
    const { allDealsProcessData, loading } = useSelector(state => state.deals)

    // handlers
    const updateDeal = (data, action) => {
        if (action === "approve") data.is_a_deal = 1;
        else if (action === "reject") data.is_a_deal = 0;
        // console.log(data)
        dispatch(updateDealsProcessData(data))
            .then(() => dispatch(getAllDealsProcessData()))
            .catch((error) => console.log(error))

    }

    const columns = [
        {
            name: 'id',
            label: 'Approve Deal',
            options: {
                customBodyRender: (id, tableMeta) => {
                    let data = {}
                    data.id = id
                    data.Store = tableMeta.rowData[7]
                    data.PID = tableMeta.rowData[8]
                    return (
                        <ButtonGroup orientation="horizontal" variant="text" color="success" size="small">
                            <Button color="success" onClick={() => updateDeal(data, "approve")}>
                                <strong>Approve</strong>
                            </Button>
                            <Button color="error" onClick={() => updateDeal(data, "reject")}>
                                <strong>Reject</strong>
                            </Button>
                        </ButtonGroup>
                    )
                },
                filter: true,
                sort: true,
            }
        },
        {
            name: 'time',
            label: 'Date & Time',
            options: {
                filter: true,
                sort: true,
            }
        },

        {
            name: 'link',
            label: 'Link',
            options: {
                customBodyRender: (link) => {
                    return <a href={link} target="_blank" rel="noreferrer">{link}</a>
                },
                filter: true,
                sort: true,
            }
        },
        {
            name: 'price',
            label: 'Price',
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: 'PID',
            label: 'Graph',
            options: {
                customBodyRender: (pid, tableMeta) => {
                    return (

                        <PIDGraph
                            // allDealsPanelData={allDealsPanelData}
                            pid={pid}
                            sid={tableMeta.rowData[7]}
                        />
                    )
                },
            }
        },
        {
            name: 'title',
            label: 'Title',
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: 'imgurl',
            label: 'Image',
            options: {
                customBodyRender: (link) => {
                    return <img src={link} width={100} />
                },
                filter: true,
                sort: true,
            }
        },
        {
            name: 'Store',
            label: 'Store',
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: 'PID',
            label: 'PID',
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: 'min',
            label: 'Min',
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: 'mean',
            label: 'Mean',
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: 'count',
            label: 'Count',
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: 'median',
            label: 'Median',
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: 'drop_median',
            label: 'Drop Median',
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: 'p20',
            label: 'p20',
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: 'drop_p20',
            label: 'Drop p20',
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: 'day_percent_30',
            label: 'Percent Day 30',
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: 'day_percent_90',
            label: 'Percent Day 90',
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: 'inStock',
            label: 'Stock',
            options: {
                customBodyRender: (stock) => {
                    if (stock === 1) return "In stock";
                    else return "Out of Stock";
                },
                filter: true,
                sort: true,
            }
        },

    ]

    useEffect(() => {
        dispatch(getAllDealsProcessData())
    }, [])
    return (
        <>
            <NavLink to="/livedeals">
                <Button
                    variant="contained"
                    color="info"
                    startIcon={<ReplyRoundedIcon />}
                    sx={{ marginBottom: 2 }}
                >
                    <strong>Show Live Deals</strong>
                </Button>
            </NavLink>
            {loading && <LinearProgress />}
            <Datatable name="Process Live Deals" columns={columns} data={allDealsProcessData && allDealsProcessData} />
        </>
    )
}

export default LiveDealsProcessed



import axios from "axios";
import AppConstants from "../../constants/AppConstants";

const API = axios.create({ baseURL: AppConstants.baseURL });


///================================================deals for you===========================================/////

// Notification  ROutes



export const getNotificationHistory = async (type, page) => await API.get(`/notification/notifyhistory?type=${type}&page=${page}`);

///================================================all category list===========================================/////
export const sendNotification = async (formData) => await API.post("/notification/notify", formData);


///================================================store wise coupons list===========================================/////
export const refreshHistory = async (p_id) => await API.get(`/notification/refreshhistory?pid=${p_id}`);
import React, { useEffect, useState } from 'react'
import {
    Paper,
    Stack,
    Toolbar,
    Typography,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    tableCellClasses,
    Divider,
    Autocomplete,
    Box,
    InputAdornment,
} from '@mui/material'
import { styled } from '@mui/material/styles';
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import { useDispatch, useSelector } from 'react-redux'
import { activateModulesData, addUpdateNewModules, showAllModules } from '../../redux/features/authSlice';
import { Popconfirm } from 'antd';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.action.hover,
        color: theme.palette.common.black,
        fontSize: "1rem",
        textAlign: "center",
        width: "max-content",
        padding: '10px !important',
        borderRight: "1px solid rgba(224, 224, 224, 1)",
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: "1rem",
        width: "max-content",
        padding: '7px 14px !important',
        borderRight: "1px solid rgba(224, 224, 224, 1)",
    },
    [`&.${tableCellClasses.root}:first-of-type`]: {
        textAlign: 'center',
    }
}));

function ViewModules() {
    const dispatch = useDispatch()

    // Modules Data
    const { allModules } = useSelector(state => state.userAuth)

    // Action selector
    const [action, setAction] = useState("")
    // Dialog input
    const [dialogOpen, setDialogOpen] = React.useState(false);

    // parent module name from id
    const getParentModuleName = (id) => {
        if (id === null) return null
        else return allModules.filter(mod => mod.module_id === id)[0].module_name;
    }

    const getParentModuleID = (val) => {
        if (val === null) return null
        else {
            let result = allModules.filter(mod => mod.module_name === val)[0];
            newModuleData.parent_module_id = result.module_id
            newModuleData.parent_module_name = result.module_name
        }
    }
    // activate/deactivate modules
    const moduleActivation = (data) => {
        console.log(data)
        dispatch(activateModulesData(data))
        dispatch(showAllModules())
    }
    // Create new
    const handleCreateNewModule = () => {
        setAction('create new')
        setDialogOpen(true);
    };

    // Edit Existing 
    const handleEditModule = (data) => {
        console.log(data.parent_module_id, "edit")
        setAction('edit')
        newModuleData.parent_module_id = data.parent_module_id;
        newModuleData.parent_module_name = getParentModuleName(data.parent_module_id);
        newModuleData.module_id = data.module_id;
        newModuleData.module_name = data.module_name;
        newModuleData.module_url = data.module_url;
        newModuleData.icon = data.icon;
        newModuleData.active = data.active;
        console.log(newModuleData)
        setDialogOpen(true);
    };

    // Dialog close
    const handleClose = () => {
        setDialogOpen(false);
        setNewModuleData({
            parent_module_id: null,
            parent_module_name: null,
            module_id: null,
            module_name: "",
            module_url: "",
            icon: "",
            active: "1",
        })
    };

    // New module data
    const [newModuleData, setNewModuleData] = useState({
        parent_module_id: null,
        parent_module_name: null,
        module_id: null,
        module_name: "",
        module_url: "",
        icon: "",
        active: "1",
    })
    // change handler
    const handleModuleChange = (key) => (e) => {
        setNewModuleData({ ...newModuleData, [key]: e.target.value })
    }

    // Create module handler
    const handleCreateModuleSubmit = () => {
        dispatch(addUpdateNewModules(newModuleData))
        handleClose();
        setNewModuleData({
            parent_module_id: null,
            parent_module_name: null,
            module_id: null,
            module_name: "",
            module_url: "",
            icon: "",
            active: "1",
        })
        dispatch(showAllModules())
    };

    useEffect(() => {
        dispatch(showAllModules())
    }, []);

    return (
        <Paper elevation={3} sx={{ minWidth: 'fit-content' }}>
            <Stack spacing={1} direction="column">
                <Toolbar
                    mt={3}
                    sx={{ padding: "1.2rem", borderBottom: "2px solid var(--primary-color)" }}
                >
                    <Typography
                        sx={{
                            flex: "1 1 100%",
                            color: "var(--primary-color)",
                            fontWeight: "bold",
                        }}
                        variant="h5"
                    >
                        All Modules
                    </Typography>
                    <Button
                        variant="contained"
                        color="info"
                        startIcon={<LibraryAddIcon />}
                        sx={{ whiteSpace: "nowrap" }}
                        onClick={handleCreateNewModule}
                    >
                        <strong>Create New</strong>
                    </Button>
                    <Dialog open={dialogOpen} onClose={handleClose} fullWidth>
                        <DialogTitle><b className='text-capitalize'>
                            {action} Module
                        </b></DialogTitle>
                        <Divider sx={{ borderColor: 'black' }} />
                        <DialogContent>
                            <Table
                                size="small"
                                sx={{
                                    width: "100%",
                                    [`& .${tableCellClasses.root}`]: {
                                        borderBottom: "none",
                                    },
                                }}>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>
                                            <Typography variant="body1" sx={{ whiteSpace: 'nowrap' }}>
                                                Module Name
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                type="text"
                                                fullWidth
                                                size="small"
                                                value={newModuleData.module_name}
                                                onChange={handleModuleChange('module_name')}
                                            />
                                        </TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell>
                                            <Typography variant="body1" sx={{ whiteSpace: 'nowrap' }}>
                                                Icon
                                            </Typography>
                                        </TableCell>
                                        <TableCell className='text-center'>
                                            <Stack direction="row" spacing={3}>
                                                <TextField
                                                    type="text"
                                                    fullWidth
                                                    size="small"
                                                    placeholder='Bootstrap icon class name'
                                                    value={newModuleData.icon}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end" sx={{ width: "18px", }} >
                                                                <i className={`bi ${newModuleData.icon}`}></i>
                                                            </InputAdornment>)
                                                    }}
                                                    onChange={handleModuleChange("icon")}
                                                />
                                                {/* <Box sx={{ height: '40px', width: "40px" }}>
                                                    <i className={`bi ${newModuleData.icon}`}></i>
                                                </Box> */}
                                            </Stack>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Typography variant="body1" sx={{ whiteSpace: 'nowrap' }}>
                                                Module URL
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                type="text"
                                                fullWidth
                                                size="small"
                                                value={newModuleData.module_url}
                                                onChange={handleModuleChange("module_url")}
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Typography variant="body1" sx={{ whiteSpace: 'nowrap' }}>
                                                Parent Module
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Autocomplete
                                                freeSolo
                                                size="small"
                                                options={allModules ? allModules.filter(item => item.parent_module_id === null).map(item => item.module_name) : []}
                                                value={newModuleData.parent_module_name}
                                                fullWidth
                                                renderInput={(params) => (
                                                    <TextField {...params} placeholder="Select Parent Module" />
                                                )}
                                                onChange={(e, val) => getParentModuleID(val)}
                                            />
                                        </TableCell>
                                    </TableRow>

                                </TableBody>
                            </Table>
                        </DialogContent>
                        <Divider />
                        <DialogActions>
                            <Button color="error" size="large" onClick={handleClose} ><b>Cancel</b></Button>
                            {action === 'edit'
                                ? <Button variant="contained" color="success" size="large" onClick={handleCreateModuleSubmit} ><b>Save</b></Button>
                                : <Button variant="contained" color="success" size="large" onClick={handleCreateModuleSubmit} ><b>Create</b></Button>}
                        </DialogActions>
                    </Dialog>
                </Toolbar>
                <Stack width="100%" p={5}>
                    <Table align="center" sx={{ minWidth: 'fit-content', border: "1px solid rgba(224, 224, 224, 1)" }}>
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>
                                    <b>Action</b>
                                </StyledTableCell>
                                <StyledTableCell>
                                    <b>Module Name</b>
                                </StyledTableCell>
                                <StyledTableCell>
                                    <b>Module URL</b>
                                </StyledTableCell>
                                <StyledTableCell>
                                    <b>Icon</b>
                                </StyledTableCell>
                                <StyledTableCell>
                                    <b>Status</b>
                                </StyledTableCell>
                                <StyledTableCell>
                                    <b>Parent Module</b>
                                </StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {allModules.map((module) => (
                                <TableRow key={`module-${module.module_id}`}>
                                    <StyledTableCell>
                                        <Button startIcon={<EditRoundedIcon />} size="small" onClick={(e) => handleEditModule(module)}><b>Edit</b></Button>
                                    </StyledTableCell>
                                    <StyledTableCell>{module.module_name}</StyledTableCell>
                                    <StyledTableCell>{module.module_url}</StyledTableCell>
                                    <StyledTableCell >
                                        <i className={`justify-content-center bi ${module.icon && module.icon}`}></i>
                                    </StyledTableCell>
                                    <StyledTableCell className='text-center'>
                                        {module.active ?
                                            <Popconfirm
                                                placement="right"
                                                title="Deactivate Module"
                                                okText="Yes"
                                                cancelText="No"
                                                onConfirm={() => moduleActivation({ module_id: module.module_id, active: 0 })}
                                            >
                                                <Button color="error" size="small" ><b>Deactivate</b></Button>
                                            </Popconfirm>
                                            : <Popconfirm
                                                placement="right"
                                                title="Activate Module"
                                                okText="Yes"
                                                cancelText="No"
                                                onConfirm={() => moduleActivation({ module_id: module.module_id, active: 1 })}
                                            >
                                                <Button color="success" size="small"><b>Activate</b></Button>
                                            </Popconfirm>
                                        }
                                    </StyledTableCell>
                                    <StyledTableCell>{getParentModuleName(module.parent_module_id)}</StyledTableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Stack>
            </Stack>
        </Paper>
    )
}

export default ViewModules
export const messageData = [
    {
        "action": "Resend",
        "parent_id": 100,
        "mode": "flipshope extension",
        "title": 'Aliexpress sale',
        "link": "http://www.localhost:5000",
        "description": "Get best deals on your favorite products",
        "image_link": "http://www.localhost:5000",
        "sent_user_count": 1000,
        "skip_user_count": 0,
        "alive_time": 2600,
        "date": "2022-11-09 17:11"
    },
    {
        "action": "Resend",
        "parent_id": 100,
        "mode": "flipshope extension",
        "title": 'Aliexpress sale',
        "link": "http://www.localhost:5000",
        "description": "Get best deals on your favorite products",
        "image_link": "http://www.localhost:5000",
        "sent_user_count": 1000,
        "skip_user_count": 0,
        "alive_time": 2600,
        "date": "2022-11-09 17:11"
    },
    {
        "action": "Resend",
        "parent_id": 100,
        "mode": "flipshope extension",
        "title": 'Aliexpress sale',
        "link": "http://www.localhost:5000",
        "description": "Get best deals on your favorite products",
        "image_link": "http://www.localhost:5000",
        "sent_user_count": 1000,
        "skip_user_count": 0,
        "alive_time": 2600,
        "date": "2022-11-09 17:11"
    },
    {
        "action": "Resend",
        "parent_id": 100,
        "mode": "flipshope extension",
        "title": 'Aliexpress sale',
        "link": "http://www.localhost:5000",
        "description": "Get best deals on your favorite products",
        "image_link": "http://www.localhost:5000",
        "sent_user_count": 1000,
        "skip_user_count": 0,
        "alive_time": 2600,
        "date": "2022-11-09 17:11"
    },
    {
        "action": "Resend",
        "parent_id": 100,
        "mode": "flipshope extension",
        "title": 'Aliexpress sale',
        "link": "http://www.localhost:5000",
        "description": "Get best deals on your favorite products",
        "image_link": "http://www.localhost:5000",
        "sent_user_count": 1000,
        "skip_user_count": 0,
        "alive_time": 2600,
        "date": "2022-11-09 17:11"
    },
    {
        "action": "Resend",
        "parent_id": 100,
        "mode": "flipshope extension",
        "title": 'Aliexpress sale',
        "link": "http://www.localhost:5000",
        "description": "Get best deals on your favorite products",
        "image_link": "http://www.localhost:5000",
        "sent_user_count": 1000,
        "skip_user_count": 0,
        "alive_time": 2600,
        "date": "2022-11-09 17:11"
    },
    {
        "action": "Resend",
        "parent_id": 100,
        "mode": "flipshope extension",
        "title": 'Aliexpress sale',
        "link": "http://www.localhost:5000",
        "description": "Get best deals on your favorite products",
        "image_link": "http://www.localhost:5000",
        "sent_user_count": 1000,
        "skip_user_count": 0,
        "alive_time": 2600,
        "date": "2022-11-09 17:11"
    },
    {
        "action": "Resend",
        "parent_id": 100,
        "mode": "flipshope extension",
        "title": 'Aliexpress sale',
        "link": "http://www.localhost:5000",
        "description": "Get best deals on your favorite products",
        "image_link": "http://www.localhost:5000",
        "sent_user_count": 1000,
        "skip_user_count": 0,
        "alive_time": 2600,
        "date": "2022-11-09 17:11"
    },
    {
        "action": "Resend",
        "parent_id": 100,
        "mode": "flipshope extension",
        "title": 'Aliexpress sale',
        "link": "http://www.localhost:5000",
        "description": "Get best deals on your favorite products",
        "image_link": "http://www.localhost:5000",
        "sent_user_count": 1000,
        "skip_user_count": 0,
        "alive_time": 2600,
        "date": "2022-11-09 17:11"
    },
    {
        "action": "Resend",
        "parent_id": 100,
        "mode": "flipshope extension",
        "title": 'Aliexpress sale',
        "link": "http://www.localhost:5000",
        "description": "Get best deals on your favorite products",
        "image_link": "http://www.localhost:5000",
        "sent_user_count": 1000,
        "skip_user_count": 0,
        "alive_time": 2600,
        "date": "2022-11-09 17:11"
    },
    {
        "action": "Resend",
        "parent_id": 100,
        "mode": "flipshope extension",
        "title": 'Aliexpress sale',
        "link": "http://www.localhost:5000",
        "description": "Get best deals on your favorite products",
        "image_link": "http://www.localhost:5000",
        "sent_user_count": 1000,
        "skip_user_count": 0,
        "alive_time": 2600,
        "date": "2022-11-09 17:11"
    },
    {
        "action": "Resend",
        "parent_id": 100,
        "mode": "flipshope extension",
        "title": 'Aliexpress sale',
        "link": "http://www.localhost:5000",
        "description": "Get best deals on your favorite products",
        "image_link": "http://www.localhost:5000",
        "sent_user_count": 1000,
        "skip_user_count": 0,
        "alive_time": 2600,
        "date": "2022-11-09 17:11"
    },
    {
        "action": "Resend",
        "parent_id": 100,
        "mode": "flipshope extension",
        "title": 'Aliexpress sale',
        "link": "http://www.localhost:5000",
        "description": "Get best deals on your favorite products",
        "image_link": "http://www.localhost:5000",
        "sent_user_count": 1000,
        "skip_user_count": 0,
        "alive_time": 2600,
        "date": "2022-11-09 17:11"
    },
    {
        "action": "Resend",
        "parent_id": 100,
        "mode": "flipshope extension",
        "title": 'Aliexpress sale',
        "link": "http://www.localhost:5000",
        "description": "Get best deals on your favorite products",
        "image_link": "http://www.localhost:5000",
        "sent_user_count": 1000,
        "skip_user_count": 0,
        "alive_time": 2600,
        "date": "2022-11-09 17:11"
    }, {
        "action": "Resend",
        "parent_id": 100,
        "mode": "flipshope extension",
        "title": 'Aliexpress sale',
        "link": "http://www.localhost:5000",
        "description": "Get best deals on your favorite products",
        "image_link": "http://www.localhost:5000",
        "sent_user_count": 1000,
        "skip_user_count": 0,
        "alive_time": 2600,
        "date": "2022-11-09 17:11"
    },
    {
        "action": "Resend",
        "parent_id": 100,
        "mode": "flipshope extension",
        "title": 'Aliexpress sale',
        "link": "http://www.localhost:5000",
        "description": "Get best deals on your favorite products",
        "image_link": "http://www.localhost:5000",
        "sent_user_count": 1000,
        "skip_user_count": 0,
        "alive_time": 2600,
        "date": "2022-11-09 17:11"
    }, {
        "action": "Resend",
        "parent_id": 100,
        "mode": "flipshope extension",
        "title": 'Aliexpress sale',
        "link": "http://www.localhost:5000",
        "description": "Get best deals on your favorite products",
        "image_link": "http://www.localhost:5000",
        "sent_user_count": 1000,
        "skip_user_count": 0,
        "alive_time": 2600,
        "date": "2022-11-09 17:11"
    }, {
        "action": "Resend",
        "parent_id": 100,
        "mode": "flipshope extension",
        "title": 'Aliexpress sale',
        "link": "http://www.localhost:5000",
        "description": "Get best deals on your favorite products",
        "image_link": "http://www.localhost:5000",
        "sent_user_count": 1000,
        "skip_user_count": 0,
        "alive_time": 2600,
        "date": "2022-11-09 17:11"
    }, {
        "action": "Resend",
        "parent_id": 100,
        "mode": "flipshope extension",
        "title": 'Aliexpress sale',
        "link": "http://www.localhost:5000",
        "description": "Get best deals on your favorite products",
        "image_link": "http://www.localhost:5000",
        "sent_user_count": 1000,
        "skip_user_count": 0,
        "alive_time": 2600,
        "date": "2022-11-09 17:11"
    }, {
        "action": "Resend",
        "parent_id": 100,
        "mode": "flipshope extension",
        "title": 'Aliexpress sale',
        "link": "http://www.localhost:5000",
        "description": "Get best deals on your favorite products",
        "image_link": "http://www.localhost:5000",
        "sent_user_count": 1000,
        "skip_user_count": 0,
        "alive_time": 2600,
        "date": "2022-11-09 17:11"
    }, {
        "action": "Resend",
        "parent_id": 100,
        "mode": "flipshope extension",
        "title": 'Aliexpress sale',
        "link": "http://www.localhost:5000",
        "description": "Get best deals on your favorite products",
        "image_link": "http://www.localhost:5000",
        "sent_user_count": 1000,
        "skip_user_count": 0,
        "alive_time": 2600,
        "date": "2022-11-09 17:11"
    }, {
        "action": "Resend",
        "parent_id": 100,
        "mode": "flipshope extension",
        "title": 'Aliexpress sale',
        "link": "http://www.localhost:5000",
        "description": "Get best deals on your favorite products",
        "image_link": "http://www.localhost:5000",
        "sent_user_count": 1000,
        "skip_user_count": 0,
        "alive_time": 2600,
        "date": "2022-11-09 17:11"
    }, {
        "action": "Resend",
        "parent_id": 100,
        "mode": "flipshope extension",
        "title": 'Aliexpress sale',
        "link": "http://www.localhost:5000",
        "description": "Get best deals on your favorite products",
        "image_link": "http://www.localhost:5000",
        "sent_user_count": 1000,
        "skip_user_count": 0,
        "alive_time": 2600,
        "date": "2022-11-09 17:11"
    }, {
        "action": "Resend",
        "parent_id": 100,
        "mode": "flipshope extension",
        "title": 'Aliexpress sale',
        "link": "http://www.localhost:5000",
        "description": "Get best deals on your favorite products",
        "image_link": "http://www.localhost:5000",
        "sent_user_count": 1000,
        "skip_user_count": 0,
        "alive_time": 2600,
        "date": "2022-11-09 17:11"
    }, {
        "action": "Resend",
        "parent_id": 100,
        "mode": "flipshope extension",
        "title": 'Aliexpress sale',
        "link": "http://www.localhost:5000",
        "description": "Get best deals on your favorite products",
        "image_link": "http://www.localhost:5000",
        "sent_user_count": 1000,
        "skip_user_count": 0,
        "alive_time": 2600,
        "date": "2022-11-09 17:11"
    }, {
        "action": "Resend",
        "parent_id": 100,
        "mode": "flipshope extension",
        "title": 'Aliexpress sale',
        "link": "http://www.localhost:5000",
        "description": "Get best deals on your favorite products",
        "image_link": "http://www.localhost:5000",
        "sent_user_count": 1000,
        "skip_user_count": 0,
        "alive_time": 2600,
        "date": "2022-11-09 17:11"
    }, {
        "action": "Resend",
        "parent_id": 100,
        "mode": "flipshope extension",
        "title": 'Aliexpress sale',
        "link": "http://www.localhost:5000",
        "description": "Get best deals on your favorite products",
        "image_link": "http://www.localhost:5000",
        "sent_user_count": 1000,
        "skip_user_count": 0,
        "alive_time": 2600,
        "date": "2022-11-09 17:11"
    }, {
        "action": "Resend",
        "parent_id": 100,
        "mode": "flipshope extension",
        "title": 'Aliexpress sale',
        "link": "http://www.localhost:5000",
        "description": "Get best deals on your favorite products",
        "image_link": "http://www.localhost:5000",
        "sent_user_count": 1000,
        "skip_user_count": 0,
        "alive_time": 2600,
        "date": "2022-11-09 17:11"
    }, {
        "action": "Resend",
        "parent_id": 100,
        "mode": "flipshope extension",
        "title": 'Aliexpress sale',
        "link": "http://www.localhost:5000",
        "description": "Get best deals on your favorite products",
        "image_link": "http://www.localhost:5000",
        "sent_user_count": 1000,
        "skip_user_count": 0,
        "alive_time": 2600,
        "date": "2022-11-09 17:11"
    }, {
        "action": "Resend",
        "parent_id": 100,
        "mode": "flipshope extension",
        "title": 'Aliexpress sale',
        "link": "http://www.localhost:5000",
        "description": "Get best deals on your favorite products",
        "image_link": "http://www.localhost:5000",
        "sent_user_count": 1000,
        "skip_user_count": 0,
        "alive_time": 2600,
        "date": "2022-11-09 17:11"
    }, {
        "action": "Resend",
        "parent_id": 100,
        "mode": "flipshope extension",
        "title": 'Aliexpress sale',
        "link": "http://www.localhost:5000",
        "description": "Get best deals on your favorite products",
        "image_link": "http://www.localhost:5000",
        "sent_user_count": 1000,
        "skip_user_count": 0,
        "alive_time": 2600,
        "date": "2022-11-09 17:11"
    }, {
        "action": "Resend",
        "parent_id": 100,
        "mode": "flipshope extension",
        "title": 'Aliexpress sale',
        "link": "http://www.localhost:5000",
        "description": "Get best deals on your favorite products",
        "image_link": "http://www.localhost:5000",
        "sent_user_count": 1000,
        "skip_user_count": 0,
        "alive_time": 2600,
        "date": "2022-11-09 17:11"
    }, {
        "action": "Resend",
        "parent_id": 100,
        "mode": "flipshope extension",
        "title": 'Aliexpress sale',
        "link": "http://www.localhost:5000",
        "description": "Get best deals on your favorite products",
        "image_link": "http://www.localhost:5000",
        "sent_user_count": 1000,
        "skip_user_count": 0,
        "alive_time": 2600,
        "date": "2022-11-09 17:11"
    }, {
        "action": "Resend",
        "parent_id": 100,
        "mode": "flipshope extension",
        "title": 'Aliexpress sale',
        "link": "http://www.localhost:5000",
        "description": "Get best deals on your favorite products",
        "image_link": "http://www.localhost:5000",
        "sent_user_count": 1000,
        "skip_user_count": 0,
        "alive_time": 2600,
        "date": "2022-11-09 17:11"
    }, {
        "action": "Resend",
        "parent_id": 100,
        "mode": "flipshope extension",
        "title": 'Aliexpress sale',
        "link": "http://www.localhost:5000",
        "description": "Get best deals on your favorite products",
        "image_link": "http://www.localhost:5000",
        "sent_user_count": 1000,
        "skip_user_count": 0,
        "alive_time": 2600,
        "date": "2022-11-09 17:11"
    }, {
        "action": "Resend",
        "parent_id": 100,
        "mode": "flipshope extension",
        "title": 'Aliexpress sale',
        "link": "http://www.localhost:5000",
        "description": "Get best deals on your favorite products",
        "image_link": "http://www.localhost:5000",
        "sent_user_count": 1000,
        "skip_user_count": 0,
        "alive_time": 2600,
        "date": "2022-11-09 17:11"
    },
]
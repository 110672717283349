import { createAsyncThunk, createSlice, } from "@reduxjs/toolkit";
import { message } from "antd"
import * as couponsApi from "../api/couponsApi";

const initialState = {
    couponCountData: [],
    scrapCountData: [],
    empTrackTableData: [],
    allCouponsData: [],
    searchCouponsData: [],
    userCouponsData: [],
    scrapedCouponsData: [],
    expiredCouponsData: [],
    expiryAlertsData: [],
    loading: false,
    error: "",
    success: "",
};

//=================================couponCount=================================/
export const getCouponCountData = createAsyncThunk(
    "coupons/getcouponcount",
    async (_, { rejectWithValue }) => {
        try {
            const resp = await couponsApi.getCouponCount();
            if (resp.data.code === 200) {
                return resp.data.data;
            } else {
                return rejectWithValue(resp.data);
            }
        } catch (error) {
            rejectWithValue(error.resp.data)
        }
    }
);

//=================================scrapCount=================================/
export const getScrapCountData = createAsyncThunk(
    "coupons/getscrapcount",
    async (_, { rejectWithValue }) => {
        try {
            const resp = await couponsApi.getScrapCount();
            if (resp.data.code === 200) {
                return resp.data.data;
            } else {
                return rejectWithValue(resp.data);
            }
        } catch (error) {
            rejectWithValue(error.resp.data)
        }
    }
);

//=================================empTrackTable=================================/
export const employeeTrackTableData = createAsyncThunk(
    "coupons/emptracktable",
    async (_, { rejectWithValue }) => {
        try {
            const resp = await couponsApi.empTrackTable();
            if (resp.data.code === 200) {
                // message.success(resp.data.success ? resp.data.success : "Data Fetched")
                return resp.data.data;
            } else {
                return rejectWithValue(resp.data);
            }
        } catch (error) {
            rejectWithValue(error.resp.data);
        }
    }
);

//=================================getAllCoupons=================================/
export const getAllCouponsData = createAsyncThunk(
    "coupons/coupons",
    async (_, { rejectWithValue }) => {
        try {
            const resp = await couponsApi.getAllCoupons();
            if (resp.data.code === 200) {
                return resp.data.data;
            } else {
                return rejectWithValue(resp.data);
            }
        } catch (error) {
            rejectWithValue(error.resp.data);
        }
    }
);

//=================================addNewCoupon=================================/
export const addNewCoupon = createAsyncThunk(
    "coupons/addcoupon",
    async (formData, { rejectWithValue }) => {
        try {
            const resp = await couponsApi.addCoupon(formData);
            console.log(resp.data);
            if (resp.data.code === 200) {
                message.success(resp.data.success ? resp.data.success : "Coupon Inserted successfully")
                return resp.data.data;
            } else {
                message.error(resp.data.message ? resp.data.message : "Something went wrong")
                return rejectWithValue(resp.data);
            }
        } catch (error) {
            rejectWithValue(error.resp.data);
        }
    }
);

//=================================UpdateCoupon=================================/
export const updateCouponData = createAsyncThunk(
    "coupons/updatecoupon",

    async (formData, { rejectWithValue }) => {
        try {
            const resp = await couponsApi.updateCoupon(formData);
            console.log(resp.data);
            if (resp.data.code === 200) {
                message.success(resp.data.success ? resp.data.success : "Coupon Updated successfully")
                return resp.data.data;
            } else {
                message.error(resp.data.message ? resp.data.message : "Something went wrong")
                return rejectWithValue(resp.data);
            }
        } catch (error) {
            rejectWithValue(error.resp.data);
        }
    }
);

//=================================deleteCoupon=================================/
export const deleteCouponData = createAsyncThunk(
    "coupons/deletecoupon",
    async ({ formData, cbFunc }, { rejectedWithValue }) => {
        try {
            const resp = await couponsApi.deleteCoupon(formData);
            if (resp.data.code === 200) {
                message.success(resp.data.success ? resp.data.success : "Coupon deleted successfully");
                //data to be returned 
                cbFunc();
                return resp.data;
            }
            else {
                message.error(resp.data.message ? resp.data.message : "Coupon can't be deleted");
                cbFunc();
                return rejectedWithValue(resp.data);
            }
        }
        catch (error) {
            rejectedWithValue(error.resp.data);
        }
    }
);

//=================================searchCoupons=================================/
export const searchCouponsData = createAsyncThunk(
    "coupons/search",
    async (formData, { rejectWithValue }) => {
        try {
            const resp = await couponsApi.searchCoupons(formData);
            if (resp.data.code === 200) {
                if (resp.data.data.length === 0) {
                    message.info("No Data found");
                } else {
                    message.success(resp.success ? resp.success : "Successful");
                }
                return resp.data.data;
            } else {
                return rejectWithValue(resp.data);
            }
        } catch (error) {
            rejectWithValue(error.resp.data);
        }
    }
);

//=================================getUserCoupons=================================/
export const getUserCouponsData = createAsyncThunk(
    "coupons/getusercoupons",
    async (_, { rejectWithValue }) => {
        try {
            const resp = await couponsApi.getUserCoupons();
            if (resp.data.code === 200) {
                return resp.data.data;
            } else {
                return rejectWithValue(resp.data);
            }
        } catch (error) {
            rejectWithValue(error.resp.data);
        }
    }
);

//=================================getScrapedCoupons=================================/
export const getScrapedCouponsData = createAsyncThunk(
    "coupons/scrapedcoupons",
    async (_, { rejectWithValue }) => {
        try {
            const resp = await couponsApi.getScrapedCoupons();
            if (resp.data.code === 200) {
                return resp.data.data;
            } else {
                return rejectWithValue(resp.data);
            }
        } catch (error) {
            rejectWithValue(error.resp.data);
        }
    }
);

//=================================addScrapCoupon=================================/
export const addScrapCouponData = createAsyncThunk(
    "coupons/addscrapcoupon",
    async (formData, { rejectedWithValue }) => {
        try {
            const resp = await couponsApi.addScrapCoupon(formData);
            if (resp.data.code === 200) {
                message.success(resp.data.success ? resp.data.success : "Scraped Coupon added successfully");
                return resp.data;
            }
            else {
                message.error(resp.data.message ? resp.data.message : "Coupon can't be added");
                return rejectedWithValue(resp.data);
            }
        }
        catch (error) {
            rejectedWithValue(error.resp.data);
        }
    }
);

//=================================deleteScrapCoupon=================================/
export const deleteScrapCouponData = createAsyncThunk(
    "coupons/deletescrapcoupon",
    async ({ formData, cbFunc }, { rejectedWithValue }) => {
        try {
            const resp = await couponsApi.deleteScrapCoupon(formData);
            if (resp.data.code === 200) {
                message.success(resp.data.success ? resp.data.success : "Coupon deleted successfully");
                //data to be returned 
                cbFunc();
                return resp.data;
            }
            else {
                message.error(resp.data.message ? resp.data.message : "Coupon can't be deleted");
                cbFunc();
                return rejectedWithValue(resp.data);
            }
        }
        catch (error) {
            rejectedWithValue(error.resp.data);
        }
    }
);

//=================================getExpiredCoupons=================================//
export const getExpiredCouponsData = createAsyncThunk(
    "coupons/expiredcoupons",
    async (_, { rejectWithValue }) => {
        try {
            const resp = await couponsApi.getExpiredCoupons();
            if (resp.data.code === 200) {
                return resp.data.data;
            } else {
                return rejectWithValue(resp.data);
            }
        } catch (error) {
            rejectWithValue(error.resp.data);
        }
    }
);

//=================================getExpiryAlert=================================//
export const getExpiryAlertsData = createAsyncThunk(
    "coupons/alertcoupons",
    async (_, { rejectWithValue }) => {
        try {
            const resp = await couponsApi.getExpiryAlerts();
            if (resp.data.code === 200) {
                return resp.data.data;
            } else {
                return rejectWithValue(resp.data);
            }
        } catch (error) {
            rejectWithValue(error.resp.data);
        }
    }
);

//=================================AssignSite=================================//
export const assignSite = createAsyncThunk(
    "coupons/assignsite",
    async (formData, { rejectWithValue }) => {
        try {
            const resp = await couponsApi.assignSiteToUser(formData);
            if (resp.data.code === 200) {
                message.success(resp.data.success ? resp.data.success : "Sites assigned successfully")
                return resp.data.data;
            } else {
                message.error(resp.data.error ? resp.data.error : "Something went wrong")
                return rejectWithValue(resp.data);
            }
        } catch (error) {
            rejectWithValue(error.resp.data);
        }
    }
);

//=================================CouponSlice=================================//
const couponsSlice = createSlice({
    name: "coupons",
    initialState,

    extraReducers: (builder) => {
        //=================================couponCountData=================================//
        builder.addCase(getCouponCountData.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getCouponCountData.fulfilled, (state, action) => {
            state.couponCountData = action.payload;
            state.loading = false;
        });
        builder.addCase(getCouponCountData.rejected, (state, action) => {
            state.error = action.payload;
        });

        //=================================scrapCountData=================================//
        builder.addCase(getScrapCountData.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getScrapCountData.fulfilled, (state, action) => {
            state.scrapCountData = action.payload;
            state.loading = false;
        });
        builder.addCase(getScrapCountData.rejected, (state, action) => {
            state.error = action.payload;
        });

        //=================================EmpTrackTableData=================================//
        builder.addCase(employeeTrackTableData.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(employeeTrackTableData.fulfilled, (state, action) => {
            state.empTrackTableData = action.payload;
            state.loading = false;
        });
        builder.addCase(employeeTrackTableData.rejected, (state, action) => {
            state.error = action.payload;
        });

        //=================================AllCouponsData=================================//
        builder.addCase(getAllCouponsData.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getAllCouponsData.fulfilled, (state, action) => {
            state.allCouponsData = action.payload;
            state.loading = false;
        });
        builder.addCase(getAllCouponsData.rejected, (state, action) => {
            state.error = action.payload;
        });

        //=================================AddNewCoupon=================================//
        builder.addCase(addNewCoupon.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(addNewCoupon.fulfilled, (state, action) => {
            state.loading = false;
        });
        builder.addCase(addNewCoupon.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

        //=================================AddNewCoupon=================================//
        builder.addCase(updateCouponData.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(updateCouponData.fulfilled, (state, action) => {
            state.loading = false;
            state.success = action.payload;
        });
        builder.addCase(updateCouponData.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

        //=================================DeleteCoupon=================================//
        builder.addCase(deleteCouponData.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(deleteCouponData.fulfilled, (state, action) => {
            state.loading = false;
            state.success = action.payload;
        });
        builder.addCase(deleteCouponData.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

        //=================================SearchCouponsData=================================//
        builder.addCase(searchCouponsData.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(searchCouponsData.fulfilled, (state, action) => {
            state.searchCouponsData = action.payload;
            state.loading = false;
        });
        builder.addCase(searchCouponsData.rejected, (state, action) => {
            state.error = action.payload;
        });

        //=================================UserCouponsData=================================//
        builder.addCase(getUserCouponsData.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getUserCouponsData.fulfilled, (state, action) => {
            state.userCouponsData = action.payload;
            state.loading = false;
        });
        builder.addCase(getUserCouponsData.rejected, (state, action) => {
            state.error = action.payload;
        });

        //=================================ScrapedCouponsData=================================//
        builder.addCase(getScrapedCouponsData.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getScrapedCouponsData.fulfilled, (state, action) => {
            state.scrapedCouponsData = action.payload;
            state.loading = false;
        });
        builder.addCase(getScrapedCouponsData.rejected, (state, action) => {
            state.error = action.payload;
        });

        //=================================AddScrapCoupon=================================//
        builder.addCase(addScrapCouponData.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(addScrapCouponData.fulfilled, (state, action) => {
            state.loading = false;
            state.success = action.payload;
        });
        builder.addCase(addScrapCouponData.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

        //=================================DeleteScrapCoupon=================================//
        builder.addCase(deleteScrapCouponData.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(deleteScrapCouponData.fulfilled, (state, action) => {
            state.loading = false;
            state.success = action.payload;
        });
        builder.addCase(deleteScrapCouponData.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

        //=================================ExpiredCouponsData=================================//
        builder.addCase(getExpiredCouponsData.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getExpiredCouponsData.fulfilled, (state, action) => {
            state.expiredCouponsData = action.payload;
            state.loading = false;
        });
        builder.addCase(getExpiredCouponsData.rejected, (state, action) => {
            state.error = action.payload;
        });

        //=================================ExpiryAlertData=================================//
        builder.addCase(getExpiryAlertsData.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getExpiryAlertsData.fulfilled, (state, action) => {
            state.expiryAlertsData = action.payload;
            state.loading = false;
        });
        builder.addCase(getExpiryAlertsData.rejected, (state, action) => {
            state.error = action.payload;
        });

        //=================================AssignSiteToUser=================================//
        builder.addCase(assignSite.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(assignSite.fulfilled, (state, action) => {
            state.loading = false;
        });
        builder.addCase(assignSite.rejected, (state, action) => {
            state.error = action.payload;
        });
    },
})



export default couponsSlice.reducer;
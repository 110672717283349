/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    LinearProgress,
    Paper,
    Stack,
    Toolbar,
    Typography,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import { useDispatch, useSelector } from 'react-redux'
import { getAllPanelUsersData, updatePanelUserData } from '../../redux/features/authSlice';


// Styled Box
const StyledBox = styled((props) => (
    <Box {...props} />
))(({ theme }) => ({
    padding: "2px 8px",
    borderRadius: "5px",
    height: "fit-content",
    '& *': {
        display: "inline-block",
    },
    '& .MuiTypography-root:first-of-type': {
        color: 'var(--primary-color)',
        opacity: '0.8'
    },
    '& .MuiTypography-root:nth-of-type(2)': {
        color: 'var(--bs-gray-700)'
    },
}));

function Approve() {
    const dispatch = useDispatch()
    // user data
    const { allPanelUsers, loading } = useSelector(state => state.userAuth)

    const validateUser = (data) => {
        let formData = { ...data }
        formData.approved = 1;
        formData.active = 1;
        // console.table(formData)
        dispatch(updatePanelUserData({ formData, cbFunc }))
    }

    const cbFunc = () => dispatch(getAllPanelUsersData())

    useEffect(() => {
        cbFunc()
    }, [])
    return (
        <Paper elevation={3}>
            <Stack spacing={3} direction="column">
                <Toolbar
                    mt={3}
                    sx={{ padding: "1.2rem", borderBottom: "2px solid var(--primary-color)" }}
                >
                    <Typography
                        sx={{
                            flex: "1 1 100%",
                            color: "var(--primary-color)",
                            fontWeight: "bold",
                        }}
                        variant="h5"
                        id="tableTitle"
                    >
                        Approvals
                    </Typography>
                </Toolbar>
                {loading ? <LinearProgress />
                    : <Box p={4} pt={1} className='d-grid' sx={{ gridTemplateColumns: 'repeat(auto-fill,minmax(371px,1fr))', gap: '24px' }}>
                        {allPanelUsers && allPanelUsers.map(user => {
                            if (!user.approved || !user.active) {
                                return (
                                    <Card key={user.email} sx={{ display: 'flex', flexDirection: 'column', minWidth: "fit-content", border: '2px solid var(--bs-border-color)' }}>
                                        <CardContent sx={{ flex: "1 1 100%" }}>
                                            <Stack spacing={1}>
                                                <StyledBox>
                                                    <Typography variant="h6">
                                                        <b>Name :&nbsp;</b>
                                                    </Typography>
                                                    <Typography variant="h6" textTransform='capitalize'>
                                                        <b>{user.name}</b>
                                                    </Typography>
                                                </StyledBox>
                                                <StyledBox>
                                                    <Typography variant="h6">
                                                        <b>Email :&nbsp;</b>
                                                    </Typography>
                                                    <Typography variant="h6">
                                                        <b>{user.email}</b>
                                                    </Typography>
                                                </StyledBox>
                                                <StyledBox>
                                                    <Typography variant="h6">
                                                        <b>Role :&nbsp;</b>
                                                    </Typography>
                                                    <Typography variant="h6" textTransform="capitalize">
                                                        <b>{user.role}</b>
                                                    </Typography>
                                                </StyledBox>
                                                <StyledBox>
                                                    <Typography variant="h6">
                                                        <b>Created on :&nbsp;</b>
                                                    </Typography>
                                                    <Typography variant="h6">
                                                        <b>{user.created}</b>
                                                    </Typography>
                                                </StyledBox>
                                                <StyledBox>
                                                    <Typography variant="h6">
                                                        <b>Status :&nbsp;</b>
                                                    </Typography>
                                                    <Typography variant="h6" textTransform="capitalize"
                                                        sx={{
                                                            color: user.active === 1 ? "var(--bs-green) !important" : "var(--bs-red) !important"
                                                        }}
                                                    >
                                                        <b>{user.active === 1 ? "Active" : "Not Active"}</b>
                                                    </Typography>
                                                </StyledBox>
                                            </Stack>
                                        </CardContent>
                                        <CardActions sx={{ justifyContent: 'center', padding: '1rem' }}>
                                            <Button
                                                variant="contained"
                                                size="large"
                                                fullWidth
                                                sx={{
                                                    bgcolor: 'var(--bs-gray-dark)',
                                                    '&:hover': {
                                                        bgcolor: 'var(--bs-gray-900)',
                                                    },
                                                }}
                                                onClick={() => validateUser(user)}
                                            >
                                                <b>Validate</b>
                                            </Button>
                                        </CardActions>
                                    </Card>
                                )
                            }
                        })}
                    </Box>}
            </Stack>
        </Paper>
    )
}

export default Approve
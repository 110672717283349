/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Button, LinearProgress } from "@mui/material";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import AddBoxIcon from "@mui/icons-material/AddBox";
import { useDispatch, useSelector } from "react-redux";
import Datatable from "../Datatable/Datatable";
import { NavLink } from "react-router-dom";
import {
  activeJobPosting,
  getJobPostingData,
} from "../../redux/features/websiteSlices/jobPostingSlice";
import dateConverter from "../../basicFunctions/dateConverter";

import Tooltip from '@mui/material/Tooltip';


function ManageJobPostings() {
  const dispatch = useDispatch();
  const { loading, jobPostData } = useSelector((state) => state.jobPost);

  const columns = [
    {
      name: "id",
      label: "Action",
      options: {
        customBodyRender: (id) => {
          let jobData = jobPostData.filter((row) => row.id === id)[0];
          return (
            <NavLink to="/job-posting/add-post" state={{ jobData }}>
              <Button
                color="info"
                variant="contained"
                size="small"
                startIcon={<EditRoundedIcon />}
              >
                <strong>Edit</strong>
              </Button>
            </NavLink>
          );
        },
        filter: true,
        sort: true,
      },
    },
    {
      name: "job_name",
      label: "Job Name",
      options: {
        filter: true,
        sort: true,
        setCellHeaderProps:()=>({
          style: {
            position: 'sticky',
            left: 0,
            background: 'white',
            zIndex: 999,
          }
        }),
        setCellProps: () => ({
          style: {
            fontWeight: 'bold',
            position: 'sticky',
            left: 0,
            background: 'white',
            zIndex: 101,
          }
        }),
      },
    },
    {
      name: "company_name",
      label: "Company name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "title",
      label: "Job Title",
      options: {
        filter: true,
        sort: true,
        setCellHeaderProps:()=>({
          style: {
            position: 'sticky',
            left: '130px', // You may need to adjust this value based on the actual width of the first column
            background: 'white',
            zIndex: 999,
          }
        }),
        setCellProps: () => ({
          style: {
            fontWeight: 'bold',
            position: 'sticky',
            left: '130px', // You may need to adjust this value based on the actual width of the first column
            background: 'white',
            zIndex: 100,
          }
        }),
      },
    },
    {
      name: "description",
      label: "Job Description",
      options: {
        customBodyRender: (desc) => {
          return (
            <Tooltip title={desc}>
              <p
                style={{
                  margin: 0,
                  width: "300px",
                  display: "-webkit-box",
                  WebkitLineClamp: 3,
                  WebkitBoxOrient: "vertical",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "pre-wrap" // This ensures that line breaks in the description are respected
                }}
              >
                {desc}
              </p>
            </Tooltip>
          );
        },
        filter: true,
        sort: true,
      },
    },
    {
      name: "job_type",
      label: "Job Type",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "experience",
      label: "Experience Required",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "education_qualification",
      label: "Education Qualification",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "job_responsibility",
      label: "Job Responsibility",
      options: {
        customBodyRender: (responsibilities) => {
          return (
            <Tooltip title={responsibilities}>
            <p
            style={{
              margin: 0,
              width: "300px",
              display: "-webkit-box",
              WebkitLineClamp: 3,
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "pre-wrap" // This ensures that line breaks in the description are respected
            }}
            >
              {responsibilities}
            </p>
            </Tooltip>
          );
        },
        filter: true,
        sort: true,
      },
    },
    {
      name: "skills",
      label: "Skills Required",
      options: {
        customBodyRender: (skills) => {
          return (
            <Tooltip title={skills}>
            <p
            style={{
              margin: 0,
              width: "300px",
              display: "-webkit-box",
              WebkitLineClamp: 3,
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "pre-wrap"
            }}
            >
              {skills}
            </p>
            </Tooltip>
          );
        },
        filter: true,
        sort: true,
      },
    },
    {
      name: "attributes",
      label: "Candidate Attributes",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "location",
      label: "Location",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "workplace_type",
      label: "Workplace Type",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "link",
      label: "Google form link",
      options: {
        customBodyRender: (link) => {
          return (
            <a href={link} target="_blank" rel="noreferrer">
              {link}
            </a>
          );
        },
        filter: true,
        sort: true,
      },
    },
    {
      name: "deadline_date",
      label: "Deadline",
      options: {
        customBodyRender: (date) => {
          return <span>{dateConverter(date)}</span>;
        },
        filter: true,
        sort: true,
      },
    },
    {
      name: "active",
      label: "Activation",
      options: {
        customBodyRender: (active, tableMeta) => {
          let data = {};
          data.id = tableMeta.rowData[0];
          data.active = active == 1 ? "0" : "1";
          if (active == 1)
            return (
              <Button
                variant="outlined"
                color="error"
                size="small"
                onClick={() => handleJobPostActivation(data)}
              >
                <strong> Deactivate</strong>
              </Button>
            );
          else
            return (
              <Button
                variant="outlined"
                color="success"
                size="small"
                onClick={() => handleJobPostActivation(data)}
              >
                <strong>Activate</strong>
              </Button>
            );
        },
        filter: true,
        sort: true,
      },
    },
  ];

  const handleJobPostActivation = (data) => {
    dispatch(activeJobPosting(data));
    dispatch(getJobPostingData());
  };

  useEffect(() => {
    dispatch(getJobPostingData());
  }, []);

  return (
    <div>
      <NavLink to="/job-posting/add-post">
        <Button
          variant="contained"
          color="success"
          startIcon={<AddBoxIcon />}
          sx={{ marginBottom: "15px" }}
        >
          <strong>Add New Job Post</strong>
        </Button>
      </NavLink>
      {loading && <LinearProgress />}
      <Datatable
        name="Job Postings"
        columns={columns}
        data={jobPostData && jobPostData}
      />
    </div>
  );
}

export default ManageJobPostings;

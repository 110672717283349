/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Table,
  TableCell,
  TableRow,
  TextField,
  tableCellClasses,
  Button,
  TableBody,
  Paper,
  Stack,
  Typography,
  Toolbar,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Autocomplete,
} from "@mui/material";

import SaveIcon from "@mui/icons-material/Save";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import ReplyIcon from "@mui/icons-material/Reply";
import { useDispatch, useSelector } from "react-redux";
import { getAllStoresData } from "../../../redux/features/storesSlice";
import { addStoresForAutoApplyCoupon } from "../../../redux/features/storesSlice";

function AddAacStore() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { allStoresData } = useSelector((state) => ({ ...state.stores }));
  const [storeAacData, setStoreAacData] = useState({
    id: state ? state.data.id : null,
    site: state ? state.data.site : null,
    merchant: state ? state.data.merchant : "",
    site_id: state ? state.data.site_id : "",
    subsite: state ? state.data.subsite : "",
    done: state ? state.data.done : 1,
    aac: state ? state.data.aac : 1,
    checked: state ? state.data.checked : 0,
    note: state ? state.data.note : "",
    assigned_to: state ? state.data.assigned_to : null,
    coupon_url_match: state ? state.data.coupon_url_match : "",
    pns_siteSelCartInitCode: state ? state.data.pns_siteSelCartInitCode : "",
    order_url_match: state ? state.data.order_url_match : "",
    pns_sitePreApplyCodeAction: state
      ? state.data.pns_sitePreApplyCodeAction
      : "",
    pns_siteRemoveCodeAction: state ? state.data.pns_siteRemoveCodeAction : "",
    pns_siteBreakAfterRemove: state ? state.data.pns_siteBreakAfterRemove : "",
    pns_siteTimeBetweenPreApply: state
      ? state.data.pns_siteTimeBetweenPreApply
      : "",
    pns_siteSelCartCodeBox: state ? state.data.pns_siteSelCartCodeBox : "",
    pns_siteSelCartCodeSubmit: state
      ? state.data.pns_siteSelCartCodeSubmit
      : null,
    pns_siteTimeBetweenRemove: state
      ? state.data.pns_siteTimeBetweenRemove
      : "",
    pns_siteSelCartTotalPrice: state
      ? state.data.pns_siteSelCartTotalPrice
      : "",
    pns_siteSelApplyButton: state ? state.data.pns_siteSelApplyButton : "",
    pns_currency: state ? state.data.pns_currency : "",
    active: state ? state.data.active : 1,
  });

  const handleStore = (val) => {
    for (let i = 0; i < allStoresData.length; i++) {
      if (allStoresData[i].store_name === val)
        setStoreAacData({
          ...storeAacData,
          site_id: allStoresData[i].store_id,
          merchant: allStoresData[i].store_name,
        });
    }
  };

  const handleChange = (e) => {
    setStoreAacData({ ...storeAacData, [e.target.name]: e.target.value });
  };
  //   console.log(storeAacData, "storeAacData");
  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(addStoresForAutoApplyCoupon(storeAacData));
    navigate("/stores/aac-stores");
  };

  useEffect(() => {
    dispatch(getAllStoresData());
  }, []);

  return (
    <Paper elevation={3}>
      <form onSubmit={handleSubmit}>
        <Stack spacing={3} direction="column">
          <Toolbar
            mt={3}
            sx={{
              padding: "1.2rem",
              display: "flex",
              borderBottom: "2px solid var(--primary-color)",
            }}
          >
            <Typography
              sx={{
                color: "var(--primary-color)",
                fontWeight: "bold",
                flex: "1 1 100%",
              }}
              variant="h5"
            >
              Add Store
            </Typography>
            <NavLink to="/stores/aac-stores">
              <Button
                variant="contained"
                color="info"
                sx={{ whiteSpace: "nowrap" }}
                startIcon={<ReplyIcon />}
              >
                <strong>Show Auto apply coupon Stores</strong>
              </Button>
            </NavLink>
            {/* <Button variant="contained" type="submit" color="info" startIcon={<SaveIcon />} sx={{ marginLeft: '15px' }}>
                            <strong>Save</strong>
                        </Button> */}
          </Toolbar>

          <Table
            size="small"
            sx={{
              width: "auto",
              margin: "8px !important",
              marginTop: "16px !important",
              [`& .${tableCellClasses.root}`]: {
                borderBottom: "none",
              },
            }}
          >
            <TableBody>
              <TableRow>
                <TableCell>
                  <Typography variant="h6">
                    <b>Merchant Name:</b>
                  </Typography>
                </TableCell>
                <TableCell>
                  <Autocomplete
                    // disablePortal
                    freeSolo
                    disableClearable
                    size="small"
                    options={
                      allStoresData
                        ? allStoresData.map((item) => item.store_name)
                        : []
                    }
                    value={storeAacData.merchant}
                    sx={{ width: 300 }}
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Select Store Name" />
                    )}
                    onChange={(e, val) => handleStore(val)}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography variant="h6">
                    <b>Site: </b>
                  </Typography>
                </TableCell>
                <TableCell>
                  <TextField
                    size="small"
                    fullWidth
                    name="site"
                    placeholder="Enter Site URL"
                    value={storeAacData.site}
                    onChange={handleChange}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography variant="h6">
                    <b>Subsite: </b>
                  </Typography>
                </TableCell>
                <TableCell>
                  <TextField
                    size="small"
                    fullWidth
                    name="subsite"
                    placeholder="Enter Store URL"
                    value={storeAacData.subsite}
                    onChange={handleChange}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography variant="h6">
                    <b>Done</b>
                  </Typography>
                </TableCell>
                <TableCell>
                  <FormControl>
                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="done"
                      value={storeAacData.done}
                      onChange={handleChange}
                      sx={{ display: "block !important" }}
                    >
                      <FormControlLabel
                        value={0}
                        control={<Radio />}
                        label="Not Done"
                      />
                      <FormControlLabel
                        value={1}
                        control={<Radio />}
                        label="Done"
                      />
                    </RadioGroup>
                  </FormControl>
                </TableCell>
              </TableRow>{" "}
              <TableRow>
                <TableCell>
                  <Typography variant="h6">
                    <b>aac </b>
                  </Typography>
                </TableCell>
                <TableCell>
                  <FormControl>
                    {/* <FormLabel id="demo-controlled-radio-buttons-group">Gender</FormLabel> */}
                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="aac"
                      value={storeAacData.aac}
                      onChange={handleChange}
                      sx={{ display: "block !important" }}
                    >
                      <FormControlLabel
                        value={0}
                        control={<Radio />}
                        label="no"
                      />
                      <FormControlLabel
                        value={1}
                        control={<Radio />}
                        label="yes"
                      />
                    </RadioGroup>
                  </FormControl>
                </TableCell>
              </TableRow>{" "}
              <TableRow>
                <TableCell>
                  <Typography variant="h6">
                    <b>Checked</b>
                  </Typography>
                </TableCell>
                <TableCell>
                  <FormControl>
                    {/* <FormLabel id="demo-controlled-radio-buttons-group">Gender</FormLabel> */}
                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="checked"
                      value={storeAacData.checked}
                      onChange={handleChange}
                      sx={{ display: "block !important" }}
                    >
                      <FormControlLabel
                        value={0}
                        control={<Radio />}
                        label="Not Checked"
                      />
                      <FormControlLabel
                        value={1}
                        control={<Radio />}
                        label="Checked"
                      />
                    </RadioGroup>
                  </FormControl>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography variant="h6">
                    <b>Note: </b>
                  </Typography>
                </TableCell>
                <TableCell>
                  <TextField
                    size="small"
                    fullWidth
                    name="note"
                    placeholder="Enter note"
                    value={storeAacData.note}
                    onChange={handleChange}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography variant="h6">
                    <b>Coupon Url Match: </b>
                  </Typography>
                </TableCell>
                <TableCell>
                  <TextField
                    size="small"
                    fullWidth
                    name="coupon_url_match"
                    placeholder="Enter Coupon Url Match"
                    value={storeAacData.coupon_url_match}
                    onChange={handleChange}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography variant="h6">
                    <b>pns_siteSelCartInitCode: </b>
                  </Typography>
                </TableCell>
                <TableCell>
                  <TextField
                    size="small"
                    fullWidth
                    name="pns_siteSelCartInitCode"
                    placeholder="Enter pns_siteSelCartInitCode"
                    value={storeAacData.pns_siteSelCartInitCode}
                    onChange={handleChange}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography variant="h6">
                    <b>order_url_match: </b>
                  </Typography>
                </TableCell>
                <TableCell>
                  <TextField
                    size="small"
                    fullWidth
                    name="order_url_match"
                    placeholder="Enter order_url_match"
                    value={storeAacData.order_url_match}
                    onChange={handleChange}
                  />
                </TableCell>
              </TableRow>{" "}
              <TableRow>
                <TableCell>
                  <Typography variant="h6">
                    <b>pns_sitePreApplyCodeAction: </b>
                  </Typography>
                </TableCell>
                <TableCell>
                  <TextField
                    size="small"
                    fullWidth
                    name="pns_sitePreApplyCodeAction"
                    placeholder="Enter pns_sitePreApplyCodeAction"
                    value={storeAacData.pns_sitePreApplyCodeAction}
                    onChange={handleChange}
                  />
                </TableCell>
              </TableRow>{" "}
              <TableRow>
                <TableCell>
                  <Typography variant="h6">
                    <b>pns_siteRemoveCodeAction: </b>
                  </Typography>
                </TableCell>
                <TableCell>
                  <TextField
                    size="small"
                    fullWidth
                    name="pns_siteRemoveCodeAction"
                    placeholder="Enter pns_siteRemoveCodeAction"
                    value={storeAacData.pns_siteRemoveCodeAction}
                    onChange={handleChange}
                  />
                </TableCell>
              </TableRow>{" "}
              <TableRow>
                <TableCell>
                  <Typography variant="h6">
                    <b>pns_siteBreakAfterRemove: </b>
                  </Typography>
                </TableCell>
                <TableCell>
                  <TextField
                    size="small"
                    fullWidth
                    name="pns_siteBreakAfterRemove"
                    placeholder="Enter pns_siteBreakAfterRemove"
                    value={storeAacData.pns_siteBreakAfterRemove}
                    onChange={handleChange}
                  />
                </TableCell>
              </TableRow>{" "}
              <TableRow>
                <TableCell>
                  <Typography variant="h6">
                    <b>pns_siteTimeBetweenPreApply: </b>
                  </Typography>
                </TableCell>
                <TableCell>
                  <TextField
                    size="small"
                    fullWidth
                    name="pns_siteTimeBetweenPreApply"
                    placeholder="Enter pns_siteTimeBetweenPreApply"
                    value={storeAacData.pns_siteTimeBetweenPreApply}
                    onChange={handleChange}
                  />
                </TableCell>
              </TableRow>{" "}
              <TableRow>
                <TableCell>
                  <Typography variant="h6">
                    <b>pns_siteSelCartCodeBox: </b>
                  </Typography>
                </TableCell>
                <TableCell>
                  <TextField
                    size="small"
                    fullWidth
                    name="pns_siteSelCartCodeBox"
                    placeholder="Enter pns_siteSelCartCodeBox"
                    value={storeAacData.pns_siteSelCartCodeBox}
                    onChange={handleChange}
                  />
                </TableCell>
              </TableRow>{" "}
              <TableRow>
                <TableCell>
                  <Typography variant="h6">
                    <b>pns_siteSelCartCodeSubmit: </b>
                  </Typography>
                </TableCell>
                <TableCell>
                  <TextField
                    size="small"
                    fullWidth
                    name="pns_siteSelCartCodeSubmit"
                    placeholder="Enter pns_siteSelCartCodeSubmit"
                    value={storeAacData.pns_siteSelCartCodeSubmit}
                    onChange={handleChange}
                  />
                </TableCell>
              </TableRow>{" "}
              <TableRow>
                <TableCell>
                  <Typography variant="h6">
                    <b>pns_siteTimeBetweenRemove: </b>
                  </Typography>
                </TableCell>
                <TableCell>
                  <TextField
                    size="small"
                    fullWidth
                    name="pns_siteTimeBetweenRemove"
                    placeholder="Enter pns_siteTimeBetweenRemove"
                    value={storeAacData.pns_siteTimeBetweenRemove}
                    onChange={handleChange}
                  />
                </TableCell>
              </TableRow>{" "}
              <TableRow>
                <TableCell>
                  <Typography variant="h6">
                    <b>pns_siteSelCartTotalPrice: </b>
                  </Typography>
                </TableCell>
                <TableCell>
                  <TextField
                    size="small"
                    fullWidth
                    name="pns_siteSelCartTotalPrice"
                    placeholder="Enter pns_siteSelCartTotalPrice"
                    value={storeAacData.pns_siteSelCartTotalPrice}
                    onChange={handleChange}
                  />
                </TableCell>
              </TableRow>{" "}
              <TableRow>
                <TableCell>
                  <Typography variant="h6">
                    <b>pns_siteSelApplyButton: </b>
                  </Typography>
                </TableCell>
                <TableCell>
                  <TextField
                    size="small"
                    fullWidth
                    name="pns_siteSelApplyButton"
                    placeholder="Enter pns_siteSelApplyButton"
                    value={storeAacData.pns_siteSelApplyButton}
                    onChange={handleChange}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography variant="h6">
                    <b>pns_currency: </b>
                  </Typography>
                </TableCell>
                <TableCell>
                  <TextField
                    size="small"
                    fullWidth
                    name="pns_currency"
                    placeholder="Enter pns_currency"
                    value={storeAacData.pns_currency}
                    onChange={handleChange}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography variant="h6">
                    <b>Active </b>
                  </Typography>
                </TableCell>
                <TableCell>
                  <FormControl>
                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="active"
                      value={storeAacData.active}
                      onChange={handleChange}
                      sx={{ display: "block !important" }}
                    >
                      <FormControlLabel
                        value={0}
                        control={<Radio />}
                        label="Deactvate "
                      />
                      <FormControlLabel
                        value={1}
                        control={<Radio />}
                        label="Activate"
                      />
                    </RadioGroup>
                  </FormControl>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={2} className="text-center">
                  <Button
                    variant="contained"
                    type="submit"
                    color="info"
                    startIcon={<SaveIcon />}
                    sx={{ marginLeft: "15px" }}
                    onClick={handleSubmit}
                  >
                    <strong>Save</strong>
                  </Button>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Stack>
      </form>
      {/* <div className="mt-3">
        <h3> Instruction for add store with examples</h3>
        <ul>
          <li>
            <strong>Store Name :</strong> eg.- (Flipkart,Amazon,croma,Pizza
            Hut....)
          </li>
          <li>
            <strong>Store Page Url :</strong> eg.-
            (flipkart,amazon,croma,pizzahut....)
          </li>
          <li>
            <strong> Store Url :</strong> eg.-
            (https://www.flipkart.com/,https://www.amazon.in/,https://www.croma.com/....)
          </li>
          <li>
            <strong> Store Site url for auto apply coupon :</strong> eg.-
            (flipkart.com,amazon.in,croma.com....)
          </li>
          <li>
            <strong> Store Affiliate Url :</strong> eg.-
            (https://track.omguk.com/?PID=12185&AID=2317160)
          </li>
          <li>
            <strong>Store Image Url :</strong> eg.- (https://myntra-logo.com)
          </li>
        </ul>
      </div> */}
    </Paper>
  );
}

export default AddAacStore;

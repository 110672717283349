/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import Datatable from '../../Datatable/Datatable';
import { Box, Button, ButtonGroup, Chip, Paper, Stack, Toolbar, Typography } from "@mui/material";
import { NavLink } from 'react-router-dom';
// import AddBoxIcon from '@mui/icons-material/AddBox';
import { Popconfirm } from 'antd';
import EditIcon from '@mui/icons-material/Edit';
import AddBoxIcon from '@mui/icons-material/AddBox';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import { useDispatch, useSelector } from "react-redux";
import { getStoreCategoryDataPanel, getAllCategoryPanel, activateDeactivateCategoryPanel } from '../../../redux/features/storesSlice';



const ManageCategory = () => {
  const dispatch = useDispatch();
  const { allStoreCatData, allcatData } = useSelector((state) => ({ ...state.stores }));

  // const handleStatus = (formData) => {
  //   dispatch(activateDeactivateStoreCategoryPanel(formData));
  //   if (formData.active == 0) message.success("Deactivated Successfully");
  //   else if (formData.active == 1) message.success("Activated Successfully");
  //   else message.error("Something went wrong!");
  //   dispatch(getStoreCategoryDataPanel());
  // };
  // const handleState = (data) =>{
  //   console.log(data, "data+++++++++++++++++++++");
  //   // dispatch(setStoreCategories(data));
  // }

  const StoreColumns = [
    {
      name: "operation",
      label: 'Operation',
      options: {
        customBodyRender: (id, tableMeta) => {
          const data = {
            store_id: tableMeta.rowData[1],
            store_name: tableMeta.rowData[2],
            category_names: tableMeta.rowData[3],
            category_ids: tableMeta.rowData[4]
          };
          // data.store_name = allStoreCatData.filter((row) => row.store_id === id)[0];


          return (
            <ButtonGroup orientation="vertical" variant="text" color="success">
              <NavLink to="/stores/assign-store-category" state={{ data, type: "assign_category" }}>
                <Button color="info" size="small" startIcon={<EditIcon />}>
                  <strong>Edit</strong>
                </Button>
              </NavLink>
              {/* <Popconfirm
                placement="right"
                title="Delete Store"
                description="Do you really want to delete the store?"
                okText="Yes"
                cancelText="No"
                onConfirm={() => console.log(id)}
              >
                <Button color="error">
                  <strong>Delete</strong>
                </Button>
              </Popconfirm> */}
            </ButtonGroup>
          )
        },
        filter: true,
        sort: true,
      }
    },
    {
      name: 'store_id',
      label: 'Store ID',
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: "store_name",
      label: "Store Name",
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: "category_names",
      label: "Category Names",
      options: {
        customBodyRender: (category_names) => {
          return (
            category_names.slice(1, category_names.length - 1)
          )
        },
        filter: true,
        sort: true,
      }
    },
    {
      name: "category_ids",
      label: "Category Id",
      options: {
        customBodyRender: (category_ids) => {
          return (
            category_ids.slice(1, category_ids.length - 1)
          )
        },
        filter: true,
        sort: true,
      }
    },
    // {
    //   name: 'active',
    //   label: 'Status',
    //   options: {
    //     customBodyRender: (active, tableMeta) => {
    //       var data = {};
    //       data.store_id = tableMeta.rowData[1];
    //       data.active = (active) ? "0" : "1";
    //       if (active)
    //         return <Popconfirm
    //           placement="topRight"
    //           title="Deactivate Store"
    //           description="Do you really want to deactivate the store?"
    //           okText="Yes"
    //           cancelText="No"
    //           onConfirm={() => handleStatus(data)}
    //         >
    //           <Button color='error' size="small" variant="outlined" ><b>Deactivate</b></Button>
    //         </Popconfirm>
    //       else
    //         return <Popconfirm
    //           placement="topRight"
    //           title="Activate Store"
    //           description="Do you really want to activate the store?"
    //           okText="Yes"
    //           cancelText="No"
    //           onConfirm={() => handleStatus(data)}
    //         >
    //           <Button color='success' size="small" variant="outlined" ><b>Activate</b></Button>
    //         </Popconfirm>
    //     },
    //     filter: true,
    //     sort: true,
    //   }
    // },
  ];

  // category deactivate
  const handleCategoryDelete = (cat_id, active) => {
    let data = {}
    data.cat_id = cat_id
    data.active = active
    console.log(data)
    dispatch(activateDeactivateCategoryPanel(data))
    dispatch(getAllCategoryPanel());
  }

  useEffect(() => {
    dispatch(getAllCategoryPanel());
    dispatch(getStoreCategoryDataPanel());
  }, [])

  return (
    <Box>
      <NavLink to="/stores/add-store-category">
        <Button variant="contained" color="success" startIcon={<AddBoxIcon />} sx={{ marginRight: "10px" }}>
          <strong>Add category</strong>
        </Button>
      </NavLink>

      <NavLink to="/stores/assign-store-category">
        <Button variant="contained" startIcon={<AddBusinessIcon />}
          sx={{
            backgroundColor: "var(--bs-success)",
            "&:hover": {
              backgroundColor: "var(--bs-success-rgb), 2)",
            }
          }}>
          <strong>Assign Store category</strong>
        </Button>
      </NavLink>

      {/* All categories */}
      <Paper elevation={2} sx={{ width: '100%', margin: "16px 0" }}>
        <Toolbar
          mt={3}
          sx={{ borderBottom: "2px solid var(--primary-color)" }}
        >
          <Typography
            sx={{
              flex: "1 1 100%",
              color: "var(--primary-color)",
              fontWeight: "bold",
            }}
            variant="h5"
            id="tableTitle"
          >
            All categories
          </Typography>
        </Toolbar>
        <Stack direction="row" sx={{ flexWrap: "wrap", gap: '12px', width: "100%", padding: "1.2rem" }}>
          {allcatData?.map(category =>
            category.active === 1 ?
              <Popconfirm
                key={`${category.cat_name}-${category.cat_id}`}
                placement="right"
                title="Deactivate category"
                okText="Yes"
                cancelText="No"
                onConfirm={() => handleCategoryDelete(category.cat_id, 0)}
              >
                <Chip
                  label={category.cat_name}
                  variant="outlined"
                  color="success"
                  // icon={<DeleteOutline />}
                  sx={{ whiteSpace: 'nowrap', fontWeight: 'bold' }}
                />
              </Popconfirm>
              :
              <Popconfirm
                key={`${category.cat_name}-${category.cat_id}`}
                placement="right"
                title="Activate category"
                okText="Yes"
                cancelText="No"
                onConfirm={() => handleCategoryDelete(category.cat_id, 1)}
              >
                <Chip
                  label={category.cat_name}
                  variant="outlined"
                  color="error"
                  // icon={<DeleteOutline />}
                  sx={{ whiteSpace: 'nowrap', fontWeight: 'bold' }}
                />
              </Popconfirm>
          )}
        </Stack>
      </Paper>

      {/* Stores with categories assigned */}
      <Datatable
        name='Categories Assigned to Stores'
        columns={StoreColumns}
        data={allStoreCatData && allStoreCatData}
      />
    </Box>
  )
}

export default ManageCategory
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import Datatable from '../../Datatable/Datatable';
// import { Button, Switch, Stack, Typography, styled, FormGroup } from "@mui/material";
import { Button } from "@mui/material";
import { NavLink } from 'react-router-dom';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { useDispatch, useSelector } from 'react-redux';
import { activateDeactivateDealsWeb, getAllDealsWebData } from '../../../redux/features/dealsSlice';
import { Popconfirm } from 'antd';


function ShowDeals() {
    const dispatch = useDispatch();
    const { allDealsWebData } = useSelector((state) => ({ ...state.deals }));

    const columns = [
        {
            name: "id",
            label: "Action",
            options: {
                customBodyRender: (id, tableMeta) => {
                    const dealData = {
                        id: id,
                        store_id: tableMeta.rowData[1],
                        store_name: tableMeta.rowData[2],
                        pid: tableMeta.rowData[3],
                        deal_url: tableMeta.rowData[4],
                        title: tableMeta.rowData[5],
                        description: tableMeta.rowData[6],
                        cashback_upto: tableMeta.rowData[7],
                        img_url: tableMeta.rowData[8],
                        actual_price: tableMeta.rowData[9],
                        disc_price: tableMeta.rowData[10],
                        start_date: tableMeta.rowData[12],
                        end_date: tableMeta.rowData[13],
                    }
                    return (
                        <NavLink to="/websitedata/add-deal" state={{ dealData }}>
                            <Button size="small" color="success" variant="outlined">
                                <strong>Edit</strong>
                            </Button>
                        </NavLink>
                    )
                },
                filter: true,
                sort: true,
            }
        },
        {
            name: "store_id",
            label: "Store ID",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "store_name",
            label: "Store Name",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "pid",
            label: "PID",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "deal_url",
            label: "Deal URL",
            options: {
                customBodyRender: (link) => {
                    return (
                        <a href={link} target="_blank" rel="noreferrer">{link}</a>
                    )
                },
                filter: true,
                sort: true,
            }
        },
        {
            name: "title",
            label: "Title",
            options: {
                customBodyRender: (title) => {
                    return <p style={{
                        margin: 0,
                        width: "200px",
                    }}>
                        {title}
                    </p>
                },
                filter: true,
                sort: true,
            }
        },
        {
            name: "description",
            label: "Description",
            options: {
                customBodyRender: (content) => {
                    return (<p style={{
                        margin: 0,
                        width: 300,
                        display: '-webkit-box',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 5,
                        overflow: 'hidden'
                    }
                    } >
                        {content}
                    </p>)
                },
                filter: true,
                sort: true,
            }
        },
        {
            name: "cashback_upto",
            label: "Cashback Upto",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "img_url",
            label: "Image url",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "actual_price",
            label: "Actual Price",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "disc_price",
            label: "Discounted Price",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: 'active',
            label: 'Status',
            options: {
                customBodyRender: (active, tableMeta) => {
                    const activationData = {
                        id: tableMeta.rowData[0],
                        store_id: tableMeta.rowData[1],
                        pid: tableMeta.rowData[3],
                        status: tableMeta.rowData[11] === 0 ? "activate" : "deactivate"
                    }
                    if (active)
                        return <Popconfirm
                            placement="topRight"
                            title="Deactivate Store"
                            description="Do you really want to deactivate the store?"
                            okText="Yes"
                            cancelText="No"
                            onConfirm={() => handleDealActivation(activationData)}
                        >
                            <Button color='error' size="small" variant="outlined" ><b>Deactivate</b></Button>
                        </Popconfirm>
                    else
                        return <Popconfirm
                            placement="topRight"
                            title="Activate Store"
                            description="Do you really want to activate the store?"
                            okText="Yes"
                            cancelText="No"
                            onConfirm={() => handleDealActivation(activationData)}
                        >
                            <Button color='success' size="small" variant="outlined" ><b>Activate</b></Button>
                        </Popconfirm>
                },
                filter: true,
                sort: true,
            }
        },
        {
            name: "start_date",
            label: "Start Date",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "end_date",
            label: "End Date",
            options: {
                filter: true,
                sort: true,
            }
        },
    ];


    const handleDealActivation = (data) => {
        dispatch(activateDeactivateDealsWeb(data))
        dispatch(getAllDealsWebData());
    }
    useEffect(() => {
        dispatch(getAllDealsWebData());
    }, []);

    return (
        <div>
            <NavLink to="/websitedata/add-deal">
                <Button variant="contained" color="success" startIcon={<AddBoxIcon />} sx={{ marginBottom: "15px" }}>
                    <strong>Add New Deal</strong>
                </Button>
            </NavLink>
            <Datatable
                name="Deals Data"
                columns={columns}
                data={allDealsWebData && allDealsWebData}
            />
        </div>
    )
}

export default ShowDeals
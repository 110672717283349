import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import * as adminApi from "../api/adminApi";

const initialState = {
    todoTaskData: [],
    taskStatusData: [],
    completetodoResult: [],
    upcomingResults: [],
    recurrenceResults: [],
    updatedResults: [],
    todoTaskAdminData: [],
    telegramMsgs:[],
    loading: false,
    error: "",
    success: "",
};




// =======================================todo task reminder===========================================
export const addTodoTaskReminder = createAsyncThunk(
    "admin/addtaskremind",
    async (formData, { rejectWithValue }) => {
        try {
            const resp = await adminApi.addTodoTaskRemind(formData);
            if (resp.data.code === 200) {
                message.success(resp.data.message ? resp.data.message : "added successfully")
                return resp.data.data;
            } else {
                message.error(resp.data.message ? resp.data.message : "Not added")

                return rejectWithValue(resp.data);
            }
        } catch (error) {
            rejectWithValue(error.resp.data);
        }
    }
);



export const updateTodoTaskReminder = createAsyncThunk(
    "admin/updatetaskremind",
    async (formData, { rejectWithValue }) => {
        try {
            const resp = await adminApi.updateTodoTaskRemind(formData);
            if (resp.data.code === 200) {
                message.success(resp.data.message ? resp.data.message : "Updated successfully")
                return resp.data.data;
            } else {
                message.error(resp.data.message ? resp.data.message : "Not Updated")

                return rejectWithValue(resp.data);
            }
        } catch (error) {
            rejectWithValue(error.resp.data);
        }
    }
);



export const deleteTodoTaskReminder = createAsyncThunk(
    "admin/deletetaskremind",
    async (formData, { rejectWithValue }) => {
        try {
            const resp = await adminApi.deleteTodoTaskRemind(formData);
            console.log(formData, "formData==========>");
            if (resp.data.code === 200) {
                message.success(resp.data.message ? resp.data.message : "Delete successfully")
                return resp.data.data;
            } else {
                message.error(resp.data.message ? resp.data.message : "Not deleted")

                return rejectWithValue(resp.data);
            }
        } catch (error) {
            rejectWithValue(error.resp.data);
        }
    }
);

export const updateTodoStatus = createAsyncThunk(
    "admin/updatetodostatus",
    async (formData, { rejectWithValue }) => {
        try {
            const resp = await adminApi.update_todoStatus(formData);
            if (resp.data.code === 200) {
                message.success(resp.data.message ? resp.data.message : "update status successfully")
                return resp.data.data;
            } else {
                message.error(resp.data.message ? resp.data.message : "Not updated")

                return rejectWithValue(resp.data);
            }
        } catch (error) {
            rejectWithValue(error.resp.data);
        }
    }
);



export const snoozeTodoTaskByUser = createAsyncThunk(
    "admin/snoozetodotask",
    async (formData, { rejectWithValue }) => {
        try {
            const resp = await adminApi.snooze_todoTaskByUser(formData);
            if (resp.data.code === 200) {
                message.success(resp.data.message ? resp.data.message : "snooze successfully")
                return resp.data.data;
            } else {
                message.error(resp.data.message ? resp.data.message : "Not snoozed")

                return rejectWithValue(resp.data);
            }
        } catch (error) {
            rejectWithValue(error.resp.data);
        }
    }
);


export const showTodoTaskByUsers = createAsyncThunk(
    "admin/showtodotask",
    async (_, { rejectWithValue }) => {
        try {
            const resp = await adminApi.show_todoTaskByUsers();
            if (resp.data.code === 200) {
                return resp.data;
            } else {
                return rejectWithValue(resp.data);
            }
        } catch (error) {
            rejectWithValue(error.resp.data);
        }
    }
);

export const showTodoTaskByAdmin = createAsyncThunk(
    "admin/showtaskbyadmin",
    async (_, { rejectWithValue }) => {
        try {
            const resp = await adminApi.showTodoTaskAdmin();
            if (resp.data.code === 200) {
                return resp.data.data;
            } else {
                return rejectWithValue(resp.data);
            }
        } catch (error) {
            rejectWithValue(error.resp.data);
        }
    }
);


// =====================================recurrence table data==================================
export const showRecurrenceTaskPanel = createAsyncThunk(
    "admin/showrecurrencetask",
    async (formData, { rejectWithValue }) => {
        try {
            const resp = await adminApi.showRecurrenceTask(formData);
            if (resp.data.code === 200) {
                return resp.data;
            } else {
                return rejectWithValue(resp.data);
            }
        } catch (error) {
            rejectWithValue(error.resp.data);
        }
    }
);





//======================================================= store with coupons data=====================================





const adminSlice = createSlice({
    name: "admin",
    initialState,

    extraReducers: (builder) => {
        ///================================= addTodoTaskReminder data===========================//////////////////////

        builder.addCase(addTodoTaskReminder.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(addTodoTaskReminder.fulfilled, (state, action) => {
            state.loading = false;
        });
        builder.addCase(addTodoTaskReminder.rejected, (state, action) => {
            state.error = action.payload;
        });
        ///================================= updateTodoTaskReminder data===========================//////////////////////

        builder.addCase(updateTodoTaskReminder.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(updateTodoTaskReminder.fulfilled, (state, action) => {
            state.loading = false;
            state.updatedResults = action.payload; //changes temporary
            // console.log(action.payload, "payload++")
        });
        builder.addCase(updateTodoTaskReminder.rejected, (state, action) => {
            state.error = action.payload;
        });
        ///================================= deleteTodoTaskReminder data===========================//////////////////////

        builder.addCase(deleteTodoTaskReminder.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(deleteTodoTaskReminder.fulfilled, (state, action) => {
            state.loading = false;
        });
        builder.addCase(deleteTodoTaskReminder.rejected, (state, action) => {
            state.error = action.payload;
        });
        ///================================= updateTodoStatus data===========================//////////////////////

        builder.addCase(updateTodoStatus.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(updateTodoStatus.fulfilled, (state, action) => {
            state.loading = false;
        });
        builder.addCase(updateTodoStatus.rejected, (state, action) => {
            state.error = action.payload;
        });
        ///================================= snoozeTodoTaskByUser data===========================//////////////////////

        builder.addCase(snoozeTodoTaskByUser.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(snoozeTodoTaskByUser.fulfilled, (state, action) => {
            state.loading = false;
        });
        builder.addCase(snoozeTodoTaskByUser.rejected, (state, action) => {
            state.error = action.payload;
        });

        ///=================================showTodoTaskByUsers data===========================//////////////////////

        builder.addCase(showTodoTaskByUsers.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(showTodoTaskByUsers.fulfilled, (state, action) => {
            state.todoTaskData = action.payload.data;
            state.taskStatusData = action.payload.todo_status;
            state.completetodoResult = action.payload.completetodoResult;
            state.upcomingResults = action.payload.upcomingResults;
        });
        builder.addCase(showTodoTaskByUsers.rejected, (state, action) => {
            state.error = action.payload;
        });

        ///=================================showtaskbyadmin data===========================//////////////////////

        builder.addCase(showTodoTaskByAdmin.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(showTodoTaskByAdmin.fulfilled, (state, action) => {
            state.todoTaskAdminData = action.payload;
            state.loading = false;
        });
        builder.addCase(showTodoTaskByAdmin.rejected, (state, action) => {
            state.error = action.payload;
        });


        // ==================================showRecurrenceTaskPanel data=================================
        builder.addCase(showRecurrenceTaskPanel.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(showRecurrenceTaskPanel.fulfilled, (state, action) => {
            state.recurrenceResults = action.payload.data;
            state.loading = false;
        });
        builder.addCase(showRecurrenceTaskPanel.rejected, (state, action) => {
            state.error = action.payload;
        });




        
    },
});
// export const { setCategories } = adminSlice.actions;
export default adminSlice.reducer;




















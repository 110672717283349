/* eslint-disable react-hooks/exhaustive-deps */
import { React, useEffect, useState } from 'react';
import {
    Table,
    TableCell,
    TableRow,
    Paper,
    Stack,
    Toolbar,
    Typography,
    Autocomplete,
    TextField,
    Button,
    InputAdornment,
    IconButton,
    TableBody,
    tableCellClasses,
} from '@mui/material';
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useDispatch, useSelector } from 'react-redux';
import { showRolesData, } from '../../redux/features/authSlice';
import { useAuth } from '../../context/authContext';

const CreateUser = () => {
    const dispatch = useDispatch()
    const { userRolesData } = useSelector((state) => state.userAuth)
    const { signupUser } = useAuth()
    // states
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        mobile: "",
        password: "",
        role: "",
    });

    const handleChange = (key) => (event) => {
        setFormData({ ...formData, [key]: event.target.value });
    };

    const handleSubmit = async () => {
        await signupUser(formData)
    }

    // password toggle
    const [showPassword, setShowPassword] = useState(false);
    // toggle password show
    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    // useEffects
    useEffect(() => {
        dispatch(showRolesData())
    }, []);

    return (
        <Paper elevation={3}>
            <form>
                <Stack spacing={3} direction="column">
                    <Toolbar
                        mt={3}
                        sx={{ padding: "1.2rem", borderBottom: "2px solid var(--primary-color)" }}
                    >
                        <Typography
                            sx={{
                                flex: "1 1 100%",
                                color: "var(--primary-color)",
                                fontWeight: "bold",
                            }}
                            variant="h5"
                            id="tableTitle"
                        >
                            Create New User
                        </Typography>
                    </Toolbar>
                    <Table
                        size="small"
                        sx={{
                            width: "auto",
                            margin: "8px !important",
                            marginTop: "16px !important",
                            [`& .${tableCellClasses.root}`]: {
                                borderBottom: "none",
                            },
                        }}
                    >
                        <TableBody>
                            <TableRow>
                                <TableCell>
                                    <Typography variant="h6">
                                        <b>Username</b>
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        size="small"
                                        fullWidth
                                        placeholder="*Enter name"
                                        onChange={handleChange("name")}
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Typography variant="h6">
                                        <b>Email</b>
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        size="small"
                                        fullWidth
                                        placeholder="*Enter email"
                                        onChange={handleChange("email")}
                                    />
                                </TableCell>
                            </TableRow>



                            <TableRow>
                                <TableCell>
                                    <Typography variant="h6">
                                        <b>Select Role</b>
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Autocomplete
                                        freeSolo
                                        size="small"
                                        id="user-combo-box"
                                        value={formData.role}
                                        options={userRolesData.map(item => item.user_type)}
                                        sx={{ width: 300 }}
                                        renderInput={(params) => (
                                            <TextField {...params} placeholder="*Select Role" />
                                        )}
                                        onChange={(e, val) => setFormData({ ...formData, role: val })}
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Typography variant="h6">
                                        <b>Mobile</b>
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        size="small"
                                        fullWidth
                                        placeholder="Enter mobile number"
                                        onChange={handleChange("mobile")}
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Typography variant="h6">
                                        <b>User Password</b>
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        placeholder="*Password"
                                        required
                                        size="small"
                                        type={showPassword ? "text" : "password"}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle-password-visibility"
                                                        onClick={handleClickShowPassword}
                                                        edge="end"
                                                    >
                                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                        onChange={handleChange("password")}
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell colSpan={2} className="text-center">
                                    <Button
                                        variant="contained"
                                        size="large"
                                        sx={{ marginTop: 2, width: '200px' }}
                                        onClick={handleSubmit}
                                    >
                                        <b>Create</b>
                                    </Button>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Stack>
            </form>
        </Paper>
    )
}

export default CreateUser
import React, { useState } from "react";
import {
  Box,
  Paper,
  Stack,
  Typography,
  TextField,
  InputAdornment,
  IconButton,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { NavLink, useNavigate } from "react-router-dom";
import { LoginSchema } from "../../validations/LoginValidation"
import { useAuth } from "../../context/authContext.js";

function Login() {
  const navigate = useNavigate();
  // antd message
  const { userData, isAuthenticate, login } = useAuth();

  // loginData
  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
  });

  // password toggle
  const [showPassword, setShowPassword] = useState(false);

  // handle value changes
  const handleChange = (prop) => (event) => {
    setLoginData({ ...loginData, [prop]: event.target.value });
  };

  // toggle password show
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  // Login handler
  const [loading, setLoading] = useState(false);
  const handleLogin = async (event) => {
    event.preventDefault();
    setLoading(true);
    await LoginSchema.validate(loginData)
      .then(async (value) => {
        await login(loginData)
      })
    setLoading(false);
    console.log("in handleLogin")
  };

  // render
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100vw",
        height: "100vh",
      }}
    >
      <Paper elevation={10} sx={{ borderRadius: "15px" }}>
        <Stack direction="row">
          <Box style={{ width: "400px", height: "inherit" }}>
            <img
              style={{
                display: "block",
                objectFit: "cover",
                height: "100%",
                width: "100%",
                borderRadius: "15px 0 0 15px",
              }}
              src="https://img.freepik.com/free-vector/geometric-wave-background-blue-color_343694-1288.jpg?w=996&t=st=1669959587~exp=1669960187~hmac=9ba6ec132be46eb2587372c3771f6a47dc733703fb67095f1a52ddfe404b51a5"
              alt="random"
            />
          </Box>
          <form onSubmit={handleLogin}>
            <Stack spacing={3} px={10} py={5}>
              <Typography variant="h4" align="center">
                Login
              </Typography>
              <Stack spacing={2}>
                <TextField
                  label="Email"
                  required
                  size="small"
                  value={loginData.email}
                  onChange={handleChange("email")}
                />

                <TextField
                  label="Password"
                  required
                  size="small"
                  type={showPassword ? "text" : "password"}
                  value={loginData.password}
                  onChange={handleChange("password")}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle-password-visibility"
                          onClick={handleClickShowPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <Typography variant="body2">
                  Don't have an account?{" "}
                  <NavLink to="/signup">
                    <strong>Signup</strong>
                  </NavLink>
                </Typography>
              </Stack>
              <Stack direction="row">
                <LoadingButton
                  variant="contained"
                  fullWidth
                  type="submit"
                  loading={loading}
                  disabled={
                    loginData.email.length > 0 && loginData.password.length > 0
                      ? false
                      : true
                  }
                >
                  Login
                </LoadingButton>
              </Stack>
            </Stack>
          </form>
        </Stack>
      </Paper>
    </Box>
  );
}

export default Login;

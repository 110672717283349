import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Paper,
  Stack,
  Typography,
  Toolbar,
  Box,
  Card,
  MenuItem,
  Select,
  FormControl,
  Button,
  Dialog,
  CardContent,
  Divider,
  DialogContent,
  DialogTitle
} from "@mui/material";
import { showTodoTaskByUsers, showTodoTaskByAdmin, updateTodoStatus, snoozeTodoTaskByUser, showRecurrenceTaskPanel } from "../../redux/features/adminSlice";
import { styled } from '@mui/material/styles';

const StyledBox = styled((props) => (
  <Box {...props} />
))(({ theme }) => ({
  padding: "2px 8px",
  borderRadius: "5px",
  height: "fit-content",
  '& *': {
    display: "inline-block",
  },
  '& .MuiTypography-root:first-of-type': {
    color: 'var(--primary-color)',
    opacity: '0.8'
  },
  '& .MuiTypography-root:nth-of-type(2)': {
    color: 'var(--bs-gray-700)'
  },
}));

function dateConverterwithoutTime(str) {
  var date = new Date(str),
    mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    day = ("0" + date.getDate()).slice(-2);
  let year = date.getFullYear();
  return `${year}-${mnth}-${day}`
};

const ShowTask = () => {
  const { todoTaskData, taskStatusData, completetodoResult, upcomingResults, recurrenceResults } = useSelector((state) => ({ ...state.admin }));
  const dispatch = useDispatch();
  // console.log(taskStatusData, "taskStatusData");
  // console.log(recurrenceResults, "recurrenceResults");

  useEffect(() => {
    dispatch(showTodoTaskByUsers());
  }, []);

  function dateConverter(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    let hours = ("0" + date.getHours()).slice(-2);
    let minutes = ("0" + date.getMinutes()).slice(-2);
    let seconds = ("0" + date.getSeconds()).slice(-2);
    let year = date.getFullYear();
    return `${year}-${mnth}-${day} ${hours}:${minutes}:${seconds}`
  };
  // console.log(todoTaskData, "todoTaskData");
  // const [status, setStatus] = useState();

  const handleChange = (event, val) => {
    // console.log('function called successfully')
    // console.log(event.target.value, val, "value");
    let data = {};
    data.id = val;
    data.status = event.target.value;
    // console.log(data, 'data');
    dispatch(updateTodoStatus(data));
    dispatch(showTodoTaskByUsers());
  };

  const handleSnooze = (val) => {
    // console.log("handle snooze working correctly", val);
    let modified_date = Date.now(val.reminder_date) + (3600 * 1000 * 24);
    let remidate = new Date(modified_date);
    let data = {};
    data.id = val.id;
    data.snooze_date = dateConverterwithoutTime(remidate);
    dispatch(snoozeTodoTaskByUser(data));
    dispatch(showTodoTaskByUsers());
  };

  // Dialog input
  const [dialogOpen, setDialogOpen] = useState(false);

  //state for dialog open 
  const [item, setItem] = useState({});
  

  const handleOpen = (val) => {
    setItem(val);
    setDialogOpen(true);
  };

  const handleClose = () => {
    setItem({});
    setDialogOpen(false);
  };

  return (
    <div style={{ display: 'flex', gap: "2%" }}>
      {/*==================================== show/current/recurrence task  ===================================================*/}
      <Paper elevation={3} sx={{ flex: 2 }}>
        <Stack spacing={3} direction="column">
          <Toolbar
            mt={3}
            sx={{ padding: "1.2rem", borderBottom: "2px solid var(--primary-color)" }}
          >
            <Typography
              sx={{
                color: "var(--primary-color)",
                fontWeight: "bold",
              }}
              variant="h5"
              id="tableTitle"
            >
              Current Task
            </Typography>
          </Toolbar>
          <Box p={4} pt={1} className='d-grid' sx={{ gridTemplateColumns: 'repeat(auto-fill,minmax(300px,1fr))', gap: '24px' }}>
            {todoTaskData && todoTaskData.map((item) => {
              if (item.status !== "Closed") {
                return (
                  <Card sx={{ minWidth: "fit-content", border: '2px solid var(--bs-border-color)' }} onClick={() => handleOpen(item)}>
                    <Table aria-label="assigned-task-table">
                      <TableBody>

                        <TableRow >
                          <TableCell>Assinged By:</TableCell>
                          <TableCell>
                            <Typography textTransform="capitalize">{item.name} (uid: {item.assigned_by})</Typography>
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell>Task Title:</TableCell>
                          <TableCell>{item.title}</TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell>Task Descriptions:</TableCell>
                          <TableCell>{item.description}</TableCell>
                        </TableRow>

                        {/* <TableRow>
                          <TableCell>Task ID:</TableCell>
                          <TableCell>{item.id}</TableCell>
                        </TableRow> */}

                        {/* <TableRow>
                          <TableCell>Task Reminder Date:</TableCell>
                          <TableCell>{item.reminder_date}</TableCell>
                        </TableRow> */}

                        {/* <TableRow>
                          <TableCell>Task Deadline Date:</TableCell>
                          <TableCell>{item.deadline_date}</TableCell>
                        </TableRow> */}

                        {/* <TableRow>
                          <TableCell>Status:</TableCell>
                          <TableCell sx={{ display: "flex", alignItems: 'center', justifyContent: "center" }}>
                            {item.status}
                            <FormControl>
                              <Select
                                size="small"
                                defaultValue={item.status}
                                style={{ marginLeft: "5px" }}
                                onChange={(e, val) => handleChange(e, item.id)}
                              >
                                {taskStatusData && taskStatusData.map(row => {
                                  return (
                                    <MenuItem value={row.id}>{row.status}</MenuItem>
                                  )
                                })}
                              </Select>
                            </FormControl>
                          </TableCell>
                        </TableRow> */}

                        {/* <TableRow>
                          <TableCell>
                            <Button
                              variant="contained"
                              size="small"
                              color="primary"
                              onClick={() => handleSnooze(item)}
                            >
                              <strong>Snooze</strong>
                            </Button>
                          </TableCell>
                        </TableRow> */}

                      </TableBody>
                    </Table>
                  </Card>
                )
              }
            })}
          </Box>
        </Stack>
      </Paper>

      {/* ===================================upcoming task ============================================= */}

      <Paper elevation={3} sx={{ flex: 2 }}>
        <Stack spacing={3} direction="column">
          <Toolbar
            mt={3}
            sx={{ padding: "1.2rem", borderBottom: "2px solid var(--primary-color)" }}
          >
            <Typography
              sx={{
                color: "var(--primary-color)",
                fontWeight: "bold",
              }}
              variant="h5"
              id="tableTitle"
            >
              Upcoming Task
            </Typography>
          </Toolbar>
          <Box p={4} pt={1} className='d-grid' sx={{ gridTemplateColumns: 'repeat(auto-fill,minmax(300px,1fr))', gap: '24px' }}>
            {upcomingResults && upcomingResults.map((item) => {
              if (item.status !== "Closed") {
                return (
                  <Card sx={{ minWidth: "fit-content", border: '2px solid var(--bs-border-color)' }}>
                    <Table aria-label="assigned-task-table">
                      <TableBody>

                        <TableRow >
                          <TableCell>Assinged By:</TableCell>
                          <TableCell>
                            <Typography textTransform="capitalize">{item.name} (uid: {item.assigned_by})</Typography>
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell>Task Title:</TableCell>
                          <TableCell>{item.title}</TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell>Task Descriptions:</TableCell>
                          <TableCell>{item.description}</TableCell>
                        </TableRow>

                        {/* <TableRow>
                          <TableCell>Task ID:</TableCell>
                          <TableCell>{item.id}</TableCell>
                        </TableRow> */}

                        <TableRow>
                          <TableCell>Task Reminder Date:</TableCell>
                          <TableCell>{dateConverterwithoutTime(item.reminder_date)}</TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell>Task Deadline Date:</TableCell>
                          <TableCell>{dateConverterwithoutTime(item.deadline_date)}</TableCell>
                        </TableRow>

                        {/* <TableRow>
                          <TableCell>Status:</TableCell>
                          <TableCell sx={{ display: "flex", alignItems: 'center', justifyContent: "center" }}>
                            {item.status}
                            <FormControl>
                              <Select
                                size="small"
                                defaultValue={item.status}
                                style={{ marginLeft: "5px" }}
                                onChange={(e, val) => handleChange(e, item.id)}
                              >
                                {taskStatusData && taskStatusData.map(row => {
                                  return (
                                    <MenuItem value={row.id}>{row.status}</MenuItem>
                                  )
                                })}
                              </Select>
                            </FormControl>
                          </TableCell>
                        </TableRow> */}

                        {/* <TableRow>
                          <TableCell>
                            <Button
                              variant="contained"
                              size="small"
                              color="primary"
                              onClick={(val) => (handleSnooze(item.reminder_date))}
                            >
                              <strong>Snooze</strong>
                            </Button>
                          </TableCell>
                        </TableRow> */}

                      </TableBody>
                    </Table>
                  </Card>
                )
              }
            })}
          </Box>
        </Stack>
      </Paper>

      {/* ==================================completed task =============================================== */}
      <Paper elevation={3} sx={{ flex: 1 }}>
        <Stack spacing={3} direction="column" >
          <Toolbar
            mt={3}
            sx={{ padding: "1.2rem", borderBottom: "2px solid var(--primary-color)" }}
          >
            <Typography
              sx={{
                color: "var(--primary-color)",
                fontWeight: "bold",
              }}
              variant="h5"
              id="tableTitle"
            >
              Completed Task
            </Typography>
          </Toolbar>
          <Box p={4} pt={1} className='d-grid' sx={{ gridTemplateColumns: 'repeat(auto-fill,minmax(300px,1fr))', gap: '24px' }}>
            {completetodoResult && completetodoResult.map((item) => {
              return (
                <Card sx={{ minWidth: "fit-content", border: '2px solid var(--bs-border-color)', flex: "auto", width: "100%" }}>
                  <Table aria-label="assigned-task-table">
                    <TableBody>

                      <TableRow>
                        <TableCell>Assinged By:</TableCell>
                        <TableCell>
                          <Typography textTransform="capitalize">{item.name} (uid: {item.assigned_by})</Typography>
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>Task Title:</TableCell>
                        <TableCell>{item.title}</TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>Task ID:</TableCell>
                        <TableCell>{item.id}</TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>Task Deadline Date:</TableCell>
                        <TableCell>{dateConverterwithoutTime(item.deadline_date)}</TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>Status:</TableCell>
                        <TableCell>
                          {item.status}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Card>
              )
            })}
          </Box>
        </Stack>
      </Paper>

      {/* ===================================================Dialog Box for current task=================================================== */}
      <Dialog open={dialogOpen} onClose={handleClose}>
        <DialogTitle>
          <b>Current Task</b>
        </DialogTitle>
        <Divider sx={{ borderColor: "black" }} />
        <DialogContent>
          <Card sx={{ minWidth: "fit-content", border: '2px solid var(--bs-border-color)' }} onClick={() => handleOpen(item)}>
            <CardContent>
              <Stack spacing={1}>
                <StyledBox>
                  <Typography >
                    <b>Assinged By :&nbsp;</b>
                  </Typography>
                  <Typography textTransform="capitalize">
                    <b>{item.name && item.name} ({item.assigned_by && item.assigned_by})</b>
                  </Typography>
                </StyledBox>
                <StyledBox>
                  <Typography >
                    <b>Title :&nbsp;</b>
                  </Typography>
                  <Typography textTransform='capitalize'>
                    <b>{item.title && item.title}</b>
                  </Typography>
                </StyledBox>
                <StyledBox>
                  <Typography >
                    <b>ID :&nbsp;</b>
                  </Typography>
                  <Typography >
                    <b>{item.id && item.id}</b>
                  </Typography>
                </StyledBox>
                <StyledBox>
                  <Typography >
                    <b>Description :&nbsp;</b>
                  </Typography>
                  <Typography >
                    <b>{item.description && item.description}</b>
                  </Typography>
                </StyledBox>
                <StyledBox>
                  <Typography >
                    <b>Reminder Date :&nbsp;</b>
                  </Typography>
                  <Typography >
                    <b>{dateConverterwithoutTime(item.reminder_date && item.reminder_date)}</b>
                  </Typography>
                </StyledBox>

                <StyledBox>
                  <Typography >
                    <b>Deadline Date :&nbsp;</b>
                  </Typography>
                  <Typography >
                    <b>{dateConverterwithoutTime(item.deadline_date && item.deadline_date)}</b>
                  </Typography>
                </StyledBox>

                <StyledBox>
                  <Typography >
                    <b>Status :&nbsp;</b>
                  </Typography>
                  <Typography textTransform="capitalize">
                    {item.status}
                    <FormControl>
                      <Select
                        size="small"
                        defaultValue={item.status}
                        style={{ marginLeft: "5px" }}
                        onChange={(e, val) => handleChange(e, item.id)}
                      >
                        {taskStatusData && taskStatusData.map(row => {
                          return (
                            <MenuItem value={row.id}>{row.status}</MenuItem>
                          )
                        })}
                      </Select>
                    </FormControl>
                  </Typography>
                </StyledBox>

                <StyledBox>
                  <Button variant="contained" onClick={() => handleSnooze(item)} color='info' >
                    <strong>Snooze Task Reminder</strong>
                  </Button>
                </StyledBox>
              </Stack>
            </CardContent>
          </Card>
        </DialogContent>
      </Dialog>

      {/* ===================================Dialog Box for recurrence task currently pending====================================================== */}
      {/* <Dialog open={dialogOpenRecurrence} onClose={handleCloseRecurrence}>
        <DialogTitle>
          <b>Recurrence Task</b>
        </DialogTitle>
        <Divider sx={{ borderColor: "black" }} />
        <DialogContent>
          <Card sx={{ minWidth: "fit-content", border: '2px solid var(--bs-border-color)' }} onClick={() => handleOpenRecurrence(itemRecurrence)}>
            <CardContent>
              <Stack spacing={1}>
                <StyledBox>
                  <Typography >
                    <b>Assinged By :&nbsp;</b>
                  </Typography>
                  <Typography textTransform="capitalize">
                    <b>{item.name && item.name} ({item.assigned_by && item.assigned_by})</b>
                  </Typography>
                </StyledBox>
                <StyledBox>
                  <Typography >
                    <b>Title :&nbsp;</b>
                  </Typography>
                  <Typography textTransform='capitalize'>
                    <b>{item.title && item.title}</b>
                  </Typography>
                </StyledBox>
                <StyledBox>
                  <Typography >
                    <b>ID :&nbsp;</b>
                  </Typography>
                  <Typography >
                    <b>{item.id && item.id}</b>
                  </Typography>
                </StyledBox>

                <StyledBox>
                  <Typography >
                    <b>Deadline Days :&nbsp;</b>
                  </Typography>
                  <Typography >
                    <b>{item.deadline_days && item.deadline_days}</b>
                  </Typography>
                </StyledBox>

                <StyledBox>
                  <Typography >
                    <b>Description :&nbsp;</b>
                  </Typography>
                  <Typography >
                    <b>{item.description && item.description}</b>
                  </Typography>
                </StyledBox>

                <StyledBox>
                  <Typography >
                    <b>Assigned To :&nbsp;</b>
                  </Typography>
                  <Typography >
                    <b>{item.assigned_to && item.assigned_to}</b>
                  </Typography>
                </StyledBox>

                <StyledBox>
                  <Typography >
                    <b>Created At :&nbsp;</b>
                  </Typography>
                  <Typography >
                    <b>{dateConverterwithoutTime(item.created_at) && dateConverterwithoutTime(item.created_at)}</b>
                  </Typography>
                </StyledBox>

                <StyledBox>
                  <Typography >
                    <b>Active :&nbsp;</b>
                  </Typography>
                  <Typography >
                    <b>{item.active && item.active}</b>
                  </Typography>
                </StyledBox>

                <StyledBox>
                  <Typography >
                    <b>Recurrence :&nbsp;</b>
                  </Typography>
                  <Typography >
                    <b>{item.recurrence && item.recurrence}</b>
                  </Typography>
                </StyledBox>

                <StyledBox>
                  <Typography >
                    <b>Team Id :&nbsp;</b>
                  </Typography>
                  <Typography >
                    <b>{item.team_id && item.team_id}</b>
                  </Typography>
                </StyledBox>

                <StyledBox>
                  <Typography >
                    <b>Status :&nbsp;</b>
                  </Typography>
                  <Typography textTransform="capitalize">
                    {item.status}
                    <FormControl>
                      <Select
                        size="small"
                        defaultValue={item.status}
                        style={{ marginLeft: "5px" }}
                        onChange={(e, val) => handleChange(e, item.id)}
                      >
                        {taskStatusData && taskStatusData.map(row => {
                          return (
                            <MenuItem value={row.id}>{row.status}</MenuItem>
                          )
                        })}
                      </Select>
                    </FormControl>
                  </Typography>
                </StyledBox>

                <StyledBox>
                  <Button variant="contained" onClick={() => handleSnooze(item)} color='info' >
                    <strong>Snooze Task Reminder</strong>
                  </Button>
                </StyledBox>
              </Stack>
            </CardContent>
          </Card>
        </DialogContent>
      </Dialog> */}

    </div>
  )
}

export default ShowTask;